.btn-totop {
	font-family: kreon_r;
	margin-top: 5px;
	letter-spacing: 2px;
	right: 35px;
	text-align: center;
	font-size: 12px;
	z-index: 10;
	p {
		margin-top: 5px;
	}
	a{
		color: #000;
	}
	&-img {
		background:resolve('btn-return-top.png') no-repeat left top;
		background-size: size('btn-return-top.png',2);
		width:width('btn-return-top.png',2);
		height:height('btn-return-top.png',2);
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	&:hover {
		.btn-totop-img {
			background:resolve('animation-loading.gif') no-repeat left top;
			background-size: size('animation-loading.gif',2);
			width:width('animation-loading.gif',2);
			height:height('animation-loading.gif',2);
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.active {
		.btn-totop-img {
			background:resolve('pagetop.gif') no-repeat left top;
			background-size: size('pagetop.gif',2);
			width:width('pagetop.gif',2);
			height:height('pagetop.gif',2);
		}
	}
}

.totop-sp {
	@extend .btn-totop;
}

@include breakpoint(sp) {
	.btn-totop {
		left: 0;
		right: 0;
		font-size: 12px;
		z-index: 10;
		text-align: center;
		display: none!important;
	}
	.totop-sp {
		display: block!important;
		margin-top: 60px;
		&:hover {
			.btn-totop-img {
				background:resolve('btn-return-top.png') no-repeat left top;
				background-size: size('btn-return-top.png');
				width:width('btn-return-top.png');
				height:height('btn-return-top.png');
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.active {
			.btn-totop-img {
				background:resolve('btn-return-top.png') no-repeat left top;
				background-size: size('btn-return-top.png');
				width:width('btn-return-top.png');
				height:height('btn-return-top.png');
			}
		}
	}
}
