.page-header {
	max-width: 1040px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	text-align: center;
	position: relative;
	margin-top: 30px;
	background-color: #fff;
	& + * {
		margin-top: 80px;
	}
	&.mod-yomimono-main-column{
		margin-top: 0;
	}
	&-title {
		padding: 23px 10px;
		color: #1c1f21;
		line-height: 1;
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 2px;
		&.mod-bird {
			&:after {
				content:"";
				background:resolve('mascot-bird.png') no-repeat left top;
				background-size: size('mascot-bird.png',2);
				width:width('mascot-bird.png',2);
				height:height('mascot-bird.png',2);
				display: block;
				position: absolute;
				right: 69.5%;
				top: 63%;
			}
		}
		&.mod-butterfly {
			&:after {
				content:"";
				background:resolve('mascot-butterfly.png') no-repeat left top;
				background-size: size('mascot-butterfly.png',2);
				width:width('mascot-butterfly.png',2);
				height:height('mascot-butterfly.png',2);
				display: block;
				position: absolute;
				right: 75.1%;
				top: 63%;
			}
		}
		&.mod-snake{
			&:after {
				content:"";
				background:resolve('mascot-snake.png') no-repeat left top;
				background-size: size('mascot-snake.png');
				width:width('mascot-snake.png');
				height:height('mascot-snake.png');
				display: block;
				position: absolute;
				right: 181px;
				bottom: 2px;
			}
		}

		&.mod-grasshopper{
			&:after {
				content:"";
				background:resolve('mascot-grasshopper.png') no-repeat left top;
				background-size: size('mascot-grasshopper.png',2);
				width:width('mascot-grasshopper.png',2);
				height:height('mascot-grasshopper.png',2);
				display: block;
				position: absolute;
				right: 27%;
				bottom: 2px;
			}
		}
		&.mod-flog {
			&:after {
				content:"";
				background:resolve('mascot-flog.png') no-repeat left top;
				background-size: size('mascot-flog.png',2);
				width:width('mascot-flog.png',2);
				height:height('mascot-flog.png',2);
				display: block;
				position: absolute;
				right: 289px;
				bottom: -1px;
			}
		}
		&.mod-duck {
			&:after {
				content:"";
				background:resolve('mascot-duck.png') no-repeat left top;
				background-size: size('mascot-duck.png',2);
				width:width('mascot-duck.png',2);
				height:height('mascot-duck.png',2);
				display: block;
				position: absolute;
				left: 287px;
				bottom: 2px;
			}
		}
		&.mod-dragonfly {
			&:after {
				content:"";
				background:resolve('mascot-dragonfly.png') no-repeat left top;
				background-size: size('mascot-dragonfly.png',2);
				width:width('mascot-dragonfly.png',2);
				height:height('mascot-dragonfly.png',2);
				display: block;
				position: absolute;
				right: 288px;
				bottom: -3px;
			}
		}
		&.mod-snails {
			&:after {
				content:"";
				background:resolve('mascot-snails.png') no-repeat left top;
				background-size: size('mascot-snails.png',2);
				width:width('mascot-snails.png',2);
				height:height('mascot-snails.png',2);
				display: block;
				position: absolute;
				right: 164px;
				bottom: 1px;
			}
		}
		&.mod-snails-s {
			&:after {
				content:"";
				background:resolve('mascot-snails-s.png') no-repeat left top;
				background-size: size('mascot-snails-s.png',2);
				width:width('mascot-snails-s.png',2);
				height:height('mascot-snails-s.png',2);
				display: block;
				position: absolute;
				right: 292px;
				bottom: 1px;
			}
		}
		&.mod-snails-ss {
			&:after {
				content:"";
				background:resolve('mascot-snails-s.png') no-repeat left top;
				background-size: size('mascot-snails-s.png',2);
				width:width('mascot-snails-s.png',2);
				height:height('mascot-snails-s.png',2);
				display: block;
				position: absolute;
				right: 377px;
				bottom: 1px;
			}
		}
		&.has-thumb{
			padding:13px 10px;
		}
		&-thumb{
			height:41px;
			width: auto;
			margin-right: 30px;
			vertical-align: middle;
		}
	}
	&:before,&:after {
		content:"";
		max-width: 100%;
		background:resolve('line-lower_header.png') no-repeat left top;
		background-size: size('line-lower_header.png');
		width:width('line-lower_header.png');
		height:height('line-lower_header.png');
		display: block;
	}
}
@include breakpoint(sp) {
	.page-header {
		margin-top: 0;
		&-title {
			font-size: 15px;
			line-height: (48 / 30);
			padding: 21px 10px;
			&:after {
				display: none!important;
			}
		}
		& + * {
			margin-top: 40px;
		}
		&-title {
			&.has-thumb{
				display: flex;
				justify-content:center;
				align-items:center;
				span {
					display: block;
					text-align: center;
					line-height: 1.8;
					&.pc {
						display: none;
					}
				}
			}
			&-thumb{
				height:30px;
				width: auto;
				margin-right: 20px;
			}
		}
	}
}
