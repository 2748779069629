.c-btn-line {
	display: inline-block;
	background: resolve("img_see_more_link.png") no-repeat center;
	background-size: size("img_see_more_link.png", 2);
	width: width("img_see_more_link.png", 2);
	height: height("img_see_more_link.png", 2);

	text-indent: 300%;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	justify-content: center;
	margin: 0 auto;
	&.mod-yomimono-new{
		margin-top: 40px;
	}
	&.sp{
		display: none;
	}
	&.mod-small{
		background: resolve("img_see_more_recipe_link.png") no-repeat
		center;
		background-size: size("img_see_more_recipe_link.png", 2);
		width: width("img_see_more_recipe_link.png", 2);
		height: height("img_see_more_recipe_link.png", 2);
	}
}
@include breakpoint(sp) {
	.c-btn-line {
		background-size: size("img_see_more_link.png", 3.3);
		width: width("img_see_more_link.png", 3.3);
		height: height("img_see_more_link.png", 3.3);

		&.pc{
			display: none;
		}
		&.sp{
			display: block;
		}
		&.mod-yomimono-new{
			margin-top: 25px;
		}
		&.mod-small{
			background-size: size("img_see_more_link.png", 3.3);
			width: width("img_see_more_link.png", 3.3);
			height: height("img_see_more_link.png", 3.3);

		}
	}
}
