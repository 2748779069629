.sp-navi-shop {
	.spnavi-inner .header-spnavi-logo {
		background:resolve('logo-sp-navi-shop.png') no-repeat left top;
		background-size: size('logo-sp-navi-shop.png',2);
		width:width('logo-sp-navi-shop.png',2);
		height:height('logo-sp-navi-shop.png',2);
	}
	a {
		color:$black;
		display: block;
	}
	&-link {
		margin-top: 15px;
		padding-left: 5px;
		padding-right: 5px;
	}
	&-link-item {
		font-size: 15px;
		letter-spacing: .1em;
		line-height: (70 / 30);
		&.mod-squere {
			a:before {
				content:"";
				display: inline-block;
				width: 5px;
				height: 5px;
				background-color: $black;
				vertical-align: 2px;
				margin-right: 9px;
			}
		}
	}
	.banner-wrap.mod-shop > * {
		width: 100%;
		padding-top: percentage(82/296);
	}
}
.footer-spnavi-shop {
	margin-top: 15px;
	padding-bottom: 15px;
	display: flex;
	justify-content:center;
	flex-wrap:wrap;
	align-items:center;
	& > * {
		margin-left: 15px;
		margin-right: 15px;
	}
	.spnavi-status-icon-item {
		margin-left: 0;
		margin-right: 0;
	}
	.lang-btn {
		border-right:1px solid $black;
	}
}
.spnavi-status-icon {
	$c:&;
	display: flex;
	justify-content:center;
	flex-wrap:wrap;
	align-items:flex-end;
	margin-top: 30px;
	&-item {
		margin-left: 10px;
		margin-right: 10px;
		position: relative;
		@extend .font-kreon_r;
		font-size: 12px;
		.text {
			letter-spacing: 0.05em;
			text-align: center;
			display: block;
			line-height: 1;
		}
		.num {
			position: absolute;
			top: -5px;
			left: -6px;
			display: block;
			color: #fff;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background-color: #474b42;
			text-align: center;
			line-height: 16px;
		}
		&:before {
			content:"";
			display: block;
			margin-bottom: 8px;
			margin-left: auto;
			margin-right: auto;
		}
		&.mod-login {
			&:before {
				background:resolve('icon_sp_mypage.png') no-repeat left top;
				background-size: size('icon_sp_mypage.png',2);
				width:width('icon_sp_mypage.png',2);
				height:height('icon_sp_mypage.png',2);
			}
		}
		&.mod-mypage {
			&:before {
				background:resolve('icon_sp_mypage.png') no-repeat left top;
				background-size: size('icon_sp_mypage.png',2);
				width:width('icon_sp_mypage.png',2);
				height:height('icon_sp_mypage.png',2);
			}
		}
		&.mod-search {
			&:before {
				background:resolve('icon_sp_search.png') no-repeat left top;
				background-size: size('icon_sp_search.png',2);
				width:width('icon_sp_search.png',2);
				height:height('icon_sp_search.png',2);
			}
		}
		&.mod-cart {
			&:before {
				background:resolve('icon_sp_cart.png') no-repeat left top;
				background-size: size('icon_sp_cart.png',2);
				width:width('icon_sp_cart.png',2);
				height:height('icon_sp_cart.png',2);
			}
		}
		&.mod-logout {
			letter-spacing: .1em;
			&:before {
				background:resolve('icon_sp_logout.png') no-repeat left top;
				background-size: size('icon_sp_logout.png',2);//retinaは第二引数に2
				width:width('icon_sp_logout.png',2);
				height:height('icon_sp_logout.png',2);
				display: inline-block;
				margin-bottom: 0;
				vertical-align: -5px;
				margin-right: 10px;
			}
		}
		&.mod-like {
			&:before {
				background:resolve('icon-heart.png') no-repeat 0 0;
			  	background-size: 22px 20.5px;
			  	width: 29px;
			  	height: 22.5px;
			}
		}
	}
}
@include breakpoint(sp) {
}

// icon_sp_cart.png
// icon_sp_mypage.png
// icon_sp_search.png
// logo-sp-navi-shop.png
