.wp-block-button{

    &.link{
        display: inline-block;
        margin-right: 7px;
        @include breakpoint(sp){
            display: inline-block;
            width: fit-content;
        }
        a.wp-block-button__link{
            min-width: 214px;
            background-color: #4f6537 !important;
            border-radius: 0;
            padding: 11px 40px 11px 20px;
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            position: relative;
            display: flex;
            color: #fff;
            justify-content: center;
            align-items: center;
            border-radius: 0 !important;
            &::after{
                content: "";
                width: 6px;
                position: absolute;
                right: 14px;
                border-top: 6px solid transparent;
                border-left: 6px solid #fff;
                border-bottom: 6px solid transparent;
                display: block;
            }
            @include breakpoint(sp){
                width: 100%;
                min-width: unset;
            }
        }
    }
        
    &.link_anchor{
        margin-top: 0;
        margin-right: 10px;
        margin-left: 0 !important;
        margin-bottom: 0.75em;

        a.wp-block-button__link{
            background-color: #4f6537 !important;
            font-size: 14px;
            font-weight: 700;
            color: #fff !important;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 5px 40px;
            border-radius: 0 !important;
            @include breakpoint(sp){
                padding: 5px 30px;
                padding-left: 20px;  
            }
    
            &::after{
                content: "";
                position: absolute;
                right: 14px;
                border-top: 6px solid #fff;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                display: block;
                @include breakpoint(sp){
                    border-top: 5px solid #fff;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent
                }
            }
        }  
        @include breakpoint(sp){
            margin-top: 0px !important;
            min-width: 48%;
            margin-left: 0px;
            margin-right: 0;
        }
    }

    &.white{
        a.wp-block-button__link{
            font-size: 13px;
            font-weight: 700;
            color: #000 !important;
            min-width: 180px;
            padding: 0 20px;
            height: 40px;
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            text-align: center;
            line-height: 40px;
            display: block;
            position: relative;
            letter-spacing: 1.3px;
            transition: all .6s;
            text-decoration: none;
            background-color: #fff !important;
            width: fit-content;
            margin: 0 auto;
            margin-top: 60px;
            border-radius: 0 !important; 

            &::after{
                transition: all .6s;
                content: "";
                position: absolute;
                top: 50%;
                right: -20px;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                background: url(../images/arrow_right_link@2x.png) no-repeat 0 0;
                background-size: 29px 9px;
                width: 29px;
                height: 9px;
                background-color: #fff;
            }
            &:hover::after{
                right: -25px !important;
                transition: all .6s ease !important;
            }
        }
    }
}

// テスト環境のリンクボタンWrapの為のスタイル
.wp-block-buttons.link_anchors{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include breakpoint(sp){
        display: flex !important;
        flex-wrap: wrap !important;
        align-items: center !important;
        justify-content: space-between !important;
    }
}

