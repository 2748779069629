.gnavi-scl{
	margin-top: 0px;
	line-height: 1;
	list-style: none;
	&-list{
		&-item{
			display: inline-block;
			position: relative;
			font-size: 15px;
			font-weight: 700;
			padding: 33px 0 32px 18px;
			letter-spacing: 1.5px;
		}
		&-item{
			$c:&;
			& + #{$c}{
				margin-left:15px;
			}
			&.mod-kreon{
				font-family: kreon_r;
				font-size: 17px;
				letter-spacing: 1.7px;
				font-weight: 500;
			}
		}
		&-child{
			font-size: 12px;
			position: absolute;
			padding-top: 11px;
			padding-bottom: 5px;
			top: 61px;
			left: 0px;
			right: 11px;
			color: #68603e;
			width: 185px;
			border-bottom-width: 0;
			border-right: 1px solid #68603e;
			border-bottom: 1px solid #68603e;
			background-color: #fff;
			z-index: 10;
			opacity: 0.8;
			display: none;
			&.mod-kreon{
				font-family: kreon_r;
			}
			&-item{
				position: relative;
				line-height: 26px;
				padding-left: 24px;
				margin-top: -1px;
				letter-spacing: 1px;
				&:hover{
					background-image: resolve('sub-navi-hover.png');
					background-repeat: no-repeat;
					background-position: 10px;
				}
			}
			& a{
				color: #68603e !important;
			}
		}
	}
	& a{
		color: #000;
	}
}

