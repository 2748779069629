.contents-idea{
	position: relative;
	margin-top: 160px;
	&-lead{
		position: absolute;
		height: 408px;
		top: -77px;
		right: 0;
		background-color: rgba(255,255,255,0.8);
		&-title{
			margin-top: 34px;
			margin-left: 50px;
		}
		&-text{
			margin-top: 30px;
			margin-left: 52px;
		}
	}
}

@include breakpoint(sp) {
	.contents-idea{
	position: relative;
	margin-top: 160px;
	&-lead{
		position: relative;
		height: inherit;
		top: 0;
		right: 0;
		background-color: rgba(255,255,255,0.8);
		padding: 0 20px;
		&-title{
			margin-top: 34px;
			margin-left: 0px;
		}
		&-text{
			margin-top: 30px;
			margin-left: 0px;
		}
	}
}
}
