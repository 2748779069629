// # text adjust
$-webkit-text-size-adjust : false;

// # text
$base_font_size:16px;
//$font_size:100%;
$line_height:1.5;
$font_color:#1c1f21;
$-webkit-tap-highlight:false;

$black:#1c1f21;
$green:#4f6537;
$red:red;
.green {
	color:$green;
}
.red {
	color:$red;
}

// link
$link_color:$green;
$link_hover_color:$green;

// BREAKPOINT
// --------------------
$breakpoints:(
	exsp:321px,
    sp: 768px,
    tb: 960px,
);

/* ===============================================
garamond typekit font
.font-garamond
or
.tk-adobe-garamond-pro
=============================================== */

//font-color

$brown: #68603e;
$green: #4f6537;
$black: #1c1f21;
