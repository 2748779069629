.topics-category{
	&_inner{
		// max-width: 1040px;
		margin: 52px auto 0px;
		font-size: 12px;
		// width: 81%;
	}
	&-items{
		display: flex;
		flex-wrap: wrap;

	}
	&-item{
		border-right: 1px solid #000;
		padding-right: 15px;
		line-height: 1;
		letter-spacing: .04em;
		margin-bottom: 16px;
		&:not(:first-child){
			padding-left: 15px;
		}
		a{
			color: #000;
			font-weight: 700;
			text-decoration: underline;
		}
	}
}
@include breakpoint(sp) {
	.topics-category{
		&_inner{
			width: 81%;
			margin-top: 40px;
			margin-bottom: 7px;
		}
		&-item{
			margin-bottom: 18px;
		}


	}
}