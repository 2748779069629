.faq{
	.box-triangle{
		position: relative;
		width: 100%;
		background-color: #f8f7f2;
		padding: 50px 110px;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			color: #c3c0b4;
			border-top: 20px solid;
			border-right: 20px solid transparent;
		}
		& + .box-triangle{
			margin-top: 75px;
		}
		h3 {
			font-size: 20px;
			letter-spacing: 3px;
			padding: 0 0 25px;
			color: #675f3e;
			text-align: center;
			&.mod-green{
				color: #4f6537;
			}
		}
		.accordion-title{
			font-size: 14px;
			font-weight: bold;
			color: #68603e;
			letter-spacing: 0px;
			position: relative;
			border-bottom: solid 1px;
			padding: 7px 67px 9px 70px;
			cursor: pointer;
			&:after{
				content: "";
				position: absolute;
				top: 35%;
				right: 19px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 7.5px 0;
				border-color: #c3c0b4 transparent transparent;
			}
			&.active{
				&:after{
				content: "";
				position: absolute;
				top: 35%;
				right: 19px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7.5px 10px;
				border-color: transparent transparent #c3c0b4  transparent;
				}
			}
			&.mod-green{
				color: #4f6537;
			}
			.list_title{
				position: absolute;
				top: 0px;
				left: 13px;
				font-size: 20px;
				font-weight: normal;
				@extend .font-kreon_r;
				letter-spacing: 2px;
			}
		}//.accordion-title
		.accordion-text{
			position: relative;
			display: none;
			background-color: #fff;
			font-size: 13px;
			padding: 14px 70px;
			line-height: 2;
			& + .accordion-title{
				margin-top: 13px;
			}
			.list_title{
				font-size: 20px;
				font-weight: normal;
				color: #68603e;
				position: absolute;
				top: 8px;
				left: 18px;
				@extend .font-kreon_r;
				letter-spacing: 2px;
				&.mod-green{
					color: #4f6537;
				}
			}
			.list_header{
				padding-left: 15px;
				background-position: 0px 10px;
			}
			&_ext-img{
					background:resolve('accordion-text_ext.png') no-repeat left top;
					background-size: size('accordion-text_ext.png',2);
					width:width('accordion-text_ext.png',2);
					height:height('accordion-text_ext.png',2);
					display: block;
					position: absolute;
					top: 20px;
					right: 70px;
			}
			&_caution{
				margin-top: 20px;
			}
		}//accordion-text
	}// End .box-triangle
	&_footer_text{
		font-size: 16px;
		font-weight: bold;
		font-style: italic;
		color: #68603e;
		width: 100%;
		text-align: center;
		margin-top: 55px;
		&_border{
			color: #68603e;
			border-bottom: solid 1px #96927f;
			padding-bottom: 2px;
		}
	}

}
@include breakpoint(sp) {
	.faq{
		padding: 0 15px;
		.box-triangle{
			position: relative;
			width: 100%;
			background-color: #f8f7f2;
			padding: 30px 15px;
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				color: #c3c0b4;
				border-top: 20px solid;
				border-right: 20px solid transparent;
			}
			& + .box-triangle{
				margin-top: 50px;
			}
			h3 {
				font-size: 14px;
				letter-spacing: 1.4px;
				padding:0 0px 15px;
			}
			.accordion-title{
				font-size: 13px;
				padding: 7px 37px 9px 43px;
				&:after{
					right: 5px;
				}
				.list_title{
					top: 5px;
					margin-right: 15px;
					font-size: 14px;
				}
			}//.accordion-title
			.accordion-text{
				padding: 15px 25px;
				margin-top: 25px;
				& + .accordion-title{
				}
				.list_title{
					position: relative;
					top: 0px;
					left: 0px;
				}
				&_ext-img{
					background:resolve('accordion-text_ext.png') no-repeat left top;
					background-size: size('accordion-text_ext.png',2);
					width:width('accordion-text_ext.png',2);
					height:height('accordion-text_ext.png',2);
					display: block;
					position: relative;
					margin-top: 25px;
					margin-left: auto;
					margin-right: auto;
					top: 0px;
					right: 0px;
				}
			}//accordion-text
		}
	}
}
