// カテゴリリニューアルで使用するCSS集 [EC-1788]
// オンラインショップ・カート共通

.sp-navi-shop .spnavi-inner .parent .group-category-list li:before {
	content: none;
}
.sp-navi-shop .spnavi-inner .parent.new-category-group li:before {
	content: none;
}

li.group-category-list > ul.product-category-list > li {
	padding-top: 16px;
}

.spnavi-inner .parent .group-category-list li + * {
	margin-top: 0;
}

.group-always-container-pc-nav {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	.group-always-container-pc-nav-item {
		width: 100%;
	}

	.group-always-container-pc-nav-link {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		padding: 6px 0 6px 8px;
		width: 100%;
		height: 100%;

		span {
			font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic",
				sans-serif;
			font-size: 14px;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: 1px;
			text-align: left;
			color: rgba(28, 31, 31, 1);
		}
	}
}

.group-category-container-pc-nav {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.group-category-container-pc-nav-title {
		color: rgba(79, 101, 55, 1);
		font-size: 13px;
		text-align: left;
		font-family: YuGothic;
		font-weight: 700;
		line-height: 13px;
		margin-bottom: 8px;
		letter-spacing: 1.5px;
	}
}

.group-container-pc-nav {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.group-category-list {
	font-size: 14px;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	gap: 8px;
	ul {
		padding-top: 5px;
		line-height: 1;
		padding-bottom: 19px;
	}
	ul li a {
		color: #1c1f21;
	}

	.group-always-container {
		padding: 0px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-bottom: 16px;

		li {
			display: flex;

			.link {
				height: 100%;
				width: 95%;
				margin: 0 auto;
			}
		}

		@media only screen and (max-width: 768px) {
			gap: 4px;
		}
	}

	.group-always-container-item {
		font-weight: 700;
		font-size: 14px !important;
		line-height: 21px !important;
		letter-spacing: 1px;
		border: 1px solid #efe9dd;
		display: flex;
		align-items: center;
		padding: 6px 0 6px 8px;
		width: 100%;

		.link {
			display: flex;
			align-items: center;
			gap: 13.25px;
		}

		@media only screen and (max-width: 768px) {
			min-height: 48px;
			width: 95%;
			margin: 0 auto;
			padding: 6px 0;
			.link {
				width: 95%;
				margin: 0 auto;
			}
			border: none;
			&:not(:last-child) {
				border-bottom: 1px solid #c6cfbc;
			}
		}
	}

	.group-title-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 0px 8px 0px 0px;
		gap: 8px;

		@media only screen and (max-width: 768px) {
			background: #f5f7f2;
			padding: 4px 8px 4px 12px;
			border-left: 3px solid #c6cfbc;
		}
	}
	.group-title {
		color: #4f6537;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 1.5px;
		line-height: 21px;
	}
	.group-container {
		display: flex;
		flex-direction: column;
		gap: 8px;

		@media only screen and (max-width: 768px) {
			gap: 0;
		}
	}

	.group-container-item {
		display: flex;
		align-items: center;
		gap: 8px;
		letter-spacing: 1px;
		line-height: 21px;
		padding: 4px 0 4px 0px;
		font-size: 14px !important;
		border: none;

		.link {
			width: 100%;
			height: 100%;
		}

		@media only screen and (max-width: 768px) {
			min-height: 48px;
			width: 95%;
			margin: 0 auto;
			&:not(:last-child) {
				border-bottom: 1px solid #c6cfbc;
			}
		}
	}
}

.category-bullet {
	width: 4px;
	height: 4px;
	background: #518f36;
	flex-shrink: 0;
}

.dotted-separator {
	background: resolve("category_separator_border_line.png");
	height: 1px;
	width: 100%;
}

div.group-underline {
	height: 13px;
	width: 100%;
	background: resolve("category_line.png");
	opacity: 0.4;
	transform: matrix(1, 0, 0, -1, 0, 0);
	background-position: 99.6%;
	@media only screen and (max-width: 768px) {
		height: 0;
		background: none;
	}
}

.gnavi-list-category-wrapper {
	width: 800px;
	box-shadow: 4px 4px 14px 0px rgba(14, 82, 58, 0.3);
	position: absolute;
	left: 0;
	top: 41px;
	padding: 20px;
	background: rgba(255, 255, 255, 1);
	opacity: 1;
	display: none;
	height: 100%;
	height: 100vh;
	max-height: 600px;

	&-inner {
		display: flex;
		flex-direction: column;
		gap: 24px;
		overflow-y: auto;
		height: 100%;
	}

	&.under-layer {
		top: 60px;
		left: 0;
		transform: none;
	}

	@media only screen and (min-width: 1130px) {
		left: 50%;
		transform: translate(-50%, 0);
	}
}
