.banner-shop-large {
	max-width: 1120px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display:flex;
	justify-content:space-between;
	@include opacity--anim(.7);
	background-size: cover;
	// &-desc{
	// 	width: 360px;
	// 	background: #4f6537;
	// 	color:#fff;
	// 	padding:45px 20px;
	// 	display:flex;
	// 	flex-direction: column;
	// 	justify-content:flex-start;
	// }
	// &-icon{
	// 	background: resolve('icon-bnr-shop.png') center no-repeat;
	// 	background-size: size('icon-bnr-shop.png',2);
	// 	width: width('icon-bnr-shop.png',2);
	// 	height: height('icon-bnr-shop.png',2);
	// 	margin: 0 auto;
	// }
	// &-title{
	// 	font-size: 22px;
	// 	font-weight: 700;
	// 	letter-spacing: 0.05em;
	// 	text-align: center;
	// 	strong{
	// 		font-family: kreon_r,sans-serif;
	// 		font-size:40px;
	// 		font-weight: 400;
	// 	}
	// }
	// &-text{
	// 	text-align: center;
	// 	font-size: 14px;
	// 	line-height: (24 / 14);
	// }
	// &-more{
	// 	text-align: center;
	// 	font-size: 13px;
	// 	margin-top:auto;
	// 	&:after{
	// 		content: "";
	// 		display:inline-block;
	// 		background: resolve('arrow-right-mask.png') center no-repeat;
	// 		background-size: size('arrow-right-mask.png');
	// 		width: width('arrow-right-mask.png');
	// 		height: height('arrow-right-mask.png');
	// 		margin-left: 5px;
	// 	}

	// }
}
@include breakpoint(sp) {
	.banner-shop-large {
		display: block;
		width: calc(100% - 20px);
		margin:0 auto;
	}
}