.post-links {
	$c: &;
	background: resolve("line-gray-dashed.png") repeat-x left top;
	margin-top: 73px;
	padding-top: 34px;
	position: static;
	display: block;
	& > ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		#{$c}-prev,
		#{$c}-next {
			font-size: 14px;
			font-weight: 500;
			line-height: (24 / 14);
			position: relative;
			a {
				display: block;
				width: 140px;
				color: $black;
				@include opacity--anim(0.4);
			}
		}
		#{$c}-prev {
			padding-left: 39px;
			&:after {
				content: "";
				background: resolve("arrow_left_link@2x.png") no-repeat left top;
				background-size: size("arrow_left_link@2x.png",2); //retinaは第二引数に2
				width: width("arrow_left_link@2x.png", 2);
				height: height("arrow_left_link@2x.png", 2);
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
			}
		}
		#{$c}-next {
			padding-right: 39px;
			&:after {
				content: "";
				background: resolve("arrow_right_link@2x.png") no-repeat left top;
				background-size: size("arrow_right_link@2x.png",2); //retinaは第二引数に2
				width: width("arrow_right_link@2x.png", 2);
				height: height("arrow_right_link@2x.png", 2);
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
			}
		}
		#{$c}-list {
			a {
				color: $black;
				@include opacity--anim(0.4);
			}
			text-align: center;
			font-size: 14px;
			font-weight: 700;
			line-height: (24 / 14);
		}
	}
	&.mod-yomimono-new {
		background: none;
		margin-top: 0;
		padding-top: 0;
		& > ul {
			justify-content: center;
			position: relative;
		}
		.post-links {
			background: none;
			&_list {
				font-size: 13px;
				// position: absolute;
				top: 8px;
				a {
					color: #000;
				}
			}
			&_prev {
				width: 70px;
				a {
					padding: 10px 15px;
					&::before {
						content: "";
						display: inline-block;
						width: 4px;
						height: 4px;
						border-top: 1px solid #000;
						border-right: 1px solid #000;
						transform: rotate(225deg);
						position: relative;
						top: -3px;
					}
				}
			}
			&_next {
				width: 70px;
				text-align: right;
				a {
					padding: 10px 15px;
					&::before {
						content: "";
						display: inline-block;
						width: 4px;
						height: 4px;
						border-top: 1px solid #000;
						border-right: 1px solid #000;
						transform: rotate(45deg);
						position: relative;
						top: -3px;
					}
				}
			}
		}
	}
}
@include breakpoint(sp) {
	.post-links {
		$c: &;
		margin-top: 45px;
		padding-top: 38px;
		& > ul {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: center;
			#{$c}-prev,
			#{$c}-next {
				font-size: 14px;
				font-weight: 500;
				line-height: (24 / 14);
				position: relative;
				a {
					display: block;
					width: 140px;
					color: $black;
					@include opacity--anim(0.4);
				}
			}
			#{$c}-prev {
				padding-left: 0;
				background: resolve("arrow_left_link@2x.png") no-repeat left top;
				background-size: size("arrow_left_link@2x.png",2); //retinaは第二引数に2
				width: width("arrow_left_link@2x.png", 2);
				height: height("arrow_left_link@2x.png", 2);
				display: inline-block;
				@include text-indent-none();
				&:after {
					content: "";
					display: none;
				}
			}
			#{$c}-next {
				padding-right: 0;
				content: "";
				background: resolve("arrow_right_link@2x.png") no-repeat left top;
				background-size: size("arrow_right_link@2x.png",2); //retinaは第二引数に2
				width: width("arrow_right_link@2x.png", 2);
				height: height("arrow_right_link@2x.png", 2);
				display: inline-block;
				@include text-indent-none();
				&:after {
					display: none;
				}
			}
			#{$c}-list {
				font-size: 13px;
			}
		}
	}
}
