.gnavi-status {
	$c:&;
	padding-left: 40px;
	ul {
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
	}
	&-item {
		@extend .font-kreon_r;
		font-size: 12px;
		letter-spacing: .1em;
		& + #{$c}-item {
			margin-left: 20px;
		}
		a {
			color: $black;
			text-decoration: none;
		}
		&.mod-my-page {
			text-align: center;
			&:before {
				content:"";
				background:resolve('icon_status_login.png') no-repeat left top;
				background-size: size('icon_status_login.png');
				width:width('icon_status_login.png');
				height:height('icon_status_login.png');
				margin-left: auto;
				margin-right: auto;
				display: block;
				margin-bottom: 5px;
			}
		}
		&.mod-login {
			text-align: center;
			a {
				display: block;
				@include opacity--anim(.6);
			}
			a:before {
				content:"";
				background:resolve('icon_mypage.png',2) no-repeat left top;
				background-size: size('icon_mypage.png',2);
				width:width('icon_mypage.png',2);
				height:height('icon_mypage.png',2);
				margin-left: auto;
				margin-right: auto;
				display: block;
				margin-bottom: 5px;
			}
		}
		&.mod-my-cart {
			text-align: center;
			position: relative;
			.num {
				position: absolute;
				top: -7px;
				left: -3px;
				@extend .font-kreon_r;
				font-size: 12px;
				width:16px;
				height:16px;
				border-radius: 50%;
				background-color: #474b42;
				color: #fff;
				line-height: 16px;
				display: block;
				text-align: center;
			}
			a {
				display: block;
				@include opacity--anim(.6);
			}
			a:before {
				content:"";
				background:resolve('icon_status_cart.png') no-repeat left top;
				background-size: size('icon_status_cart.png',1.8);
				width:width('icon_status_cart.png',1.8);
				height:height('icon_status_cart.png',1.8);
				margin-left: auto;
				margin-right: auto;
				display: block;
				margin-bottom: 5px;
			}
		}
		/* お気に入り */
		&.mod-heart {
			a:before {
				content: '';
				background: resolve('icon-heart.png') no-repeat 0;
				background-size: 16px 15px;
				width: 16px;
				height: 18px;
				margin-left: auto;
				margin-right: auto;
				display: block;
				margin-bottom: 5px;
			}
		}
	}
	& + .lang.mod-scl {
		margin-left: 40px;
	}
}
@include breakpoint(sp) {
}
