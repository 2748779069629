.sub-navi{
	font-size: 0px;
	margin-top: 26px;
	margin-right: 102px;
	float: right;
	&-list{
		$c:&;
		&-item{
			position: relative;
			padding-bottom: 30px;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 1px;
			display: inline-block;
			letter-spacing: 1px;
			&.mod-auth {
				a:before {
					content:"";
					display: inline-block;
					background:resolve('icon_mypage.png') no-repeat left top;
					background-size: size('icon_mypage.png',2);
					width:width('icon_mypage.png',2);
					height:height('icon_mypage.png',2);
					vertical-align: -2px;
					margin-right: 6px;
				}
			}
			&.mod-mypage {
				a:before {
					content:"";
					display: inline-block;
					background:resolve('icon_mypage.png') no-repeat left top;
					background-size: size('icon_mypage.png',2);
					width:width('icon_mypage.png',2);
					height:height('icon_mypage.png',2);
					vertical-align: -2px;
					margin-right: 6px;
				}
			}
			&.mod-cart {
				a:before {
					content:"";
					display: inline-block;
					background:resolve('icon_cart.png') no-repeat left top;
					background-size: size('icon_cart.png',2);
					width:width('icon_cart.png',2);
					height:height('icon_cart.png',2);
					vertical-align: -2px;
					margin-right: 6px;
				}
				.num {
					position: absolute;
					top: -7px;
					left: -9px;
					display: block;
					color: #fff;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: #474b42;
					text-align: center;
					line-height: 16px;
				}
			}
			&.mod-info {
				a:before {
					content:"";
					display: inline-block;
					background:resolve('icon_info.png') no-repeat left top;
					background-size: size('icon_info.png',2);
					width:width('icon_info.png',2);
					height:height('icon_info.png',2);
					vertical-align: -3px;
					margin-right: 6px;
				}
			}
			&.mod-search {
				a:before {
					content:"";
					display: inline-block;
					background:resolve('icon_lower_search.png') no-repeat left top;
					background-size: size('icon_lower_search.png',2);
					width:width('icon_lower_search.png',2);
					height:height('icon_lower_search.png',2);
					vertical-align: -1px;
					margin-right: 6px;
				}
			}
			/* お気に入り*/
			&.mod-like {
				a:before {
					content: '';
					display: inline-block;
					background: resolve('icon-heart.png') no-repeat 0 0;
					background-size: 15px 14px;
					width: 16px;
					height: 14px;
					vertical-align: -2px;
					margin-right: 6px;
				}
			}
		}
		&-child{
			position: absolute;
			left: -23px;
			color: $brown;
			font-family: kreon_r;
			width: 125px;
			height: 85px;
			padding-top: 3px;
			margin-top: 8px;
			border-bottom-width: 0;
			border-right: 1px solid $brown;
			border-bottom: 1px solid $brown;
			background-color: #fff;
			display: none;
			z-index: 10;
			&-item{
				position: relative;
				letter-spacing: 2px;
				line-height: 25px;
				padding-left: 23px;
				&:hover{
					background-image: resolve('sub-navi-hover.png');
					background-repeat: no-repeat;
					background-position: 10px;
				}
				& a{
					color: #68603e !important;
				}
			}
		}
		a {
			color: #000;
		}

		&.mod-shop {
			text-align: right;
			#{$c}-item {
				padding-bottom: 0;
			}
			#{$c}-item a {
				font-size: 12px;
				letter-spacing: 1.2px;
			}
			#{$c}-item + #{$c}-item {
				margin-left: 26px;
			}
			.search-form {
				margin-top: 26px;
				width: 201px;
				display: inline-block;
				margin-right: 2px;
			}
		}
	}
}
