.article-header {
	$p: &;
	&-date {
		font-family: "kreon_r";
		font-size: 14px;
		font-weight: 400;
		line-height: (24 / 13);
		padding-bottom: 3px;
		letter-spacing: 1.4px;
		background: resolve("line-black-dashed.png") repeat-x left bottom;
		background-size: size("line-black-dashed.png", 2);
	}
	&-title {
		padding-top: 17px;
		font-size: 20px;
		font-weight: 700;
		line-height: (24 / 20);
		text-align: center;
		word-break: keep-all;
	}
	&-headline {
		font-size: 13px;
		font-style: italic;
		position: relative;
		color: #68603e;
		padding-bottom: 8px;
		font-weight: 700;
		border-bottom: 1px solid #c3c0b4;
		margin-bottom: 20px;
		&.mod-extend {
			font-size: 22px;
			font-style: italic;
			color: #68603e;
			padding: 15px 0 13px 28px;
			font-weight: 700;
			border-bottom: 1px solid #c3c0b4;
			margin-bottom: 40px;
			letter-spacing: 1px;
			&.mod-green {
				color: #4f6537;
				border-bottom: 1px solid #4f6537;
			}
			.btn-more {
				color: #000;
				font-size: 13px;
				font-weight: 700;
				letter-spacing: 1px;
				position: absolute;
				top: 26px;
				right: 2px;
				font-style: normal;
			}
		}
		&.mod-thumbnail {
			font-size: 16px;
			font-style: normal;
			color: #68603e;
			padding: 10px 0 8px 17px;
			font-weight: 700;
			border-bottom: 0 solid #c3c0b4;
			margin-bottom: 17px;
			letter-spacing: 2px;
			.btn-more {
				font-size: 13px;
				color: #000;
				font-weight: 700;
				letter-spacing: 1px;
				position: absolute;
				top: 13px;
				right: 0px;
			}
		}
		&.mod-green {
			color: #4f6537;
		}
	}
	&-headline_triangle {
		font-size: 22px;
		font-weight: 700;
		position: relative;
		color: #4f6537;
		padding-bottom: 8px;
		font-weight: 700;
		border-bottom: 1px solid #c3c0b4;
		margin-bottom: 35px;
		padding: 15px 0 13px 28px;
		letter-spacing: 1px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			border-top: 20px solid #c6cfbc;
			border-right: 20px solid transparent;
		}
		&.mod-h1 {
			color: #000;
		}
		&.mod-h2 {
			font-size: 16px;
		}
	}
	&-headline_square {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.498;
		font-style: normal !important;
		&:before {
			content: "";
			width: 10px;
			height: 10px;
			background-color: #c6cfbc;
			display: inline-block;
			margin-right: 14px;
			vertical-align: 2px;
		}
	}

	//single
	&.mod-yomimono-new {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		position: relative;
		#{$p}_mv {
			width: 640px;
			margin: 0 auto 40px;
		}
		#{$p}_titleWrap {
			width: 65%;
			margin: 0 auto;
		}
		#{$p}_title {
			line-height: 1.7;
			text-align: center;
			display: inline;
			font-size: 24px;
		}
		#{$p}_meta {
			display: flex;
			margin-top: 28px;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
		}
		#{$p}_label {
			// margin-left: 10px;
			margin-right: 10px;
		}
		#{$p}_tag {
			margin-left: 6px;
			margin-right: 6px;
		}
		#{$p}_date {
			font-family: "calibri", Garamond, "Comic Sans MS", sans-serif;
			font-size: 13px;
			margin-left: 10px;
			letter-spacing: -0.03em;
			display: flex;
			align-items: center;
			&:before {
				content: "";
				display: inline-block;
				background-color: #000;
				width: 1px;
				height: 12px;
				margin-right: 17px;
			}
		}
	}

	&-yomimono {
		&-link {
			position: relative;
			margin-top: 30px;
			width: 100%;
			margin: 30px auto 0;
			&:before {
				background-image: linear-gradient(
					90deg,
					#9d9d9d 4px,
					transparent 0,
					transparent 0
				);
				background-size: 6px 1px;
				background-repeat: repeat-x;
				content: "";
				display: inline-block;
				height: 10px;
				width: 100%;
			}
			&-btn {
				position: absolute;
				right: 0;
				top: 58px;
			}
		}
		&-sectionTitle{
			margin:50px auto 30px;
			text-align: center;
			&.mod-mt-0{
				margin-top: 0px;
			}
			&.mod-mb-0{
				margin-bottom: 0;
			}
		}

		&-headline {
			margin: 53px auto 13px;
			text-indent: 200%;
			white-space: nowrap;
			overflow: hidden;

			&._category-title {
				margin-top: 25px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				height: 61px;
				width: 100%;
			}

			&._01 {
				background: resolve("img_yomimono_header01.png", 2) no-repeat
					center;
				width: width("img_yomimono_header01.png", 2);
				// height: height("img_yomimono_header01.png",2);
				background-size: 100%;
				height: 0;
				padding-top: 9%;
				margin-top: 0;
			}
			&._02 {
				background: resolve("img_yomimono_header02.png", 2) no-repeat
					center;
				width: width("img_yomimono_header02.png", 2);
				height: height("img_yomimono_header02.png", 2);
				background-size: 100%;
			}
			&._03 {
				background: resolve("img_yomimono_header03.png", 2) no-repeat
					center;
				width: width("img_yomimono_header03.png", 2);
				height: height("img_yomimono_header03.png", 2);
				background-size: 100%;
				margin-bottom: 52px;
			}
			&._04 {
				background: resolve("img_yomimono_header04.png", 2) no-repeat
					center;
				width: width("img_yomimono_header04.png", 2);
				height: height("img_yomimono_header04.png", 2);
				background-size: 100%;
			}
		}

		&._category {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 30px;
		}
		&._category-child {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 50px 0;
		}
		&._latest {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 30px;
		}
		&-title {
			width: 30%;
			&._category-child {
				width: 100%;
				text-align: center;
				font-weight: 700;
			}
			&._latest{
				text-align: center;
			}
		}
		&-leadtext {
			text-indent: inherit;
			white-space: inherit;
			overflow: inherit;
			font-size: 13px;
			letter-spacing: 0.13em;
			// width: 66%;
			width: 65%;
			line-height: 2;
			// margin: 10px 0;
			font-weight: 700;
			&._category-child {
				width: 100%;
				margin-top: 15px;
			}
		}

		&-form {
			&._category {
				display: none;
			}
		}
	}
}
@include breakpoint(sp) {
	.article-header {
		$p: &;
		&-title {
			padding-top: 10px;
			font-size: 16px;
			padding-left: 20px;
			padding-right: 20px;
			text-align: left;
		}
		&-date {
			padding-left: 20px;
			padding-right: 20px;
		}
		&-headline_triangle {
			font-size: 22px;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				border-top: 16px solid #c6cfbc;
				border-right: 16px solid transparent;
			}
		}
		&-headline_square {
			font-size: 16px;
			&:before {
				width: 8px;
				height: 8px;
				margin-right: 11px;
				vertical-align: 1px;
			}
		}

		//single
		&.mod-yomimono-new {
			width: 100%;
			text-align: left;
			margin-bottom: 10px;
			#{$p}_mv {
				width: 100%;
				margin: 0 auto 40px;
				order: 1;
			}
			#{$p}_titleWrap {
				width: 100%;
				margin-top: 15px;
				order: 3;
			}
			#{$p}_title {
				font-size: 18px;
				text-align: left;
			}
			#{$p}_meta {
				margin: 0;
				justify-content: flex-start;
				order: 2;
			}
			#{$p}_label {
				font-size: 10px;
				margin: 5px 0 0;
				padding-top: 1px;
			}
			#{$p}_tag {
				font-size: 11px;
				margin: 0 0 0 10px;
			}
			#{$p}_date {
				position: absolute;
				font-size: 12px;
				margin: 0;
				bottom: 40px;
				right: 5px;
				&:before {
					display: none;
				}
			}
			.article-sns {
				order: 4;
				margin-bottom: 0;
			}
			.article-sns-cont {
				justify-content: flex-end;
			}
		}

		&-yomimono {
			&-sectionTitle{
				margin:40px auto 30px;
			}
			&-headline {
				margin-top: 37px;
				&._category-title {
					height: 52px;
					margin: 16px auto 18px;
				}
				&._01 {
					width: 139px;
					padding-top: 14%;
					margin-top: 4px;
				}
				&._02 {
					background: resolve("img_yomimono_header02.png", 2.4)
						no-repeat center;
					width: width("img_yomimono_header02.png", 2.4);
					height: height("img_yomimono_header02.png", 2.4);
					background-size: 100%;
					margin-bottom: 26px;
				}
				&._03 {
					background: resolve("img_yomimono_header03.png", 2.4)
						no-repeat center;
					width: width("img_yomimono_header03.png", 2.4);
					height: height("img_yomimono_header03.png", 2.4);
					background-size: 100%;
					margin-bottom: 26px;
				}
				&._04 {
					background: resolve("img_yomimono_header04.png", 2.4)
						no-repeat center;
					width: width("img_yomimono_header04.png", 2.4);
					height: height("img_yomimono_header04.png", 2.4);
					background-size: 100%;
					margin-bottom: 26px;
				}
			}
			&._category {
				flex-direction: column;
			}
			&._category-child {
				margin: 30px 0 40px;
			}
			&-title {
				margin: 0 auto 20px;
				width: 40%;
				width: 70%;
				text-align: center;
				&._category-child {
					margin-bottom: 0;
				}
			}
			&-leadtext {
				margin: 0 auto;
				font-size: 11px;
				line-height: 1.7;
				width: 86%;
			}
			&-form {
				&._category {
					display: block;
					width: 69%;
					margin: 31px auto 0;
					form {
						input {
							font-size: 10px;
						}
						input[type="text"] {
							padding-top: 5px;
							padding-bottom: 5px;
							border-color: #ccc;
						}
						input[type="submit"] {
							background: resolve("icon_search.png") no-repeat
								left center;
							background-size: size(
								"icon_search.png",
								2.5
							); //retinaは第二引数に2
							width: width("icon_search.png", 2.5);
							height: height("icon_search.png", 2.5);
						}
					}
				}
			}
			&-link {
				// width: 90%;
				&:before {
					// width: 90%;
				}
			}
		}
	}
}
