.box-slash-colored {
	display: flex;
	justify-content:flex-start;
	flex-wrap:wrap;
	align-items:center;
	&-num {
		position: relative;
		top: -14px;
		right: 14px;
		@extend .font-kreon_r;
		color: #675f3e;
		font-size: 26px;
		font-weight: 400;
		line-height: 1;
		font-style: italic;
		letter-spacing: 1px;
	}
	&-sep {
		width: 1px;
		height: 63px;
		background-color: #675f3e;
		transform:rotate(45deg);
		display: block;
	}
	&-title {
		position: relative;
		top: 13px;
		left: 12px;
		color: #675f3e;
		font-size: 15px;
		font-weight: 700;
		line-height: 1;
		font-style: italic;
	}
}
@include breakpoint(sp) {
	.box-slash-colored {
		display: block;
		&-num {
			position: static;
			text-align: center;
			display: block;
			font-size: 20px;
		}
		&-sep {
			display: none;
		}
		&-title {
			position: static;
			text-align: center;
			display: block;
			font-size: 14px;
			margin-top: 14px;
		}
	}
}
