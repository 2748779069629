.event-modal {
	&-header {
		font-size: 20px;
		display: block;
		text-align: center;
		font-style: 'kreon_r';
		margin-bottom: 30px;
		color: #1c1f21;
		&:before {
			display: inline-block;
			content:"";
			background:resolve('icon_hasevent.png') no-repeat left top;
			background-size: size('icon_hasevent.png',2);
			width:width('icon_hasevent.png',2);
			height:height('icon_hasevent.png',2);
			margin-right: 14px;
		}
	}
	&-list {
		width: 83.9285%;
		margin-left: auto;
		margin-right: auto;
	}
	&-list-item {
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		color:$black;
		.btn-half_line {
			min-width: auto;
		}
	}
	&-list-item + &-list-item {
		padding-top: 20px;
		margin-top: 20px;
		border-top: 1px dotted $black;
	}
	&-list-area {
		background-color: #4f6537;
		font-size: 11px;
		color: #fff;
		position: absolute;
		top: -1px;
		left: -1px;
		border:1px solid #fff;
		font-weight: bold;
		padding-left: 4px;
		padding-right: 4px;
	}
	&-list-img {
		width: 42.553%;
		position: relative;
	}
	&-list-text {
		width: 52.1276%;
	}
	&-list-title {
		font-size: 13px;
		line-height: (48 / 26);
		text-align: left;
	}
	&-list-tags {
		width: 100%;
	}

}
.remodal-wrapper {
	padding: 0;
	width: 87.5%;
	margin-left: auto;
	margin-right: auto;
}
.remodal {
	padding:40px 0;
}
.remodal-close {
	top: 20px;
	right: 20px;
	left: auto;
	width:width('btn_modal_close.png',2);
	height:height('btn_modal_close.png',2);
	&:before {
		content:"";
		display: block;
		background:resolve('btn_modal_close.png') no-repeat left top;
		background-size: size('btn_modal_close.png',2);
		width:width('btn_modal_close.png',2);
		height:height('btn_modal_close.png',2);
	}
}
.remodal-overlay {
	background-color: rgba(195,192,180,.8);
}
@include breakpoint(sp) {
}
