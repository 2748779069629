.card {
	position: relative;
	color:#1c1f21;
	display: flex;
	justify-content: start;
	flex-direction: column;
	&:hover{
		opacity: 1;
	}
	&.mod-4col{
		width: 225px;
	}
	&.mod-5col{
		width: 176px;
	}
	&.mod-slide-5col{
		width: 196px;
		padding:0 10px;
		height: 100%;
	}
	&-thumb{
		width: 100%;
		padding-top:100%;
		height: 0;
		position: relative;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&:before{
			content: "MORE";
			display:block;
			position: relative;
			color: #fff;
			font-family: kreon_r;
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding-top:calc(50% - 1em);
			letter-spacing: 0.1em;
			background-color: rgba(0,0,0,0.4);
			text-align: center;
			transition: all 0.6s ease;
			opacity: 0;
		}
		&:after{
			content: '';
			position: absolute;
			background:resolve("arrow-right-mask.png") center no-repeat;
			background-size:size("arrow-right-mask.png");
			width:width("arrow-right-mask.png");
			height:height("arrow-right-mask.png");
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			margin-top: 16px;
			transition: all 0.6s ease;
			opacity: 0;
		}
		&:hover:before,
		&:hover:after{
			opacity: 1;
		}
	}
	&-title{
		font-size: 15px;
		font-weight: 700;
		margin-top: 16px;
		letter-spacing: 0.05em;
	}
	&-text{
		font-size: 13px;
		margin-top: 5px;
		line-height: (23 / 13);
	}
	&-tag{
		border:2px solid #fff;
		color:#fff;
		display: inline-block;
		position: absolute;
		bottom:-7px;
		left:-7px;
		font-size: 12px;
		line-height: 1;
		padding:7px 5px;
		font-weight: 700;
		&.mod-article{
			background-color: #545a5e;
			padding-left: 28px;
			&:before{
				content: "";
				display:block;
				background: resolve('icon-mod-article.png') center no-repeat;
				background-size: size('icon-mod-article.png',2);
				width: width('icon-mod-article.png',2);
				height: height('icon-mod-article.png',2);
				position: absolute;
				left:6px;
				top:50%;
				transform: translate(0,-40%);
			}
		}
	}
	&-price{
		margin-top:auto;
		padding-top: 5px;
		font-size: 14px;
		text-align: right;
		font-weight: 700;
		small{
			font-size: 80%;
		}
	}
	&-more{
		margin-top:auto;
		padding-top: 5px;
		font-size: 14px;
		text-align: right;
		font-weight: 700;
	}
}
@include breakpoint(sp) {
	.card{
		margin-top: 20px;
		&.mod-4col{
			width: calc(50% - 5px);
		}
		&.mod-slide-5col{
			width: 166px;
			padding:0 6px;
			margin-top:0;
			padding-top:20px;
		}
	}
}
