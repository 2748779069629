//pika-style

.pika-lendar{
	background-color: #dde6d2;
	padding: 5px;
	margin: 0px;
}
.pika-table th{
	color: #48602e;
}
.pika-label{
	background-color: #dde6d2;
	color: #38521d;
}
.pika-prev{
	background-color: #f1f5ed;
	opacity: 1;
}
.pika-next{
	background-color: #fff;
	opacity: 1;
}
.pika-button{
	background-color: #fff;
	text-align: center;
	color: #38521d;
	border: solid 1px #9ead8e;
}
.is-today .pika-button{
	color: #fff;
	background-color: #38521d;
}
.is-rtl .pika-next, .pika-prev{
	background-image: resolve('prev_month@2.png',2);
	width: width('prev_month@2.png',2);
	height: height('prev_month@2.png',2);
	background-size: 100%;
	background-position: center;
}

.is-rtl .pika-prev, .pika-next{
	background-image: resolve('next_month@2.png',2);
	width: width('next_month@2.png',2);
	height: height('next_month@2.png',2);
	background-size: 100%;
	background-position: center;
}
.has-event .pika-button, .is-selected .pika-button{
	background-color: #fff;
	text-align: center;
	color: #38521d;
	border: 1px solid #9ead8e;
	outline: none;
}

.is-disabled{
	background-color: #c6cfbb;
	color: #eaefe5
}

.has-event .pika-button, .is-selected .pika-button{
	border: solid 2px;
	padding: 4px;
	background-color: #fff;
	box-shadow: none;
}

.pika-button:hover, .pika-row.pick-whole-week:hover .pika-button{
	background-color: #fff;
	color: #38521d;
	background: inherit;
	box-shadow: none;
	border-radius: 3px;
	opacity: 0.8;
}
.pika-table abbr{
	cursor: inherit;
}
