body.lineup{
	min-width: 1200px;
}

.lineup{
	.nav-top{
		width: 100%;
		font-size: 14px;
		font-weight: bold;
		padding-left: 27px;
		margin-left: auto;
		margin-right: auto;
		color: #68603e;
		margin-top: 75px;
		max-width: 1040px;
	}
	.header-month {
		display: flex;
		width: 83.6875%;
		justify-content: space-between;
		margin: 0 auto;
		margin-top: 12px;
		@extend .font-kreon_r;
		.month {
			font-size: 14px;
			padding: 3px 0px;
			width: 82px;
			text-align: center;
			border-right: solid 1px #c3c0b4;
			letter-spacing: 1.5px;
			&:last-of-type{
				border-right: solid 0px #c3c0b4;
			}
			&.odd{
				color: #68603e;
			}
			&.even{
				color: #5a6f44;
			}
			.num {
				font-size: 16px;
			}
		}
	}
	.contents-month{
		&_inner{
			display: block;
			margin-top: 40px;
			padding: 50px 105px;
			max-width: 1170px;
			.main_col{
				margin-top: 50px;
			}
			.btn_wrap-around-center_arrow_left{
				color: #000;
				font-weight: bold;
				top: 40%;
				position: absolute;
				left: 10px;
			}
			.btn_wrap-around-center_arrow_right{
				color: #000;
				top: 40%;
				font-weight: bold;
				position: absolute;
				right: 10px;
			}
		}
		&-info-wrap{
			font-size: 16px;
			color: #4f6537;
			width: 100%;
			display: flex;
			margin-top: 0px;
			.month_title{
				width: 65%;
			}
			.contents-month_set-items_btn{
				text-align: right;
			}
			.box-link{
				&.mod_no_arrow{
					background: #fff;
					&:after{
					height: 0px;
					}
				}
				&.mod-half{
					width: 170px;
					margin-top: 0px;
				}
			}
		}
		&_set-items_btn{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 40%;
			margin: 0 auto;
			&.pc{
				display: flex;
			}
		}
	}
	.btn_wrap {
		margin:20px auto;
	}
	.title.mod-triangle-above.mod-green{
		//font-color_chabge
		color: #1c1f21;
	}
	.glid.mod-content-2col{
		margin-top: 13px;
		padding-bottom: 20px;
		border-bottom: solid 1px #cdd2d5;
		font-size: 13px;
		line-height: 2;
		& + .title.mod_under-line{
			margin-top: 50px;
		}
		.harf_col{
			
			.title.mod-triangle-above{
				padding-bottom: 0px;
			}
			.list_header-triangle{
				padding-left: 0px;
			}
			.list_header{
				background: resolve("text-list-glay.png") no-repeat;
				padding-left: 10px;
				background-position: 0px 11px;
			}
		}
	}
	.btn_wrap-around-center{
		width: 100%;
		position: relative;
		font-weight: bold;
		&_arrow_left{
			color: #000;
			margin: 0;
			margin-left: 0;
			margin-right: auto;
			font-size: 13px;
			&:after{
				top: 40px;
				left: -20px;
			}
		}
		&_arrow_right{
			color: #000;
			margin: 0;
			margin-right: 0;
			margin-left: auto;
			position: relative;
			font-size: 13px;
			&:after{
				top: 40px;
				right: -20px;
			}
		}
	}
}

@include breakpoint(sp) {
	body.lineup{
		min-width: 100%;
	}
	.lineup{
		.nav-top{
			width: 83.6875%;
			padding-left: 0px;
			margin: 20px auto 0;
			font-size: 14px;
			text-align: center;
		}
		.header-month{
			display: flex;
			width: 83.6875%;
			justify-content: space-between;
			margin: 0 auto;
			.month{
				font-size: 16px;
				padding: 8px 0px;
				width: 82px;
				text-align: center;
				border-right: solid 1px #c3c0b4;
				&:last-of-type{
					border-right: solid 0px #c3c0b4;
				}
				&.odd{
				color: #68603e;
				}
				&.even{
				color: #5a6f44;
				}
			}
		}
		.contents-month{
			background-color: #fff;
			&_inner{
				display: block;
				justify-content: space-between;
				width: 87.5%;
				margin-top: 40px;
				margin-left: auto;
				margin-left: auto;
				padding: 10px 0px;
				.main_col{
					margin-top: 25px;
				}
				.box-link{
					& + .box-link{
						margin-top: 25px;
					}
					&.mod_no_arrow{
					background: #fff;
						&:after{
						height: 0px;
						}
					}
					&.mod-half{
						width: 170px;
						margin-top: 0px;
					}
				}
			}
			&-info-wrap{
				font-size: 16px;
				color: #4f6537;
				width: 100%;
				display: flex;
				margin-top: 0px;
				.month_title{
					width: 100%;
					font-size: 20px;
					font-weight: normal;
					text-align: left;
					.ext_title{
						font-size: 20px;
					}
				}
			}
			.thumbnail-item-has_under_title.glid-6col{
				width: 49.1071%;
			}
			.glid.thumbnail-item-has_under_title-wrap{
				justify-content: space-between;
			}
			&_set-items_btn{
				&.pc{
					display: none;
				}
			}
		}
		.glid.mod-content-1col{
			width: 85%;
			justify-content: center;
		}
		.glid.mod-content-2col{
			margin-top: 13px;
			padding-bottom: 20px;
			border-bottom: solid 1px #cdd2d5;
			& + .title.mod_under-line{
				margin-top: 60px;
			}
			.harf_col{
				width: 100%;
				font-size: 13px;
				line-height: 2;
				& + .harf_col{
					margin-top: 20px;
				}
				.title.mod-triangle-above{
					padding-bottom: 0px;
				}
				.list_header-triangle{
					padding-left: 0px;
				}
				.list_header{
					background: resolve("text-list-glay.png") no-repeat;
					padding-left: 10px;
					background-position: 0px 11px;
				}
			}
		}
		.btn_wrap-around-center{
			width: 100%;
			position: relative;
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
			&_arrow_left{
				margin: 0;
				margin-left: 5px;
				margin-right: auto;
				position: static!important;
				display: block;
				&.pc {
					display: none;
				}
				&:after{
					position: static;
					transform:none;
					text-indent: 0;
					display: block;
					background-position: left center;
				}
			}
			&_arrow_right{
				margin: 0;
				margin-right: 0;
				margin-left: auto;
				position: static!important;
				display: block;
				text-align: right;
				&.pc {
					display: none;
				}
				&:after{
					position: static;
					transform:none;
					text-indent: 0;
					display: block;
					background-position: right center;
				}
			}
		}
		.btn-half_line.mod-has-img {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			img {
				margin-right: 15px;
			}
			letter-spacing: 0;
		}
	}
}
