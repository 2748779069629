.header-under-layer-gnavi{
	line-height: 45px;
	list-style: none;
	margin-top: -18px;
	display: flex;
	justify-content:flex-end;
	flex-wrap:wrap;
	align-items:center;
	a {
		color: #000;
	}
	.nav-current {
		color: #4f6537;
		* {
			color: #4f6537;
		}
	}
	&-list{
		&-item{
			display: inline-block;
			position: relative;
			font-size: 15px;
			font-weight: bold;
			margin-left: 0px;
			letter-spacing: 1.5px;
			& + & {
				margin-left: 33px;
			}
			& > *{
			}
			&.mod-kreon{
				letter-spacing: 2px;
				@extend .font-kreon_r;
				font-size: 17px;
			}
		}
		&-child{
			font-size: 12px;
			letter-spacing: 1.2px;
			position: absolute;
			padding-top: 11px;
			text-align: left;
			left: -80px;
			top: 45px;
			color: #68603e;
			width: 185px;
			border-bottom-width: 0;
			border-right: 1px solid #68603e;
			border-bottom: 1px solid #68603e;
			background-color: #fff;
			z-index: 10;
			display: none;
			padding-bottom: 5px;
			&-item{
				position: relative;
				line-height: 26px;
				padding-left: 24px;
				margin-top: -1px;
				letter-spacing: 1px;
				a {
					color: #68603e;
				}
				&:hover{
					background-image: resolve('sub-navi-hover.png');
					background-repeat: no-repeat;
					background-position: 10px;
				}
			}
		}
	}
}

