h3.h3_brown{

    font-size: 16px;
    font-weight: 700;
    display: flex;
    margin: 10px auto !important; 
    align-items: center;
    font-style: normal;
    color: #68603e !important;

    &::before{
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        margin-right: 9px;
        background-color: #c3c0b4 !important;
    }
}
