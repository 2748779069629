.article-shop-banners {
	max-width: 720px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: center;
	padding: 40px;

	&-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 81px;
	}

	@media only screen and (max-width: 768px) {
		& {
			padding: 0;
			margin: 40px 0;
		}
	}
}
