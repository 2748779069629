.thumbnail-item-has_under_title-wrap{
	margin-top: 15px;
	justify-content: space-between;
}
.thumbnail-item-has_under_title {
	width: 150px;
	&.mod-3items{
		width: 300px;
		margin-top: 0px;
	}
	$c:&;
	@at-root {
		a#{$c} {
			@include opacity--anim(.5);
		}
	}
	&.mod-even {
		#{$c}-title {
			color: #4f6537;
			&:after {
				background-color: #f5f7f2;
			}
		}
	}
	&-img {
		width: 100%;
		height: 103px;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		&.mod-3items{
			height: 190px;
		}
	}
	&-title {
		color: #000;
		font-size: 13px;
		font-weight: 700;
		line-height: 28px;
		position: relative;
		text-align: center;
		padding-top: 12px;
		padding-bottom: 33px;
		&.mod-font-size14{
			font-size: 14px;
		}
	}
}
@include breakpoint(sp) {
	.thumbnail-item-has_under_title-wrap{
		margin-top: 15px;
	}
	.thumbnail-item-has_under_title {
		&.mod-3items{
			width: 137.5px;
		}
		$c:&;
		&-img {
			width: 100%;
			height: 0;
			padding-top: 68.66666666666667%;
			&.mod-3items{
			padding-top : 60%;
			height: 0px;
			}
		}
		&-title {
			color: #000;
			font-size: 13px;
			font-weight: 700;
			line-height: 1.8;
			padding-top: 12px;
			padding-bottom: 33px;
		}
	}
}
