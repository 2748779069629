.common-component-cart{
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	textarea {
		border-radius: 0;
	}
	.box-triangle{
		padding: 20px 20px 15px;
		h3{
			text-align: center;
			letter-spacing: 2px;
			font-size: 14px;
			font-weight: bold;
			color: #4f6537;
			border-bottom: solid 1px;
			border-color: #c6cfbc;
			padding-bottom: 11px;
			margin-bottom: 13px;
		}
		&_under{
			margin-top: 7px;
			display: flex;
			&_date{
				font-size: 12px;
				margin-right: 20px;
				@extend .font-kreon_r;
			}
			&_text{
				font-size: 14px;
				line-height: 1.7;
			}
		}
	}//box-triangle
	.box-triangle.order-thumbnail{
		padding: 20px 20px 40px;
		h3{
			text-align: left;
			letter-spacing: 2px;
			font-size: 14px;
			font-weight: bold;
			color: #4f6537;
			padding-bottom: 11px;
			margin-bottom: 13px;
			border: 0px;
		}
	}//box-triangle.order-thumbnail
	.box-link.mod-resposive{
		font-size: 15px;
		padding: 25px 27px;
		.box-link-bg{
			top: 29px;
		}
	}//.box-link.mod-resposive
	.box-has_triangle-point{
		&::before{
			display: none;
		}
		&-title{
			font-size: 13px;
			font-weight: bold;
			color: #68603e;
		}
	}
}
.order-thumbnail-wrap{
	display: flex;
	justify-content: space-around;
	align-items: center;
	.order-thumbnail-item{
		position: relative;
		text-align: center;
		color: #4f6537;
		font-size: 13px;
		font-weight: bold;
		&.current {
			opacity: .6;
		}
		p {
			position: absolute;
			bottom: -25px;
			left: 0;
			right: 0;
		}
	}
	&.mod-has_text {
		align-items: stretch;
		justify-content: space-between;
	}
	.order-thumbnail-textitem {
		font-size: 14px;
		text-align: center;
		background-color: #4f6537;
		padding:10px 7px;
		color: #fff;
		font-weight: 700;
		width:200px;
		span {
			display: block;
			font-size: 12px;
		}
	}
	.strech-inner {
		display: flex;
		justify-content:center;
		align-items:center;
		flex-wrap:wrap;
	}
}
@include breakpoint(sp) {
	.common-component-cart{
		.box-triangle.order-thumbnail{
			padding: 20px 20px 15px;
		}
		.order-thumbnail-wrap{
			display: flex;
			flex-wrap: wrap;
			//width: 90%;
			margin-left: auto;
			margin-right: auto;
			align-items: center;
			.order-thumbnail-item{
				margin-bottom: 15px;
				p {
					position: static;
					margin-top: 5px;
				}
			}
		}
	}

	.order-thumbnail-wrap{
		&.mod-has_text {
			display: block;
		}
		.order-thumbnail-textitem {
			width: 100%;
			+ .order-thumbnail-item {
				text-align: center;
				transform:rotate(90deg);
			}
		}
	}
}
