.cotents-delivery{
	width: 100%;
	padding: 28px;
	font-size: 14px;
	background-color: #f5f7f2;
	hr {
		border-top:1px dashed #4f6537;
		visibility: visible;
		margin-top: 25px;
		margin-bottom: 20px;
	}
	h5{
		font-size: 15px;
		font-weight: bold;
		letter-spacing: -1px;
	}
	a {
		color: #4f6537;
		border-bottom: 1px solid #c6cfbb;
	}
	.text-area{
		line-height: 1.9;
		&.mod-small{
			font-size: 13px;
		}
	}
	.list_header{
		padding-left: 14px;
		background-position: left 40%;
		vertical-align: middle;
	}
	.thumbnail_2col_delivery{
		display: flex;
		justify-content: space-between;
		.inner_left{
			width: 50%;
		}
	}
	.comment-area{
		textarea {
			width: 100%;
			height: 100px;
			border: solid 1px #c6cfbc;
		}

	}
	&-back_confirm{
		position: relative;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		.btn_wrap-around-center_arrow_left {
			font-size: 13px;
			top: 0;
			color: #000;
			position: relative;
		}
		.box-link{
			background-color: #f5f7f2;
			color: #4e6635;
			border-top:0;
			border-left:0;
			display: inline-block;
		}
	}
	label{
		display: block;
		margin-bottom: 15px;
		.radio-text{
			cursor: pointer;
			font-weight: bold;
			&:before{
				content: '';
				display: inline-block;
				background: resolve("radio_button.png")  no-repeat 0 0;
				width: width("radio_button.png");
				height: height("radio_button.png");
				margin-right: 6px;
			}
			&.active:before {
				content: '';
				background: resolve("radio-button_on.png")  no-repeat 0 0;
				width: width("radio-button_on.png");
				height: height("radio-button_on.png");
			}
		}
	}
}
@include breakpoint(sp) {
	.cotents-delivery{
		.thumbnail_2col_delivery{
			display: block;
			.inner_left{
				width: 100%;
			}
		}
		a {
			border-bottom: 0;
			text-decoration: underline;
		}
		&-back_confirm{
			position: relative;
			.btn_wrap-around-center_arrow_left{
				font-size: 11px;
				top: 0;
				color: #000;
				position: relative;
				&:after{
					position: absolute;
					left: -20px;
					top: 30px;
				}
			}
			.box-link{
	 			min-width: 44%;
	 			max-width: 60%;
	 			width: auto;
	 			font-size: 11px;
				background-color: #f5f7f2;
				color: #4e6635;
				line-height: 1.8;
				height: auto;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-right: 0;
			}
			&.sp-align-vertical {
				flex-direction:column;
				align-items:center;
				justify-content:flex-start;
				.sp-order-1 {
					order:1;
				}
				.sp-order-2 {
					order:2;
				}
			}
		}
	}


}
