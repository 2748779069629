.btn-more {
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 1px;
	top: 50px;
	right: 159px;
	position: absolute;
	color: $black;
	display: inline-block;
	cursor: pointer;
	&:after{
		content: ".";
		background: resolve("arrow-right-more.png") no-repeat 0 0;
		display: inline-block;
		margin-left: 5px;
		width: 30px;
		background-position: center;
		color: #fff;
		transition: all .6s ease;
	}
	&:hover:after{
		transform: translateX(15px);
		transition: all .6s ease;
	}
	&.mod-project{
		top: 56px;
		right: 160px;
	}
	&.mod-static {
		position: relative;
		top: 5px;
		left: -4px;
		&:hover {
			opacity: .6;
		}
		&:hover:after{
			transform: translateX(0);
		}
	}
	&.mod-static + &.mod-static {
		margin-left: 44px;
	}
}

@include breakpoint(sp) {
	.btn-more{
		/*
		font-size: 13px;
		font-weight: 700;
		letter-spacing: 1px;
		width: 170px;
		height: 40px;
		border-right: 2px solid #000;
		border-bottom: 2px solid #000;
		display: block;
		margin: 0 auto;
		text-align: center;
		line-height: 40px;
		display: block;
		position: relative;
		top: 0;
		right: 0;
		*/
		&.pc{
			display: none;
		}
		&.sp{
			display: block;
		}
		&:after{
			content: ".";
			background: resolve("arrow-right-more.png") no-repeat 0 0;
			display: inline-block;
			margin-left: 5px;
			width: 30px;
			background-position: center;
			color: #fff;
			transition: all .6s ease;
		}
		&:hover:after{
			transform: translateX(15px);
			transition: all .6s ease;
		}
		&.mod-project{
			top: 0px;
			right: 0px;
			margin-top: 30px;
		}
	}
}
