.article-company-description {
	background-image: url('../images/img_common_banner_9.png');
	background-repeat: no-repeat;

	&-container {
		padding: 32px;		
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 32px;
	}

	&-text {
		color: #fff;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 150%;
		white-space: pre-line;
	}

	&-link {
		width: 100%;
		display: flex;
    justify-content: center;
		align-items: center;
	}


	@media only screen and (max-width: 768px) {
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		&-container {
			padding: 32px 32px 15px 32px;
		}

		&-text {
			font-size: 14px;
		}

	}
}

