.box-has-outer_line {
	$c:&;
	position: relative;
	background-color: #f8f7f2;
	padding:53px 35px 18px 15px;
	&:before {
		content:"";
		position: absolute;
		top: -5px;
		left: -5px;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		border-right:1px solid #c3c0b4;
		border-bottom:1px solid #c3c0b4;
		display: block;
		z-index: 1;
	}
	.box-slash-colored {
		position: absolute;
		left: -3px;
		top: -25px;
		z-index: 2;
		background: #fff;
		padding-left: 10px;
	}
	&-inner {
		position: relative;
		z-index: 2;
	}
	&-img {
		text-align: center;
	}
	&-text {
		color: $black;
		font-size: 13px;
		font-weight: 400;
		line-height: (26 / 13);
		margin-top: 13px;
	}
	&.mod-even {
		background-color: #f5f7f2;
		.box-slash-colored {
			&-num {
				color: #4f6537;
			}
			&-sep {
				background-color: #4f6537;
			}
			&-title {
				color: #4f6537;
			}
		}
	}
	&.mod-underline {
		$cc:&;
		background-color: #f8f7f2;
		padding:40px 60px 15px;
		width: 100%;
		max-width: 1120px;
		margin-left: auto;
		margin-right: auto;
		#{$c}-inner {
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
		}
		&:before {
			display: none;
		}
		&:after {
			bottom: -4px;
			left: 0;
			position: absolute;
			content:"";
			width: 100%;
			height: 1px;
			background-color: #c3c0b4;
		}
		@at-root {
			.title-has-center_line + #{$cc} {
				position: relative;
				z-index: 1;
				margin-top: -20px;
			}
		}
	}
}


@include breakpoint(sp) {
	.box-has-outer_line {
		$c:&;
		padding:10px 20px 37px 20px;
		.box-slash-colored {
			position: absolute;
			padding-left: 0;
			top: -20px;
			left: 0;
			width: 100%;
			text-align: center;
			background-color: transparent;
		}
		&-inner {
			margin-top: 38px;
		}
		&-text {
			line-height: (48 / 26);
			margin-top: 28px;
		}
		& + #{$c} {
			margin-top: 60px;
		}

		&.mod-underline {
			$cc:&;
			padding:40px 12px;
			width: 100%;
			max-width: (600 / 640) * 100%;
			#{$c}-inner {
				display: block;
				margin-top: 0;
			}
			&:before {
				display: none;
			}
			&:after {
				bottom: -4px;
			}
			@at-root {
				.title-has-center_line + #{$cc} {
					margin-top: -13px;
				}
			}
		}
	}
}
