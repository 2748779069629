.box-cat{
	display: table;
	font-size: 12px;
	font-weight: bold;
	padding: 2px 8px;
	color: #fff;
	&.mod-line_right_bottom{
	border-right: 2px solid #fff;
	border-bottom: 2px solid #fff;
	}
	&.mod-brown{
		background-color: #68603e;
	}
	&.mod-green{
		background-color: #4f6537;
	}
	&.pc{
		display: table;
	}
	&.sp{
		display: none;
	}
}

@include breakpoint(sp) {
.box-cat{
	display: table;
	font-size: 12px;
	padding: 2px 6px;
	color: #fff;
	margin-top: 10px;
	&.mod-brown{
		background-color: #68603e;
	}
	&.mod-green{
		background-color: #4f6537;
	}
	&.pc{
		display: none;
	}
	&.sp{
		display: table;
	}
}

}
