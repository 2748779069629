.nav-column {
    &-row {
        display: flex;
        justify-content:flex-start;
        flex-wrap:wrap;
        align-items:center;
    }
    &-row + &-row {
        margin-top: 10px;
    }
    &-title {
        font-size: 13px;
        color: #68603e;
        font-weight: bold;
        padding-right: 30px;
        &:before {
            content:"";
            width: 6px;
            height: 6px;
            display: block;
            background-color:#68603e;
            display: inline-block;
            margin-right: 8px;
            vertical-align: 2px;
        }
    }
    &-list {
        display: flex;
        justify-content:flex-start;
        flex-wrap:wrap;
        align-items:center;
    }
    &-item {
        min-width: 100px;
        padding-left: 35px;
        padding-right: 35px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin-left: -1px;
        border:1px solid #68603e;
        background-color: #fff;
        cursor: pointer;
        color: #68603e;
        transition:all .3s;
        &.mod-active, &:hover {
            color: #fff;
            background-color: #68603e;
        }
        &.mod-active:hover {
            color: #fff;
            background-color: #68603e;
        }
        @include breakpoint(sp){
            &:hover {
                color: #68603e;
                background-color: #fff;
            }
        }
    }
}

.ie_11 {
    .nav-column-item {
        padding-top: 2px;
    }
}
@include breakpoint(sp){
    .nav-column {
        &-row {
            display: block;
        }
        &-row + &-row {
            margin-top: 15px;
        }
        &-title {
            padding-right: 0;
            margin-bottom: 15px;
        }
        &-list {
            display: flex;
            justify-content:flex-start;
            flex-wrap:wrap;
            align-items:center;
        }
        &-item {
            padding-left: 0;
            padding-right: 0;
            min-width: 50%;
            margin-bottom: 8px;
        }
    }
}