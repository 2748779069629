

@include breakpoint(sp) {
	.thumbnail_under_line_box{
		width: 90%;
		margin: 0 auto;
		position: relative;
		border-bottom: 1px solid #cdd2d5;
		padding-bottom: 5px;
		margin-bottom: 5px;
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:last-of-type{
			margin-bottom: 60px;
		}
		&::after{
			content: '';
			display: inline-block;
			margin-left: 15px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7.5px 0 7.5px;
			border-color: #c3c0b4 transparent transparent transparent;
		}
		&-img{
			display: inline-block;
			background: resolve("collect_sp.jpg") no-repeat 0 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: 100px;
			height: 50px;
			position: relative;
		}
		&-text{
			display: inline-block;
			font-size: 14px;
			font-weight: bold;
			color: #675f3e;
			margin-left: 20px;
			width: (100% - 100)px;
			&.mod-green{
				color: #4f6537;
			}
		}
	}
}
