.header-month{
	display: flex;
	max-width: 1040px;
	justify-content: space-between;
	margin: 0 auto;
	@extend .font-kreon_r;
	.month{
		font-size: 14px;
		padding: 8px 0px;
		width: 82px;
		text-align: center;
		&.odd{
		color: #68603e;
			&.on{
				border-top: solid 1px #c3c0b4;
				background-color: #f8f7f2;
			}
		}
		&.even{
		color: #5a6f44;
			&.on{
				border-top: solid 1px #c6cfbc;
				background-color: #f5f7f2;
			}
		}
	}
}

@include breakpoint(sp) {
	.header-month{

	}
}
