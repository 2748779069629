.common-component{
	.post-content {
		margin-top: -12px;
		max-width: 660px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.post-content * + * {
		margin-top: 0;
	}
	.article-row {
		margin-top: 75px;
		& + .article-row {
			margin-top: 70px;
		}
	}
	.article-header-headline_triangle {
		&.mod-h1 {
			color: #4f6537;
			padding-top: 18px;
			padding-bottom: 18px;
			letter-spacing: 0.1em;
		}
		&.mod-h2 {
			border : none;
			color: #1c1f21;
			margin-top: 48px;
			letter-spacing: 0.1em;
		}
	}
	.article-header-headline_square {
		margin-bottom: 28px;
		margin-top: 48px;
	}
	.box-link-cat_faq {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		+ * {
			margin-top: 62px;
		}
	}
	.box-linkarea {
		margin-top: 58px;
		.box-link {
			&.mod-resposive {
				padding : 15px 20px 11px 20px;
			}
			&_center {
				&.short,&.long {
					margin-left: -15px;
				}
			}
			&_right {
				&.more {
					margin-right: -4px;
				}
				&.short,&.long {
					margin-right: 17px!important;
				}
			}
			&_left,&_center,&_right {
				margin-top: 29px;
				&.long {
					&.mod-resposive {
						padding-left: 26px;
					}
				}
				&.more {
					margin-left: 6px;
				}
				a {
					color: #1c1f21;
					text-decoration: none;
				}
			}
		}
	}
	.boxarea {
		border-bottom: 1px dotted #cdd2d5;
		font-size: 13px;
		line-height: 2;
		max-width: 960px;
		width: 100%;
		margin: 60px auto 114px;
		padding-bottom: 107px;
	}
	.box-link-cat-item{
		letter-spacing: 0.1em;
		&:after {
			border-color: #c6cfbc transparent transparent;
		}
	}
	.box-over_right-bottom-line {
		padding: 60px 72px 63px;
		&.mod-harf {
			margin: 0;
			padding-left: 69px;
			padding-right: 64px;
		}
	}
	.box-shadow.mod-parts {
		padding: 28px 20px 23px 25px;
		&.mod-harf.mod-brown {
			padding: 30px 15px 22px 16px;
			&:first-of-type {
				margin-left: 8px;
			}
		}
		&.mod-trisection.mod-brown {
			padding: 23px 15px 10px 24px;
		}
	}
	.contents-margin{
		margin-top: 20px;
	}
	.detail-info-info {
		dd {
			padding-left: 4px;
			padding-right: 46px;
		}
		dd + dd {
			text-align: left;
			padding-right: 15px;
			padding-left: 65px;
		}
		dd:first-of-type {
			border-top: 1px solid #cdd2d5;
		}
		dt:first-of-type {
			border-top: 1px solid #cdd2d5;
		}
	}
	* + .detail-info-info {
		margin-top: 194px;
	}

	.grid {
		$grid:&;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		#{$grid}.mod-inner + #{$grid}.mod-inner {
			margin-top: 60px;
			margin-left: -4px;
		}

		&-single_col-img {
			max-width: 460px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			* + & {
				margin-top: 60px;
			}
		}
		&.mod-2col {
			max-width:660px;
			> .col {
				width: 46.9696%;
			}
		}
		&.mod-only-img {
			> .col {
				width: 320px;
			}
		}
		.col {
			font-size: 13px;
			letter-spacing: 0;
			line-height: 2;
		}
		@at-root {
			.common-component {
				* + .grid {
					margin-top: 60px;
				}
			}
		}
	}

	.list-num {
		margin-top: 11px;
		li {
			counter-increment: list_num;
			&:before {
				content: counter(list_num);
				font-size: 17px;
				color: #4f6537;
				display: inline-block;
				margin-right: 10px;
				@extend .font-kreon_r;
			}
			&:nth-of-type(n + 2) {
				margin-top: -5px;
			}
		}
	}
	.page-header + * {
		margin-top: 62px;
	}
	.text_link {
		border: none;
		display: block;
		margin-top: 19px;
	}
	.post_text {
		margin-top: 11px;
	}
	.post_title_h3 {
		font-size: 16px;
		margin-top: 49px;
	}
	.post_title_h4 {
		margin-top: 46px;
		font-size: 14px;
		color: #4f6537;
	}
	.post_title_h5 {
		margin-top: 46px;
		font-size: 14px;
	}
}
@include breakpoint(sp) {
	.common-component {
		.post-content {
			width: 87.5%;
			margin-left: auto;
			margin-right: auto;
		}
		.article-header-headline_triangle {
			&.mod-h1 {
				padding: 7px 16px 8px;
				font-size: 18px;
				&:before {
					border-top: 10px solid #c6cfbc;
					border-right: 10px solid transparent;
				}
			}
			&.mod-h2 {
				padding: 15px 25px;
				&:before {
					border-top: 15px solid #c6cfbc;
					border-right: 15px solid transparent;
				}
			}
		}
		.article-header-headline_square {
			font-size: 16px;
			margin-bottom: 20px;
			margin-top: 40px;
		}
		.box-link-cat_faq {
			width: 87.5%;
			max-width: auto;
			margin: 0 auto;
			+ * {
				margin-top: 30px;
			}
		}
		.boxarea {
			width: 93.75%;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 50px;
			margin-bottom: 50px;
		}
		.box-link {
			width : 85.714285716%;
			&_left,&_center,&_right {
				&.long {
					a {
						line-height: (40 / 26);
					}
				}
			}
			&.mod-resposive {
				padding: 5px 11.5%;
			}
		}
		.box-link-cat-item_separate:last-of-type {
			display: none;
		}
		.box-over_right-bottom-line {
			padding: 40px 15px;
			&.mod-harf {
				padding-left: 15px;
				padding-right: 15px;
			}
			+ .box-over_right-bottom-line {
				margin-top: 30px;
			}
		}
		.detail-info-info-sp {
			margin-top: 45px;
			dl {
				font-size: 11px;
			}
			dd + dd {
				padding-left: 15px;
				text-align: left;
			}
		}
		.grid {
			width: 100%;
			&.mod-2col {
				width: 100%;
				margin-top: 20px;
				.col {
					width: 100%;
					margin-top: auto;
					&-img {
						max-width: 320px;
						&.left,&.right,&.center {
							margin-left: auto;
							margin-right: auto;
						}
					}
					* + * {
						font-size: 13px;
						margin-top: 30px;
					}
				}
				.col + .col {
					margin-top: 28px;
				}
				&.mod-wimg.sp {
					display: block!important;
				}
			}
			&.mod-inner {
				width: 100%;
				margin-left: auto;
				margin-right: auto;
			}
		}
		ul,ol {
			line-height: (48 / 26);
		}
		.list-num {
			li {
				&:before {
					font-size: 14px;
					margin-right: 7px;
				}
			}
		}
		.more,.short {
			span {
				font-size: 12px;
			}
		}
		* + .list-pointnone {
			margin-top: 22px;
		}
		.list_header {
			background-position: 0 20%;
		}

		.post_text {
			font-size: 13px;
		}
	}//.common-component
}//breakpoint
