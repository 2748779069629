.tag_list_wrap {
  margin-bottom: 50px;
}
.article_mv + .tag_list_wrap {
  margin-top: -30px;
}
.article-footer + .tag_list_wrap {
  margin-top: 50px;
  margin-bottom: 0;
}
.tag_list {
  display: flex;
  justify-content:flex-start;
  flex-wrap:wrap;
  height: 80px;
  overflow: hidden;
  &_item {
    a {
      display: block;
      background-color: #68603e;
      padding:5px 10px;
      border-radius: 30px;
      color: #ffffff;
      min-width: 100px;
      text-align: center;
      font-weight: 700;
      margin-right: 10px;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
  &_more {
    display: block;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    margin-top: 15px;
    color: #68603e;
    font-size: 14px;
  }
}

.tag_list._show {
  height: auto;
}

@media only screen and (max-width: 768px){
  .article_mv + .tag_list_wrap {
    margin-top: 0;
  }
  .tag_list {
    height: 110px;
  }
  .tag_list_item a {
    font-size: 12px;
    min-width: 50px;
    margin-bottom: 10px;
  }
  .tag_list._show {
    height: auto;
  }
}