.home {
	#wrap {
		opacity: 0;
		position: relative;
		&:before {
			content:"";
			background:resolve('bg@2x.png') repeat left top;
			background-size: size('bg@2x.png',2);//retinaは第二引数に2
			position: absolute;
			top: 0;
			width: 100%;
			height: 1190px;
		}

	}
	.glid.mod-transform-img {
		margin-bottom: -40px;
		> * {
			margin-bottom: 40px;
		}
		&:after {
			content:"";
			width:24.47917%;
			height: 0;
		}
	}
}
.home_btn-more_wrap {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 960px;
	.btn-more {
		right: 0;
	}
}

.contents-news,.contents-event {
	padding-bottom: 144px;
	.box-link {
		position: absolute;
		position:absolute;
		bottom: 32px;
		left:50%;
		transform:translate(-50%,-50%);
	}
}
@include breakpoint(sp) {
	.glid.mod-transform-img {
		&:after {
			display: none;
		}
	}
	.glid.mod-project {
		margin-top: 40px;
		.glid-3col + .glid-3col {
			margin-top: 20px;
		}
		.thumbnail-item {
			width:(560 / 640) * 100%;
			&-img {
				height: 0;
				padding-top: 25%;
			}
		}
	}
	.home {
		.section.mod-sns {
			margin-top: 40px;
		}
		.footer {
			margin-top: 0;
		}

		.glid.mod-transform-img {
			margin-bottom: -30px;
			> * {
				margin-bottom: 30px;
			}
			&:after,&:before {
				display: none;
			}
		}
	}
	.contents-news,.contents-event {
		.no-results {
			text-align: center;
		}
	}
}
