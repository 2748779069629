.mv_slide {
	$c:&;
	width: 840px;
	height: 500px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	//margin-left: 190px;
	&-wrapper {
		max-width: 100%;
		overflow: hidden;
		padding-left: 148px;
		margin-top: -200px;
	}
	&-item {
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		width: 200px;
		height: 120px;
		&.has_link {
			cursor: pointer;
			&:hover {
				transition:opacity .4s;
				opacity: .5!important;
			}
		}
	}
	&-info{
		width: 370px;
		height: auto;
		padding: 17px 18px;
		background-color: rgba(255,255,255,0.6);
		position: absolute;
		display: inline-block;
		bottom: 0;
		right: 0;
		color: #000;
		z-index: 20;
		display:none;
		&-title{
			font-size: 14px;
			font-weight: bold;
		}
		&-text{
			font-size: 13px;
			margin-top: 6px;
			line-height: 20px;
		}
	}
	&-center-placeholder {
		#{$c}-item {
			position: absolute;
			width: 100%;
			height: 100%;
			transition:opacity 1.5s;
			opacity:0;
			&.active {
				opacity:1;
			}
		}
	}
	&-left_pager {
		width: 300%;
		display: flex;
		justify-content:flex-end;
		flex-wrap:wrap;
		position: absolute;
		bottom: 0;
		right: 100%;
		#{$c}-item {
			margin-right: 15px;
			cursor: pointer;
			&:hover {
				transition:opacity .4s;
				opacity: .5!important;
			}
		}
	}
	&-right_pager {
		width: 300%;
		display: flex;
		justify-content:flex-start;
		flex-wrap:wrap;
		position: absolute;
		bottom: 0;
		left: 100%;
		#{$c}-item {
			margin-left: 15px;
			cursor: pointer;
			&:hover {
				transition:opacity .4s;
				opacity: .5!important;
			}
		}
		&.js-trans {
			transition:all .6s;
			transform: translateX(-220px);
		}
	}
}

.mv_slide-ctrl {
	position:absolute;
	width: 100%;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	& > * {
		@include opacity--anim(.4);
		cursor: pointer;
	}
	.prev {
		background:resolve('icon_mv_prev.png') no-repeat left top;
		background-size: size('icon_mv_prev.png');//retinaは第二引数に2
		width:width('icon_mv_prev.png');
		height:height('icon_mv_prev.png');
		@include text-indent-none();
		position:absolute;
		top:50%;
		left:-37px;
		transform:translate(0,-50%);
	}
	.next {
		background:resolve('icon_mv_next.png') no-repeat left top;
		background-size: size('icon_mv_next.png');//retinaは第二引数に2
		width:width('icon_mv_next.png');
		height:height('icon_mv_next.png');
		@include text-indent-none();
		position:absolute;
		top:50%;
		right:-33px;
		transform:translate(0,-50%);
	}
}

.js-phs-item.active {
	.mv_slide-info {
		display: block;
	}
}

@include breakpoint(sp) {
}
