.contents-pickup{
	width: 960px;
	margin: 0 auto;
	padding: 30px 40px;
	padding-right: 30px;
	border-right: 2px solid #c6cfbc;
	border-bottom: 2px solid #c6cfbc;
	position: relative;
	background-color: #fff;
	&:after{
		content: "";
		width: 100%;
		height: 100%;
		right: -10px;
		bottom: -10px;
		position: absolute;
		background-color: #f5f7f2;
		z-index: -10;
	}
	&-thumbnail{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 30px;
		&-subtitle {
			font-style: italic;
		}
		&-item{
			width: 195px;
			&:hover{
				opacity: 1.0;
			}
		}
		&-img{
			position: relative;
			background-image: url('http://placehold.jp/195x130.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: 195px;
			height: 130px;
			&:before{
				width: 52px;
				height: 27px;
				font-size: 14px;
				font-family: "kreon_r";
				top: -6px;
				left: -6px;
				color: #fff;
				background-color: #fff;
				position: absolute;
				line-height: 24px;
				letter-spacing: 2px;
			}
			&-new{
				min-width: 52px;
				padding-left: 2px;
				padding-right: 2px;
				height: 27px;
				font-size: 14px;
				font-family: "kreon_r";
				top: -6px;
				left: -6px;
				color: #fff;
				background-color: #fff;
				position: absolute;
				line-height: 24px;
				letter-spacing: 2px;
				span {
					height: 25px;
					background-color: #474b42;;
					text-align: center;
					display: inline-block;
					padding-left: 2px;
					padding-right: 2px;
					width: 100%;
				}
			}
			&-description{
				font-size: 12px;
				position: relative;
				z-index: 3;
				color: #4f6537;
				left: 0;
				bottom: 0;
				background-color: hsla(0,0%,100%,.8);
				word-break: break-word;
				margin-top: -18px;
				padding: 0 5px;
				display: table;
				font-style: italic;
				font-weight: bold;
			}
			&-mask{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(0,0,0,.4);
				transition: all .6s ease;
				opacity: 0;
				p {
					position: relative;
					color: #fff;
					margin-top: 45px;
					text-align: center;
					font-family: "kreon_r";
					&::after{
						content: '';
						position: absolute;
						background-image: resolve('arrow-right-mask.png');
						background-repeat: no-repeat;
						width: 30px;
						height: 10px;
						bottom: -13px;
						margin: 0 auto;
						left: 0;
						right: 0;
					}
				}
			}
			&:hover &-mask{
				opacity: 1;
			}
		}
		&-title{
			font-size: 13px;
			font-weight: bold;
			text-align: center;
			margin-top: 35px;
			color: #000;
		}
	}
}

@include breakpoint(sp) {
	.contents-pickup{
		&:after{
			content: "";
			width: 100%;
			height: 100%;
			right: -10px;
			bottom: -10px;
			position: absolute;
			background-color: #f5f7f2;
			z-index: -10;
		}
		&-textarea {
			overflow: hidden;
			width: 100%;
		}
		&-thumbnail{
			&-item{
				display: flex;
				width: 100%;
				&:last-of-type{
					margin-right: 0px;
				}
				& + &{
					margin-top: 15px;
				}
			}
			&-img{
				width:41.7857%;
				display: inline-block;
				position: relative;
				margin-right: 10px;
				padding-top: 30%;
				height: auto;
				&-new{
					width: auto;
					height: auto;
					font-size: 12px;
					line-height: 1;
					font-family: "kreon_r";
					top: -4px;
					left: -4px;
					color: #fff;
					background-color: #474b42;
					padding:4px 5px;
					position: absolute;
					border:1.5px solid #fff;
					span {
						width: auto;
						height: auto;
						position: relative;
						text-align: center;
						display: block;
						line-height: 1;
						top: 0.5px;
						letter-spacing: 1.2px;
					}
				}
				&-description{
					display: none;
				}
				&-mask{
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-color: rgba(0,0,0,.4);
					transition: all .6s ease;
					opacity: 0;
					p {
						position: relative;
						color: #ffff;
						margin-top: 45px;
						text-align: center;
						font-family: "kreon_r";
						&::after{
							content: '';
							position: absolute;
							background-image: resolve('arrow-right-mask.png');
							background-repeat: no-repeat;
							width: 30px;
							height: 10px;
							top: 25px;
							bottom: 0px;
							left: 0;
							right: 0;
						}
					}
				}
				&:hover &-mask{
					opacity: 1;
				}
			}
			&-subtitle {
				font-size: 11px;
				font-weight: 700;
				font-style: italic;
				color: #4f6537;
			}
			&-title{
				margin-top: 8px;
				font-size: 14px;
				text-align: left;
			}
		}
	}
}
