.banner-wrap.mod-recruit {
	margin-top: 120px;
	margin-bottom: 120px;
}
@include breakpoint(sp) {
	.banner-wrap.mod-recruit {
		margin-top: 60px;
		margin-bottom: 60px;
	}
}
