.basic_inner {
	max-width: 960px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	&.mod-670{
		max-width: 670px;
	}
}
@include breakpoint(sp) {
	.basic_inner {
		width: 90%;
	}
}
