.c-relatedArticle {
	border: 1px solid #666666;
	position: relative;
	margin: 20px 0;
	max-width: 640px;

	&:hover .c-relatedArticle_icon {
		transform: rotate(-10deg);
	}

	> a {
		text-decoration: none;
	}

	&_link {
		display: grid;
		grid-template-columns: 192px 1fr;
		gap: 16px;
	}
	&_icon {
		display: block;
		position: absolute;
		top: -11px;
		left: -15px;
		width: 64px;
		transition: transform 0.15s ease-in-out;
	}
	&_colL {
		margin: 0;
		padding: 8px 0 8px 8px;
	}
	&_colR {
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;
		padding-right: 8px;
	}

	&_cat {
		font-weight: bold;
		color: #000;
	}
	&_title {
		margin-top: 0;
		font-size: 16px;
		line-height: 1.41;
		font-weight: bold;
		color: #000;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		@supports (-webkit-line-clamp: 2) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: initial;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			/*! autoprefixer: off */
			-webkit-box-orient: vertical;
			/*! autoprefixer: on */
		}
	}
	&_img {
		width: 192px;
		height: 115px;
		background-size: cover;
		background-position: center;
		background-color: grey;
	}

	&_link-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0;
		gap: 7px;
	}

	&_article-price {
		display: flex;
		justify-content: space-between;
		margin-top: 0;

		&-text {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 150%;
			color: #a6943f;
		}

		&-link {
			margin-top: 0;
			object-fit: contain;
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}
@include breakpoint(sp) {
	.c-relatedArticle {
		&_colR {
			padding: 0;
			padding-top: 8px;
		}
		&_link {
			grid-template-columns: 119px 1fr;
			gap: 8px;
		}
		&_icon {
			top: -6px;
			left: -12px;
			width: 45px;
		}
		&_cat {
			font-size: 12px;
			margin-top: 0;
		}
		&_title {
			font-size: 14px;
			line-height: 1.41;
			padding-right: 8px;
		}
		&_img {
			width: 100%;
			height: 0;
			padding-top: 59.9%;
		}

		&_article-price {
			&-text {
				font-size: 14px;
				line-height: 150%;
				margin-bottom: 0;
			}

			&-link {
				display: initial;
				position: initial;
				align-self: flex-end;
			}
		}
	}
}
