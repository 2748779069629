.title-has-center_line {
	position: relative;
	z-index: 2;
	max-width: 1120px;
	margin-top: 47px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:center;
	img {
		position: relative;
		top: -4px;
	}
	&:before,&:after {
		content:"";
		background-color: #c3c0b4;
		width: 41.875%;
		height: 1px;
		display: block;
	}
	a {
		display: block;
	}
}
@include breakpoint(sp) {
	.title-has-center_line {
		max-width: (600 / 640) * 100%;
		margin-top: 47px;
		a {
			display: block;
			width: 32%;
		}
		img {
			position: relative;
			top: -4px;
		}
		&:before,&:after {
			width: 28%;
		}
	}
}
