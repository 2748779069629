.article-yomimono {
	&-entry{
		&-wrap{
		}
		&-cont{
			a{
				transition: opacity .6s;
				// background-color: red;
				color: #000;
				position: relative;
				z-index: 9;
				display: block;
				&:nth-child(odd){
					.article-yomimono-entry-col{
						flex-direction: row;
					}
				}
				&:nth-child(even){
					.article-yomimono-entry-col{
						flex-direction: row-reverse;
					}
				}
				&:not(:last-child){
					.article-yomimono-entry-col{
						margin-bottom: 60px;
					}
				}
			}
		}
		&-col{
			display: flex;
			justify-content: space-between;
			// align-items: center;
			position: relative;
			flex-direction: row-reverse;

			// &:not(:last-child){
			// 	margin-bottom: 60px;
			// }
			&:nth-child(odd){
				flex-direction: row;
			}
		}
		&-meta{
			display: flex;
			justify-content: space-between;
		}
		&-tag{
			// font-size: 12px;
			// border-radius: 10px;
			// border: 1px solid;
			// width: 110px;
			&-cont{
				display: flex;
				flex-direction: column;
			}
		}
		&-date{
			font-size: 11px;
		}
		&-img{
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			// width: width("img_yomimono_entry01.png",2);
			width: percentage(442/720);
			height: 0;
			padding-top: 42%;
			position: relative;
			&.has-icon-new{
				&:before{
					content: "";
					display:block;
					background: resolve('icon-new.png') no-repeat left top;
					background-size:contain;
					width:width('icon-new.png',2);
					height:height('icon-new.png',2);
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
		&-detail{
			width: 34%;
			padding-top: 30px;
			position: relative;

			.c-icon-new{
				margin-bottom: 5px;
			}
		}
		&-tit{
			font-size: 21px;
			font-weight: 700;
			margin-top: 18px;
			letter-spacing: -.004em;
		}
		&-txt{
			font-size: 14px;
			font-weight: 700;
			margin-top: 22px;
			line-height: 1.7;
			letter-spacing: -.05em;
		}
		&-link{
			background: resolve("img_yomimono_read-more.png",2) no-repeat center;
			width: width("img_yomimono_read-more.png",2);
			// height: height("img_yomimono_read-more.png",2);
			background-size: 100%;
			margin: 20px auto 0;
			float: right;
			height: 0;
			padding-top: 8%;

		}
		&-line{
			background: resolve("img_line_grass.png",2) no-repeat center;
			// width: width("img_line_grass.png",2);
			width: 100%;
			height: height("img_line_grass.png",2);
			background-size: cover;
			overflow: hidden;
			margin-top: 99px;
		}
	}
	&-label{
		&-items{
			display: grid;
			place-items: center;
			margin-left: auto;
			margin-right: auto;
			margin-top: 51px;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 90%;
		}
		&-item{
			display: flex;
			justify-content: center;
			width: 48%;
			&-link{
				font-size: 11px;
				padding:5px 0 24px 16px;
				letter-spacing: .13em;
				a{
					text-decoration: underline;
					color: #333;
					&:not(:first-child):before{
						content: '/';
						display: inline-block;
					}
				}
			}
			&-cont{
				display: grid;
				place-items: center;
			}
		}
	}
}
@include breakpoint(sp) {
	.article-yomimono {
		&-entry{
			&-wrap{
				width: 100vw;
				position: relative;
				left:50%;
				transform:translate(-50% ,0%);
			}
			&-col{
				flex-direction: column !important;
				&:not(:last-child){
					margin-bottom: 27px;
				}
			}
			&-detail{
				width: 86%;
				padding-top: 21px;
				margin-left: auto;
				margin-right: auto;
				line-height: 1.8;
			}	
			&-img{
				width: 100%;
				padding-top: 68.416%;
				&.has-icon-new{
					&:before{
						top:0;
						left:10px;
					}
				}
			}
			&-tit{
				margin-top: 7px;
				font-size: 17px;
				letter-spacing: 0.01em;
			}
			&-txt{
				font-size: 14px;
				margin-top: 12px;
			}
			&-link{
				width: 94px;
				margin-top: 14px;
			}
			&-line{
				margin-top: 30px;
				position: relative;
				left:50%;
				transform:translate(-50% ,0%);
				width: 100vw;
			}
		}
		&-label{
			&-items{
				width: 100%;
			}
			&-item{
				margin-bottom: 8px;
				width: 100%;
				justify-content: center;
				&-link{
					font-size: 12px;
					padding-top: 4px;
					padding-bottom: 0px;
				}
			}
		}
	}


}