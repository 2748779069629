.search_region {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 102px;
	$c:&;
	&.mod-yomimono-renew{
		margin-bottom: 50px;
	}
	&-button {
		align-items: center;
		background: #4f6537;
		color: #fff;
		display: flex;
		font-weight: 700;
		height: 30px;
		justify-content: center;
		padding-right: 20px;
		max-width: 430px;
		width: 100%;
	}
	&-mv {
		max-width: 210px;
		height: auto;
		position: relative;
		background-image: url("http://placehold.jp/210x268.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
	}
	&-contents {
		font-size: 14px;
		max-width: 430px;
		width:100%;
		margin-left: auto;
		&.mod-green {
			color: #4f6537;
			a {
				color: #4f6537;
			}
		}
		a:hover {
			transition: all .6s;
			opacity: .5;
		}
	}
	&-contents-global {
		align-items: center;
		border-bottom: 1px solid #c6cfbc;
		display: flex;
		font-size: 13px;
		padding: 6px 0;
		padding-left: 10px;
		max-height: 33px;

		ul.mod-normal {
			font-weight: 400;
			li {
				padding: 4px 6px;
			}
			li.mod-on {
				font-weight: 700;
				background: #eaefe5;
			}
		}
		li.mod-flex {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			width: 100%;
			p {
				align-items: center;
				display: flex;
				justify-content: flex-start;
				max-width: 68px;
				width: 100%;
			}
		}
		li.mod-bold {
			font-weight: 700;
		}
		ul li {
			display: inline-block;
			a {
				color: #4f6537;
			}
		}
	}
}
.mrl-30 {
	margin-left: 30px;
	margin-right: 30px;
}
.thumbnail-basic-item-label-lead.mod-h2 {
	font-family: 'kreon_r';
}

.ie {
	.search_region-contents-global ul.mod-normal li {
		padding-top: 0;
		padding-bottom: 0;
	}
}

@include breakpoint(sp) {
	.search_region {
		$c:&;
		justify-content: center;
		margin-bottom: 40px;
		&.mod-yomimono-renew{
			margin-top: 20px;
			#{$c}-contents{
				max-width: 100%;
			}
			#{$c}-button{
				max-width: 100%;
			}
		}
		&-mv {
			max-width: 560px;
			height: 240px
		}
		&-contents {
			font-size: 12px;
			&-global {
				padding: 5px 0;
				padding-left: 5px;
				max-height: 100%;
				& li {
					font-size: 12px;
					padding: 2px 5px;
				}
				& li.mod-flex {
					display: flex;
					justify-content: flex-start;
					flex-wrap: nowrap;
					width: 100%;
					p {
						font-size: 11px;
						flex-wrap: nowrap;
					}
					p.kinki {
						align-items: flex-start;
					}
			}
		}
		}
		&-button {
			color: #4f6537;
			background: #fff;
			padding: 0;
			padding-bottom: 10px;
			padding-left: 5px;
			border-bottom: 1px solid #4f6537;
			justify-content: left;
			margin-top: 30px;
		}
	}
	.h2_noukasantitle {
		color: #68603e;
		font-size: 13px;
		font-style: oblique;
		font-weight: 700;
		border-bottom: 1px solid #c3c0b4;
		padding-bottom: 5px;
		margin-bottom: 20px;
	}
	.thumbnail-basic-item-wrap.mod-under-border.mod-h2 {
		&:before {
			content: none;
		}
	}
	.thumbnail-basic-item-label-lead.mod-h2 {
		font-size: 13px;
	}
}
