.calender {
	&-content{
		width: 100%;
		max-width: 960px;
		margin:0 auto;
		position: relative;
		.title{
			position: absolute;
			top:0;
		}
	}
	&-table{
		padding-top: 77px;
		table{
			width: 100%;
			table-layout: fixed;
		}

		//ナビ
		.fc-toolbar{
			position: absolute;
			top:25px;
			right:0;
		}

		.fc-today-button{
			display: none;
		}

		.fc-prev-button,
		.fc-next-button{
			background: none;
			border:0;
			box-shadow:0 0 0;
			.fc-icon{
				display: none;
			}
		}
		.fc-prev-button{
			background:resolve("arrow_calender_prev.png") center no-repeat;
			background-size:size("arrow_calender_prev.png",2);
			width:width("arrow_calender_prev.png",2);
			height:1em;
		}
		.fc-next-button{
			background:resolve("arrow_calender_next.png") center no-repeat;
			background-size:size("arrow_calender_next.png",2);
			width:width("arrow_calender_next.png",2);
			height:1em;
		}
		.fc-button-group h2{
			font-size: 20px;
			font-weight: normal;
			color:#68603e;
			padding:0 20px;
			line-height: 1;
			font-family: kreon_r;
		}
		button:hover{
			opacity:0.6;
		}

		//表
		th{
			background:#f8f7f2;
			font-weight: normal;
			font-size: 12px;
			padding:6px 0;
			border:1px solid #c3c0b4;
			font-family: kreon_r;
			color: #68603e;
			letter-spacing: 1.2px;
		}
		td{
			border:1px solid #c3c0b4;
		}
		.fc-day-top{
			font-size: 12px;
			color: #68603e;
			line-height: 1;
			padding:4px 4px 0;
			height: 16px;
			text-align: left;
		}
		.fc-ltr .fc-basic-view .fc-day-top .fc-day-number{
			float: left;
		}
		.fc-event{
			background:#ebe9e2;
			border:1px solid #ebe9e2;
			color:#68603e;
			font-size: 10px;
			border-radius: 0;
			padding:6px;
		}


		.fc-day-grid-event{
			margin:4px 4px 0;
		}
	}
}

.fc-time {
	display: none!important;
}

.calender-table td.hasEvent {
	.fc-day-number {
		display: none;
	}
}
.fc-day-number {
	font-family: 'kreon_r';
}

.fc-view-container {
	background-color: #fff;
}

.fc-button-group-mon {
	font-size: 20px;
	font-family: 'kreon_r';
}
.fc-button-group-mon-after {
	font-size: 14px;
	display: inline-block;
	padding-left: 2px;
}
.fc-basic-view .fc-body .fc-row {
	min-height: 86px;
}
.calender-table .fc-event {
	padding:3px 6px;
}
.fc-day-grid-event .fc-content {
	white-space: normal;
}
.fc-row .fc-content-skeleton {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
}
.fc .fc-row .fc-content-skeleton td {
	vertical-align: bottom;
	padding-bottom: 2px;
}
.fc-scroller.fc-day-grid-container {
	//height: auto!important;
}
.calender-table table {
	//height: 100%;
}

@include breakpoint(sp) {
	.calender{
		&-content{
			padding:0 20px;
			.title{
				position: relative;
			}
		}
		&-table{
			padding-top: 0;
			table{
				width: 100%;
				table-layout: fixed;
			}

			//ナビ
			.fc-toolbar{
				position: static;
				margin-bottom: 0;
				.fc-right{
					float: none;
					margin:0 auto;
					padding-top: 12px;
				}
			}
			.fc .fc-toolbar>*>*{
				margin-left: 0;
				float: none;
			}

			.fc-button-group h2{
				font-size: 17px;
			}
		}
	}

	.fc-event-container {
		display: none;
	}
	.fc-basic-view .fc-body .fc-row {
		min-height: auto;
	}
	.fc-row.fc-week.fc-widget-content {
		//height: 0!important;
	}
	.fc-row .fc-content-skeleton {
		padding-top: 12%;
		position: relative;
		table {
			position: absolute;
			top: 0;
		}
	}
	.fc-scroller.fc-day-grid-container {
		overflow: hidden!important;
		//height: auto!important;
	}
	.fc-row .fc-content-skeleton {
		z-index: 0;
	}
	.calender-table td.hasEvent {
		background-color: #ebe9e2;
		background-image:resolve('bg_hasevent.png');
		background-position: center bottom 3px;
		background-repeat: no-repeat;
		background-size: size('bg_hasevent.png',2);//retinaは第二引数に2
		.fc-day-number {
			font-size: 12px;
			color: #68603e;
			display: block;
			line-height: 1;
			padding:6px 6px 0;
		}
	}
	.fc-day.fc-today {
		.fc-day-number {
			font-size: 12px;
			font-weight: 400;
			padding:0 6px;
			display: inline-block;
			vertical-align: 2px;
			color: #68603e;
		}
	}
}
