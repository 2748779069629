.thumbnail-transform-item_foot_components{
	order: 5;
	margin-top: 135px;
	@extend .base_width;
	.thumbnail-transform-item{
		padding-bottom: 17px;
		width: (235 / 960) * 100%;
		&:hover{
			opacity: 1.0;
		}
		&.mod-odd{
			border-bottom: solid 1px #c3c0b4;
			position: relative;
			color: #68603e;
			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 5px 5px;
				border-color: transparent transparent #c3c0b4 transparent;
			}
		}
		&.mod-even{
			border-bottom: solid 1px #c6cfbc;
			position: relative;
			color: #4f6537;
			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 5px 5px;
				border-color: transparent transparent #c6cfbc transparent;
			}
		}
		&-img{
			position: relative;
			width: 235px;
			height: 135px;
			background-image: url("http://placehold.jp/235x135.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			&-book{
				width: 100%;
				height: 145px;
				z-index: 2;
				position: relative;
			}
			&-mask{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				background-color: rgba(0,0,0,0.4);
				transition: all 0.6s ease;
				opacity: 0;
				p{
					position: relative;
					color: #ffff;
					margin-top: 54px;
					text-align: center;
					font-family: kreon_r;
					&:after{
						content: '';
						position: absolute;
						background-image: resolve('arrow-right-mask.png');
						background-repeat: no-repeat;
						background-position: center;
						width: 100%;
						height: 10px;
						top: 25px;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
			}
			&:hover &-mask{
				opacity: 1;
				z-index: 1;
			}
		}
		&-label{
			z-index: 0;
			position: relative;
			padding: 0 20px;
			padding-top: 20px;
			&-title{
				font-size: 16px;
				font-weight: bold;
				position: relative;
			}
			&-date{
				font-size: 12px;
				margin-top: 21px;
				font-family: kreon_r;
				font-weight: normal;
			}
			&-lead{
				font-size: 14px;
				margin-top: 9px;
			}
			&:before{
				content: "";
				background-color: #f8f7f2;
				width: 35px;
				height: 40%;
				top: 0;
				left: 8px;
				position: absolute;
			}
		}
	}//thumbnail-transform-item
	.article-header {
		&-date {
			font-family: 'kreon_r';
			font-size: 14px;
			font-weight: 400;
			line-height: (24 / 13);
			padding-bottom: 3px;
			letter-spacing: 1.4px;
			background:resolve('line-black-dashed.png') repeat-x left bottom;
			background-size: size('line-black-dashed.png',2);
		}
		&-title {
			padding-top: 17px;
			font-size: 20px;
			font-weight: 700;
			line-height: (24 / 20);
			text-align: center;
		}
		&-headline {
			font-size: 13px;
			font-style:italic;
			position: relative;
			color: #68603e;
			padding-bottom: 8px;
			font-weight: 700;
			border-bottom: 1px solid #c3c0b4;
			margin-bottom: 20px;
			&.mod-extend{
			font-size: 22px;
			font-style: italic;
			color: #68603e;
			padding: 15px 0 13px 28px;
			font-weight: 700;
			border-bottom: 1px solid #c3c0b4;
			margin-bottom: 40px;
			letter-spacing: 1px;
				&.mod-green{
				color: #4f6537;
				border-bottom: 1px solid #4f6537;
				}
				.btn-more{
				color: #000;
				font-size: 13px;
				font-weight: 700;
				letter-spacing: 1px;
				position: absolute;
				top: 26px;
				right: 2px;
				}
			}
			&.mod-thumbnail{
			font-size: 16px;
			font-style: normal;
			color: #000;
			padding: 15px 0 8px 31px;
			font-weight: 700;
			border-bottom: 0 solid #c3c0b4;
			margin-bottom: 26px;
			letter-spacing: 2px;
				.btn-more{
				font-size: 13px;
				color: #000;
				font-weight: 700;
				letter-spacing: 1px;
				position: absolute;
				top: 17px;
				right: 0px;
				}
			}
			&.mod-green{
			color: #4f6537;
			}
		}
		&-headline_triangle{
			font-size: 22px;
			font-weight: 700;
			position: relative;
			color: #4f6537;
			padding-bottom: 8px;
			font-weight: 700;
			border-bottom: 1px solid #c3c0b4;
			margin-bottom: 35px;
			padding: 15px 0 13px 28px;
			letter-spacing: 1px;
			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				border-top: 20px solid #c6cfbc;
				border-right: 20px solid transparent;
			}
		}
	}//article-header
}




@include breakpoint(sp) {
	.thumbnail-transform-item_foot_components{
		order: 5;
		margin: 70px auto 0px;
		.thumbnail-transform-item{
			padding-bottom: 26px;
			width: 48.96429%;
			margin-bottom: 30px;
			&:last-of-type{
				margin-right: 0px;
			}
			&.mod-odd{
				border-bottom: solid 1px #c3c0b4;
				position: relative;
				color: #68603e;
				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					right: 0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 5px 5px;
					border-color: transparent transparent #c3c0b4 transparent;
				}
			}
			&.mod-even{
				border-bottom: solid 1px #c6cfbc;
				position: relative;
				color: #4f6537;
				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					right: 0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 5px 5px;
					border-color: transparent transparent #c6cfbc transparent;
				}
				.thumbnail-transform-item-label:before {
					background-color: #f5f7f2;
				}
			}
			&-img{
				width: 100%;
				position: relative;
				z-index: 2;
				position: relative;
				&-book{
					width: 100%;
					height: 145px;
					z-index: 2;
				}
				&-mask{
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					background-color: rgba(0,0,0,0.4);
					transition: all 0.6s ease;
					opacity: 0;
					p{
						position: relative;
						color: #ffff;
						margin-top: 54px;
						text-align: center;
						font-family: kreon_r;
						&:after{
							content: '';
							position: absolute;
							background-image: resolve('arrow-right-mask.png');
							background-repeat: no-repeat;
							background-position: center;
							width: 100%;
							height: 10px;
							top: 25px;
							bottom: 0;
							left: 0;
							right: 0;
						}
					}
				}
				&:hover &-mask{
					opacity: 1;
					z-index: 1;
				}
			}
			&-label{
				margin-left: 0px;
				z-index: 1;
		 		position: relative;
				&-title{
					font-size: 14px;
					font-weight: bold;
					text-align: center;
				}
				&-date{
					font-size: 12px;
					margin-top: 10px;
				}
				&-lead{
					font-size: 12px;
					margin-top: 9px;
				}
				&:before{
					content: "";
					background-color: #f8f7f2;
					width: 35px;
					height: 110%;
					top: 0;
					left: 8px;
					position: absolute;
					z-index: -10;
				}
			}
		}
		.article-header {
			&-title {
				padding-top: 10px;
				font-size: 16px;
				padding-left: 20px;
				padding-right: 20px;
				text-align: left;
			}
			&-date {
				padding-left: 20px;
				padding-right: 20px;
			}
			&-headline_triangle{
				font-size: 16px;
				&::before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					border-top: 16px solid #c6cfbc;
					border-right: 16px solid transparent;
				}
			}
		}
	}
}
