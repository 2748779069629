.header-logo{
	position: relative;
	padding-top: 59px;
	margin-left: 118px;
	float: left;
	&-line{
		position: absolute;
		top: 0px;
		left: -38px;
	}
	&.mod-under-layer{
		padding-top: 0px;
		margin-left: 0px;
		display: inline-block;
		margin-right: 0;
		float: none;
		img{
			width: 257px;
		}
	}
}

@media only screen and (max-width:1205px){
	.header-logo {
		margin-left: 48px;
	}
}
