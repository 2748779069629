.box-link{
	font-size: 13px;
	font-weight: bold;
	color: #000;
	//width: 180px;
	min-width: 180px;
	padding: 0 20px;
	height: 40px;
	border-right: solid 1px #000;
	border-bottom: solid 1px #000;
	text-align: center;
	line-height: 40px;
	display: block;
	position: relative;
	letter-spacing: 1.3px;
	transition: all .6s;
	&:hover{
		opacity: 1;
		background-color: #f8f7f2 !important;
	}
	&.sp{
		display: none;
	}
	&.mod-idea{
		margin-top: 60px;
		margin-left: 282px;
		margin-right: auto;
	}
	&.mod-event{
		background-color: #fff;
		width: 225px;
		margin: 0 auto;
		margin-top: 50px;
	}
	&.mod-news{
		background-color: #fff;
		width: 170px;
		margin: 0 auto;
		margin-top: 49px;
	}
	&:after{
		transition:all .6s;
		content:"";
		position:absolute;
		top:50%;
		right: -20px;
		transform:translate(0,-50%);
		background:resolve('arrow_right_link@2x.png') no-repeat left top;
		background-size: size('arrow_right_link@2x.png',2);
		width:width('arrow_right_link@2x.png',2);
		height:height('arrow_right_link@2x.png',2);
		background-color: #fff;
	}
	&:hover:after{
		right: -25px;
		transition: all .6s ease;
	}
	&-bg{
		position: absolute;
		background-color: #fff;
		width: 5px;
		height: 5px;
		top: 18px;
		right: -2px;
	}

	&.mod-area-has-map{
		background: #fff;
		margin:34px auto 10px;
		position: relative;
		left: -9px;
		width: 192px;
	}
	&.mod-resposive{
		width: 100%;
		display: inline;
		padding: 15px;
	}
	&_center{
		text-align: center;
	}
	&_left{
		text-align: left;
	}
	&_right{
		text-align: right;
	}
	&.mod_no_arrow{
		background: #fff;
		&:hover{
			background-color: #f8f7f2 !important;
			transition: all .6s;
			opacity: 0.8;
		}
		&::after{
			height: 0px;
		}
	}

	&.mod-entry{
		background-color: #fff;
		width: 170px;
		margin: 0 auto;
		margin-top: 60px;
		&:hover{
			background-color: transparent !important;
		}
	}
}

@include breakpoint(sp) {
	.box-link{
		font-size: 13px;
		width: 90%;
		height: 40px;
		border-right: solid 1px #000;
		border-bottom: solid 1px #000;
		text-align: center;
		line-height: 40px;
		display: block;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		&.sp{
			display: block;
		}
		&.mod-idea{
			margin-top: 60px;
			margin-left: auto;
			margin-right: auto;
			width: 80%;
		}
		&.mod-event{
			position: relative;
			font-weight: 700;
			background-color: #fff;
			width: 220px;
			bottom: inherit;
			right: inherit;
			margin: 40px auto 0;
		}
		&.mod-news{
			position: relative;
			font-weight: 700;
			background-color: #fff;
			width: 220px;
			bottom: inherit;
			margin: 0 auto;
			margin-top: 45px;
			right: inherit;
		}
		&.mod-width-ex{
			display: block;
			width: 240px;
			margin-top: 35px;
			margin-left: auto;
			margin-right: auto;
		}
		&:after{
			transition:none;
			content:"";
			position:absolute;
			top:50%;
			right: -20px;
			transform:translate(0,-50%);
			background:resolve('arrow_right_link@2x.png') no-repeat left top;
			background-size: size('arrow_right_link@2x.png',2);
			width:width('arrow_right_link@2x.png',2);
			height:height('arrow_right_link@2x.png',2);
			background-color: #fff;
		}
		&:hover:after{
			right: -20px;
		}
		&-bg{
			position: absolute;
			background-color: #fff;
			width: 5px;
			height: 5px;
			top: 17px;
			right: -4px;
		}
		&.mod-resposive{
			width: 75%;
			padding: 0px;
			margin: 0 auto;
			display: block;
			line-height: 2;
			height: inherit;
		}
		&_center{
			text-align: center;
		}
		&_left{
			text-align: center;
		}
		&_right{
			text-align: center;
		}
		&.mod-entry{
			position: relative;
			font-weight: 700;
			background-color: #fff;
			width: 220px;
			bottom: inherit;
			margin: 0 auto;
			margin-top: 45px;
			right: inherit;
		}
	}
}
