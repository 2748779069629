.spnavi{
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 9999999;
	background-color: #fff;
}
// .spnavi.sp {
// 	display: block!important;
// }
.spnavi-inner{
	padding: 10px 0;
	width: 84.375%;
	margin-left: auto;
	margin-right: auto;
	li .font-kreon_r {
		font-weight: 400;
	}
	.header-spnavi{
		display: flex;
		justify-content: space-between;
		align-items:center;
		&-logo{
			width: 150px;
			height: 45px;
			background: resolve("logo-sp-navi.png") no-repeat 0 0;
			background-size: 150px 45px;
			display: inline-block;
		}
		&-close{
			width: 25px;
			height: 25px;
			background: resolve("btn-close.png") no-repeat 0 0;
			background-size: 25px;
			display: inline-block;
		}
	}
	.parent{
		margin-top: 25px;
		padding-left: 15px;
		padding-right: 15px;
		& > li > a.font-kreon_r {
			font-size: 17px;
		}
		li {
			font-size: 15px;
			font-weight: bold;
			letter-spacing: 1.5px;
			color: #000;
			& + *{
				margin-top: 25px;
			}
			&:before{
				content: "";
				display: inline-block;
				margin-right: 8px;
				background: url(../images/gnavi-hover.png) no-repeat 0 0;
				width: 5px;
				height: 5px;
				vertical-align: 3px;
			}
			a{
				color: #000;
			}
		}
		&.mod-under{
			li {
				font-size: 15px;
				font-weight: bold;
				color: #000;
				& + *{
					margin-top: 25px;
				}
				&:before{
					display: none;
				}
				a{
					color: #000;
				}
			}
		}
	}
	.btn-sp-navi-open-close{
		width: 35px;
		height: 20px;
		content: attr(btn-open-close);
		background: resolve("btn-open-close-active.png") no-repeat;
		color: #c3c0b4;
		display: inline-block;
		margin-left: 10px;
		background-position: 0 10px;
		background-size: 50%;
		&.mod-active{
			background: resolve("btn-open-close.png") no-repeat;
			background-position: 0 10px;
			background-size: 50%;
		}
	}
	.child{
		display:none;
		li {
			font-size: 12px;
			text-indent: 1em;
			color: #000;
			margin-top: 15px;
			letter-spacing: 1.2px;
			&:before{
				content: "-";
				display: block;
				float: left;
				margin-right: 8px;
				background: none;
			}
			a{
				color: #000;
			}
		}
	}

	.footer-spnavi{
		padding-left: 15px;
		padding-right: 15px;
		margin-top: 10px;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		align-items:center;
	}

	.lang-btn.mod-footer-spnavi {
		display: inline-block;
		width: auto;
		line-height: 1;
		& + .lang-btn.mod-footer-spnavi {
			margin-top: 0;
		}
		a {
			font-size: 14px;
			letter-spacing: 1.4px;
		}
	}
}


