.u_project-wrap {
	margin-top: 72px;
	margin-bottom: 160px;
	max-width: 960px;
	margin-right: auto;
	margin-left: auto;
}
.u_project-chapter {
	color: #68603e;
	font-size: 16px;
	font-style: oblique;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.1em;
	line-height: (32 / 16);
	a {
		color: #68603e;
		text-decoration: underline;
	}
}
.u_project-item {
	margin-top: 30px;
	height: auto;
	&:after {
		content: "";
		background-image: url("../images/u_projectlist-thumnail.png");
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		width: 300px;
		height: 0;
	}
}

.tentative {
	&-u_project-img-01 {
		background-image: url("../images/u_project-img01.png")!important;
	}
	&-u_project-img-02 {
		background-image: url("../images/u_project-img02.png")!important;
	}
	&-u_project-img-03 {
		background-image: url("../images/u_project-img03.png")!important;
	}
	&-u_project-img-04 {
		background-image: url("../images/u_project-img04.png")!important;
	}
	&-u_project-img-05 {
		background-image: url("../images/u_project-img05.png")!important;
	}
	&-u_project-img-06 {
		background-image: url("../images/u_project-img06.png")!important;
	}
	&-u_project-img-07 {
		background-image: url("../images/u_project-img07.png")!important;
	}
}

@include breakpoint(sp) {
	.u_project-wrap {
		margin-top: 42.5px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
		width: 87.5%;
	}
	.u_project-chapter {
		text-align: left;
		font-weight: 400;
		font-size: 12px;
		line-height: (44 / 24);
	}
	.tentative-u_project-img {
		height: 0;
		padding-top: 31.37%;
	}
	.u_project-item {
		height: 100%;
		&:after{
			content: none;
		}
		&.mod-placeholder {
			display: none;
		}
	}
}
