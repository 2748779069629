.inputLabelPayment {
    padding: 5px;
}
.inputLabelPayment p {
    margin: 5px;
}
.inputLabelPayment ul {
    margin: 7px 2px;
    list-style: inside;
}
.inputLabelPayment ul li {
    text-indent: -1rem;
    padding-left: 1rem;
    margin-top: 2px;
}
.inputLabelPayment strong.font-red {
    color: red;
}
p.alert {
    font-size: 14px;
    color:red;
    margin:2px 0;
    padding:2px;
}
div.inline {
    width:35%;
    display:inline-block;
}
a.hint {
    display: inline-block;
    text-decoration: underline;
    position: relative;
    font-size: 14px;
}
.remodal_content {
    font-size: 14px;
    margin: 15px auto;
    text-align: left;
    line-height: 1.5;
}
.js-hidden-container {
  display: none;
}
.js-notice-button {
  text-decoration: underline;
}
.position-block {
  position: relative;
}
.js-toggle-button {
  background: #8FC6A8;
  width: 148px;
  padding: 13px 13px;
  color: white;
  border-radius: 5px;
  position: absolute;
  bottom: 17px;
  right: 269px;
  font-size: 15px;
  font-weight: 600;
  z-index: 1;
}
.pc-vegetable-description tr:nth-child(2) {
  opacity: 0.4;
}
.sp-vegetable-description ul.table-list:nth-of-type(2) {
  opacity: 0.4;
}
.js-sp-toggle-button {
  background: #8FC6A8;
  width: 148px;
  padding: 13px 13px;
  color: white;
  border-radius: 5px;
  position: absolute;
  font-size: 15px;
  font-weight: 600;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 17px;
}
.table-flex-block {
  display: flex;
  font-size: 15px;
}
.flex-container {
    display: flex;
 }
@media only screen and (max-width: 641px) {
    .flex-container {
      display: block;
    }
}
.js-hidden-container {
    background:white;
    padding:30px;
    width:100%;
    display:none
}
.field_with_errors {
    display: inline;
}
.remodal-flex-container {
    display: flex;
}
@media only screen and (max-width: 641px) {
    .remodal-flex-container {
      display: block;
    }
}
.remodal-flex-container {
    display: flex;
}
@media only screen and (max-width: 641px) {
    .remodal-flex-container {
    display: block;
    }
}
@media only screen and (max-width: 641px) {
.remodal-container {
    padding-right: 0px;
    padding-left: 0px;
    }
}
