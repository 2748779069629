//改行された時の接頭の画像は無視されます。
.list_header{
	background: resolve("text-list-glay.png")  left no-repeat;
	padding-left: 19px;
	background-position: 3px 10px;
}

//改行された時の接頭の画像を含みます。。
.list_header_indent_on{
	&.mod-small{
		font-size: 14px;
		&::before{
			content: '.';
			color: #fff;
			background: resolve("text-list-glay.png")  left no-repeat;
			background-position: center;
			padding-left: 1em;
			background-position: 5px 7px;
			display: inline-block;
		}
	}
	&::before{
		content: '.';
		color: #fff;
		background: resolve("text-list-glay.png")  left no-repeat;
		background-position: center;
		padding-left: 1em;
		background-position: 3px 10px;
		display: inline-block;
	}
	&.mod-small_fs13{
		&::before{
		background: resolve("text-list-glay_13pt.png")  left no-repeat;
		}
	}
}

.list_header-num{
	font-size: 14px;
	span{
		font-size: 17px;
		@extend .font-kreon_r;
		color: #4f6537;
		margin-right: 12px;
	}
}


.list_header-triangle{
	position: relative;
	font-size: 13px;
	font-weight: bold;
		&::before{
		content: '';
		background: resolve("text-list-glay-triangle_13pt.png")  left no-repeat;
		padding-left: 1em;
		background-position: 0 0px;
		display: inline;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
	}
}

@include breakpoint(sp) {
	.list_header{
		background: resolve("text-list-glay.png")  left no-repeat;
		padding-left: 19px;
		background-position: 0 49%;
	}
}
