.triangle{
	position: absolute;
	top: 0;
	left: 0;
	border-top: 20px solid;
	border-right: 20px solid transparent;
	color: #c6cfbc;
	&.mod-brown{
	color: #68603e !important;
	}
	&.mod-brown{
	color: #68603e !important;
	}
	&.mod-green{
	color: #c6cfbc !important;
	}
	&.mod-gray{
	color: #c3c0b4 !important;
	}
	&.mod-small{
	border-top: 13px solid;
	border-right: 13px solid transparent;
	color: #c3c0b4;
	}
}


.triangle-up{
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 15px 120px 0;
	border-color: #fff transparent transparent transparent;
	z-index: 0;
}


.triangle-left-under{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 14px 0 0 120px;
	border-color: transparent transparent transparent #fff;
	z-index: 0;
}

.triangle-right-under{
	position: absolute;
	bottom: 0;
	right: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 14px 120px;
	border-color: transparent transparent #fff transparent;
	z-index: 0;
}

.triangle_left_corner_thumbail{
	background: url(../images/slider-left-corner.png) no-repeat 0 0;
	width: 17px;
	height: 17px;
	position: absolute;
	top: -1px;
	left: -1px;
}

.triangle_left_up_bg_green{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 0;
	border-color: #c6cfbc transparent transparent transparent;
	position: absolute;
	z-index: 1;
	&.mod-big{
		border-width: 18px 18px 0 0;
	}
}

.triangle_left_up_bg_gray{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 0;
	border-color: #c3c0b4 transparent transparent transparent;
	position: absolute;
	z-index: 1;
	&.mod-big{
		border-width: 18px 18px 0 0;
	}
}

//*.triangle_left_up_bgの背景色用スタイル*/
.triangle_left_up_bg_white{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 9px 9px 0 0;
	border-color: #fff transparent transparent transparent;
	position: absolute;
	&.mod-big{
		border-width: 22px 22px 0 0;
	}
}

@include breakpoint(sp) {
	.triangle{
		position: absolute;
		top: 0;
		left: 0;
		border-top: 15px solid;
		border-right: 15px solid transparent;
		color: #c6cfbc;
	}


	.triangle-up{
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 120px 0;
		border-color: #fff transparent transparent transparent;
		z-index: 0;
	}


	.triangle-left-under{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 14px 0 0 120px;
		border-color: transparent transparent transparent #fff;
		z-index: 0;
	}

	.triangle-right-under{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 14px 120px;
		border-color: transparent transparent #fff transparent;
		z-index: 0;
	}
	.triangle_left_up_bg_green{
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0 0;
		border-color: #c6cfbc transparent transparent transparent;
		position: absolute;
		z-index: 1;
		&.mod-big{
			border-width: 28px 28px 0 0;
		}
	}

	.triangle_left_up_bg_gray{
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0 0;
		border-color: #c3c0b4 transparent transparent transparent;
		position: absolute;
		z-index: 1;
		&.mod-big{
			border-width: 28px 28px 0 0;
		}
	}

	//*.triangle_left_up_bgの背景色用スタイル*/
	.triangle_left_up_bg_white{
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 9px 9px 0 0;
		border-color: #fff transparent transparent transparent;
		position: absolute;
		&.mod-big{
			border-width: 32px 32px 0 0;
		}
	}
}
