.form-login{
	.form {
		button {
			-webkit-appearance:none;
			border-top:0;
			border-left:0;
			cursor: pointer;
		}
		&-wrapper {
			width: 100%;
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 0px;
			padding-right: 0px;
			fieldset {
				display: block;
				& + fieldset {
					margin-top: 20px;
				}
			}
			.btn-half_line {
				margin-left: auto;
				margin-right: auto;
				display: block;
				width: 190px;
				font-size: 13px;
				padding:13px 25px;
				&:hover {
					background-color: transparent;
				}
			}
			.mw_wp_form .error {
				font-size: 14px;
				margin-top: 4px;
			}
		}
		&-item {
			$c:&;
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
			align-items:center;
			position: relative;
			&.mod-align-top {
				align-items:flex-start;
				.form-item-title {
					padding-top: 8px;
				}
			}
			&-title {
				width: 28.7%;
				font-size: 14px;
				font-weight: 700;
				line-height: (24 / 14);
				color: $black;
				position: relative;
			}
			&-input {
				position: relative;
				width: 71.2727%;
				input[type="text"],input[type="email"],input[type="tel"],textarea,.text-input {
					-webkit-appearance:none;
					outline: none;
					font-size: 14px;
					width: 100%;
					border-width: 1px;
					border-color: #675f3e;
					border-style: solid;
					background-color: #ffffff;
					padding: 6px 8px;
					&.mod-29percent{
						width: 29%;
					}
					&.mod-35percent{
						width: 35%;
					}
					&.mod-short {
						width: 60px;
					}
					&:focus {
						outline: 0;
						background-color: #f8f7f2;
					}
				}
				.select-input {
					background:resolve('ico_data-pick_select.png') no-repeat left top;
					background-size: size('ico_data-pick_select.png',2);//retinaは第二引数に2
					background-position: right 5px center;
					background-repeat: no-repeat;
					padding:6px 21px 6px 8px;
					width: auto;
				}
				input[type="radio"],input[type="checkbox"]{
					position: absolute;
					opacity: 0;
					margin-right: 0;
					left: 0;
					top: 0;
					z-index: 1;
				}
				.radio-text{
					cursor: pointer;
					font-size: 14px;
					font-weight: normal;
					letter-spacing: 1.6px;
					&:before{
						content: '';
						display: inline-block;
						background: resolve("radio_button.png")  no-repeat 0 0;
						width: width("radio_button.png");
						height: height("radio_button.png");
						margin-right: 6px;
					}
					&.active:before {
						content: '';
						background: resolve("radio-button_on.png")  no-repeat 0 0;
						width: width("radio-button_on.png");
						height: height("radio-button_on.png");
					}
				}
				.check-text{
					cursor: pointer;
					position: relative;
					z-index: 2;
					&:before{
						content: '';
						margin-bottom: -2px;
						display: inline-block;
						background: resolve("check_button.png")  no-repeat 0 0;
						width: width("check_button.png");
						height: height("check_button.png");
						margin-right: 6px;
					}
					&.active:before {
						content: '';
						background: resolve("check_button_active.png",2)  no-repeat 0 0;
						width: width("check_button_active.png",2);
						height: height("check_button_active.png",2);
						background-size: 100%;
					}
				}
				textarea {
					height: 140px;
				}
				&.mod-has-select {
					position: relative;
					&:after {
						content:"";
						position: absolute;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 8px 8px 0 8px;
						border-color: #c3c0b4 transparent transparent transparent;
						position:absolute;
						top:50%;
						right:11px;
						transform:translate(0,-50%);
					}
				}
				&.mod-fz12{
					font-size: 12px;
				}
			}
			&.mod-flex-end{
				align-items: end;
			}
			.require {
				color: #ff4f20;
			}
		}
	}
}
.require-text {
	font-size: 12px;
	font-weight: bold;
	color: #ff4f20;
}
.form-item-info {
	background-color: #f8f7f2;
	padding:15px 21px 17px 30px;
	margin-top: 40px;
	.desined-scrollbar {
		padding-right: 15px;
	}
	&-inner {
		height: 80px;
	}
	&-title {
		color: #68603e;
		font-size: 12px;
		font-weight: 700;
		line-height: 1.8;
		text-align: center;
		letter-spacing: 1.2px;
	}
	&-text {
		padding-top: 9px;
		color: #68603e;
		font-size: 12px;
		font-weight: 500;
		line-height: (24 / 12);
	}
}

.dummy-select {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
}

input[name="submitConfirm"],input[name="submit"],input[name="submitBack"] {
	position: absolute;
	opacity: 0;
	left: -100%;
}

.mw_wp_form_input .btn-half_line {
	&.btn-submit,&.btn-back {
		display: none;
	}
}

.mw_wp_form_confirm {
	.js-dummy-input,.form-item-input.mod-has-select:after,.form-item-info {
		display: none;
	}
	.btn-half_line {
		margin-top: 0;
		&.btn-confirm {
			display: none;
		}
		&.btn-submit,&.btn-back {
			display: block;
		}
	}
}

@include breakpoint(sp) {
	.form-login{
		.form {
			&-wrapper {
				margin-top: 40px;
				//width: 560 / 640 * 100%;
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				.btn-half_line {
					width: 85%;
					margin-top: 40px;
				}
				fieldset + fieldset {
					margin-top: 25px;
				}
			}
			&-item {
				$c:&;
				display: block;
				&.mod-align-top {
					align-items:flex-start;
				}
				&-title {
					width: 100%;
					display: block;
					font-size: 13px;
				}
				&-input {
					margin-top: 8px;
					width: 100%;
					display: block;
					font-size: 13px;
					textarea {
						height: 80px;
					}
				}
			}
		}
		.form-item-info {
			background-color: #f8f7f2;
			padding:20px 15px 30px;
			margin-top: 30px;
			&-inner {
				height: 180px;
			}
		}
	}
}
