.i_tokushukiji_page {
	.i-vol {
		font-size: 20px;
	}
.i_tokushukiji-top_img {
	background-image: url('https://placehold.jp/660x280.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	max-width: 660px;
	height: 280px;
	margin-bottom: 40px;
}
.i_tokushukiji {
	padding-left: 34px;
	padding-bottom: 23px;
}
.mod-i_vol-title {
	color: #68603e;
	font-family: 'kreon_r';
	font-size: 18px;
	padding-top: 13px;
	letter-spacing: 0;
	padding-left: 35px;
	padding-bottom: 15px;
	&::before {
		left: 4px;
	}
}
.box-shadow.mod-area-has-map.i_tokushukiji-shadowcol:after {
	background-color: #f8f7f2;
	right: -5px;
	bottom: -5px;
}
.box-shadow.mod-area-has-map.i_tokushukiji-shadowcol {
	border-color: #c3c0b4;
	border-width: 1px;
	margin-left: -5px;
}
.area-has-map-text-inner.i_tokushukiji {
	th { position : relative; padding-right: 20px; }
	th:after {
		content: ':';
		position: absolute;
		right: 5px;
	}
}
.post-links>ul .post-links-next a ,
.post-links>ul .post-links-prev a {
	width: 150px;
}
}

@include breakpoint(sp) {
	.i_tokushukiji_page .i_tokushukiji-top_img {
		height: 140px;
	}
	.i_tokushukiji_page .area-has-map-text-inner.i_tokushukiji th {
		width: 8em;
	}
}
