.area-has-bottom_line {
	$c:&;
	position: relative;
	background-color: #f5f7f2;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	&:after {
		content: "";
		bottom: -5px;
		left:0;
		position: absolute;
		width: 100%;
		border-bottom: 2px solid #c6cfbc;
	}
	&.mod-shop-pickup{
		max-width: 1120px;
		padding:40px 80px 25px;
	}
	&.has-triangle{
		&:before{
			content: "";
			display:block;
			border-left:20px solid #c6cfbc;
			border-top:20px solid #c6cfbc;
			border-right:20px solid transparent;
			border-bottom:20px solid transparent;
			position: absolute;
			left:0;
			top:0;
		}
	}
}
@include breakpoint(sp) {
	.area-has-bottom_line {
		&.mod-shop-pickup{
			padding:20px 15px;
		}
		&.has-triangle{
			&:before{
				border-left:10px solid #c6cfbc;
				border-top:10px solid #c6cfbc;
				border-right:10px solid transparent;
				border-bottom:10px solid transparent;
			}
		}
	}
}
