.c-tag-area{
  margin-top: 20px;
  &_title{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .tag_list_wrap{
    margin-top: 20px;
  }
  .tag_list {
    display: flex;
    justify-content:center;
    flex-wrap:wrap;
    margin-top: 20px;
    height: 86px;
    overflow: hidden;
    &.is-show{
      height: auto;
    }
    &_item {
      a {
        background-color: #efe9dd;
        padding:5px 15px;
        color: #000;
        min-width: 80px;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        margin: 0px 4px 10px;
        border-radius: 2px;
      }
    }
    &_more {
      display: block;
      cursor: pointer;
      text-align: center;
      font-weight: 700;
      margin-top: 15px;
      color: #000;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after{
        content: "";
        display:block;
        width: 6px;
        height: 6px;
        border-bottom:2px solid #000;
        border-right:2px solid #000;
        transform:rotate(45deg);
        margin-left: 10px;
        position: relative;
        top:-3px;
      }

      &.is-show{
        &:after{
          transform:rotate(-135deg);
          top:0;
        }
      }
    }
  }
}




@media only screen and (max-width: 768px){
  .c-tag-area{
    margin-top: 10px;
    &_title{
      font-size: 13px;
    }
    .tag_list_wrap{
      margin-top: 10px;
    }
    .tag_list {
      display: flex;
      justify-content:center;
      flex-wrap:wrap;
      margin-top: 20px;
      height: 66px;
      overflow: hidden;
      &_item {
        a {
          padding:5px 15px;
          min-width: 50px;
          font-size: 12px;
          margin: 0px 2px 6px;
        }
      }
      &_more {
        margin-top: 5px;
        font-size: 12px;
        &:after{
          width: 4px;
          height: 4px;
          margin-left: 10px;
          top:-1px;
        }
        &.is-show{
          &:after{
            transform:rotate(-135deg);
            top:0;
          }
        }
      }
    }
  }
}