.border-bottom-dotted {
	background : url('../images/shop-border.png') repeat-x 0 100%;
	background-size: size('shop-border.png',2);
}
.product-category-list {
	font-size: 14px;
	font-weight: 700;
	p  {
		border-bottom: 1px solid #1c1f21;
		line-height: 1;
		padding-bottom: 9px;
	}
	ul {
		padding-top: 5px;
		line-height: 1;
		padding-bottom: 19px;
	}
	ul li {
		padding-top: 16px;
	}
	ul li a {
		color: #1c1f21;
	}
	%shop_li {
		font-size: 12px;
		position: relative;
		padding-left: 29px;
		letter-spacing: 0.1em;
		position: relative;
	}
	li.shop_info {
		@extend %shop_li;
		&:before {
			content:"";
			background:resolve('ico-shop_info.png') no-repeat left top;
			background-size: size('ico-shop_info.png',2);
			width:width('ico-shop_info.png',2);
			height:height('ico-shop_info.png',2);
			position: absolute;
			top: 13px;
			left: 0;
		}
	}
	li.shop_login {
		@extend %shop_li;
		&:before {
			content:"";
			background:resolve('shop_login.png') no-repeat left top;
			background-size: size('shop_login.png',2);
			width:width('shop_login.png',2);
			height:height('shop_login.png',2);
			position: absolute;
			top: 15px;
			left: 0;
		}
	}
	li.shop_cart {
		@extend %shop_li;
		&:before {
			content:"";
			background:resolve('shop_cart.png') no-repeat left top;
			background-size: size('shop_cart.png',2);
			width:width('shop_cart.png',2);
			height:height('shop_cart.png',2);
			position: absolute;
			top: 15px;
			left: 0;
		}
	}

	li.shop_faq {
		@extend %shop_li;
		&:before {
			content:"";
			background:resolve('shop_faq.png') no-repeat left top;
			background-size: size('shop_faq.png',2);
			width:width('shop_faq.png',2);
			height:height('shop_faq.png',2);
			position: absolute;
			top: 15px;
			left: 0;
		}
	}
	/* お気に入り*/
	li.shop_like {
		@extend %shop_li;
		&:before {
			content: '';
			background: resolve('icon-heart.png') no-repeat 0 0;
			background-size: 15px 14px;
			width: 19px;
			height: 16px;
			position: absolute;
			top: 15px;
			left: 0;
		}
	}
}
.mypage {li.shop_login::before {
	content:"";
	background:resolve('question_icon.png') no-repeat left top;
	background-size: size('question_icon.png',2);
	width:width('question_icon.png',2);
	height:height('question_icon.png',2);
	position: absolute;
	top: 15px;
	left: 0;
}}
.box-link.mod_no_arrow.mod-half.mod-put_cart {
	align-items: center;
	background: #f5f7f2;
	border-color: #c6cfbc;
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-right: 30px;
	letter-spacing: 0;
	width: 214px;
	height: 35px;
	transition: all .6s;
	&:hover {
		opacity: .6;
	}
	span {
		font-size: 12px;
		letter-spacing: 0.1em;
		position: relative;
	&:after {
		content: "";
		background: resolve('shop_cart.png') no-repeat bottom;
		background-size: size('shop_cart.png',2);
		width: width('shop_cart.png',2);
		height: height('shop_cart.png',2);
		position: absolute;
		top: 12px;
		right: -24px;
	}
	}
}

.search-form.shop {
	margin-top: 0;
}

@include breakpoint(sp) {
	.box-link.mod_no_arrow.mod-half.mod-put_cart {
		width: 100%;
	}
	.box-link.mod_no_arrow.mod-half.mod-put_cart {
		font-size: 11px;
	}
}
