.glid.mod-img-aside.mod-teiki {
	margin-top: 40px;
	.col-img {
		img + img {
			margin-top: 49px;
		}
	}
	.post-content {
		margin-top: 0;
		h1,h2,h3 {
			&:first-child {
				margin-top: 0;
			}
		}
		h3 + * {
			margin-top: 19px;
		}
	}
	.img_wrap-2col.sp + h3 {
		margin-top: 0;
	}
}
@include breakpoint(sp) {
	.glid.mod-img-aside.mod-teiki {
		.img_wrap-2col.sp + h3 {
			margin-top: 30px;
		}
		.post-content {
			br {
				display: none;
			}
			* {
				display: inline-block;
			}
			@for $i from 1 through 5 {
				h#{$i} {
					display: block;
				}
			}
		}
	}
}
