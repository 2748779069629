.header-sp{
	display: none;
	position: fixed;
	width: 100%;
	z-index: 9999;
	top: 0;
	background-color: rgba(255,255,255,0.8);
	&-inner{
		margin: 0 auto;
		padding: 17px 20px 10px 10px;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
	}
	&-logo{
		display:inline-block;
		margin-right: 30px;
		img{
			width: 160px;
		}
	}
}
@include breakpoint(sp) {
	.header-sp{
		display: block;
		position: static;
		width: 100%;
		z-index: 9999;
		top: 0;
		background-color: rgba(255,255,255,0.8);
		&.fixed {
			position: fixed;
		}
		&-inner{
			margin: 0 auto;
			padding-top: 17px;
			text-align: center;
			align-items:center;
			a {
				img {
					vertical-align: middle;
				}
			}
		}
		&-logo{
			display:block;
			margin-right: 0;
			img{
				width: 160px;
			}
		}
		&.fixed {
			.header-sp-links {
				span {
					display: none;
				}
				.btn-shop + a img{
					position: relative;
					top: -3px;
				}
			}
		}
		&.sp{
			display: block;
		}
	}
}
