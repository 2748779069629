.order-area{
	$c:&;
	position: relative;
	margin-top: 10px;
	padding-bottom: 55px;
	&-info{
		position: relative;
		font-size: 14px;
		border-bottom: dashed 1px;
		border-color: #cdd2d5;
		padding-bottom: 5px;
		&_emp{
			color: #4f6537;
			font-weight: bold;
		}
		& p {
			width: 65%;
			margin-bottom: 10px;
		}
		#{$c}-btn-wrap{
			display: flex;
			justify-content: flex-end;
			width: 195px;
			position: absolute;
			right: 0px;
			bottom: 28px;
			.box-link.mod-half.mod_no_arrow{
				width: 117px;
				height: 36px;
			}
		}
		& + & {
			margin-top: 15px;
		}
	}
	&_count{
		font-size: 12px;
		@extend .font-kreon_r;
	}
}
@include breakpoint(sp) {
	.order-area{
		$c:&;
		position: relative;
		margin-top: 10px;
		padding-bottom: 55px;
		&-info{
			position: relative;
			font-size: 14px;
			border-bottom: dashed 1px;
			border-color: #cdd2d5;
			padding-bottom: 5px;
			&_emp{
				color: #4f6537;
				font-weight: bold;
			}
			& p {
				width: 65%;
				margin-bottom: 10px;
			}
			#{$c}-btn-wrap{
				display: block;
				position: static;
				width: 100%;
				position: static;
				.box-link.mod-half.mod_no_arrow{
					width: 180px;
					height: 36px;
					border: 1px solid #000;
					margin: 20px auto 20px auto;
				}
			}
			& + & {
				margin-top: 15px;
			}
		}
	}
}
