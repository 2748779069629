.thumbnail-basic-item-horizontal-wrap{
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;
}
.thumbnail-basic-item-horizontal {
	width: 48%;
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	// &:nth-child(n + 3){
	// 	margin-top: 15px;
	// }
	&.mod-mt-large{
		margin-top: 40px;
	}
	&.mod-mt-medium{
		margin-top: 17px;
	}
	&-colImg {
		width: 31.56%;
		padding-bottom: 0;
	}
	&-colText {
		width: 70%;
		padding-top: 0;
		padding: 0 0 0 17px;
	}

	&-img {
		position: relative;
		height: 0;
		width: 100%;
		padding-top: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		&.has-icon-new{
			&:before{
				content: "";
				display:block;
				background: resolve('icon-new.png') no-repeat left top;
				background-size:contain;
				width:width('icon-new.png',2);
				height:height('icon-new.png',2);
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		&.has-border{
			border:1px solid #1c1f21;
		}
	}
	&-tags{
		display: flex;
	}
	&-tag {
		display: flex;
		flex-direction: column;
	}
	&-bottomTags{
		display: flex;
		margin-top: 5px;
	}

	&-title {
		letter-spacing: -0.02em;
		color: #000;
		font-size: 15px;
		font-weight: 700;
		line-height: 1.5;
		margin-top: 5px;
	}
	&-cont {
		display: flex;
		justify-content: space-between;
		margin-top: 14px;
		line-height: 1;
		align-items: center;
		&.mod-align-top{
			margin-top: -5px;
		}
		&.mod-mt0{
			margin-top: 0;
		}
	}
	&-date {
		color: #333;
		font-family: "calibri", Garamond, "Comic Sans MS", sans-serif;
		font-size: 15px;
		padding-top: 2px;
		margin-left: auto;
		line-height: 1;
	}
	&-text{
		font-size: 12px;
		color:#666666;
		font-weight: 700;
		margin-top: 5px;
		line-height: (36 / 24);
	}
}

@include breakpoint(sp) {
	.thumbnail-basic-item-horizontal {
		width: 100%;
		margin-top: 8px;
		&.mod-mt-large{
			margin-top: 24px;
		}
		&.mod-mt-medium{
			margin-top: 17px;
		}
		&-img {
			&.has-icon-new{
				&:before{
					content: "";
					display:block;
					background: resolve('icon-new.png') no-repeat left top;
					background-size:contain;
					width: 37%;
					height: 37%;
				}
			}
		}
		&-colImg {
			width: 33%;
		}
		&-colText {
			width: 63%;
			padding-left: 0;
		}
		&-title {
			font-size: 14px;
		}
		&-cont {
			margin-top: 0;
			&.mod-align-top{
				margin-top: -2px;
			}
		}
		&-date {
			padding-top: 0;
			font-size: 11px;
		}
		&-tag{
			font-size: 11px;
		}

		&-btn {
			&.only_sp {
			}
		}
	}
	& + .thumbnail-basic-item {
		&-btn {
			&.only_sp {
				display: block;
				margin-top: 30px;
			}
		}
	}
	&._mod-category {
		.thumbnail-basic-item {
			margin-top: 15px;
			&-img {
				// width: 107px;
				// padding-top: 31.705%
			}
			&-label {
				&-text {
					font-size: 10px;
					color: #666666;
					width: 95%;
					line-height: 1.4;
				}
				width: 73%;
				&-date {
					font-size: 12px;
				}
			}
			&:nth-child(-n + 4) {
				margin-top: 15px;
			}
		}
		&._mod-article {
			width: 88%;
			.thumbnail-basic-item-label {
				width: 69%;
				padding-left: 17px;
			}
		}
	}
}
