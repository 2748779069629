.category-title-book {
	$c:&;
	position: relative;
	width: 100%;
	background-color: #c3c0b4;
	padding: 9px 18px;
	min-height: 104px;
	display: flex;
	flex-wrap:wrap;
	flex-direction:column;
	margin-top: 13px;
	&.mod-jc_center{
		justify-content: center;
	}
	&.mod-jc_start{
		justify-content: start;
	}
	&:before {
		content:"";
		background:resolve('img_category-title-book_top_1.png') no-repeat left top;
		background-size: size('img_category-title-book_top_1.png');//retinaは第二引数に2
		width:width('img_category-title-book_top_1.png');
		height:height('img_category-title-book_top_1.png');
		top: -13px;
		left: 0;
		position: absolute;
	}
	&:after {
		content:"";
		background:resolve('img_category-title-book_bottom_1.png') no-repeat left top;
		background-size: size('img_category-title-book_bottom_1.png');//retinaは第二引数に2
		width:width('img_category-title-book_bottom_1.png');
		height:height('img_category-title-book_bottom_1.png');
		bottom: -26px;
		left: 0;
		position: absolute;
	}
	&-sub {
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		font-style: italic;
		border-bottom: 1px solid #fff;
		padding-left: 4px;
		padding-bottom: 9px;
		margin-bottom: 22px;
		&.mod-2line_title{
			margin-bottom: 10px;
		}
	}
	&-main {
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		font-style: italic;
		line-height: (30 / 22);
		text-align: center;
	}
	&.mod-other {
		background-color: #c6cfbc;
		#{$c}-main {
			font-style:italic;
			display: flex;
			justify-content:center;
			flex-wrap:wrap;
			flex-direction:column;
			align-items:center;
			min-height: 84px;
		}
		&:before {
			content:"";
			background:resolve('img_category-title-book_top_2.png') no-repeat left top;
			background-size: size('img_category-title-book_top_2.png');//retinaは第二引数に2
			width:width('img_category-title-book_top_2.png');
			height:height('img_category-title-book_top_2.png');
			top: -13px;
			left: 0;
			position: absolute;
		}
		&:after {
			content:"";
			background:resolve('img_category-title-book_bottom_2.png') no-repeat left top;
			background-size: size('img_category-title-book_bottom_2.png');//retinaは第二引数に2
			width:width('img_category-title-book_bottom_2.png');
			height:height('img_category-title-book_bottom_2.png');
			bottom: -26px;
			left: 0;
			position: absolute;
		}
	}
}

.category-title-book + * {
	margin-top: 58px;
}
@include breakpoint(sp) {
	.category-title-book {
		display: none;
	}
	.category-title-book + * {
		margin-top: 0px;
	}
}
