.email-area{
	.radio-text{
		cursor: pointer;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 1.6px;
		&:before{
			content: "";
			display: inline-block;
			background: url(../images/radio_button.png) no-repeat 0 0;
			width: 10px;
			height: 10px;
			margin-right: 6px;
		}
		&.active:before {
			content: '';
			background: resolve("radio-button_on.png")  no-repeat 0 0;
			width: width("radio-button_on.png");
			height: height("radio-button_on.png");
		}
	}

}
@include breakpoint(sp) {
}
