.article-shop-top-slides {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 1fr;
	gap: 11px;

	&-image {
		background: grey;
		width: 100%;
		height: auto;
	}
	@media only screen and (max-width: 430px) {
		grid-template: auto / 1fr;
	}
}
