.form-date-select {
	span{
		font-size: 14px;
		font-weight: bold;
		margin-right: 25px;
		}
	.form-date-select_select{
		width: 160px;
		font-size: 15px;
		padding: 2px 9px;
		@extend .font-kreon_r;
		background-image: resolve('ico_data-pick_select.png');
		background-repeat: no-repeat;
		background-color: #fff;
		border: solid 1px #c6cfbc;
		background-position: 92%;
		background-size: 7%;
	}
}
@include breakpoint(sp) {
	.form-date-select {	
		.form-date-select_select{
			width: 100%;
			background-position: 94%;
			background-size: 4%;
		}
	}
}
