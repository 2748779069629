.form {
	&-wrapper {
		margin-top: 72px;
		width: 720px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 23px;
		padding-right: 34px;
		fieldset {
			display: block;
			& + fieldset {
				margin-top: 18px;
			}
		}
		.btn-half_line {
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 260px;
			margin-top: 62px;
		}
		.mw_wp_form .error {
			font-size: 14px;
			margin-top: 4px;
		}
	}
	&-item {
		$c:&;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		align-items:flex-start;
		position: relative;
		&.mod-align-top {
			align-items:flex-start;
		}
		&-title {
			width: 25%;
			font-size: 14px;
			font-weight: 700;
			line-height: (24 / 14);
			color: $black;
			position: relative;
			padding-top: 5px;
		}
		&_price{
			margin-left: 15px;
			font-weight: bold;
		}
		&-input {
			position: relative;
			width: 72.2727%;
			&.mod-horizon {
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
				.btn-submit {
					width: 180px;
					order:2;
				}
				.btn-back {
					width: 180px;
					order:1;
					&:after {
						right:auto;
						left: -20px;
						transform: rotate(180deg);
					}
					&:hover {
						&:after {
							left: -25px;
						}
					}
				}
			}
			input[type="text"],input[type="email"],input[type="tel"],textarea,.text-input{
				-webkit-appearance:none;
				font-size: 14px;
				width: 100%;
				border-width: 1px;
				border-color: #675f3e;
				border-style: solid;
				background-color: #ffffff;
				padding: 6px 8px;
				&.mod-short {
					width: 60px;
				}
				&.mod-middle {
					width: 120px;
				}
				&:focus {
					outline: 0;
					background-color: #f8f7f2;
				}
			}
			.select-input {
				@extend .text-input;
			}
			label {
				display: block;
			}
			textarea {
				height: 140px;
			}
			&.mod-has-select {
				position: relative;
				&:after {
					content:"";
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 8px 0 8px;
					border-color: #c3c0b4 transparent transparent transparent;
					position:absolute;
					top:50%;
					right:11px;
					transform:translate(0,-50%);
				}
			}
			.radio-text{
				cursor: pointer;
				z-index: 2;
				position: relative;
				&:before{
					content: "";
					display: inline-block;
					background: url(../images/radio_button.png) no-repeat 0 0;
					width: 10px;
					height: 10px;
					margin-right: 6px;
				}
				&.active:before {
					content: '';
					background: resolve("radio-button_on.png")  no-repeat 0 0;
					width: width("radio-button_on.png");
					height: height("radio-button_on.png");
				}
			}
		}
		.require {
			color: #ff4f20;
		}
	}
	&-label_wrap {
		display: flex;
		justify-content:flex-start;
		flex-wrap:wrap;
	}
}

.form-item-info {
	background-color: #f8f7f2;
	padding:15px 21px 17px 30px;
	margin-top: 40px;
	.desined-scrollbar {
		padding-right: 15px;
	}
	&-inner {
		height: 80px;
	}
	&-title {
		color: #68603e;
		font-size: 12px;
		font-weight: 700;
		line-height: 1.8;
		text-align: center;
		letter-spacing: 1.2px;
	}
	&-text {
		padding-top: 9px;
		color: #68603e;
		font-size: 12px;
		font-weight: 500;
		line-height: (24 / 12);
	}
}

.dummy-select {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
}

input[name="submitConfirm"],input[name="submit"],input[name="submitBack"] {
	position: absolute;
	opacity: 0;
	left: -100%;
}

.form-item-input.default-style {
	input[type=radio],input[type=checkbox] {
		position: static;
		opacity: 1;
		display: inline-block;
		margin-right: 5px;
	}
	.radio-text:before,.check-text:before {
		content:none;
	}
}
select.default-style {
	-webkit-appearance:menulist;
	background-image: none!important;
	width:auto;
}

.radioButtonLabel.active {
	border:1px solid #ffd9ae;
	background-color: #fdf5ed;
	box-sizing: border-box;
}

.mw_wp_form_input .btn-half_line {
	&.btn-submit,&.btn-back {
		display: none;
	}
}

.mw_wp_form_confirm {
	.js-dummy-input,.form-item-input.mod-has-select:after,.form-item-info,.fs12.red {
		display: none;
	}
	.btn-half_line {
		margin-top: 0;
		&.btn-confirm {
			display: none;
		}
		&.btn-submit,&.btn-back {
			display: block;
		}
	}
}

.mw_wp_form_input,.mw_wp_form_confirm {
	padding-top: 150px;
	margin-top: -150px;
	display: block;
}
.mw_wp_form_confirm{
	.form-item-input {
		margin-top: 0;
	}
	a {
		color: #1c1f21;
		text-decoration: none;
	}
}
.mw_wp_form_complete {
	font-size: 14px;
	font-weight: 500;
	line-height: 2;
	text-align: center;
	@extend .base_width;
	max-width: 100%;
}

@include breakpoint(sp) {
	.form {
		&-wrapper {
			margin-top: 40px;
			width: 560 / 640 * 100%;
			padding-left: 0;
			padding-right: 0;
			.btn-half_line {
				width: 85%;
				margin-top: 40px;
			}
			fieldset {
				& + fieldset {
					margin-top: 25px;
				}
			}
		}
		&-item {
			$c:&;
			display: block;
			&.mod-align-top {
				align-items:flex-start;
			}
			&-title {
				width: 100%;
				display: block;
				font-size: 13px;
			}
			&-input {
				margin-top: 8px;
				width: 100%;
				display: block;
				font-size: 13px;
				textarea {
					height: 80px;
				}
				&.mod-horizon {
					.btn-submit {
						width: 43%;
						min-width:auto;
						margin-top: 0;
					}
					.btn-back {
						width: 43%;
						min-width:auto;
						margin-top: 0;
					}
				}
			}
		}
	}
	.form-item-info {
		background-color: #f8f7f2;
		padding:20px 15px 30px;
		margin-top: 30px;
		&-inner {
			height: 180px;
		}
	}
	.mw_wp_form_confirm {
		padding-top: 120px;
		margin-top: -120px;
	}

	.mw_wp_form_complete {
		text-align: left;
	}
}
