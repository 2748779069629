.mv-slide-sp {
	overflow: hidden;
	position: relative;
	// height: 285px;
	padding-bottom: 35px;
	.swiper-slide{
		width: 100%;
		height: 0;
		padding-top: 100%;
		background-size: cover;
		background-position: center;
	}
	.swiper-button-prev{
		display: none;
	}
	.swiper-button-next{
		display: none;
	}
	.pagenate-sp {
		bottom: 0px!important ;
		.swiper-pagination-bullet{
			display: inline-block;
			vertical-align: middle;
			&:nth-of-type(odd){
				border: 1px solid #4f6537;
				width: 10x;
				height: 10x;
				border-radius: 0%;
				opacity: 1;
				background-color: #fff;
			}
			&-active:nth-of-type(odd){
				background-color: #4f6537 !important;
				width: 15px;
				height: 15px;
				border-radius: 0%;
				opacity: 1;
			}
			&:nth-of-type(even){
				border: 1px solid #68603e;
				width: 10x;
				height: 10x;
				border-radius: 0%;
				opacity: 1;
				background-color: #fff;
			}
			&-active:nth-of-type(even){
				background-color: #68603e !important;
				width: 15px;
				height: 15px;
				border-radius: 0%;
				opacity: 1;
			}
		}
	}
}

.mv_swiper-info{
	width: 100%;
	height: auto;
	padding: 17px 18px;
	background-color: rgba(255,255,255,0.6);
	position: absolute;
	display: inline-block;
	bottom: 0;
	right: 0;
	color: #000;
	z-index: 9999;
	display:none;
	&-title{
		font-size: 14px;
		font-weight: 700;
	}
	&-text{
		font-size: 13px;
		margin-top: 6px;
		line-height: 20px;
	}
}

.swiper-slide-active{
	.mv_swiper-info {
		display: block;
	}
}

