.article_mv {
	$c: &;
	width: 660px;
	position: relative;
	margin-bottom: 90px;
	&-img {
		// background-image: url(http://placehold.jp/EF3245/fff/660x285.png?text=2);
		background-size: cover;
		background-position: center;
		height: 280px;
	}
	&-text {
		position: absolute;
		left: 21px;
		bottom: -13px;
		.text-bg-line {
			font-size: 16px;
			font-weight: bold;
			background-color: #e4dfdf;
			color: #68603e;
			opacity: 0.8;
			line-height: 1.3;
			padding: 0 10px;
		}
	}
	.text-bg-line-wrap + .text-bg-line-wrap {
		margin-top: 10px;
	}
	&.mod-large {
		width: 100%;
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		.text-bg-line {
			color: #675f3e;
		}
		#{$c}-text {
			left: 16px;
		}
		#{$c}-img {
			height: 320px;
		}
	}
	&.mod-yomimono-new {
		width: 100%;
		max-width: 1040px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 50px;
		margin-bottom: 100px;
		#{$c}-text {
			left: 13px;
			font-style: normal;
			letter-spacing: 0.1em;
			line-height: 2;
			font-weight: bold;
			font-size: 13px;
			left: 565px;
			bottom: 5px;
		}
		#{$c}-img {
		}
		#{$c}-img-sp {
			display: none;
		}
		#{$c}-search-sp {
			display: none;
		}
	}
	&_col {
		width: 100%;
		margin-bottom: 30px;
		&.mod-yomimono-new {
			margin-bottom: 60px;
		}
	}
	&-wrap {
		display: flex;
		justify-content: space-between;
	}
	&-cont {
		&.mod-left {
			background: resolve("img_yomimono_mv_left.png", 2) no-repeat left;
			width: width("img_yomimono_mv_left.png", 2);
			// height: height("img_yomimono_mv_left.png",2);
			padding-top: 33%;
			height: 0;
			background-size: 100%;
			margin-left: 22px;
		}
		&.mod-right {
			margin-top: 44px;
			margin-right: 12px;
			// display: flex;
			// flex-direction: column;
			.article_mv-image {
				background: resolve("img_yomimono_mv_right.png", 2) no-repeat
					left;
				width: width("img_yomimono_mv_right.png", 2);
				// height: height("img_yomimono_mv_right.png",2);
				background-size: 100%;
				height: 0;
				padding-top: 33%;
			}
			.article_mv-lead {
				font-size: 13px;
				font-weight: 700;
				display: flex;
				justify-content: center;
				line-height: 2;
				letter-spacing: 0.12em;
				margin-top: 22px;
			}
		}
	}
}

@include breakpoint(sp) {
	.article_mv {
		$c: &;
		width: 100%;
		position: relative;
		margin-bottom: 35px;
		&-wrap {
			flex-direction: column;
			width: 85%;
			margin: 0 auto;
		}
		&-cont {
			&.mod-left {
				background: resolve("img_yomimono_mv_sp.png") no-repeat left;
				width: 96%;
				margin: 0 auto;
				background-size: cover;
				padding-top: 47%;
			}
			&.mod-right {
				margin-right: 0;
				margin-top: 8px;
				.article_mv-image {
					width: 100%;
					background: resolve("img_yomimono_mv_right_sp.png", 2)
						no-repeat left;
					width: width("img_yomimono_mv_right_sp.png", 2);
					// height: height("img_yomimono_mv_right.png",2);
					background-size: 100%;
					height: 0;
					padding-top: 33%;
					margin: 0 auto;
				}
				.article_mv-lead {
					width: 82%;
					line-height: 1.9;
					margin-top: 14px;
					margin-left: auto;
					margin-right: auto;
					font-size: 11px;
				}
			}
		}
		&-img {
			background-image: url(http://placehold.jp/EF3245/fff/660x285.png?text=2);
			background-size: cover;
			background-position: center;
			height: 120px;
		}
		&-text {
			margin-top: 25px;
			position: static;
			font-size: 12px;
			line-height: (44 / 24);
			.text-bg-line {
				font-weight: normal;
				background-color: #fff;
				padding: 0px;
				display: inline;
				font-size: 12px;
				line-height: (44 / 24);
				& + .text-bg-line {
					margin-top: 0px;
				}
			}
			* {
				display: inline;
			}
		}
		&.mod-large {
			.text-bg-line {
				color: #675f3e;
				font-size: 12px;
				line-height: (44 / 24);
			}
			#{$c}-text {
				left: 0;
				margin-left: auto;
				margin-right: auto;
				width: 90%;
				font-size: 12px;
				line-height: (44 / 24);
				* {
					display: inline;
				}
			}
			#{$c}-img {
				height: 0;
				padding-top: 45%;
			}
		}
		&.mod-yomimono-new {
			margin: 0 auto 35px;
			#{$c}-text {
				width: 86%;
				font-size: 14px;
				line-height: (38 / 22);
				margin: 20px auto 0;
				br {
					display: none;
				}
			}
			#{$c}-img {
				display: none;
			}
			#{$c}-img-sp {
				// background-size: cover;
				// background-position: center;
				// background-repeat: no-repeat;
				display: block;
				// height: 73vw;
			}
			#{$c}-search-sp {
				display: block;
				width: 86%;
				margin:20px auto 0;
			}
		}
	}
}
