.modal{
	&-item{
		width: 67%;
		margin: 0 auto;
		&-thumbnail_wrap{
			width: 100%;
			margin: 0 auto;
		}
		&-thumbnail{
			width: 100%;
			height: 219px;
			background-position: center;
			background-size: cover;
		}
		&-info{
			text-align: left;
			margin-top: 25px;
			&_title{
				font-size: 18px;
				font-weight: bold;
				border-bottom: solid 1px #000;
				letter-spacing: 0px;
				padding-left: 1px;
				padding-bottom: 5px;
			}
			&_label{
				font-size: 13px;
				border-bottom: 1px dashed #cdd2d5;
				line-height: 2;
				margin-top: 6px;
			}
			&_detail{
				font-size: 14px;
				border-bottom: dotted 1px #cdd2d5;
				letter-spacing: 0px;
				padding-top: 12px;
				line-height: 2;
				padding-bottom: 8px;
			}
		}
	}
	&-footer{
		margin-top: 30px;
		&_pager{
			display: flex;
			justify-content: space-between;
			font-size: 13px;
			font-weight: bold;
			.prev{
				position: relative;
				&:after{
					content: '.';
					left: 5px;
					bottom: -35px;
					margin-top: 15px;
					display: block;
					color: #fff;
					background: resolve('arrow_left_modal@2x.png') no-repeat 0 0;
					width: width('arrow_left_modal@2x.png');
					height: height('arrow_left_modal@2x.png');
					background-size: 50%;
				}
			}
			.next{
				position: relative;
				&:after{
					content: '.';
					right: -29px;
					bottom: -36px;
					display: block;
					color: #fff;
					background: resolve('arrow_right_modal@2x.png') no-repeat 0 0;
					width: width('arrow_right_modal@2x.png');
					height: height('arrow_right_modal@2x.png');
					margin-top: 15px;
					margin-left: auto;
					margin-right: 0px;
					background-position-x: right;
					background-size: 50%;
				}
			}
		}
	}
}

.page-template-default {
	.modal-item-info_title {
		@extend .clearfix;
		border-bottom: 0;
		width: 100%;
		font-size: 16px;
		line-height: 2;
		.name_sep {
			float: left;
		}
		.part {
			overflow: hidden;
		}
		.sep {
			min-width: 25px;
			visibility:visible!important;
			text-align: center;
			display: inline-block;
		}
	}
	&.shop-top {
		.modal-item-info_title {
			border-bottom: 1px solid #000;
		}
	}
	.modal-footer_pager {
		.prev,.next {
			font-weight: bold;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		.prev:after {
			display: none;
		}
		.prev:before {
			content: ".";
			display: inline-block;
			color: #fff;
			background:resolve('arrow_left_modal@2x.png') no-repeat left top;
			background-size: size('arrow_left_modal@2x.png',2);
			width:width('arrow_left_modal@2x.png',2);
			height:height('arrow_left_modal@2x.png',2);
			vertical-align: -3px;
			margin-top: 0;
			margin-right: 13px;
		}
		.next:after {
			display: inline-block;
			background:resolve('arrow_right_modal@2x.png') no-repeat left top;
			background-size: size('arrow_right_modal@2x.png',2);
			width:width('arrow_right_modal@2x.png',2);
			height:height('arrow_right_modal@2x.png',2);
			vertical-align: -3px;
			margin-top: 0;
			margin-left: 13px;
		}
	}
}

@include breakpoint(sp) {
	.modal-item{
		margin: 0 auto;
		&-thumbnail_wrap{
			width: 100%;
			margin: 0 auto;
		}
		&-thumbnail{
			width: 100%;
			padding-top: 68.4375%;
			height: 0px;
			background-position: center;
			background-size: cover;
		}
		&-info{
			text-align: left;
			&_title{
				font-size: 18px;
				font-weight: bold;
				border-bottom: solid 1px #000;
				letter-spacing: 2px;
			}
			&_label{
				font-size: 13px;
				border-bottom: dotted 1px #000;

			}
			&_detail{
				font-size: 14px;
				border-bottom: dotted 1px #cdd2d5;
				letter-spacing: 2px;
			}
		}
		&-footer{
			&-footer_pager{
				display: flex;
				justify-content: space-between;
				.left{

				}
				.right{

				}
			}
		}
	}
	.page-template-default {
		.modal-item-info_title {
			display: block;
			span {
				display: block;
				max-width: 100%;
			}
			.name_sep {
				float: none;
				display: block;
			}
			.part {
				display: block;
				font-size: 14px;
			}
			.name {
				max-width: 100%;
			}
			.sep + span {
				font-size: 14px;
				line-height: 1.8;
			}
			.sep {
				display: none;
			}
		}
	}
}
