.side_logged-nav {
	display: block;
	position: relative;
	height: auto;
	margin-bottom: 40px;
	&-title {
		font-family: 'kreon_r';
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.1em;
		padding-bottom: 7px;
		border-bottom: 1px solid #1c1f21;
		line-height: 1;
		margin-bottom: 10px;
	}
	.child{
		li {
			letter-spacing: 0px;
			font-size: 14px;
			background-color: #f5f7f2;
			border: 1px dotted #1c1f21;
			& + li {
				margin-top: 10px;
			}
			a{
				width: 100%;
				color: #000;
				display: block;
				padding: 8px 0px;
				vertical-align: middle;
			}
			span{
				vertical-align: middle;
			}
		}//li
		.mail-magazine-button {
		}
		.creditcard-button {
			line-height : (24 / 14);
			align-items: center;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-pack: start;
			justify-content: flex-start;
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
	}
	.account-button::before {
		content: '';
		background : resolve('account-icon.png');
		background-size: size('account-icon.png',2);
		background-repeat: no-repeat;
		display: inline-block;
		width: width('account-icon.png',2);
		height: height('account-icon.png',2);
		position: relative;
		background-position: center;
		padding-left: 20px;
		padding-right: 21px;
		vertical-align: middle;
	}
	.address-button::before {
		content: '';
		background : resolve('address-icon.png');
		background-size: size('address-icon.png',2);
		background-repeat: no-repeat;
		display: inline-block;
		width: width('address-icon.png',2);
		height: height('address-icon.png',2);
		position: relative;
		background-position: center;
		padding-left: 20px;
		padding-right: 21px;
		display: inline-block;
		vertical-align: middle;
	}
	.creditcard-button::before {
		content: '';
		background : resolve('credit-icon.png');
		background-size: size('credit-icon.png',2);
		background-repeat: no-repeat;
		display: inline-block;
		width: width('credit-icon.png',2);
		height: height('credit-icon.png',2);
		position: relative;
		background-position: center;
		padding-left: 20px;
		padding-right: 21px;
		display: inline-block;
		vertical-align: middle;
	}
	.creditcard-sub-text {
		padding-top: 5px;
		padding-left: 8px;
		font-size: 12px;
		font-weight: 400;
	}
	.logout-button::before {
		content: '';
		background : resolve('logout-icon.png');
		background-size: size('logout-icon.png',2);
		background-repeat: no-repeat;
		display: inline-block;
		width: width('logout-icon.png',2);
		height: height('logout-icon.png',2);
		position: relative;
		background-position: center;
		padding-left: 20px;
		padding-right: 21px;
		display: inline-block;
		vertical-align: middle;
	}
	.mail-magazine-button::before {
		content: "";
		background : resolve('mail-icon.png');
		background-size: size('mail-icon.png',2);
		background-repeat: no-repeat;
		background-position: center;
		display: inline-block;
		width: width('mail-icon.png',2);
		height: height('mail-icon.png',2);
		padding-left: 20px;
		padding-right: 21px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}
	/* お気に入り */
	.like-button::before {
		content: '';
		background : resolve('icon-heart.png');
		background-size: 20px 19px;
		background-repeat: no-repeat;
		display: inline-block;
		width: 15px;
		height: 19px;
		position: relative;
		top: 10px;
		left: 18px;
		padding:10px 20px;
	}
}

@include breakpoint(sp) {}
