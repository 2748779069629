.section.mod-instagram {
	.title.mod-triangle-above {
		font-weight: 400;
	}
	.glid-5col {
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		display: block;
	}
}
.single-projects {
	.lower-footer-bg {
		padding-top: 70px;
		margin-top: 70px;
		padding-bottom: 150px;
		background:resolve('bg@2x.png') repeat center top;
		background-size: size('bg@2x.png',2);//retinaは第二引数に2
	}
	.footer {
		margin-top: 0;
	}
}

@include breakpoint(sp) {
	.single-projects {
		.glid.mod-wrap-4col {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			& > * {
				width: 48.9323%;
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
			}
			& + .btn-half_line {
				width: 80%;
			}
		}
		.lower-footer-bg {
			padding-top: 35px;
			margin-top: 35px;
		}
		.footer {
			margin-top: 20px;
		}
		.totop-sp {
			margin-top: -100px;
		}
	}

	.glid.mod-wrap-5col + .btn-half_line {
		width: 80%;
	}

	.glid.mod-wrap-5col {
		.glid-5col:nth-child(n + 5) {
	 		display: none;
		}
	}

}
