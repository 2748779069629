.btn_wrap-around-center {
	display: flex;
	justify-content:center;
	flex-wrap:wrap;
	margin-top: 80px;
	position: static;
	width: auto;
	height: auto;
	> * {
		margin-left: 39px;
		margin-right: 35px;
	}
	&_arrow_left{
		transition:opacity .6s;
		&.mod-icon-bottom {
			text-align: left;
			&:after {
				position: static;
				transform:none;
				display: block;
				background-position: left center;
			}
		}
		&:after {
			content: '';
			background: resolve("arrow_contents-month_left.png",2) no-repeat 0 0;
			width: width("arrow_contents-month_left.png");
			height: height("arrow_contents-month_left.png");
			background-size: 50%;
			background-position: center;
			position: absolute;
			top: 45%;
			left: -125px;
			transform: translateY(-50%);
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	&_arrow_right{
		transition:opacity .6s;
		&.mod-icon-bottom {
			text-align: right;
			&:after {
				position: static;
				transform:none;
				display: block;
				background-position: right 0 center;
			}
		}
		&:after {
			content: '';
			background: resolve("arrow_contents-month_right.png",2) no-repeat 0 0;
			width: width("arrow_contents-month_right.png");
			height: height("arrow_contents-month_right.png");
			background-size: 50%;
			background-position: center;
			position: absolute;
			top: 45%;
			right: -120px;
			transform: translateY(-50%);
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	&.pc{
		display: flex;
	}
	&.sp{
		display: none;
	}
}
@include breakpoint(sp) {
	.btn_wrap-around-center {
		margin-top: 40px;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		transform: translateX(-5px);
		> * {
			margin-left: auto;
			margin-right: auto;
		}
		&.pc{
			display: none;
		}
		&.sp{
			display: flex;
		}
	}
}
