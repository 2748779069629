.w_hojin-x_noukasan,
.purchasers {
	p a {
		@extend .c-4f6537;
		border-bottom: 1px solid #4f6537;
	}

	.before-point {
		padding-top: 30px;
		&:before {
			content: "";
			background-color: #c6cfbc;
			width: 6px;
			height: 6px;
			display: inline-block;
			margin-right: 8px;
			vertical-align: 2px;
			border-radius: 50%;
		}
	}

	.box-shadow {
		padding: 0;
		margin-left: -2px;
		margin-top: 67px;
		&.mod-area-has-map.guide {
			background: #f8f7f2;
			border: none;
			.title.mod-triangle-above {
				padding-top: 38px;
			}
			&:after {
				content: none;
			}
		}
	}

	.exam {
		padding-top: 54px;
	}

	.farm0-img {
		background: resolve("farm_logo.png") no-repeat center;
		background-size: size("farm_logo.png", 2);
		height: 173px;
	}

	.k-img {
		background-image: url("http://placehold.jp/300x200.png");
		background-repeat: no-repeat;
		background-size: cover;
		max-width: 300px;
		width: 100%;
		height: 200px;
	}

	.mod-k-flex {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		&-imgarea {
			max-width: 300px;
			width: 100%;
			margin-top: 6px;
		}
		&-textarea {
			max-width: 600px;
			width: 100%;
			margin-left: auto;
			.k-text:nth-child(n+2) {
				margin-top: 47px;
			}
		}
		&.mod-farmer {
			margin-top: 25px;
			justify-content: space-between;
			flex-wrap: wrap;
			padding:0 30px 38px 30px;
		}
	}


	.k-text {
		max-width: 600px;
		width: 100%;
		margin-left: auto;
		line-height: 2;
		&.mod-farmer {
			font-size: 13px;
			line-height: 2.1538;
			&-parttext {
				line-height: 1.8461;
				margin-top: 4px;
				margin-left: -1px;
			}
			&-partbutton {
				margin-top: 41px;
				margin-left: -25px;
			}
		}
		&.w-720.mod-farm0 {
			padding-left: 9px;
			&.mod-farmer-parttext {
				font-size: 13px;
				line-height: 1.8461;
				margin-top: 7px;
				margin-left: -1px;
				padding-left: 13px;
			}
		}
	}

	.k-box {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 40px;
		&.mod-farmer {
			margin-top: 37px;
		}
	}

	.box-link {
		width: 195px;
		+.box-link {
			margin-left: 101px;
			transform: translateX(-20px);
		}
	}

	.article-header-headline_triangle {
		margin-top: 130px;
		margin-bottom: 32px;
		letter-spacing: 0.1em;
		&.mt-0 {
			margin-top: 0;
		}
		&.mod-farmer {
			margin-top: 137px;
		}
	}

	.w-240 {
		max-width: 240px;
		width: 100%;
		&.mod-farm0 {
			padding-right: 12px;
			padding-top: 12px;
		}
	}

	.w-390 {
		max-width: 390px;
		width: 100%;
	}

	.w-440 {
		max-width: 440px;
		width: 100%;
	}

	.w-720 {
		max-width: 720px;
		width: 100%;
	}

	.w-half {
		width: 50%;
	}

	.w-3col {
		max-width: 260px;
		width: 100%;
		p {
			line-height: 1.8461538462;
			margin-top: 10px;
		}
		&.f-13.mod-farmer {
			margin-left: 58px;
			margin-right: 58px;
		}
	}

	.p-top10 {
		margin-top: 10px;
	}

	.kreon-r {
		font-family: "kreon_r";
	}

	.c-4f6537,p a {
		color: #4f6537;
	}

	.c-675f3e {
		color: #675f3e;
	}

	.f-13 {
		font-size: 13px;
	}

	.f-15 {
		font-size: 15px;
	}

	.f-17 {
		font-size: 17px;
	}

	.f-20 {
		font-size: 20px;
	}

	h3 {
		font-size: 13px;
		letter-spacing: 0;
	}

	.hw-title {
		padding-top: 27px;
		p {
			padding-top: 10px;
			padding-left: 2px;
		}
	}

	.hw-num {
		padding-right: 14px;
	}

	.w-half.f-13.hw-title.hw02 {
		padding-left: 31px;
	}

	.title.mod-triangle-above {
		padding-top: 20px;
		padding-bottom: 25px;
		margin-top: 67px;
	}

	.glid.mod-thumbnail_2col-wrap {
		margin-bottom: 0;
	}
}//noukasan

@include breakpoint(sp) {
	.w_hojin-x_noukasan,.purchasers {
		.article-header-headline_triangle {
			margin-top: 100px;
		}
		.box-link {
			width: 90%;
		}
		.k-box.mod-farmer.mod-farmer-partbutton a {
				padding-left: 7.3333333%!important;
				padding-right: 6.3333333%!important;
				height: 42px;
			}
		.exam .box-shadow.mod-area-has-map {
			margin-top: 0;
			padding: 20px 20px;
			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				top: 0;
				left: 0;
				border-top: 15px solid #c3c0b4;
				border-right: 15px solid transparent;
			}
		}
		.k-text {
			&.mod-farmer {
				font-size: 13px;
				line-height: 2.1538461538;
				&.mod-farmer-parttext {
					line-height: 1.8461538462;
					margin-top: 4px;
					margin-left: -1px;
				}
			}
			&.w-720.mod-farm0.mod-farmer-parttext {
				margin-top: 20px;
				padding: 0;
			}
		}
		.title.mod-triangle-above {
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;
			padding-top: 0 !important;
			&:before {
				display: none;
			}
		}
		.article-header-headline_triangle.mod-farmer {
			margin-top: 105px;
		}
		.mod-k-flex {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			&-imgarea {
				margin-bottom: 30px;
				max-width: 100%;
			}
			&-textarea {
				margin-left: auto;
				.k-text:nth-child(n + 2) {
					margin-top: 47px;
				}
			}
			&.mod-farmer {
				margin-top: 0;
				padding-left: 0;
				padding-right: 0;
			}
		}

		.k-box {
			a {
				padding: 0 20px;
				&:nth-child(1) {
					margin-bottom: 30px;
				}
			}
			&.mod-farmer.mod-farmer-partbutton a {
				line-height: 1.5384615385;
				padding-left: 12%;
				padding-right: 12%;
			}
		}
		.w-half.f-13.hw-title {
			width: 100%;
			&.hw02 {
				padding-left: 0px !important;
			}
		}
		.k-img {
			background-image: url("http://placehold.jp/300x200.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			max-width: 560px;
			width: 100%;
			padding-top: calc(200 / 300 * 100%);
			height: 0;
		}
		.box-shadow.mod-area-has-map.guide.title.mod-triangle-above {
			padding-left: 3.125%;
			padding-right: 3.90625%;
		}
		.k-box.mod-farmer {
			margin-top: 45px;
			margin-left: 3.5%;
			margin-right: 3.5%;
			a {
				margin: 0;
			}
		}
		.box-link + .box-link {
			margin-top: 30px !important;
			margin-left: auto !important;
		}
		.w-3col {
			&.f-13.mod-farmer {
				margin: 30px auto 0;
				padding: 0;
			}
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			+ .w-3col {
				margin-top: 30px;
			}
		}
		.w-240.mod-farm0 {
			padding: 0;
		}
	}
	.w_hojin-x_noukasan .k-text.w-720.mod-farm0 {
		padding-left: 0;
		margin-top: 20px;
	}

}//breakpoint
