.small_post-contents{
	width: 100%;
	border: solid 4px #f5f7f2;
	padding: 30px 83px 35px;
	&_inner{
		h3{
			font-size: 18px;
			font-weight: bold;
			color: #4f6537;
			padding-bottom: 10px;
			border-bottom: solid 1px;
		}
		.main-thumbnail{
			width: 100%;
			margin-top: 20px;
			&-img{
				width: 100%;
				height: 0px;
				padding-top: 61.2244%;
				background: no-repeat 0 0;
				background-position: center;
				background-size: cover;
			}

		}
		.text-area{
			margin-top: 35px;
			font-size: 14px;
			line-height: 2;
		}
	}

	&_inner + &_inner {
		margin-top: 40px;
	}
}


@include breakpoint(sp) {
	.small_post-contents{
		border: solid 2px #f5f7f2;
		padding: 15px 20px 10px;
		&_inner {
			.text-area{
				margin-top: 25px;
				font-size: 13px;
				line-height: (46 / 26);
			}
		}
		&_inner + &_inner {
			margin-top: 30px;
		}
	}
}
