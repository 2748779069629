.order-table{
	margin-top: 100px;
	line-height: (22 /13);
	.title.mod-triangle-above.mod-green {
		color: #1c1f21;
		padding : 15px 30px;
	}
	.table-list {
		border-bottom: 1px solid #cdd2d5;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	ul.table-list.border-top.top {
		border-top: 1px solid #cdd2d5;
		margin-top: 19px;
	}
	.num1 li{
		 border-left: none!important;
	}
	ul li {
		font-size: 13px;
		text-align: center;
		p.tit {
			padding-top: 13px;
			padding-bottom: 11px;
		}
		ul li {
			border-top: 1px solid #cdd2d5;
			max-width: 100%!important;
			width: 100%;
			padding:0;
			padding : 13px 25px 10px;
		}
		&:nth-child(-n + 2) {
			ul li {
			border-left: 1px;
			font-weight: 400;
			padding : 10px 25px 13px;
			text-align: left;
			}
		}
		&:nth-child(1) {
			border-left: none;
			max-width: 254px;
			width: 100%;
		}
		&:nth-child(2) {
			max-width: 129px;
			width: 100%;
		}
		&:nth-child(3) {
			max-width: 134px;
			width: 100%;
			ul li {
				border-left: 1px;
				padding: 0;
				padding-top: 20px;
				padding-bottom: 17px;
			}
			span {
				font-family: 'kreon_r';
				font-size: 18px;
				font-weight: 400;
			}
		}
		&:nth-child(4) {
			max-width: 143px;
			width: 100%;
			ul li {
				border-left: 1px;
				padding-top: 23px;
				padding-bottom: 21px;
			}
			span {
				font-family: 'kreon_r';
				font-size: 14px;
				font-weight: 400;
			}
		}
		&:nth-child(n + 2) {
			border-left: 1px dotted #cdd2d5;
		}
	}
}

@include breakpoint(sp) {
	.order-table{
		margin-top: 40px;
		ul li {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			text-align: left;
			padding-top: 10px;
			padding-bottom: 10px;
			ul li {
				border-top: 1px dotted #cdd2d5;
			}
			p.tit {
				padding: 0;
			}
			&:nth-child(-n + 2) {
				ul li {
					padding: 0;
				}
			}
			&:nth-child(1) {
				max-width: 100%;
			}
			&:nth-child(2) {
				max-width: 100%;
			}
			&:nth-child(3) {
				max-width: 100%;
				ul li {
					padding: 0;
				}
			}
			&:nth-child(4) {
				max-width: 100%;
				ul li {
					padding: 0;
				}
			}
			&:nth-child(n + 2) {
				border: none;
			}
		}
	}//order
}
