.side-nav {
	$p: &;
	& + .side-nav {
		margin-top: 27px;
	}
	display: block;
	position: relative;
	height: auto;
	& > ul > li > a {
		color: $black;
		font-size: 16px;
		font-weight: 700;
		line-height: (24 / 16);
		&:before {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 0 6px 6px;
			border-color: transparent transparent transparent #c3c0b4;
			content: "";
			display: inline-block;
			vertical-align: 1px;
			margin-right: 10px;
		}
		& + ul {
			padding-top: 12px;
			padding-left: 16px;
			li + li {
				margin-top: 11px;
			}
			a {
				color: $black;
				font-size: 14px;
				font-weight: 700;
				line-height: 1.4;
				display: block;
				padding-left: 0.5em;
				text-indent: -0.5em;
				&:before {
					content: "-";
					margin-right: 2px;
				}
			}
		}
	}
	&.mod-yomimono-new {
		ul {
			& > li {
				a {
					font-size: 14px;
					position: relative;
					padding-left: 23px;
					#{$p}-icon {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: 0;
						width: 26px;
						height: 26px;
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
					}
					img {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: 0;
					}
					& + ul {
						padding-top: 8px;
						li + li {
							margin-top: 5px;
						}
						a {
							color: #666666;

							&:before {
								content: "・";
								margin-right: 2px;
							}
						}
					}
				}
				&:first-child {
					margin-top: 0;
				}
			}
		}
		& > ul {
			& > li {
				margin-top: 29px;
				& > a {
					&:before {
						content: "";
						display: inline-block;
						border: none;
					}
				}
				&:first-child > a:before {
					// background-image: url('/build/images/icon_nav_yomimono01.png');
					// background-size: cover;
					// height: height('icon_nav_yomimono01.png',2);
					// width: width('icon_nav_yomimono01.png',2);
				}
			}
		}
	}
}
.side-nav + .side-nav {
	& > ul > li > a:before {
		border-color: transparent transparent transparent #c6cfbc;
	}
}
@include breakpoint(sp) {
	.side-nav {
		& + .side-nav {
			margin-top: 20px;
		}
		& > ul > li > a {
			font-size: 14px;
			& + ul {
				padding-top: 6px;
				padding-left: 0;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				li {
					margin-right: 10px;
				}
				li + li {
					margin-top: 0;
					&:before {
						content: "|";
						margin-right: 10px;
					}
				}
				a {
					display: inline-block;
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
