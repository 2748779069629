.thumbnail-item{
	$c:&;
	position: relative;
	font-size: 14px;
	position: relative;
	display: block;
	&:hover{
		opacity: 1;
	}
	&.mod-odd{
		color: #68603e;
		background-color: #f8f7f2;
		border-bottom: 1px solid #c3c0b4;
		&:after{
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 5px 5px;
			border-color: transparent transparent #c3c0b4;
		}
		#{$c}-linkmore {
			color: #68603e;
			&:after {
				content:"";
				display: inline-block;
				background:resolve('arrow_right_brown.png') no-repeat left top;
				background-size: size('arrow_right_brown.png',2);
				width:width('arrow_right_brown.png',2);
				height:height('arrow_right_brown.png',2);
				margin-left: 9px;
			}
		}
	}
	&.mod-even{
		position: relative;
		font-size: 14px;
		color: #4f6537;
		background-color: #f5f7f2;
		border-bottom: 1px solid #c6cfbc;
		&:after{
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 5px 5px;
			border-color: transparent transparent #c6cfbc;
		}
	}
	&.mod-shop-cat{
		#{$c}-img{
			height: 150px;
		}
	}
	&-mask{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.4);
		transition: all 0.6s ease;
		opacity: 0;
		& p{
			color: #fff;
			font-family: kreon_r;
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			letter-spacing: 0.1em;
			&:after{
				content: '';
				position: absolute;
				background:resolve("arrow-right-mask.png") center no-repeat;
				background-size:size("arrow-right-mask.png");
				width:width("arrow-right-mask.png");
				height:height("arrow-right-mask.png");
				bottom: -15px;
				margin: 0 auto;
				left: 0;
				right: 0;
			}
		}
	}
	&:hover &-mask{
		top: 0;
		opacity: 1;
	}
	&-img{
		position: relative;
		background-image: url('http://placehold.jp/300x190.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 190px;
		display: block;
		&-icon{
			position: absolute;

			top:50%;
			left:50%;
			transform:translate(-50%,-50%);

			background-repeat: no-repeat;
			background-position: center;
			&.mod-mekong{
				background-image:resolve('logo_mekong.png');
				background-size:size("logo_mekong.png");
				width:width("logo_mekong.png");
				height:height("logo_mekong.png");
			}
			&.mod-farmo{
				background-image:resolve('logo_farmo.png');
				background-size:size("logo_farmo.png");
				width:width("logo_farmo.png");
				height:height("logo_farmo.png");
			}
			&.mod-recruit{
				background-image:resolve('logo_recruit.png');
				background-size:size("logo_recruit.png");
				width:width("logo_recruit.png");
				height:height("logo_recruit.png");
			}
			&.mod-uganda {
				background-image:resolve('logo_uganda.png',2);
				background-size:size("logo_uganda.png",2);
				width:width("logo_uganda.png",2);
				height:height("logo_uganda.png",2);
			}
		}

		&.mod-img-on {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			transition:opacity .8s;
			&:hover {
				opacity: 1;
			}
		}

		$c: &;
		&.mod-4col{
			height: 140px;
			#{$c}-icon{
				&.mod-mekong{
					background-image:resolve('logo_mekong@2x.png');
					background-size:size("logo_mekong@2x.png",2.5);
					width:width("logo_mekong@2x.png",2.5);
					height:height("logo_mekong@2x.png",2.5);
				}
				&.mod-farmo{
					background-image:resolve('logo_farmo@2x.png');
					background-size:size("logo_farmo@2x.png",2.5);
					width:width("logo_farmo@2x.png",2.5);
					height:height("logo_farmo@2x.png",2.5);
				}
				&.mod-recruit{
					background-image:resolve('logo_recruit@2x.png');
					background-size:size("logo_recruit@2x.png",2.5);
					width:width("logo_recruit@2x.png",2.5);
					height:height("logo_recruit@2x.png",2.5);
				}
			}
		}
	}
	&-text{
		padding: 16px 20px;
		line-height: (24 / 14);
		&.mod-u_project {
			padding-top: 7px;
			padding-left: 21px;
			line-height: 24px;
		}
	}
	&-title{
		text-align: center;
		font-weight: bold;
		vertical-align: middle;
		min-height: 60px;
		display: flex;
		align-items:center;
		width: 100%;
		p{
			text-align: center;
			width:100%;
			padding:0 20px;
		}
		&.mod-u_project {
			padding-top: 20px;
			padding-left: 18px;
			font-size: 16px;
			min-height: 0;
			text-align: left;
		}
		&.mod-staff-name{
			@extend .clearfix;
			width: 100%;
			min-height:0;
			font-size: 14px;
			display: block;
			margin-top: 18px;
			text-align-last: auto;
			padding:0 20px;
			.name_sep {
				display: block;
				float: left;
				text-align: left;
			}
			.sep {
				width:25px;
				display: inline-block;
				text-align: center;
				visibility:visible!important;
			}
			.part {
				overflow: hidden;
				display: block;
				text-align: left;
			}
			& + .thumbnail-item-text {
				padding:6px 20px 18px;
			}
		}
	}
	&.mod-placeholder {
		background: url(../images/bg_placeholder.png) repeat center;
		background-size: cover;
	}
	&-linkmore {
		font-size: 13px;
		line-height: 1;
		font-weight: bold;
		color: #4f6537;
		width: 100%;
		text-align: right;
		display: block;
		margin-top: 15px;
		&:after {
			content:"";
			display: inline-block;
			background:resolve('arrow_right_green.png') no-repeat left top;
			background-size: size('arrow_right_green.png',2);
			width:width('arrow_right_green.png',2);
			height:height('arrow_right_green.png',2);
			margin-left: 9px;
		}
	}
}

.ie_10 {
	.thumbnail-item-title {
		display:table;
		& > p {
			display:table-cell;
			vertical-align: middle;
		}
	}
}

@include breakpoint(sp) {
	.thumbnail-item{
		$cc:&;
		position: relative;
		// & + & {
		// 	margin-top: 40px;
		// }
		width: 300px;
		&:hover{
			opacity: 1.0;
		}
		&.mod-odd{
			color: #68603e;
			background-color: #f8f7f2;
			border-bottom: 1px solid #c3c0b4;
			&:after{
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 5px 5px;
				border-color: transparent transparent #c3c0b4;
			}
		}
		&.mod-even{
			position: relative;
			font-size: 14px;
			color: #4f6537;
			background-color: #f5f7f2;
			border-bottom: 1px solid #c6cfbc;
			&:after{
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 5px 5px;
				border-color: transparent transparent #c6cfbc;
			}
		}
		&-mask{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.4);
			transition: all 0.6s ease;
			opacity: 0;
			p{
				font-family: kreon_r;
				&:after{
					content: '';
					position: absolute;
					background-image: resolve('arrow-right-mask.png');
					background-repeat: no-repeat;
					width: 30px;
					height: 10px;
					bottom: -15px;
					margin: 0 auto;
					left: 0;
					right: 0;
				}
			}
		}
		&:hover &-mask{
			top: 0;
			opacity: 1;
		}
		&-img{
			width: 100%;
			&-icon{
				background-repeat: no-repeat;
				background-position: 50% 50%;
				&.mod-mekong{
					background-image:resolve('logo_mekong.png');
					background-size:auto 43px;
				}
				&.mod-farmo{
					background-image:resolve('logo_farmo.png');
					background-size:auto 43px;
				}
				&.mod-recruit{
					background-image:resolve('logo_recruit.png');
					background-size:auto 43px;
				}
			}

			&.mod-img-on {
				display: none;
			}

			$c: &;
			&.mod-4col{
				height: 0;
				padding-top: (168 / 275) * 100%;
				#{$c}-icon{
					background-repeat: no-repeat;
					&.mod-mekong{
						background-image:resolve('logo_mekong@2x.png');
						background-size:contain;
						width:75%;
						height:75%;
					}
					&.mod-farmo{
						background-image:resolve('logo_farmo@2x.png');
						background-size:contain;
						width:75%;
						height:75%;
					}
					&.mod-recruit{
						background-image:resolve('logo_recruit@2x.png');
						background-size:contain;
						width:75%;
						height:75%;
					}
				}
			}
		}
		&-text{
			padding: 15px 12px;
			font-size: 13px;
		}
		&-title{
			min-height: 55px;
			&.mod-u_project {
				font-size: 14px;
			}
			p{
				font-size: 12px;
			}
		}
		&.mod-sp-fullwidth {
			width: 100%;
			display: flex;
			justify-content:space-betweeen;
			flex-wrap:wrap;
			padding:13px 11px;
			#{$cc}-img {
				order:2;
				width: 41.07142%;
				height: 0;
				padding-top: 24.8695%;
			}
			#{$cc}-title {
				order:1;
				width: 100%;
				margin: 0 0 13px 0;
				&.mod-staff-name {
					padding:0;
					p {
						padding:0;
					}
				}
			}
			#{$cc}-text {
				order:3;
				width: calc(100% - calc(41.07142% + 10px));
				padding:0 0 0 10px;
			}
		}
		&.mod-sp-fullwidth + &.mod-sp-fullwidth {
			margin-top: 20px;
		}
		&-linkmore {
			font-size: 12px;
			margin-top: 5px;
		}
	}
	.thumbnail-item.u_project-item {
		width: 100%;
	}
}
