.footer-navi-col-second{
	float: left;
	padding-right: 0px;
	margin-top: -3px;
	&-inner{
		&.mod-shop{
			margin-top: 37px;
		}
		> li > ul {
			margin-top: 12px;
			li+li {
				margin-top: 0;
			}
		}
	}
	a:nth-child(2){
		margin-top: 18px;
	}
	a {
		color: #000;
	}
	li+li {
		margin-top: 12px;
	}
}


@include breakpoint(sp) {
	.footer-navi-col-second{
		float: none;
		padding-right: 0px;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		&-inner{
			margin-top: 0;
			order: 2;
			&.mod-shop{
				margin-top: 15px;
				order: 1;
			}
			a + a{
				margin-left: 20px;
			}
			> li > ul {
				margin-top: 0;
			}
		}
		li {
			margin-left: 0px;
			margin-top: 5px;
			display: inline-block;
		}
		a:nth-child(2){
			margin-top: 0px;
		}
		li:first-child{
			margin-top: 5px;
		}

		.footer-navi-title-under-line + .footer-navi-title-under-line {
			margin-left: 15px;
		}
	}

}
