.shop-top {
	.footer-shop.mod-above {
		margin-top: 0;
	}
}
.footer-shop {
	$c:&;
	width: 100%;
	background-color: #f5f7f2;
	&.mod-above {
		margin-top: 160px;
	}
	&.mod-bottom {
		margin-top: 30px;
		background-color: #eaefe5;
		#{$c}-inner {
			justify-content:flex-start;
			.col:first-child {
				padding-right: 38px;
				max-width:50%;
			}
			.col:last-child {
				margin-left: auto;
			}
		}
	}
	&-inner {
		padding:45px 0;
		max-width: 960px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		color:$black;
		font-size: 12px;
		font-weight: 500;
		line-height: (24 / 12);
		letter-spacing: .1em;
		a {
			color:$black;
		}
		> * {
			max-width:32%;
		}
	}
	&-title {
		font-size: 14px;
		font-weight: 700;
		line-height: (24 / 14);
		letter-spacing: .1em;
		&:before {
			content:"";
			width: 6px;
			height: 6px;
			background-color: #1c1f21;
			display: inline-block;
			vertical-align: 1px;
			margin-right: 10px;
		}
		&.mod-line {
			display: block;
			a {
				border-bottom: 1px solid $black;
				line-height: 1;
				padding-bottom: 5px;
				display: inline-block;
			}
			&:before {
				display: none;
			}
		}
	}
	&-content {
		&.mt-l {
			margin-top: 24px;
		}
		.fl:first-child {
			width: 130px;
			margin-right: 6px;
			& >*:nth-child(n + 2){
				position: relative;
				&:after {
					content:"⇒";
					position: absolute;
					right: 13px;
					position:absolute;
					top:50%;
					transform:translate(0,-50%);
				}
			}
		}
	}
	&-content:nth-of-type(n+2) {
		margin-left: 48px;
	}
	&-more {
		color: #4f6537!important;
		font-size: 11px;
		font-weight: 700;
		line-height: 1;
		border-bottom: 1px solid #c6cfbc;
		padding-bottom: 6px;
		display: inline-block;
		margin-top: 24px;
	}
	.emph {
		font-weight: 700;
		color: #4f6537;
	}
}
@include breakpoint(sp) {
	.footer-shop {
		&-inner {
			padding:30px 0;
			> * {
				max-width:100%;
			}
		}
		&-title {
			font-size: 15px;
			&.mod-line {
				border-bottom-color:#c6cfbc;
				&.mod-sp-exchange {
					border-bottom: 0;
					padding-bottom: 0;
					&:before {
						content:"";
						width: 6px;
						height: 6px;
						background-color: #1c1f21;
						display: inline-block;
						vertical-align: 1px;
						margin-right: 10px;
					}
				}
			}
		}
		&.mod-above {
			display: none;
		}
		&.mod-bottom {
			.footer-shop-inner {
				width: 87.5%;
				display: block;
				.col:first-child {
					display: none;
				}
				.col:last-child {
					display: flex;
					justify-content:flex-start;
					flex-wrap:wrap;
					margin-top: 15px;
				}
			}
			p + .footer-shop-content {
				margin-top: 0;
				margin-left: 20px;
			}
			p.footer-shop-content + .footer-shop-content {
				margin-top: 13px;
				margin-left: 0;
			}
		}
		&-content:nth-of-type(n+2) {
			margin-left: 0px;
		}
	}
}
