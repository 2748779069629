.header-month_sp{
	margin: 20px 0px;
	table{
		width: 87.5%;
		margin: 0 auto;
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		color: #c6cfbc;
		border-top: solid 1px;
		border-left: solid 1px;
		@extend .font-kreon_r;
	}
	td{
		padding: 10px 20px;
		border-right: solid 1px;
		border-bottom: solid 1px;
		border-color: #c6cfbc;
	}

	.odd{
		a{
			color: #68603e;
		}
	}
	.even{
		a{
			color: #4f6537;
		}
	}
}

@include breakpoint(sp) {
	.header-month{

	}
}
