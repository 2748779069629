.area-has-map {
	$c:&;
	margin-bottom: 120px;
	display: flex;
	justify-content:space-between;
	flex-wrap:wrap;
	&-text{
		width: 500px;
		&-inner{
			padding:0 40px 20px;
		}
	}
	&-map{
		width: 435px;
		position: relative;
		&-wrap {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.js-map{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	&-table{
		font-size: 14px;
		line-height: 2;
		table-layout:fixed;
		th{
			text-align: left;
			white-space: nowrap;
		}
		.sep {
			text-align: left;
			font-weight: 700;
			width: 1em;
		}
		a {
			text-decoration: underline;
			color: $black;
			@include opacity--anim(.5);
			&:hover {
				text-decoration: none;
			}
		}
	}
	.title{
		font-size: 14px;
		padding:1em 40px;
	}

	@at-root {
		#{$c}.mod-company{
			margin-top: 17px;
			#{$c}{
				&-text{
					width: 440px;
					&-inner{
						background: #f8f7f2;
						padding:20px 60px;
						font-size: 13px;
						line-height: 2;
					}
				}
				&-map{
					width:520px;
				}
			}
		}
	}
}
@include breakpoint(sp) {
	.area-has-map {
		$c:&;
		margin-bottom: 110px;
		display: block;
		&-text{
			width: 100%;
			float: none;
			margin-bottom: 40px;
			&-inner{
				padding:0 10px 20px;
			}
		}
		&-map{
			float: none;
			width: 100%;
			padding-top: 53.9%;
			&.mod-img {
				padding-top: 0;
			}
		}
		&-table{
			font-size: 13px;
			line-height: 2;
			th{
				width: 3em;
				white-space: inherit;
			}
		}
		.title{
			text-align: center;
		}

		@at-root {
			#{$c}.mod-company{
				margin-bottom: 60px;
				#{$c}{
					&-text{
						width: 100%;
						&-inner{
							padding:20px;
							font-size: 13px;
						}
					}
					&-map{
						width:100%;
					}
				}
			}
		}
	}
}
