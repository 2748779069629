.pager{
	position: relative;
	text-align: center;
	&-num{
		width: 8px;
		height: 8px;
		margin: 0 5px;
		display: inline-block;
		vertical-align: middle;
		&.mod-odd{
			border: solid 1px #4f6537;
			&.active{
				background-color: #4f6537;
				width: 12px;
				height: 12px;
			}
		}
		&.mod-even{
			border: solid 1px #68603e;
			&.active{
				background-color: #68603e;
				width: 12px;
				height: 12px;
			}
		}
	}
}


