.box-caution{
	font-size: 14px;
	position: absolute;
	width: 550px;
	top: 120px;
	right: 0;
	border: solid 1px $green;
	border-right: 0px;
	&-title{
		width: 135px;
		font-weight: bold;
		color: #fff;
		background-color: $green;
		border-right: 0px;
		padding: 9px 15px;
	}
	&-text{
		color: $green;
		border-right: 0px;
		letter-spacing:1px;
		padding: 9px 15px;
	}
}

@include breakpoint(sp){
	.box-caution{
		&-wrap {
			display: block;
			width: 100%;
			overflow:hidden;
			margin-bottom: 15px;
			table {
				width: 100%;
			}
		}
		font-size: 12px;
		position: static;
		width: 100%;
		&-title{
			width: auto;
			white-space: nowrap;
			padding: 7px 5px;
			text-align: center;
		}
		&-text{
			padding:7px;
			background-color: #fff;
			> p {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width:250px;
			}
		}

	}
}

@include breakpoint(exsp){
	.box-caution{
		&-text{
			> p {
				width:210px;
			}
		}
	}
}

