.desined-scrollbar {
	position: relative;
	height: 365px;
	padding-right: 21px;
	.ps__rail-y {
		background-color: #c3c0b4;
		width: 1px;
		opacity: 1;
	}
	.ps__thumb-y {
		background-color: #675f3e;
		width: 1px;
		right: 0;
	}
}
@include breakpoint(sp) {
	.desined-scrollbar {
		height: 195px;
		padding-right: 13px;
	}
}
