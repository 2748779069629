.set-text {
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	@include text-color-scheme;
	@include border-top-color-scheme;
	@include triangle-top-left-color-scheme;

	position: relative;
	border-style: solid;
	border-width:1px 0 0;
	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0 0 ;
	}
	&-title{
		padding: 26px 20px 20px 25px;
		font-size: 18px;
		font-weight: bold;
		font-style:italic;
		letter-spacing: 0.1em;

	}
	&-textarea{
		padding:0 40px 0 45px;
		font-size: 14px;
		line-height: 2;
		* + p{
			margin-top: 2em;
		}
	}
}
@include breakpoint(sp) {
	.set-text {
		max-width:100%;
		border-top: 0;
		position: relative;
		&:before{
			display: none;
		}
		&-title{
			max-width: 100%;
			width: 90%;
			margin:0 auto;
			padding:0 0 1em;
			font-size: 14px;
		}
		&-textarea{
			max-width: 100%;
			width: 90%;
			margin:0 auto;
			padding:0;
  			font-size: 13px;
  			* + p{
  				margin-top: 2em;
  			}
		}
	}
}
