body.yasaiset{
	min-width: 1200px;
}
.yasaiset{
	.title.mod-triangle-above.mod-green{
		color: #000;
	}
	.thumbnail-item-has_under_title{
		transition: opacity .6s;
		opacity: 1;
		&:hover{
			transition: opacity .6s;
			opacity: 0.6;
		}
	}
	.thumbnail-item-has_under_title-img {
		height: 150px;
	}
	.text-caution {
		display: inline-block;
		padding-left: 15px;
	}
}

@include breakpoint(sp) {
	body.yasaiset {
		min-width: 100%;
	}
	.yasaiset{
		.glid {
			//width: 87.5%;
			&.mod-content-1col {
				padding: 0;
				margin-top: 0px;
				width: 87.5%;
				margin-left: auto;
				margin-right: auto;
				margin-top: 40px;
			}

			&.thumbnail-item-has_under_title-wrap {
				width: 100%;
			}
		}
		.title.mod-triangle-above {
			padding-left: 1.5em;
			padding-right: 0;
			// margin-top: 90px;
			margin-top: 0;
			.yasaiset_small-title {
				margin-left: -1.5em;
			}
		}

		.thumbnail-item-has_under_title {
			width: 48.1071%;
			margin-left: 0;
			margin-right: 0;
		}

		.thumbnail-item-has_under_title-img {
			padding-top: 100%;
			height: 0;
		}
		.text-caution {
			font-size: 11px;
			display: block;
			padding-left: 0;
			margin-left: 0;
		}
	}
}
