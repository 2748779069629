.table-sum-list{
	width: 100%;
	text-align: right;
	font-size: 14px;
	margin-top: 5px;
	td:first-of-type{
		font-weight: bold;
		width: 90%;
		padding: 5px 0px;
	}
	td:last-of-type{
		width: 10%;
		padding: 5px 0px;
	}
}
@include breakpoint(sp) {
	.table-sum-list{
		td:last-of-type {
			width: 20%;
		}
		td:first-of-type{
			width: auto;
		}
	}
}
