article.mod-r_productlist {
	margin-top: 85px;
}
.need-select_size {
	margin:0;
	margin-right: -8px!important;
}
.r_productlist-title {
	color: #4f6537;
	margin-right: 3px;
	.box-shadow.mod-area-has-map:after {
		right: -6px;
		bottom: -6px;
	}
}
.modal-product {
	background-color: rgb(255,255,255);
	padding: 0;
	right: 3px;
	max-width: 51.7%;
	width: 100%;
	z-index: 999999;
	&-area {
		position: relative;
		padding-top: 10.169491525%;
		padding-bottom: 10.169491525%;
		button.remodal-close {
			width : 20px;
			height : 20px;
			&::before {
				content:"";
				background-image: url('../images/btn-close-rlist.png');
				background-size: 20px;
				width: 20px;
				height: 20px;
				left: -11px;
				top: 4px;
			}
		}
		.thumbnail-basic-item-img.mod-r_productlist {
			margin-left: auto;
			margin-right: auto;
			margin-top: 7px;
		}
		.list {
			margin-top: 5.084745762%;
			padding: 0 6.557377049%;
			p {
				border-bottom: 1px solid #1c1f21;
			}
			&-items {
				border-bottom: 1px solid #1c1f21;
				width : 100%;
				padding: 7px 0;
				&-catal {
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					padding: 10px 0;
					.name{
						font-size: 14px;
						padding-right: 30px;
					}
					.type{
						font-family: 'kreon_l';
						font-size: 16px;
						padding-right: 30px;
					}
					.price{
						font-family: 'kreon_l';
						font-size: 16px;
						padding-right: 30px;
					}
					.num{
						font-size: 14px;
						padding-right: 30px;
					}
					.num-x{
						padding-right: 30px;
						input {
							width: 40px;
						}
					}
					.add-cart{
						margin-left: auto;
					}
				}
			}
			.thumbnail-basic-item-label-num.mod-r_productlist {
				font-size: 18px;
				letter-spacing: 0.1em;
				padding-bottom: 12px;
				width: 100%;
			}
		}
	}
	.thumbnail-basic-item-img.mod-r_productlist {
		max-width: 324px;
		width: 100%;
		height: 254px;
	}
}

.title.mod-triangle-above.mod-r_productlist {
	letter-spacing: 0;
	font-size: 18px;
	padding-bottom: 4px;
	padding-top: 15px;
	&:before {
		border-color: #c6cfbc transparent;
	}
}
.area-has-map-text-inner.mod-r_productlist {
	font-size: 14px;
	line-height: (28 / 14);
	padding: 3px 31px 12px 31px;
}
.thumbnail-basic-item-img.mod-r_productlist {
	background-image: url('http://placehold.jp/213x140.png');
	height: 140px;
}
.thumbnail-basic-item-label-num.mod-r_productlist {
	color: #1c1f21;
	font-size: 13px;
	font-weight: 700;
	text-align: left;
	width: 58.8235294%;
	@extend .font-yu_go;
}
.thumbnail-basic-item.mod-brown.mod-r_productlist {
	border:none;
	padding-bottom: 0;
	a {
		display: block;
		transition: all .6s;
		&:hover {
			opacity: 0.6;
		}
	}
	&:after {
		content: none;
	}
}
.thumbnail-basic-item-label.mod-r_productlist {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 0 5px;
	padding-top: 17px;
	padding-bottom: 20px;
}
.thumbnail-basic-item-label-lead.mod-r_productlist {
	width: 41.1764706%;
}
p.thumbnail-basic-item-label-lead.mod-r_productlist {
	color: #1c1f21;
	font-family: 'kreon_l';
	text-align: right;
	margin-top: -5px;
	& span.mod-f18 {
		font-size: 18px;
		margin-right: 5px;
	}
	& span.mod-f13 {
		font-size: 13px;
		vertical-align: middle;
		margin-right: 2px;
	}
}
.previouspostslink.r_productlist {
	margin-left: 15px;
	margin-right: 25px;
}

@include breakpoint(sp) {
	.modal-product {
		right: 0;
		max-width: 100%;
		&-area {
			button.remodal-close {
				width : 20px;
				height : 20px;
				&::before {
					content:"";
					background-image: url('../images/btn-close-rlist.png');
					background-size: 20px;
					width: 20px;
					height: 20px;
					left: 0;
					top: 0;
				}
			}
			.thumbnail-basic-item-img.mod-r_productlist {
				max-width: 57.142857142%;
				margin-left: auto;
				margin-right: auto;
				height: 216px;
				padding-top: 216px;
			}
			.list {
				margin-top: 5.084745762%;
				padding: 0 6.557377049%;
				p {
					border-bottom: 1px solid #1c1f21;
				}
				&-items {
					border-bottom: 1px solid #1c1f21;
					width : 100%;
					padding: 7px 0;
					&-catal {
						display: flex;
						justify-content: flex-start;
						flex-wrap: wrap;
						padding: 10px 0;
						.name{
							font-size: 14px;
							padding-right: 30px;
						}
						.type{
							font-family: 'kreon_l';
							font-size: 16px;
							padding-right: 30px;
						}
						.price{
							font-family: 'kreon_l';
							font-size: 16px;
							padding-right: 30px;
						}
						.num{
							font-size: 14px;
							padding-right: 30px;
						}
						.num-x{
							padding-right: 30px;
							input {
								width: 27.5px;
							}
						}
						.add-cart{
							margin-left: auto;
						}
					}
				}
				.thumbnail-basic-item-label-num.mod-r_productlist {
					font-size: 15px;
					letter-spacing: 0.1em;
					padding-bottom: 6px;
					width: 100%;
				}
			}
		}
	}
	.r_productlist-title {
		margin-right: 0;
	}
	.title.mod-triangle-above.mod-r_productlist {
		padding-top: 30px;
		padding-bottom: 20px;
		font-size: 14px;
		text-align: center;
	}
	.area-has-map-text-inner.mod-r_productlist {
		padding: 0 1.5625% 30px;
		font-size: 13px;
		line-height: (48 / 26);
	}
	.thumbnail-basic-item-label-num.mod-r_productlist {
		width: 100%;
	}
	.thumbnail-basic-item-label-lead.mod-r_productlist {
		width: 100%;
	}
	.thumbnail-basic-item-label.mod-r_productlist {
		padding: 0 2px;
		padding-bottom: 15px;
	}
	.thumbnail-basic-item-label-lead.mod-r_productlist {
		padding-top: 10px;
	}
}
