.detail-info {
	&-info-sp{
		width: 100%;
		max-width: 660px;
		margin: 0 auto;

		a{
			color:#4f6537;
		}
		h3{
			border-bottom: 1px solid #000;
			padding:14px 10px;
			font-size: 14px;
		}
		dl{
			@extend .clearfix;
			line-height: 2;
			border-bottom: 1px solid #cdd2d5;
			padding-bottom: 5px;
			font-size: 13px;
		}
		dt{
			clear: left;
			float: left;
			font-size: 13px;
			width: 100%;
			font-weight: bold;
			padding: 0px;
			margin-top: 10px;
			border-top: 1px solid #cdd2d5;
			padding-top: 8px;
			padding-left: 5px;
		}
		dd{
			float: left;
			padding:0px;
			width: 49%;
			border-top: 0px solid #cdd2d5;
			background:resolve('line-user-table.png') repeat-y 100% top;
			background-size: size('line-user-table.png',2);
			padding: 0 5px;
			& + dd{
				float: left;
				width: 49%;
				text-align: center;
				border-top: 0px solid #cdd2d5;
				background-image: none;
			}
		}
	}
}
.common-component {
	* + .detail-info-info-sp dt {
		margin-top: 10px;
	}
}
@include breakpoint(sp) {

}
