.footer-navi-col{
	float: left;
	display: inline-block;
	padding-right: 80px;
}

@include breakpoint(sp) {
	.footer-navi-col{
		float: none;
		width: 90%;
		padding-right: 0px;
		font-size: 0px;
		display: flex;
		flex-wrap: wrap;
		ul{
			margin-top: 0px;
		}
		li{
			display: inline-block;
			margin-left: 0px;
		}
		li:first-child{
			margin-top: 0px;
		}
		li:nth-of-type(2n){
			margin-left: 0px;
			position: relative;
		}
	}

}
