.ichinichi{
	&-wrap{
		position: relative;
	}
	.thumbnail-transform-item_foot_components{
		margin-top: 149px;
		.article-header-headline.mod-thumbnail{
			margin-bottom: 18px;
		}
		& + .thumbnail-transform-item_foot_components{
			margin-top: 136px;
			.article-header-headline.mod-thumbnail{
				margin-bottom: 30px;
			}
		}
	}
	.banner-wrap{
		margin-top: 0px;
	}
//layouts
	.time_line-bar{
		background-color: #f8f7f2;
		border-left: solid 1px #c3c0b4;
		height: 3726px;
		p {
			display: inline;
		}
		&-hader_thumbnail{
			width: 300px;
			&_img{
				background: resolve("collect.jpg",2) no-repeat 0 0;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				height:height('collect.jpg',2);
			}
		}
		&-title{
			font-size: 16px;
			font-weight: bold;
			color: #68603e;
			text-align: center;
			padding-top: 20px;
			letter-spacing: 2px;
			line-height: 1.7;
			.ext-big{
				font-size: 20px;
			}
			&.mod-green{
				color: #4f6537;
			}

			p {
				display: block;
			}
		}
		&_clear_fix{
			margin-top: 47px;
			opacity: 0;
		}
		.time_line_clock{
			font-size: 17px;
			@extend .font-kreon_r;
			font-weight: 400;
			padding-left: 35px;
			padding-bottom: 3px;
			letter-spacing: 2px;
			position: relative;
			&::before{
				position: absolute;
				top: 4px;
				left: 0px;
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 0 8px 7px;
				border-color: transparent transparent transparent #c3c0b4;
			}
			&.mod-green{
				&::before{
					position: absolute;
					top: 4px;
					left: 0px;
					content: "";
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 0 8px 7px;
					border-color: transparent transparent transparent #c6cfbc;
				}
			}
			.small{
				font-size: 12px;
				margin-left: 9px;
				letter-spacing: 1px;
			}
		}
		.time_line_start_clear_fix{
			margin-top: 0px;
			opacity: 0;
		}
		//time_line_clockの調整
		&.collect{
			.time_line_clock{
				&.mod-7{
					margin-top: 47px;
				}
				&.mod-9{
					margin-top: 47px;
				}
				&.mod-10{
					margin-top: 87px;
				}
				&.mod-13{
					margin-top: 50px;
				}
				&.mod-14_30{
					margin-top: 593px;
				}
				&.mod-15{
					margin-top: 92px;
				}
				&.mod-17{
					margin-top: 72px;
				}
			}
		}
		&.office{
			background-color: #f5f7f2;
			border-left: 1px solid #c6cfbc;
			.time_line_clock{
				&.mod-7{
					margin-top: 0px;
				}
				&.mod-9{
					margin-top: 50px;
				}
				&.mod-11{
					margin-top: 170px;
				}
				&.mod-14_30{
					margin-top: 465px;
				}
				&.mod-13{
					margin-top: 50px;
				}
				&.mod-15{
					margin-top: 48px;
				}
				&.mod-ex{
					margin-top: 53px;
					&.mod-font_jp{
						font-size: 15px;
						font-weight: bold;
					}
				}
				&.mod-17_30{
					margin-top: 50px;
				}
				&.mod-19_30{
					margin-top: 77px;
				}
			}
		}
		&.store{
			.time_line_clock{
				&.mod-9_30{
					margin-top: 78px;
				}
				&.mod-10{
					margin-top: 50px;
				}
				&.mod-12{
					margin-top: 70px;
				}
				&.mod-13_30{
					margin-top: 50px;
				}
				&.mod-15{
					margin-top: 220px;
				}
				&.mod-17{
					margin-top: 77px;
				}
				&.mod-17_30{
					margin-top: 42px;
				}
				&.mod-19{
					margin-top: 71px;
				}
			}
		}
		&.mod-grren{
			color: #f5f7f2;
		}
//componetnt
		.thumbnail-basic-item{
			width: 230px;
			padding-bottom: 0px;
			margin: 0 auto 0;
			&:hover{
				opacity: 1.0;
			}
			&:nth-child(-n + 3){
				//上から１２３のみ指定
				margin-top: 0px;
			}
			&-img{
				position: relative;
				width: 230px;
				height: 140px;
				background-image: url("http://placehold.jp/230x140.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}
			&-label{
				z-index: 0;
				position: relative;
				padding: 0px;
				padding-top: 17px;
				&-title{
					font-size: 14px;
					font-weight: bold;

				}
				&-lead{
					font-size: 13px;
					@extend .font-kreon_r;
					margin-top: 5px;
					line-height: 1.7;
					letter-spacing: 0px;
				}
			}
		}
	}
	.time_line-img{
		&.mod-bike{
			background: resolve("time_line_bike.png") no-repeat 0 0;
			background-size: size('time_line_bike.png',2);
			width:width('time_line_bike.png',2);
			height:height('time_line_bike.png',2);
			margin-top: 162px;
			margin-left: 77px;
			margin-right: auto;
		}
		&.mod-sleep_walk{
			background: resolve("time_line_sleep_walk.png") no-repeat 0 0;
			background-size: size('time_line_sleep_walk.png',2);
			width:width('time_line_sleep_walk.png',2);
			height:height('time_line_sleep_walk.png',2);
			margin-top: 44px;
			margin-left: 51px;
			margin-right: auto;
		}
		&.mod-sweep_read{
			background: resolve("time_line_sweep_read.png") no-repeat 0 0;
			background-size: size('time_line_sweep_read.png',2);
			width:width('time_line_sweep_read.png',2);
			height:height('time_line_sweep_read.png',2);
			margin-top: 105px;
			margin-left: 48px;
			margin-right: auto;
		}
	}
}//ichinichi

.thumbnail_overlay{
	width: 560px;
	position: absolute;
	top: 1574px;
	left: 30px;
	padding: 23px 38px 9px;
	background-color: #fff;
	border: solid 1px #eaefe5;
	z-index: 999;
	&-img-omp{
			position: absolute;
			top: -20px;
			left: 40%;
			background: resolve("omp@2x.png") no-repeat 0 0;
			background-size: size('omp@2x.png',2);
			width:width('omp@2x.png',2);
			height:height('omp@2x.png',2);
	}
	&-header{
		font-size: 20px;
		@extend .font-kreon_r;
		margin-bottom: 12px;
		letter-spacing: 2px;
		position: relative;
		&::before{
			position: absolute;
			top: 5px;
			left: -38px;
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 11px 0 11px 10px;
			border-color: transparent transparent transparent #eaefe5;
		}
		.small{
			font-size: 16px;
			font-weight: bold;
			margin-left: 8px;
			letter-spacing: 0px;
		}
	}
	.thumbnail-basic-item{
		width: 230px;
		margin-top: 0px;
		&-img{
			position: relative;
			width: 230px;
			height: 140px;
			background-image: url("http://placehold.jp/230x140.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
		&-label{
			z-index: 0;
			position: relative;
			padding: 0px;
			padding-top: 12px;
			&-lead{
				font-size: 13px;
				@extend .font-kreon_r;
				margin-top: 5px;
				line-height: 1.7;
				letter-spacing: 0px;
			}
		}
		& + .thumbnail-basic-item{
		margin-left: 22px;
		}
	}
}

.ie_10 {
	.ichinichi .time_line-bar.store .time_line_clock.mod-10 {
		margin-top: 71px;
	}
	.ichinichi .time_line-bar.office .time_line_clock.mod-14_30 {
		margin-top: 489px;
	}
	.ichinichi .time_line-bar.office .time_line_clock.mod-15 {
		margin-top: 71px;
	}
	.ichinichi .time_line-bar.office .time_line_clock.mod-19_30 {
		margin-top: 92px;
	}
}

@include breakpoint(sp) {
	.ichinichi{
		.thumbnail-transform-item_foot_components{
			margin-top: 70px;
			& + .thumbnail-transform-item_foot_components{
				margin-top: 70px;
			}
		}
		.time_line-bar{
			padding-bottom: 35px;
			height: inherit;
			&-hader_thumbnail{
				width: 100%;
				&_img{
					background: resolve("collect.jpg",2) no-repeat 0 0;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					height:height('collect.jpg',2);
					height: 0px;
					padding-top: 63.5%;
				}
			}
			&-title{
				font-size: 14px;
				font-weight: bold;
				color: #68603e;
				text-align: center;
				padding-top: 20px;
				letter-spacing: 2px;
				line-height: 1.7;
				padding-top: 70px;
				margin-top: -40px;
				.ext-big{
					font-size: 14px;
				}
				&.mod-green{
					color: #4f6537;
				}
			}
			.thumbnail-basic-item{
				width: 90%;
				padding-bottom: 0px;
				margin: 0 auto 0;
				&-img{
					width: 100%;
					padding-top: 60.86956521739131%;
				}
			}
			&.collect{
				margin-bottom: 100px;
				.time_line_clock{
					&.mod-7{
						margin-top: 35px;
					}
					&.mod-9{
						margin-top: 35px;
					}
					&.mod-10{
						margin-top: 35px;
					}
					&.mod-14_30{
						margin-top: 35px;
					}
					&.mod-15{
						margin-top: 35px;
					}
					&.mod-17{
						margin-top: 35px;
					}
				}
			}

			&.office{
				margin-bottom: 100px;
				.time_line_clock{
					&.mod-7{
						margin-top: 0px;
					}
					&.mod-9{
						margin-top: 35px;
					}
					&.mod-11{
						margin-top: 35px;
					}
					&.mod-14_30{
						margin-top: 35px;
					}
					&.mod-15{
						margin-top: 35px;
					}
					&.mod-ex{
						margin-top: 35px;
						&.mod-font_jp{
							font-size: 15px;
							font-weight: bold;
						}
					}
					&.mod-17_30{
						margin-top: 35px;
					}
					&.mod-19_30{
						margin-top: 35px;
					}
				}
			}
			&.store{
				margin-bottom: 0px;
				.time_line_clock{
					&.mod-9_30{
						margin-top: 35px;
					}
					&.mod-10{
						margin-top: 35px;
					}
					&.mod-12{
						margin-top: 35px;
					}
					&.mod-13_30{
						margin-top: 35px;
					}
					&.mod-15{
						margin-top: 35px;
					}
					&.mod-17{
						margin-top: 35px;
					}
					&.mod-17_30{
						margin-top: 35px;
					}
					&.mod-19{
						margin-top: 35px;
					}
				}
			}
		}
		.time_line-img{
			&.mod-bike{
				background: resolve("time_line_bike.png") no-repeat 0 0;
				background-size: size('time_line_bike.png',2);
				width:width('time_line_bike.png',2);
				height:height('time_line_bike.png',2);
				margin-top: 0px;
				margin-left: auto;
				margin-right: auto;
			}
			&.mod-sleep_walk{
				background: resolve("time_line_sleep_walk-sp.png") no-repeat 0 0;
				background-size: size('time_line_sleep_walk-sp.png',2);
				width:width('time_line_sleep_walk-sp.png',2);
				height:height('time_line_sleep_walk-sp.png',2);
				margin-top: 0px;
				margin-left: auto;
				margin-right: auto;
			}
			&.mod-sweep_read{
				background: resolve("time_line_sweep_read-sp.png") no-repeat 0 0;
				background-size: size('time_line_sweep_read-sp.png',2);
				width:width('time_line_sweep_read-sp.png',2);
				height:height('time_line_sweep_read-sp.png',2);
				margin-top: 105px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.time_line-bar .time_line_clock {
			padding-left: 5%;
		}
	}
}
