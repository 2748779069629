.breadcrumb-list{
	max-width: 960px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 41px;
	margin-bottom: -30px;
	&.mod-wide {
		max-width:1040px;
	}
	&.mod-mb-small {
		margin-bottom: -20px;
	}
	&-item {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		li {
			font-size: 13px;
			a {
				color : #1c1f21;
				font-size: 13px;
			}
			&:nth-of-type(n + 2) {
				&:before {
					content: ">";
					font-size: 13px;
					padding-right: 3px;
					padding-left: 5px;
				}
			}
		}
	}
	&.mod-basic {
		max-width:1040px;
		margin-top: 10px;
		margin-bottom: 0;
		.breadcrumb-list-item > span > span > a{
			&:before {
				content:"\f015";
				color: #1c1f21;
    			font-size: 13px;
    			font-family: "Font Awesome 5 Free";
    			font-weight: 900;
    			vertical-align: 1px;
    			margin-right: 4px;
			}
		}
		span {
			a {
				color : #1c1f21;
				font-size: 13px;
				&:after {
					content: ">";
					font-size: 13px;
					padding-right: 3px;
					padding-left: 5px;
				}
			}
		}
		.breadcrumb_last {
			font-size: 13px;
		}
	}
}

@include breakpoint(sp) {
	.breadcrumb-list{
		max-width: 88.4375%!important;
		margin-top: 20.5px;
		margin-bottom: 0;
		&.mod-mb-small {
			margin-bottom: 0;
		}
		&-item {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			li {
				&:nth-of-type(n + 2) {
					&:before {
						padding-right: 2.5px;
						padding-left: 2.5px;
					}
				}
			}
		}
		+ .lower-content .page-header {
			margin-top: 9px;
		}
		&.mod-basic {
			margin-bottom: 20px;
		}
	}
	.shop-lower .breadcrumb-list + .page-header,
	.tax-shopnews_category .breadcrumb-list + .page-header,
	.breadcrumb-list + .glid {
		margin-top: 9px;
	}
}
