.box-pickup{
	width: 960px;
	height: 323px;
	margin: 0 auto;
	margin-top: 75px;
	position: relative;
	padding: 30px 40px;
	padding-right: 30px;
	border-right: solid 2px #c6cfbc;
	border-bottom: solid 2px #c6cfbc;
	position: relative;
	background-color: #fff;
	&::after{
		content: '';
		width: 100%;
		height: 100%;
		right: -10px;
		bottom: -10px;
		position: absolute;
		background-color: #f5f7f2;
		z-index: -10;
	}
}

@include breakpoint(sp) {
	.box-pickup{
		width: 90%;
		height: 100%;
		margin: 0 auto;
		margin-top: 75px;
		position: relative;
		padding: 20px;
		border-right: solid 2px #c6cfbc;
		border-bottom: solid 2px #c6cfbc;
		position: relative;
		background-color: #fff;
		&::after{
			content: '';
			width: 100%;
			height: 100%;
			right: -10px;
			bottom: -10px;
			position: absolute;
			background-color: #f5f7f2;
			z-index: -10;
		}
	}
}
