.c-article-tag {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: flex-start;
	margin: 30px auto 0px;
	@include breakpoint(sp) {
		order: 3;
		justify-content: flex-start;
		width: 100%;
	}
	&-title {
		font-size: 16px;
		font-weight: 700;
		margin-right: 20px;
		padding-top: 1px;
		flex-shrink: 0;
		margin-bottom: 10px;
		@include breakpoint(sp) {
			font-size: 14px;
			width: 100%;
		}
	}
	&-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		&-item {
			font-size: 12px;
			border-radius: 2px;
			margin-right: 8px;
			margin-bottom: 8px;
			font-weight: 700;
			@include breakpoint(sp) {
				font-size: 11px;
			}
			a {
				color: #000;
				display: block;
				padding: 4px 12px;
				background-color: #efe9dd;
				@include breakpoint(sp) {
					padding: 3px 10px 2px;
				}
			}
		}
	}
}
