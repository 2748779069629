.title-full-width {
	width: 100%;
	max-width: 1120px;
	margin-left: auto;
	margin-right: auto;
	$c:&;
	&.mod-01{
		background:resolve('title_anim_insect.gif') 20px 8px no-repeat, resolve('line-title-full-width-01.png') center -5px no-repeat;
		background-size: size('title_anim_insect.gif',2),size('line-title-full-width-01.png',2);
	}
	&.mod-02{
		padding-top: 25px;
		background: resolve('title_anim_bird.gif') 100px 0px no-repeat,resolve('line-title-full-width.png') center 25px no-repeat;
		background-size: size('title_anim_bird.gif',2),size('line-title-full-width.png',2);
	}
	&.mod-03{
		background: resolve('title_anim_grass.gif') 220px 10px no-repeat,resolve('line-title-full-width.png') center top no-repeat;
		background-size: size('title_anim_grass.gif',2),size('line-title-full-width.png',2);
	}
	&_title{
		font-size: 26px;
		letter-spacing: 0.05em;
		text-align: center;
		font-weight: 700;
		line-height: 1.5;

		&.mod-eng{
			font-family: kreon_r,sans-serif;
			font-weight: 400;
			font-size: 32px;
			letter-spacing: 0.1em;
		}
	}
	&_subtitle{
		font-size: 13px;
		letter-spacing: 0.05em;
		text-align: center;
		font-weight: 700;
	}
	&_text{
		text-align: center;
		font-size: 16px;
		line-height: (40 / 16);
		font-weight: 700;
		margin-top: 10px;
	}
}
@include breakpoint(sp) {
	.title-full-width {
		&.mod-01{
			background-position: 0px 8px,left -5px;
			background-size: size('title_anim_insect.gif',2),size('line-title-full-width-01.png',2);
		}
		&.mod-02{
			padding-top: 25px;
			background-position:100px 0px,left 25px;
			background-size: size('title_anim_bird.gif',2),size('line-title-full-width.png',2);
		}
		&.mod-03{
			background-position: 220px 10px,left top;
			background-size: size('title_anim_grass.gif',2),size('line-title-full-width.png',2);
		}
		&_title{
			font-size: 22px;
			padding-top: 40px;
			&.mod-eng{
				font-size: 26px;
				padding-top: 40px;
			}
		}
		&_text{
			font-size: 14px;
			padding:0 10px;
			line-height: 1.4;
			font-weight: 400;
		}
	}
}