.title {
	&.mod-section{
		font-size: 27px;
		font-family: "kreon_r";
		letter-spacing: 5px;
 		margin-left: 101px;
 		display: inline;
 		font-weight: normal;
 		&.sp {
 			display: none;
 		}
 		&.mod-category {
			font-size: 26px;
			letter-spacing: 3px;
			margin-left: 90px;
 		}
 		&.mod-article {
 			font-size: 30px;
			letter-spacing: 3px;
			margin-left: 89px;
 		}
 		&.mod-about {
			font-size: 30px;
			letter-spacing: 4px;
			margin-left: 99px;
		}
	}
	&.mod_under-line{
		font-size: 16px;
		padding-bottom: 5px;
		font-weight: bold;
		border-bottom: solid 1px #000;
		@extend .font-kreon_r;
		.mod-ext{
			font-size: 26px;
			font-weight: normal;
			margin-right: 5px;
		}
	}
	&_post_h3{
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}
	&_post_h4{
		font-size: 14px;
		font-weight: bold;
		color: #4f6537;
	}
	&_post_h5{
		font-size: 14px;
		font-weight: bold;
		color: #000;
	}
	&-small{
		font-size: 14px;
		margin-left: 15px;
		display: inline;
		&.mod-project{
			margin-left: 9px;
		}
		&.mod-shop-pickup{
			text-align: center;
			font-size: 16px;
			margin:0 auto;
			display: block;
			margin-top: 5px;
		}
	}
	&.mod-section-pickup{
		font-size: 27px;
		font-family: "kreon_r";
		letter-spacing: 2px;
		margin-left: 0px;
		&-small{
			font-size: 14px;
			margin-left: 12px;
		}
	}
	&.mod-triangle-above{
		font-size: 16px;
		position: relative;
		padding:18px 30px;
		font-weight: bold;
		letter-spacing: 0.1em;
		&.font-kreon_r {
			font-size: 19px;
			letter-spacing: 1.9px;
		}
		&:before{
			content:"";
			display: block;
			position: absolute;
			width:0;
			height:0;
			top: 0;
			left: 0;
			border-top: 20px solid #c3c0b4;
			border-right: 20px solid transparent;
		}
		&.mod-green {
			color:#4f6537;
			&:before {
				border-top: 20px solid #c6cfbc;
			}
		}
		&.mod-small {
			padding:15px 30px 23px 19px;
			&:before {
				border-top-width: 12px;
				border-right-width: 12px;
			}
		}
		&.mod-fz-large{
			font-size: 22px;
		}
		.yasaiset_small-title{
			font-size: 13px;
			font-weight: normal;
			margin-left: 25px;
			letter-spacing: 0px;
		}
	}
	&.mod-triangle-both {
		position: relative;
		padding:14px 42px 27px;
		width: 100%;
		&:after {
			content:"";
			display: block;
			position: absolute;
			width:0;
			height:0;
			bottom: 0;
			right: 0;
			border-bottom: 12px solid #c3c0b4;
			border-left: 12px solid transparent;
		}
		&:before{
			content:"";
			display: block;
			position: absolute;
			width:0;
			height:0;
			top: 0;
			left: 0;
			border-top: 12px solid #c3c0b4;
			border-right: 12px solid transparent;
		}
	}
	&.mod-font-large {
		font-size: 22px;
		letter-spacing: 2.2px;
		padding-bottom: 14px;
	}
	&.mod-underline {
		border-bottom: 1px solid #c6cfbc;
	}
	& + .text_caution{
		text-align: right;
		padding-top: 5px;
	}
	&-udner-text{
		margin-top: 40px;
		font-size: 14px;
		letter-spacing: 2px;
		line-height: 1.7;
		&.mod-order{
			letter-spacing: 1.4px;
		}
	}
	&.mod-text-black {
		color: $black!important;
	}

	// shop top renewal
	&.mod-shop-pickup{
		font-size: 30px;
		font-family: "kreon_r",sans-serif;
		letter-spacing: 0.1em;
		font-weight: 400;
		text-align: center;
	}

	//yomimono renewal
	&.mod-article-list{
		font-size: 18px;
		font-weight: 700;
		margin:55px 0 0px;
	}
}

@include breakpoint(sp) {
	.title{
		$modsection:"&.mod-section";
		#{$modsection},#{$modsection}.mod-category,#{$modsection}.mod-article,#{$modsection}.mod-about {
			font-size: 26px;
			font-family: "kreon_r";
			letter-spacing: 5px;
	 		margin-right: 0px;
	 		margin-left: 0px;
	 		text-align: center;
	 		display: block;

	 		&.pc {
	 			display: none;
	 		}
	 		&.sp {
 				display: block;
 			}
		}
		&-small{
			font-size: 14px;
			font-weight: bold;
			display: block;
			text-align:center;
			margin-left: 0px;
			&.mod-project{
				margin-left: 0px;
			}
		}
		&.mod-section-pickup{
			font-size: 18px;
			font-family: "kreon_r";
			letter-spacing: 2px;
			margin-left: 0px;
			display: block;
			text-align: center;
			&-small{
				font-size: 12px;
				margin-left: 12px;
			}
		}
		&.mod-triangle-above{
			font-size: 14px;
			padding:11px 24px;
			&.font-kreon_r {
				font-size: 17px;
				letter-spacing: 1.7px;
			}
			&:before{
				content:"";
				display: block;
				position: absolute;
				width:0;
				height:0;
				top: 0;
				left: 0;
				border-top: 15px solid #c3c0b4;
				border-right: 15px solid transparent;
			}
			&.mod-green {
				&:before {
					border-top-width: 15px;
				}
			}
			.yasaiset_small-title{
				display: block;
				margin-top: 10px;
				margin-left: -1em;
			}
		}
		&.mod-triangle-both {
			position: relative;
			padding:13px 0;
			&:after {
				border-bottom: 10px solid #c3c0b4;
				border-left: 10px solid transparent;
			}
			&:before{
				border-top: 10px solid #c3c0b4;
				border-right: 10px solid transparent;
			}
		}

		&.mod-article-list{
			font-size: 16px;
			position: relative;
			padding-bottom: 8px;
			margin:40px 0 0px;
			text-align: center;
			&:after{
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background-image: linear-gradient(90deg,#9d9d9d 3px,transparent 0,transparent 0);
				background-size: 6px 1px;
				background-repeat: repeat-x;
				position: absolute;
				bottom:0;
				left:0;
			}
		}
	}
}
