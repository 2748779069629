.footer-logo{
	display: inline-block;
	margin-top: 41px;
	margin-right: 390px;
	float: left;
	background-image: resolve('footer_logo.png');
	background-repeat: no-repeat;
	width: width('footer_logo.png');
	height: height('footer_logo.png');
}

@include breakpoint(sp) {
	.footer-logo{
		display: inline-block;
		margin-right: 0px;
		float: none;
		background-image: resolve('footer_logo_sp.png');
		background-repeat: no-repeat;
		background-size: 170px 45px;
		width: 170px;
		height: 45px;
		margin-top: 20px;
		margin-left: 20px;
	}
}
