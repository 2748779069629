.c-pagination{
	display: flex;
	margin: 60px auto 20px;
	justify-content: center;
	font-family: Arial, Helvetica, sans-serif;
	
	.page-numbers{
		font-size: 14px;
		color: #000;
		border: 1px solid #cccccc;
		padding:6px 10px;
		margin: 0 3.5px;
		&.current{
			background-color: #cccccc;
		}
	}
	&_prev{
		margin-right: 18px;
		&::before{
			content: '';
			display: inline-block;
			left: 3px;
			width: 4px;
			height: 4px;
			border-top: 1px solid #000;
			border-right: 1px solid #000;
			-webkit-transform: rotate(225deg);
			transform: rotate(225deg);
		}
	}
	&_next{
		margin-left: 18px;
		&::before{
			content: '';
			display: inline-block;
			left: 3px;
			width: 4px;
			height: 4px;
			border-top: 1px solid #000;
			border-right: 1px solid #000;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
@include breakpoint(sp) {

	

}