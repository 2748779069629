.yasaiset,.page-template-default,.single-products,.home,.page-template-default.shop-top {
	.remodal-wrapper {
		padding: 0;
		margin-left: auto;
		margin-right: auto;
		z-index: 1000000;
	}
	.remodal {
		width: 480px;
		padding: 59px 0 32px;
	}
	.remodal-close {
		top: 30px;
		right: 35px;
		left: auto;
		width:width('btn_modal_close.png',2);
		height:height('btn_modal_close.png',2);
		&:before {
			content:"";
			display: block;
			background:resolve('btn_modal_close.png') no-repeat left top;
			background-size: size('btn_modal_close.png',2);
			width:width('btn_modal_close.png',2);
			height:height('btn_modal_close.png',2);
		}
	}
	.remodal-prev{
		position: relative;
		background-color: transparent;
		border: none;
		cursor: pointer;
		outline: none;
		padding: 0;
		appearance: none;
		padding-bottom: 6px;
	}
	.remodal-next{
		position: relative;
		background-color: transparent;
		border: none;
		cursor: pointer;
		outline: none;
		padding: 0;
		appearance: none;
	}
	.remodal-overlay {
		background-color: rgba(195,192,180,.8);
	}
}

@include breakpoint(sp) {
	.yasaiset,.page-template-default,.single-products,.home,.page-template-default.shop-top{
		.modal-item {
			width: 79%;
		}
		.remodal {
			width: auto;
			.modal-item {
				width: 86%;
			}
		}
		.remodal {
			width: 95.5%;
			padding:60px 0;
		}
	}
}
