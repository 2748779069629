.table-list {
	border-bottom: 1px solid #cdd2d5;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	&-title {
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 6px;
		font-weight: 700;
	}
	&.sp {
		display: none;
	}
}
.table-list + .table-list {
	margin-top: -1px;
}

.history-table {
	margin-top: 96px;
	line-height: (22 / 13);
	.title.mod-triangle-above.mod-green {
		color: #1c1f21;
		padding : 15px 30px;
	}

	ul.table-list.border-top.top {
		margin-top: 19px;
		border-top: 1px solid #cdd2d5;
		.tit {
			display: block;
		}
	}
	.num1 li{
		 border-left: none!important;
	}
	ul li {
		font-size: 13px;
		text-align: center;
		font-weight: normal;

		ul li {
			max-width: 100%!important;
			padding: 0;
			border-top: 1px solid #cdd2d5;
			padding : 13px 25px 10px;
		}
		&:nth-child(n + 2) {
			border-left: 1px dotted #cdd2d5;
		}
		&:nth-child(n + 4) {
			ul li {
				font-weight: 400;
			}
		}
		&:nth-child(-n + 2) {
			ul li {
				padding-left: 9px;
				padding-right: 9px;
				border-left: 1px;
			}
		}
		&:nth-child(-n + 3) {
			font-weight: 700;
			max-width: 88px;
			width: 100%;
			ul li {
				padding-top: 24px;
				padding-bottom: 20px;
				font-weight: normal;
			}
			span {
				font-family: 'kreon_r';
				font-size: 14px;
			}
			span:nth-of-type(2) {
				font-size: 13px;
				font-weight: 700;
			}
		}
		&:nth-child(3) {
			ul li {
				border-left: 1px;
				padding-left: 20px;
				padding-right: 20px;
				padding-bottom: 20px;
			}
		}
		&:nth-child(4) {
			max-width: 250px;
			width: 100%;
			ul li {
				border-left: 1px;
				text-align: left;
				padding-left: 27px;
				padding-right: 16px;
			}
		}
		&:nth-child(5) {
			max-width: 98px;
			width: 100%;
			ul li {
				border-left: 1px;
				padding-left: 11px;
				padding-right: 0;
				text-align: left;
			}
		}
		&:nth-child(6) {
			max-width: 47px;
			width: 100%;
			ul li {
				border-left: 1px;
				padding-left: 4px;
				padding-right: 0;
				padding-top: 24px;
				padding-bottom: 21px;
			}
			a {
				color: #4f6537;
				border-bottom: 1px solid #4f6537;
			}
		}
	}
}

@include breakpoint(sp) {
	.table-list {
		border-top: 2px solid #cdd2d5;
	}
	.table-list + .table-list {
		margin-top: 35px;
	}

	.history-table {
		margin-top: 40px;
		ul.table-list {
			&.sp {
				display: flex;
			}
			.tit {
				display: block;
			}
		}
		&-list {
			display: block;
		}
		> ul > li {
			display: flex;
			justify-content: flex-start;
			flex-wrap: nowrap;
			padding-top: 10px;
			padding-bottom: 10px;
			width: 100%;
			p.tit {
				padding: 0;
				max-width: 21.428571428%;
				width: 100%;
				text-align: left;
				font-weight: bold;
			}
			> ul {
				max-width: 78.571428571%;
				width: 100%;
				padding-left: 3.125%;
				li {
					max-width: 100%!important;
					padding: 0;
					border: none!important;
					text-align: left;
				}
			}
			&:nth-child(n + 2) {
				border: none;
				border-top: 1px dotted #cdd2d5;
			}
			&:nth-child(-n + 2) {
				ul li {
					padding: 0;
				}
			}
			&:nth-child(-n + 3) {
				max-width: 100%;
				ul li {
					padding: 0;
				}
			}
			&:nth-child(3) {
				ul li {
					padding: 0;
				}
			}
			&:nth-child(4) {
				max-width: 100%;
				ul li {
					padding: 0;
				}
			}
			&:nth-child(5) {
				max-width: 100%;
				ul li {
					padding: 0;
				}
			}
			&:nth-child(6) {
				max-width: 100%;
				ul li {
					padding:0;
				}
			}
		}
	}
}
