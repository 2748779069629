.voice {
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;
	align-items: center;
	&-icon {
		width: 50px;
		height: 50px;
		background-size: cover;
		background-position: center;
		background-repeat: none;
		border-radius: 100%;
		overflow: hidden;
		margin-right: 15px;
	}
	&-text{
		flex:1;
		background: #f5f7f2;
		border-left:1px solid #c6cfbc;
		border-bottom:1px solid #c6cfbc;
		font-size: 12px;
		line-height: (20 / 12);
		padding:1em;
		position: relative;
		&:before{
			content: "";
			display:block;
			background: resolve('fukidashi_voice.png') center no-repeat;
			background-size: size('fukidashi_voice.png',2);
			width: width('fukidashi_voice.png',2);
			height: height('fukidashi_voice.png',2);
			position: absolute;
			left:0px;
			top:50%;
			transform:translate(-100% ,20%);
		}
	}
}
@include breakpoint(sp) {
}
