.circle {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: inline-block;
	text-align: center;
	line-height: 30px;
	font-size: 18px;
	&.mod-black {
		background-color: #1c1f21;
		color: #fff;
	}
}
@include breakpoint(sp) {
}
