.wp-block-buttons{
    &.link_anchors{
        padding:15px 0;     

        .wp-block-button{
            margin-top: 0!important;
            margin-right: 10px!important;;
            margin-left: 0 !important;
            margin-bottom: 0.75em!important;;
    
            a.wp-block-button__link{
                background-color: #4f6537 !important;
                font-size: 14px;
                font-weight: 700;
                color: #fff !important;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 5px 40px;
                border-radius: 0 !important;
                @include breakpoint(sp){
                    padding: 5px 30px;
                    padding-left: 20px;  
                }
                @include breakpoint(exsp){
                    padding: 5px 23px;
                    padding-left: 9px;
                    font-size: 12px;
                }
        
                &::after{
                    content: "";
                    position: absolute;
                    right: 14px;
                    border-top: 6px solid #fff;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    display: block;
                    @include breakpoint(sp){
                        border-top: 5px solid #fff;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent
                    }
                    @include breakpoint(exsp){
                        right: 8px;
                    }
                }
            }  
            @include breakpoint(sp){
                margin-top: 0px !important;
                min-width: 48%;
                margin-left: 0px;
                margin-right: 0 !important;
            }
            @include breakpoint(exsp){
                min-width: 49%;
            }

        }
        @include breakpoint(sp){
            display: flex !important;
            flex-wrap: wrap !important;
            align-items: center !important;
            justify-content: space-between !important;
        }



    }
    &.link_buttons{
        margin: 15px auto !important;
        text-align: center !important;

        .wp-block-button{
            display: inline-block;
            margin-right: 7px;
            @include breakpoint(sp){
                display: inline-block;
                width: fit-content;
            }
            a.wp-block-button__link{
                min-width: 214px;
                background-color: #4f6537 !important;
                border-radius: 0;
                padding: 11px 40px 11px 20px;
                font-size: 14px;
                font-weight: 700;
                text-decoration: none;
                position: relative;
                display: flex;
                color: #fff;
                justify-content: center;
                align-items: center;
                border-radius: 0 !important;
                &::after{
                    content: "";
                    width: 6px;
                    position: absolute;
                    right: 14px;
                    border-top: 6px solid transparent;
                    border-left: 6px solid #fff;
                    border-bottom: 6px solid transparent;
                    display: block;
                }
                @include breakpoint(sp){
                    width: 100%;
                    min-width: unset;
                }
            }
        }
    }
}

