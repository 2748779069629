.faq{
	table , td, th {
		border: 0px solid #595959;
		border-collapse: collapse;
		font-size: 13px;
		text-align: center;
		margin-top: 39px;
	}
	td, th {
		width: 30px;
		height: 25px;
		line-height: 1.7;
	}
	th {
		background: #fff;
		padding-bottom: 8px;
		&:first-of-type{
		padding-right: 10px;
		}
	}
	td {
		padding-top: 5px;
		&:first-of-type{
		padding-right: 10px;
		}
		span{
			font-weight: bold;
		}
	}

	.even {
		background: #fbf8f0;
	}
	.odd {
		background: #fefcf9;
	}
	.width_fisrt_row{
		width: 125px;
	}
	.width_second_row{
		width: 204px;
	}
	.width_third_row{
		width: 211px;
	}
	.border-right{
		border-right: 1px solid #cdd2d5;
	}
	.border-bottom{
		border-bottom: 1px solid #cdd2d5;
	}
	.border-right_dotted{
		border-right: 1px dotted #cdd2d5;
	}
}
@include breakpoint(sp) {
	.faq{
		table , td, th {
			border: 0px solid #595959;
			border-collapse: collapse;
			font-size: 13px;
			text-align: center;
			margin-top: 39px;
		}
		td, th {
			width: 30px;
			height: 25px;
			line-height: 1.7;
		}
		th {
			background: #fff;
			padding-bottom: 8px;
			&:first-of-type{
			padding-right: 10px;
			}
		}
		td {
			padding-top: 5px;
			&:first-of-type{
			padding-right: 0px;
			}
			span{
				font-weight: bold;
			}
		}

		.even {
			background: #fbf8f0;
		}
		.odd {
			background: #fefcf9;
		}
		.width_fisrt_row{
			width: 125px;
		}
		.width_second_row{
			width: 204px;
		}
		.width_third_row{
			width: 211px;
		}
		.border-right{
			border-right: 1px solid #cdd2d5;
		}
		.border-bottom{
			border-bottom: 1px solid #cdd2d5;
		}
		.border-right_dotted{
			border-right: 1px dotted #cdd2d5;
		}
	}
}
