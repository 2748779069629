.vision {
    .glid {
        width: 1040px;
        flex-direction: column;
        &.mod-vision-mission {
            width: 960px;
            // margin-top: 160px;
        }
        @include breakpoint(sp){
             width: 100%;
            &.mod-vision-mission {
                width: 90%;
            }
        }
        &.has-bg {
            position: relative;
            width: 100%;
            overflow: hidden;
            &:after {
                content:"";
                background-color: #f8f7f2;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 200px;left: 0;
                display: block;
                z-index: 0;
                 @include breakpoint(sp){
                     top: 0;
                 }
            }
        }
    }
    .box-slash-heading.mod-shishin {
        left: 72px;
    }
    .box-shadow {
        border:0;
        margin-top: 50px;
        font-size: 14px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #675f3e;
        padding-left: 145px;
        padding-right: 150px;
        a {
            color: #675f3e;
            text-decoration: underline;
        }
        &:after {
            display: none;
        }
        @include breakpoint(sp){
            margin-top: 0;
            padding: 0px;
        }
    }
    .small-em {
        font-family: kreon_r;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 2px;
        text-align: left;
        color: #675f3e;
        display: inline-block;
        padding-right: 20px;
        font-weight: 400;
        text-align: center;
        @include breakpoint(sp){
            display: block;
            margin-bottom: 8px;
        }
    }
    .article-header-headline_triangle {
        width: 100%;
        color: #675f3e;
        font-size: 25px;
        z-index: 1;
        &:before {
            border-top-color: #c3c0b4;
            border-left-color: #c3c0b4;
        }
    }
    .article_shisin{
        p{
            line-height: 2;
        }
        @include breakpoint(sp){
            width: 100%;
        }
    }
    .article_shisin.mod-margin-top {
        margin-top: 47px;
        @include breakpoint(sp){
            margin-top: 50px;
            width: 100%;
        }
    }
    .article_shisin_header-img {
        width: 960px;
        + .box-shadow{
            margin-top: 100px;
        }
        @include breakpoint(sp){
            // width: 112%;
            width: 100%;
            margin-left: 0;
            width: 100vw;
            transform:translateX(-5%);
            + .box-shadow{
                margin-top: 30px;
                padding:10px;
            }
        }
    }
    .box-slash-heading.mod-shishin {
        top: -15px;
    }
    .box-slash-heading.mod-shishin .box-slash-heading-above span {
        font-size: 28px;
    }
    .box-slash-heading.mod-shishin .box-slash-heading-below {
        font-size: 22px;
    }
    
    @include breakpoint(sp){
        .box-slash-heading.mod-shishin .box-slash-heading-above span {
            font-size: 24px;
        }
        .box-slash-heading.mod-shishin .box-slash-heading-below {
            font-size: 18px;
            padding-bottom: 15px;
        }
    }
}

// remodal
.remodal.mod-pv{
    max-width: 100vw;
    width: 100%;
    padding:30px;
    .remodal-close{
        top:5px;
        right:5px;
    }
    .modal-item-yotuube_wrap{
        position: relative;
        width: 100%;
        padding-top:  56.25%;
        iframe{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
        }
    }
}

.mv_vision {
    width: 1040px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;z-index: 1;
    @include breakpoint(sp){
        width: 100%;
    }
    + * {
        margin-top: 60px;
        @include breakpoint(sp){
            margin-top: 40px;
        }
    }
    &-title {
        width: 660px;
        margin-left: auto;
        margin-right: auto;
        color: #675f3e;
        background-color: #fff;
        position: relative;
        margin-top: -69px;
        padding-top: 24px;
        padding-bottom: 37px;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 2.8px;
        @include breakpoint(sp){
            width: 90%;
            font-size: 20px;
            margin-top: -20px;
            padding-top: 15px;
            padding-bottom: 30px;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-top: 20px solid #c3c0b4;
            border-right: 20px solid transparent;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            border-bottom: 20px solid #c3c0b4;
            border-left: 20px solid transparent;
        }
        
        
        > span {
            display: block;
            font-family: kreon_r;
            font-weight: 400;
            font-size: 24px;
            line-height: 1.17;
            letter-spacing: 2.4px;
            margin-bottom: 16px;
            @include breakpoint(sp){
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
    }
}
.vision-lead  {
    width: 659px;
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #675f3e;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    padding-bottom: 80px;
    p + p {
        margin-top: 30px;
    }
    &-img{
        margin-top:40px;
        margin-bottom: 40px;
    }
    @include breakpoint(sp){
        width: 90%;
        padding-bottom: 50px;
        p + p {
            margin-top: 30px;
        }
    }
}

.pv_vision {
    width: 100%;
    height: 500px;
    background:resolve('img_vision_03.jpg') no-repeat center;
    background-size: cover;
    position: relative;
    &-btn {
        position: absolute;
        top:50%;
        left:50%;
        display: block;
        transform:translate(-50%,-50%);
        width: 112px;
    }
    + * {
        margin-top: 120px;
    }
    @include breakpoint(sp){
        height: 250px;
        + * {
            margin-top: 50px;
        }
    }
}

.article-bg-fullsize {
    margin-top: 150px;
    @include breakpoint(sp){
        margin-top: 50px;
    }
    .article-header-headline_triangle {
        width: 960px;
        margin-left:auto;
        margin-right:auto;
        margin-bottom: 60px;
        color: #4f6537;
        &:before {
            border-top-color: #c6cfbc;
            border-left-color: #c6cfbc;
        }
        @include breakpoint(sp){
            width: 90%;
        }
    }
    &-inner {
        background:resolve('img_vision_bg_1.jpg') no-repeat left top;
        background-size:cover;
    }
    &-text {
        width: 960px;
        margin-left:auto;
        margin-right:auto;
        background-color:rgba(255, 255, 255, .8);
        font-size: 16px;
        font-weight: 500;
        line-height: 2.25;
        color: #4f6537;
        padding:65px 150px 55px;
        .author {
            font-size: 14px;
            margin-top: 2em;
            text-align: right;
        }
        @include breakpoint(sp){
            font-size: 14px;
            width: 90%;
            padding:30px 20px;
            line-height: 1.8;
        }
    }
}