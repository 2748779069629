.btn_wrap {
	display: flex;
	justify-content:center;
	flex-wrap:wrap;
	> * {
		margin-left: 20px;
		margin-right: 20px;
	}
}
.btn-half_line {
	padding: 26px 41px;
	min-width: 188px;
	border-right: 1px solid #1c1f21;
	border-bottom: 1px solid #1c1f21;
	background-color: #ffffff;
	display: inline-block;
	position: relative;
	color: #1c1f21;
	font-size: 15px;
	font-weight: 700;
	line-height:1;
	text-align: center;
	letter-spacing: 1.5px;
	vertical-align: bottom;
	transition: all .6s;
	&:hover{
		background-color: #f8f7f2 !important;
		transition: all .6s;
		opacity: 1;
	}
	&:after {
		transition:all .2s;
		content:"";
		position:absolute;
		top:50%;
		right: -20px;
		transform:translate(0,-50%);
		background:resolve('arrow_right_link@2x.png') no-repeat left top;
		background-size: size('arrow_right_link@2x.png',2);
		width:width('arrow_right_link@2x.png',2);
		height:height('arrow_right_link@2x.png',2);
		background-color: #fff;
	}
	&.mod-en {
		font-family: 'kreon_r';
		color: #1c1f21;
		font-size: 18px;
		font-weight: 400;
		line-height:1;
		letter-spacing: 1.8px;
	}
	&.mod-no-arrow {
		&:after {
			display: none;
		}
	}
	&.mod-narrow {
		padding: 10px 41px;
	}
	&.mod-blank p:after {
		content:"";
		display: inline-block;
		background:resolve('icon_blank@2x.png') no-repeat left top;
		background-size: size('icon_blank@2x.png',2);
		width:width('icon_blank@2x.png',2);
		height:height('icon_blank@2x.png',2);
		margin-left: 6px;
	}
	&.mod-sp,&.sp{
		display:none;
	}
	&:hover {
		//background-color: #f8f7f2;
		transition: all .6s;
		opacity: 1;
		&:after {
			right:-25px;
		}
	}
	&.mod-has-img {
		padding-top: 0;
		padding-left: 0;
		display: flex;
		justify-content:flex-start;
		flex-wrap:wrap;
		align-items:center;
		padding-bottom: 10px;
		img {
			margin-right: 20px;
		}
		&:hover {
			background-color: transparent;
		}
	}

}
@include breakpoint(exsp) {
	// .btn-half_line {
	// 	&.mod-has-img {
	// 		width: 86%;
	// 		img {
	// 			margin-right: 10px;
	// 		}
	// 	}
	// }

}
@include breakpoint(sp) {
	.btn_wrap {
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		> *, > .btn-half_line {
			margin-left: 0;
			margin-right: 0;
			width: 46%;
			min-width: auto;
			padding:10px;
		}
	}
	.btn-half_line{
		font-size: 12px;
		padding:10px 0;
		width: 85%;
		margin:20px auto;
		display: block;
		&:after {
			transition:none;
		}
		&.mod-en {
			font-size: 14px;
		}
		&.mod-area-has-map {
			p:after {
				content:"";
				display: inline-block;
				background:resolve('icon_blank@2x.png') no-repeat left top;
				background-size: size('icon_blank@2x.png',2);
				width:width('icon_blank@2x.png',2);
				height:height('icon_blank@2x.png',2);
				margin-left: 6px;
			}
		}
		&.mod-sp,&.sp{
			display:block;
		}
		&.mod-narrow-width {
			width: 70%;
		}
		&.mod-margin{
			margin-top: 20px;
			margin-bottom: 80px;
		}
		&.mod-has-img {
			width: 83%;
			padding-bottom: 10px;
			img {
				width: 60px;
				margin-right: 35px;
			}
		}
	}
}
