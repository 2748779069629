/**
 * body
 */
body.home_en {
	min-width: 1150px;
	padding-top: 20px;
	@extend .font-kreon_r;
	#wrap:before {
		height: 720px;
	}
}
/**
 * header
 */
.header-en {
	$c:&;
	width: 100%;
	position: relative;
	&-inner {
		max-width: 1150px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}
	&-logo {
		background:resolve('logo_en.png') no-repeat left top;
		background-size: size('logo_en.png',2);
		width:width('logo_en.png',2);
		height:height('logo_en.png',2);
		margin:0 auto;
		@include text-indent-none();
		margin-bottom: 22px;
	}
	&.fixed {
		position: fixed;
		top:0;
		left:50%;
		transform:translate(-50%,0);
		background-color: rgba(255,255,255,.7);
		z-index: 100;
		padding: 10px 0;
		#{$c}-logo {
			background-size: 224px auto;
			width:224px;
			height:50px;
			margin-bottom: 5px;
		}
		.btn-img {
			top: 9px;
			right: 35px;
		}
		.box-link-bg {
			opacity: 0.6;
		}
	}
}
/**
 * gnavi
 */
.gnavi-en {
	&-list {
		display: flex;
		justify-content:center;
		flex-wrap:wrap;
	}
	&-list-item a {
		font-size: 15px;
		line-height: 1;
		letter-spacing: 0.8px;
		text-align: left;
		color: $black;
		@extend .font-kreon_r;
		&:before {
			content:"";
			display:inline-block;
			background:resolve('carret_botttom_lightgreen.png') no-repeat left top;
			background-size: size('carret_botttom_lightgreen.png',2);
			width:width('carret_botttom_lightgreen.png',2);
			height:height('carret_botttom_lightgreen.png',2);
			margin-right: 6px;
		};
		.sub {
			font-size: 12px;
			display: block;
			margin-top: 3px;
			margin-left: 22px;
		}
	}
	&-list-item + * {
		margin-left: 30px;
	}
	.sns-list-icon-item {
		font-size: 18px;
	}
}
.box-link.mod-en-contact {
	width: 118px;
	min-width: 118px;
	padding: 0;
	position: absolute;
	top: 19px;
	right: 14px;
	font-weight: 400;
	height: 32px;
	line-height: 32px;
	font-size: 16px;
	&:before {
		content:"";
		display:inline-block;
		background:resolve('icon_contact.png') no-repeat left top;
		background-size: size('icon_contact.png',2);
		width:width('icon_contact.png',2);
		height:height('icon_contact.png',2);
		margin-right: 7px;
		vertical-align: -2px;
	}
	&:after {
		background:resolve('arrow_calender_next.png') no-repeat left top;
		background-size: size('arrow_calender_next.png',2);
		width:width('arrow_calender_next.png',2);
		height:height('arrow_calender_next.png',2);
	}
	.box-link-bg {
		top: 13px;
		right: -2px;
	}
}
.btn-img {
	position: absolute;
	top: 19px;
	right: 14px;
	transition: all .6s;
	&:after {
		content:"";
		transition: all .2s;
		position: absolute;
		position:absolute;
		top: 11px;
		right: -20px;
		background:resolve('arrow_calender_next.png') no-repeat left top;
		background-size: size('arrow_calender_next.png',2);
		width:width('arrow_calender_next.png',2);
		height:height('arrow_calender_next.png',2);
	}
	&:hover {
		background-color: #f8f7f2!important;
		opacity: 1!important;
		&:after {
			right: -25px;
		}
	}
	.box-link-bg {
		display: none;
	}
}
/**
 * mv
 */
.mv-en {
	background-repeat:no-repeat;
	background-position:center center;
	background-size:cover;
	width: 100%;
	height: 570px;
	margin:12px auto 80px;
}
/**
 * titles
 */
.section-title-en {
	@extend .font-kreon_r;
	font-size: 28px;
	line-height: 1;
	letter-spacing: 1.4px;
	text-align: center;
	color: $black;
	text-align: center;
	&:before {
		content:"";
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	};
	&.mod-lead:before {
		background-image: resolve('img_en_title_lead.png');
		background-size: size('img_en_title_lead.png',2);
		width:width('img_en_title_lead.png',2);
		height:height('img_en_title_lead.png',2);
	}
	&.mod-partners:before {
		background-image: resolve('img_en_title_partner.png');
		background-size: size('img_en_title_partner.png',2);
		width:width('img_en_title_partner.png',2);
		height:height('img_en_title_partner.png',2);
	}
	&.mod-shopping:before {
		background-image: resolve('img_en_title_onlineshop.png');
		background-size: size('img_en_title_onlineshop.png',2);
		width:width('img_en_title_onlineshop.png',2);
		height:height('img_en_title_onlineshop.png',2);
		margin-bottom: 27px;
	}
	&.mod-vegetables:before {
		background-image: resolve('img_en_title_vegetables.png');
		background-size: size('img_en_title_vegetables.png',2);
		width:width('img_en_title_vegetables.png',2);
		height:height('img_en_title_vegetables.png',2);
	}
	&.mod-voices:before {
		background-image: resolve('img_en_title_voices.png');
		background-size: size('img_en_title_voices.png',2);
		width:width('img_en_title_voices.png',2);
		height:height('img_en_title_voices.png',2);
		margin-bottom: 13px;
	}
	&.mod-shop:before {
		background-image: resolve('img_en_title_shop.png');
		background-size: size('img_en_title_shop.png',2);
		width:width('img_en_title_shop.png',2);
		height:height('img_en_title_shop.png',2);
		margin-bottom: 23px;
	}
	&.mod-info:before {
		background-image: resolve('img_en_title_info.png');
		background-size: size('img_en_title_info.png',2);
		width:width('img_en_title_info.png',2);
		height:height('img_en_title_info.png',2);
		margin-bottom: 26px;
	}
}
/**
 * lead
 */
.lead-en {
	@extend .font-kreon_r;
	text-align: center;
	font-size: 16px;
	font-weight: 300;
	@extend .font-kreon_l;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: center;
	.section-title-en {
		margin-bottom: 25px;
	}
	.article-row {
		text-align: left;
	}
	.article-row + .article-row {
		margin-top: 82px;
	}
	.set-imagetext-text-textarea {
		margin-top: 24px;
	}
}
/**
 * partners
 */
.partners-en {
	margin-top: 130px;
	.section-title-en.mod-partners:before {
		margin-bottom: 27px;
	}
	.thumbnail-item-horizontal-title {
		font-weight: normal;
	}
	.thumbnail-item-horizontal-text {
		@extend .font-kreon_l;
	}
	.thumbnail-item-horizontal.mod-odd,
	.thumbnail-item-horizontal.mod-even {
		border-bottom: 0;
		&:after {
			border-width: 0 0 12px 12px;
		};
	}
	.thumbnail-item-horizontal.mod-photo-s {
		.thumbnail-item-horizontal-img.mod-1col {
			width: 305px;
		}
		.thumbnail-item-horizontal-textarea {
			padding: 29px 33px;
			width: calc(100% - 305px);
		}
	}
	.thumbnail-item-horizontal.mod-large_triangle {
		min-width: 90%;
		max-resolution: res;-width: 90%;
	}
}
.text-enph-marker {
	display: inline-block;
	@extend .font-kreon_r;
	font-size: 20px;
	line-height: 2;
	letter-spacing: 2px;
	text-align: center;
	color: #675f3e;
	font-style: italic;
	background: linear-gradient(transparent 60%, #f8f7f2 60%);
	background-position: 20px 38px;
	padding:0 6px 18px 6px;
}
/**
 * online shopping
 */
.shopping-en {
	background:resolve('bg_light_green_has_pattern.jpg') repeat center center;
	background-size: size('bg_light_green_has_pattern.jpg',2);
	height:height('bg_light_green_has_pattern.jpg',2);
	width: 100%;
	display: flex;
	justify-content:center;
	align-items:center;
	flex-wrap:wrap;
	margin-top: 100px;
	&.common-component {
		.grid.mod-2col {
			background-color: #fff;
			max-width:960px;
			width: 960px;
			height: 230px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 46px;
			justify-content:flex-start;
			.bold {
				@extend .font-kreon_r;
				font-size: 20px;
				line-height: 1.4;
				text-align: left;
				color: $black;
				margin-bottom: 10px;
			}
			> .col {
				font-size: 16px;
				width: auto;
				@extend .font-kreon_l;
				img {
					margin-bottom: -47px;
				}
			}
		}
		.col {
			display: flex;
			justify-content:center;
			align-items: flex-start;
			flex-wrap:wrap;
			flex-direction:column;
		}
	}
	.box-link {
		width: 307px;
		height: 69px;
		line-height: 69px;
		background-color: #fff;
		@extend .font-kreon_r;
		font-size: 18px;
		margin-top: 60px;
		margin-left: auto;
		margin-right: auto;
		letter-spacing: 2px;
		&:after {
			background:resolve('arrow_calender_next.png') no-repeat left top;
			background-size: size('arrow_calender_next.png',2);
			width:width('arrow_calender_next.png',2);
			height:height('arrow_calender_next.png',2);
		}
	}
	.box-link-bg {
		top: 31px;
		right: -1px;
	}
}
/**
 * vegetables
 */
.vegetables-en {
	margin-top: 100px;
	.box-slash-colored-title {
		font-weight: normal;
	}
	.box-has-outer_line-text {
		@extend .font-kreon_l;
	}
	.glid.mod-shop-characteristic {
		margin-top: 67px;
	}
	.box-has-outer_line-inner {
		width: 300px;
		margin-left:auto;
		margin-right:auto;
	}
}
/**
 * voices
 */
.voices-en {
	margin-top: 96px;
	.box-has-outer_line.mod-underline {
		margin-top: 58px;
		height: 284px;
		max-width: 960px;
		&:before {
			content: "";
			top: -4px;
			left: 0;
			position: absolute;
			display: block;
			width: 100%;
			height: 1px;
			background-color: #c3c0b4;
			border:0;
		};
	}
	.box-has-outer_line.mod-underline .box-has-outer_line-inner {
		justify-content: center;
	}
	.box-has-outer_line.mod-underline .box-voice {
		width: 380px;
		@extend .font-kreon_l;
		+ * {
			margin-left: 40px;
		}
	}
	.box-voice-inner {
		height: 107px;
	}
	.box-voice-icon {
	}
	.box-voice-icon:after {
		position: static;
		background-color: #fff;
		width: 74px;
		height: 74px;
		border-radius: 50%;
		background-position:center center;
		transform:none;
	}
}
/**
 * shop
 */
.shop-en {
	margin-top: 115px;
	.section-title-en + .area-has-deviated_line {
		margin-top: 60px;
	}
	.box-slash-heading.mod-lower .box-slash-heading-above {
		font-size: 20px;
		font-weight: normal;
	}
	.box-slash-heading.mod-lower .box-slash-heading-below {
		@extend .font-kreon_r;
		font-size: 14px;
	}
	.area-has-deviated_line-explain {
		@extend .font-kreon_l;
	}
	.area-has-deviated_line-detail_table th {
		font-weight: normal;
		font-size: 13px;
		width: 95px;
	}
	.area-has-deviated_line-detail_table td {
		font-size: 13px;
  		font-weight: 300;
  		@extend .font-kreon_l;
	}
}
/**
 * info
 */
.information-en {
	margin-top: 130px;
	.table-basic {
		margin-top: 55px;
		th {
			font-weight: normal;
			width: 217px;
		}
		td {
			@extend .font-kreon_l;
			font-weight: 300;
		}
	}
}
/**
 * footer
 */
.footer-en {
	margin-top: 160px;
	padding:25px 80px;
	.footer-inner {
		max-width: 800px;
	}
	&-title {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: 1.6px;
		text-align: left;
		color:$black;
	}
	&-text {
		margin-top: 11px;
		font-size: 14px;
		@extend .font-kreon_l;
		line-height: 1.71;
		letter-spacing: normal;
		text-align: left;
		color: $black;
	}
	small {
		@extend .font-kreon_l;
		font-size: 13px;
		line-height: 1.85;
		letter-spacing: 1.3px;
	}
	.sns-list-icon-item {
		font-size: 20px;
	}
}
@include breakpoint(sp) {
	/**
	 * body
	 */
	body.home_en {
		min-width: 100%;
		padding-top: 0;
		.anchor {
			padding-top: 85px;
			margin-top: -85px;
		}
	}
	/**
	 * header
	 */
	.header-sp-logo.mod-en {
		img {
			width: 240px;
		}
	}
	.home_en .header-spnavi-logo.mod-en {
		background:resolve('logo_en.png') no-repeat left top;
		background-size: 200px;
		width:200px;
		height:height('logo_en.png',2.6);
	}
	.header-en {
		display: none;
	}
	.gnavi-en {
		display: none;
	}
	.box-link.mod-en-contact {
		display: block;
		position: relative;
		margin-left:0;
    	right: 0px;
	}
	.home_en {
		.sp-navi-shop-link {
			text-align: left;
			margin-top: 30px;
			a {
				display: inline-block;
				font-weight: normal;
			}
			li.no_icon {
				font-size: 20px;
				&:before {
					display: none;
				}
			}
			li + li {
				margin-top: 15px;
			}
		}
	}
	/**
	 * mv
	 */
	.mv-en {
		height: 250px;
		margin:14px auto 60px;
	}
	/**
	 * titles
	 */
	.section-title-en {
		font-size: 20px;
	}
	/**
	 * lead
	 */
	.lead-en {
		font-size: 15px;
		.article-row + .article-row {
			margin-top: 40px;
		}
		.set-imagetext-text-textarea {
			margin-top: 0;
		}
	}
	/**
	 * partners
	 */
	.partners-en {
		//margin-top: 40px;
		.thumbnail-item-horizontal.mod-photo-s {
			.thumbnail-item-horizontal-img.mod-1col {
				width: 100%;
			}
			.thumbnail-item-horizontal-textarea {
				padding: 24px 0;
				width: 92.8571%;
			}
		}
	}
	.text-enph-marker {
		font-size: 18px;
	}

	/**
	 * voices
	 */
	.voices-en {
		//margin-top: 40px;
		.box-has-outer_line.mod-underline {
			margin-top: 40px;
			height: auto;
		}
		.box-has-outer_line.mod-underline .box-has-outer_line-inner {
			display: block;
		}
		.box-has-outer_line.mod-underline .box-voice {
			width: 100%;
			+ * {
				margin-left: 0;
			}
		}
		.box-voice-inner {
			height: auto;
		}
		.box-voice-icon {
		}
		.box-voice+.icon_wrap .box-voice-icon {
			width: 52px;
			height: 52px;
		}
		.box-voice-icon:after {
			position: absolute;
			background-color: #fff;
			width: 49px;
			height: 49px;
			transform: translate(-50%,-50%);
		}
	}
	/**
	 * online shopping
	 */
	.shopping-en {
		//margin-top: 50px;
		height: auto;
		padding:20px 0;
		&.common-component {
			.grid.mod-2col {
				max-width:100%;
				width: 90%;
				padding:20px;
				height: auto;
				display: block;
				.bold {
					margin-bottom: 10px;
				}
				& > .col {
					width: 100%;
					margin:auto!important;
					margin-top: 20px!important;
					img {
						margin-bottom: 0;
					}
				}
			}
			.col {
				display: block;
			}
			.col + .col {
				margin-top: 15px!important;
			}
			.col *+* {
				margin-top: 0;
			}
		}
		.box-link {
			width: 280px;
			height: 50px;
			line-height: 50px;
			margin-top: 40px;
			font-size: 18px;
		}
	}
	.vegetables-en {
		//margin-top: 40px;
	}
	/**
	 * shop
	 */
	.shop-en {
		//margin-top: 40px;
		.box-slash-heading.mod-lower {
			.box-slash-heading-above {
				font-size: 15px;
				letter-spacing: 1px;
			}
		}
	}
	/**
	 * info
	 */
	.information-en {
		//margin-top: 40px;
		.table-basic {
			margin-top: 35px;
			th {
				width: auto;
			}
		}
	}

	/**
	 * footer
	 */
	.home.home_en .footer.footer-en {
		margin-top: 30px;
		padding:30px 20px;
		&-title {
			font-size: 15px;
		}
		&-text {
			font-size: 13px;
		}
		small {
			display: block;
			text-align: center;
			margin-top: 10px;
			font-size: 11px;
		}
	}
}
@include breakpoint(exsp){
	.spnavi-inner .parent {
		padding-left: 0;
		padding-right: 0;
		li {
			font-size: 14px;
		}
	}
	/**
	 * shop
	 */
	.shop-en {
		.box-slash-heading.mod-lower .box-slash-heading-above {
			font-size: 14px;
			letter-spacing: 0;
		}
	}

	.vegetables-en .box-has-outer_line-inner {
			width: 100%;
		}

}
