.footer-sns{
	position: absolute;
	top: 140px;
}

@include breakpoint(sp) {
	.footer-sns{
		position: relative;
		top: inherit;
		bottom: 0px;
		right: 0;
	}
}

