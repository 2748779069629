.link-arrow {
	position: relative;
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 0.1em;
	color:#1c1f21;
	padding-right: calc(width('arrow_right_link@2x.png',2) + 10px);
	@include opacity--anim(.5);
	&:before {
		content:"";
		position:absolute;
		right:0;
		top:50%;
		transform:translate(0,-50%);
		background:resolve('arrow_right_link@2x.png') no-repeat left top;
		background-size: size('arrow_right_link@2x.png',2);
		width:width('arrow_right_link@2x.png',2);
		height:height('arrow_right_link@2x.png',2);
		background-color: #fff;
	}
	&.mod-on-title{
		position:absolute;
		right:0;
		top:50%;
		transform:translate(0,-50%);
	}
	&.mod-area-has-map{
		right:40px;
	}
	&.mod-blank:after {
		content:"";
		display: inline-block;
		background:resolve('icon_blank@2x.png') no-repeat left top;
		background-size: size('icon_blank@2x.png',2);
		width:width('icon_blank@2x.png',2);
		height:height('icon_blank@2x.png',2);
		margin-left: 6px;
	}
}
@include breakpoint(sp) {
	.link-arrow {
		&.mod-on-title{
			display: none;
		}
	}
}
