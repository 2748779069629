.page-template-stores {
	.box-triangle {
        margin-bottom: 42px;
        padding: 25px 40px;
        max-width: 960px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 90px;
        &:before {
            border-top: 12px solid;
            border-right: 12px solid transparent;
        }
        @include breakpoint(sp){
            padding: 20px;
            width: 90%;
        }
    }
    
    .box-slash-heading.mod-lower .box-slash-heading-above {
        font-family: kreon_r;
        font-size: 24px;
        font-weight: 400;
        padding-top: 0px;
        padding-right: 14px;
    }

    @include breakpoint(sp){
        .area-has-deviated_line+.area-has-deviated_line {
            margin-top: 95px;
        }
    }
}

#masonry-wrap {
    max-width: 990px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 16px;
    .area-has-deviated_line-btns > .btn-half_line + a {
        margin-left: 15px;
    }
    @include breakpoint(sp){
        max-width: 100%!important;
        left:0!important;
        margin-left:auto;
        margin-right: auto;
        > div {
            width: 100%!important;
            height: auto!important;
        }
    }
}

.icon_store {
    position: absolute!important;
    display: flex;
    justify-content:flex-start;
    flex-wrap:wrap;
    opacity: 1;
    z-index: 3!important;
    > * {
        display: block;
        width: 50px;
        height: 50px;
    }
}
.icon_buy {
    background:resolve('icon_buy.png') no-repeat center;
    background-size: size('icon_buy.png',2);
    background-color: #68603e;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}

.icon_eat {
    background:resolve('icon_eat.png') no-repeat center;
    background-size: size('icon_eat.png',2);
    background-color: #4f6537;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}


