.banner-wrap {
	max-width: 960px;
	width: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;
	a {
		@include opacity--anim(.7);
		display: inline-block;
		&.sp {
			display: none;
		}
	}
	&.mod-order_4{
		order: 4;
	}
	&.mod-multiple {
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		margin-bottom: -20px;
		margin-top: 73px;
		&:hover {
			opacity: 1;
		}
		&:before,&:after {
			content:"";
			width:230px;
			height: 0;
		}
		&:before {
			order:2;
		}
		&:after {
			order:1;
		}
		& > * {
			position: relative;
			margin-bottom: 20px;
			&:before {
				content:"";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color:rgba(0,0,0,.5);
				opacity: 0;
				transition:opacity .6s;
			}
			&:after {
				content:"";
				background:resolve('icon_more.png') no-repeat left top;
				background-size: size('icon_more.png',2);
				width:width('icon_more.png',2);
				height:height('icon_more.png',2);
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				opacity: 0;
				transition:opacity .6s;
			}
			&:hover {
				opacity: 1;
				&:before,&:after {
					opacity: 1;
				}
			}
		}
	}
	&.mod-company{
		margin:120px auto;
		margin-bottom: 100px;
		& > *{
			margin-left:30px;
			margin-right: 30px;
			margin-bottom: 20px;
		}
	}
	&.mod-shop{
		display: flex;
		justify-content:flex-start;
		flex-wrap:wrap;
		max-width: 750px;
		margin-top: 50px;
		> *{
			margin:10px 10px 0;
			width: 230px;
			height: 65px;
			display: block;
			background-size: cover;
			background-position: center;
		}
		&.mod-center{
			justify-content:center;
		}
	}
}
@include breakpoint(sp) {
	.banner-wrap {
		max-width: 87.5%;
		a {
			&.sp {
				display: block;
			}
			&.pc {
				display: none;
			}
		}
		&.mod-multiple {
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
			margin-bottom: -10px;
			margin-top: 40px;
			&:before,&:after {
				display: none;
			}
			& > * {
				width: 49%;
				margin-bottom: 10px;
				&:before {
					display: none;
				}
				&:after {
					display: none;
				}
			}
		}
		&.mod-company{
			margin:60px auto;
			width: 75%;
			margin-bottom: 40px;
			& > *{
				margin:0 0 20px;
				display:inline-block;
			}
		}
		&.mod-shop{
			justify-content:space-between;
			margin-top: 40px;
			padding:0 10px;
			> *{
				width: 49%;
				margin:10px 0 0;
				height: 0;
				padding-top:percentage(65 / 230) / 2;
			}
			&.mod-center{
				padding:0 5px;
				> *{
					width: calc(50% - 10px);
					margin:10px 5px 0;
				}
			}
		}
	}
}
