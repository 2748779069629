.table-user-info {
	font-size: 13px;
	text-align: center;
	font-weight: 400;
	table {
		width: 100%;
	}
	td,th {
		border-top: 1px solid #cdd2d5;
		border-bottom: 1px solid #cdd2d5;
		vertical-align: middle;
		+ td,
		+ th {
			background:resolve('line-user-table.png') repeat-y left top;
			background-size: size('line-user-table.png',2);
		}
	}
	span {
		font-family: kreon_r;
		font-size: 14px;
	}
	thead {
		th {
			padding: 6px;
			font-weight: 700;
			text-align: center;
		}
	}
	tbody {
		td {
			text-align: left;
			border-left: 1px;
			padding:14px 10px;
			font-size: 13px;
			line-height: (22 / 13);
		}
	}
	a {
		color: #4f6537;
		border-bottom: 1px solid #4f6537;
		@include opacity--anim(.6);
	}
	.cell-exnarrow {
		width: 50px;
		padding-left: 0;
		padding-right: 0;
	}
	.cell-narrow {
		width: 85px;
	}
	.cell-semi-narrow {
		width:98px;
	}
	.cell-semi-medium {
		width: 110px;
	}
	.cell-medium {
		width: 134px;
	}
	.large {
		font-size: 18px;
	}
}

@include breakpoint(sp) {
	.sp-can-swipe {
		width: 100%;
		overflow-x: scroll;
		> * {
			width: 660px!important;
		}
	}
}
