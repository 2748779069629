.about{
	.footer {
		margin-top: 0;
	}
	.mod-article-row-top {
		margin-top: 128px;
	}
	.a_footer {
		margin-top: 310px;
		padding-top: 60px;
		padding-bottom: 160px;
		background-image: url('../images/a_background.png');
		background-size: size('a_background.png',2);
		width: 100%;
		height: 100%;
		background-repeat: repeat;
	}
	.article.mod-about {
		width: 100%;
	}
	.article-center {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 104px;
		position: relative;
		line-height: 2;
		&:after {
			content: "";
			background : url('../images/a_sonnawakede-img.png');
			background-size: cover;
			background-position: center bottom;
			background-repeat: no-repeat;
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: -52%;
			z-index: -1;
			left: 0;
		}
		p:first-child {
			letter-spacing: 0.1em;
			padding-bottom: 13px;
			margin-left: -3px;
		}
		&-text {
			padding-right: 9.9%;
		}
	}
	.article_mv {
		margin-bottom: 25px;
		max-width: 960px;
		width: 100%;
		&-img {
			height: 210px;
			background:resolve('img_about_header.png') no-repeat center center;
			background-size: cover;
		}
		&-text {
			bottom: 20px;
			left: 39px;
			& .text-bg-line {
				font-size: 20px;
				letter-spacing: 0.1em;
			}
		}
	}
	.article-row-column.set-intro{
		width: 87%;
	}
	.article-row + .article-row {
		margin-top: 112px;
	}
	.article_shisin-text {
		padding-left: 50px;
		margin-bottom: 62px;
		line-height: ( 28 / 14 );
	}
	.banner-wrap.mod-multiple {
		margin-top: 112px;
	}
	.box-shadow {
		background-color: #fff;
		border-right: 1px solid #c3c0b4;
		border-bottom: 1px solid #c3c0b4;
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 46px;
		position: relative;
		max-width: 460px;
		width: 100%;
		line-height: (26 / 13);
		.box-slash-heading.mod-num01 {
			top: -4%;
			left: -9%;
		}
		.box-slash-heading.mod-num02 {
			top: -3%;
		}
		.bold.col-black.sp {
			display: none;
		}
		&:after {
			content: "";
			background-color: #f8f7f2;
			position: absolute;
			top: 5px;
			left: 5px;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
		a {
			display: block;
			position: absolute;
			letter-spacing: 0.1em;
			padding-right: 22px;
			right: 23px;
			bottom: 13px;
		}
		a:after {
			border-color: #c3c0b4;
			content: "";
			position: absolute;
			top: 7px;
			right: 2px;
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7.5px 0;
			border-color: #c3c0b4 transparent transparent;
		}
	}
	.box-slash-heading {
		font-style: oblique;
		top: -13px;
		left: -36px;
		&-above {
			font-family: 'kreon_r';
			font-size: 18px;
			padding-right: 23px;
			padding-top: 0;
			.col-brown.normal.mod-num02 {
				left: 20%;
			}
		}
		&-below {
			padding-left: 4px;
			padding-bottom: 23px;
			line-height: (24 / 14);
			transform: translateY(15px);
		}
	}
	.col-black {
		color: #1c1f21;
	}
	.col-brown {
		color: #675f3e;
	}
	.col-grbrown {
		color: #68603e;
	}
	.f-13 {
		font-size: 13px;
	}
	.f-14 {
		font-size: 14px;
	}
	.f-18 {
		font-size: 18px;
	}
	.glid.mod-thumbnail_2col-wrap {
		margin-top:30px;
		margin-bottom: 70px;
	}
	.page-header-title.mod-butterfly::after {
		right: 69.2%;
	}
	.ml-40 {
		margin-left: 40px;
	}
	.mt-30 {
		margin-top: 32px;
	}
	.set-intro-image{
		width: calc(50% - 133px);
		margin-right: 60px;
		&-element{
			height: 260px;
			& + .set-intro-image-element{
				margin-top: 10px;
			}
		}
	}
	.set-intro-text{
		&-title{
			max-width: 630px;
			padding: 26px 0 20px 40px;
		}
		&-textarea{
			max-width: 630px;
			padding-left: 40px;
			a {
				border-bottom: 1px solid #4f6537;
				color: #4f6537;
				padding-bottom: 1px;
			}
		}
	}
	.textarea {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		text-align: center;
		.text {
			position: relative;
			line-height: (28 / 14);
			&:before {
				content: "";
				background: url('../images/a_left-arrow.png');
				background-size: size('a_left-arrow.png',2);
				background-repeat: no-repeat;
				position: absolute;
				width: 12px;
				height: 35.5px;
				left: -16%;
				top: 17%;
			}
			&:after {
				content: "";
				background: url('../images/a_right-arrow.png');
				background-size: size('a_right-arrow.png',2);
				background-repeat: no-repeat;
				position: absolute;
				width: 12px;
				height: 35.5px;
				right: -13%;
				top: 17%;
			}
		}
		&.mod-arrow {.text{
			&:before {
				top: 35%;
				left: -9%;
			}
			&:after {
				top: 35%;
				right: -8%;
			}
		}}
	}
	.thumbnail_2col {
		background-color: #fff;
	}
	.two-col {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: -3px;
		.box-shadow.f-13.ml-40 > .box-slash-heading {
			left: -46px;
		}
	}
	.oblique {
		font-style: oblique;
	}

}//about
@include breakpoint(sp) {
	.about{
		.mod-article-row-top {
			margin-top: 50px;
		}
		.footer {
			margin-top: 20px;
		}
		.a_footer {
			background: none;
			margin-top: 110px;
			width: 87.5%;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 0;
		}
		.article-center {
			font-size: 12.975px;
			margin-top: 58.5px;
			&:after {
				background-size: contain;
				bottom: -100%;
			}
			p:first-child {
				font-size: 13.975px;
			}
			&-text {
				width: 87.5%;
				margin-left: auto;
				margin-right: auto;
				padding: 0;
				line-height: ( 47.91 / 25.95 );
			}
		}
		.article_mv {
			margin-bottom: 25px;
			max-width: 960px;
			width: 100%;
			&-img {
				height: 240px;
			}
			&-text {
				width: 87.5%;
				margin-left: auto;
				margin-right: auto;
				bottom: 20px;
				left: 39px;
				.text-bg-line {
					font-size: 12.975px;
					font-weight: 700;
					letter-spacing: 0.1em;
					color: #1c1f21;
				}
			}
		}
		.article_shisin-text {
			font-size: 12.975px;
			padding-left: 0;
			margin-bottom: 35px;
			line-height: ( 47.91 / 25.95 );
			width: 87.5%;
			margin-left: auto;
			margin-right: auto;
		}
		.box-shadow {
			border: none;
			border-bottom: 1px solid #c3c0b4;
			display: flex;
			padding : 10px 4px;
			&:after {
				content: none;
			}
			.box-slash-heading.mod-num01 {
				top: 29%;
				left: -13%;
			}
			.box-slash-heading.mod-num02 {
				top: 29%;
				left: -13%;
			}
			.bold.col-black.pc {
				display: none!important;
			}
			.bold.col-black.sp {
				display: block!important;
			}
			&.f-13.ml-40 {
				margin-left: 0;
			}
			a {
				display: block;
				position: absolute;
				right: 0;
				bottom: 50%;
			}
			a:after {
				border-color: #c3c0b4;
				content: "";
				position: absolute;
				top: 0;
				right: 5px;
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 7.5px 0;
				border-color: #c3c0b4 transparent transparent;
			}
		}
		.box-slash-heading {
			top: 29%;
			left: -13%;
			&-above {
				font-family: 'kreon_r';
				font-size: 16.97px;
				padding: 0;
			}
			&-below {
				font-size: 12.975px;
				padding: 0 9%;
				padding-bottom: 8px;
				margin-top: -16px;
				line-height: (43.92 / 25.95 );
			}
			&-slash.pc {
				display: none!important;
			}
		}
		.set-intro {
			width: 87.5%;
			margin-left: auto;
			margin-right: auto;
			&-image {
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;
				margin: 0!important;
				width: 100%!important;
				&-element {
					width: 49.1071428575%;
					height: 0;
					padding-top: 30%;
					margin-top: 0!important;
				}
			}
			&-text{
				&-textarea {
					line-height: ( 47.92 / 25.96 );
					width: 100%;
					margin:0;
					padding: 0!important;
				}
				&-title{
					margin: 0;
					margin-bottom: 18px;
					max-width: 630px;
					padding: 0;
				}
			}
		}
		.textarea {
			text-align: left;
			.text {
				line-height: (28 / 14);
				&:before {
					content: none;
				}
				&:after {
					content: none;
				}
			}
		}
		.two-col {
			border: 1px solid #c3c0b4;
			border-bottom: none;
			width: 87.5%;
			margin-left: auto;
			margin-right: auto;
			padding: 0 4px;
			&.pc {
				display: none;
			}
			&.mt-30 {
				margin-top: 0;
				border-top: none;
				border-bottom: 1px solid #c3c0b4;
				.box-shadow.f-13.ml-40 {
					border-bottom: none;
				}
			}
			.box-shadow.f-13.ml-40 > .box-slash-heading {
				left: -13%;
			}
		}
		.article-row + .article-row {
			margin-top: 60px;
		}
		.glid.mod-thumbnail_2col-wrap {
			margin-bottom: 50px;
		}
		.banner-wrap.mod-multiple {
			max-width: 100%;
		}

		.inner-link {
			border:1px solid #c3c0b4;
			width: 87.5%;
			margin-left: auto;
			margin-right: auto;
			padding-left: 5px;
			padding-right: 5px;
			li {
				a {
					display: flex;
					justify-content:flex-start;
					flex-wrap:wrap;
					color: #675f3e;
					padding:9px 0;
					position: relative;
					&:after {
						content: "";
						position: absolute;
						position:absolute;
						top:50%;
						transform:translate(0,-50%);
						right: 5px;
						display: inline-block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 7.5px 0;
						border-color: #c3c0b4 transparent transparent
					}
				}
				+ li a {
					border-top: 1px solid #c3c0b4;
				}
			}
			.num {
				font-family: 'kreon_r';
				font-style: italic;
				font-size: 17px;
				width: 30px;
				letter-spacing: 1.7px;
			}
			.link {
				font-style: italic;
				font-size: 14px;
				font-weight: bold;
				width: calc(100% - 30px);
				display: block;
				padding-top: 3px;
				padding-right: 30px;
			}
		}
	}//about
}//breakpoint
