.section{
	&.mod-header{
		//background-image: resolve('bg@2x.png');
		//background-size: 85%;
	}
	&.mod-slider{
		//background-image: resolve('bg@2x.png');
		//background-size: 85%;
	}
	&.mod-pickup{
		//background-image: resolve('bg@2x.png');
		//background-size: 85%;
		margin-top: 80px;
	}
	&.mod-idea{
		position: relative;
		margin-top: 218px;
		max-width:1280px;
		margin-left: auto;
		margin-right: auto;
		.box-link {
			width: 180px;
		}
	}
	&.mod-event-news{
		margin: 195px 25px 0px;
		margin: 0 auto;
		margin-top: 195px;
		max-width: 1280px;
		padding: 0px 17px;
		font-size: 0px;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
	}
	&.mod-article{
		margin-top: 147px;
		position: relative;
	}
	&.mod-project{
		margin-top: 123px;
		position: relative;
	}
	&.mod-shop{
		margin-top: 80px;
	}
	&.mod-sns{
		margin-top: 73px;
		padding-top: 54px;
		padding-bottom: 120px;
		background-image: resolve('bg@2x.png');
		background-size: 85%;
		background-repeat: repeat;
	}
	&.mod-bg{
		background-image: resolve('bg@2x.png');
		background-size: 83%;
		background-repeat: repeat;
	}
	&-gif{
		display: block;
		margin-bottom: 17px;
		max-width:100%;
		overflow: hidden;
		&.mod-category {
			margin-bottom: 23px;
		}
		&.mod-project{
			margin-bottom: 7px;
		}
		&.mod-about {
			margin-bottom: 9px;
		}
	}

	// project detail
	&.mod-other-project{
		margin-top: 100px;
	}

	&.mod-shop-info {
		margin-top: 99px;
		width: 100%;
		max-width:960px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		& > * {
			width: 470px;
		}
	}
	&.mod-bg-pattern-green{
		padding-top: 70px;
		background:resolve('bg-shop@2x.png') repeat center top;
		background-size: size('bg-shop@2x.png',2);
		&.mod-footer {
			margin-top: 20px;
			padding-top: 90px;
			padding-bottom: 160px;
		}
	}

	//company
	&.mod-company + .section{
		margin-top: 120px;
	}

	//shop top renewal
	&.mod-shop-staff-voice{
		display: none;
	}
	&.mod-shop-standard{
		padding:100px 0 80px;
	}
	&.mod-shop-pickup{
		padding:0px 0 120px;
		position: relative;
		overflow: hidden;
		&:after{
			content: "";
			display:block;
			background: resolve('ill-shop-top.png') center no-repeat;
			background-size: size('ill-shop-top.png',2);
			width: width('ill-shop-top.png',2);
			height: height('ill-shop-top.png',2);
			position: absolute;
			bottom:0;
			left:50%;
			margin-left: 283px;
		}
	}
	&.mod-shop-entry{
		padding:60px 0 60px;
		overflow: hidden;
		&.mod-has-bg{
			background: #f8f7f2;
		}
	}
	&.mod-shop-banner{
		padding:0px 0 0;
	}
	&.mod-shop-category{
		padding:75px 0 0;
	}
	&.mod-shop-banner-large{
		padding:100px 0 85px;
	}
	&.mod-shop-article{
		padding:0 0 120px;
	}
}

@include breakpoint(sp) {
	.section{
		&-gif {
			img {
				width: 100%;
			}
			&.mod-project,
			&.mod-about {
				img {
					width: 260%;
					height: auto;
					max-width: 260%;
				}
			}
		}
		&.mod-pickup{
			width: 100%;
			margin: 0 auto;
			margin-top: 45px;
			padding-top: 0px;
			padding-bottom: 0;
		}
		&.mod-idea{
			position: relative;
			margin-top: 95px;
			max-width:100%;
		}
		&.mod-event-news{
			margin: 0;
			margin-top: 115px;
			display: block;
		}
		&.mod-article{
			margin-top: 105px;
		}
		&.mod-project{
			margin-top: 85px;
			position: relative;
		}
		&.mod-shop{
			margin-top: 105px;
		}
		&.mod-shop.mod-pickup {
			margin-top: 55px;
		}
		&.mod-sns {
			margin-top: 0;
			padding-top: 60px;
			padding-bottom: 30px;
			background-size: size('bg@2x.png',2);
			background-repeat: repeat;
		}
		// project detail
		&.mod-other-project{
			margin-top: 100px;
		}
		&.mod-shop-info {
			margin-top: 80px;
			width: 93.75%;
			min-width: inherit;
			display: block;
			& > * {
				width: 100%;
			}
			& > * + * {
				margin-top: 40px;
			}
		}
		&.mod-bg-pattern-green.mod-footer {
			padding-bottom: 30px;
		}

		//company
		&.mod-company + .section{
			margin-top: 60px;
		}

		//shop top renewal
		&.mod-shop-staff-voice{
			display: block;
			padding:35px 10px 10px;
		}
		&.mod-shop-standard{
			padding:20px 0 80px;
		}
		&.mod-shop-pickup{
			padding:0px 10px 120px;
			&:after{
				background-size: size('ill-shop-top.png',3);
				width: width('ill-shop-top.png',3);
				height: height('ill-shop-top.png',3);
				left:auto;
				right: 10px;
				margin-left: 0;
			}
		}
		&.mod-shop-entry{
			padding:40px 0 50px;
			overflow: hidden;
		}
		&.mod-shop-banner{
			padding:20px 0 0px;
		}
		&.mod-shop-category{
			padding:50px 0 0;
		}
		&.mod-shop-banner-large{
			padding:50px 0 50px;
		}
		&.mod-shop-article{
			padding:0 0 60px;
		}
	}
}
