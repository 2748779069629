.cooktop {
	margin-top: 173px;
}
.page-voice-contents-top_border-left {
	border-top: 1px solid #c3c0b4;
	position: absolute;
	top: -5px;
	left: 0;
	width: 10.416666666%;
}
.page-voice-contents-top_border-right {
	border-top: 1px solid #c3c0b4;
	position: absolute;
	top: -5px;
	right: 0;
	width: 10.416666666%;
}
.page-voice-contents-bottom_border {
	border-top: 1px solid #c3c0b4;
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
}
.mod-cfc {
	color: #675f3e;
}
.mod-ctc {
	color: #4f6537;
}
.mod-ctcbg {
	background: #f5f7f2!important;
}
.mod-ctc-border {
	border-color: #c6cfbc!important;
}
.page-voice-contents-top.bold.mod-ctc::after {
	border-color: #f5f7f2 #f5f7f2 transparent transparent;
}

.access {
	font-size: 13px;
	margin-top: 28px;
	line-height: (22 / 13);
	a {
		border-bottom: 1px solid #4f6537;
		color: #4f6537;
	}
}
.customer-feedback-footer {
	color: #675f3e;
	font-size: 16px;
	margin-top: 80px;
	text-align: center;
	width: 100%;
}
.page-voice-wrap {
	max-width: 960px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.page-voice-top {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.1em;
	line-height: (32 / 16);
	&-text {
		color: #68603e;
		font-style: oblique;
		margin-top: -10px;
		text-align: center;
		a {
			color : #68603e;
			border-bottom: 1px solid #68603e;
		}
	}
	&-menubox {
		border: 1px solid #4f6537;
		margin-left: 55px;
		margin-right: 5px;
		max-width: 222px;
		padding: 0 10px;
		width: 100%;
		height: 100px;
		a {
			display: block;
			color: #4f6537;
			height: 49px;
			position: relative;
			padding: 8px 0;
			padding-left: 10px;
			letter-spacing: 0.1em;
		}
		a:after{
			content: "";
			position: absolute;
			top: 20px;
			right: 10px;
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7.5px 0;
			border-color: #c6cfbc transparent transparent;
		}
		a.mod-border {
			border-bottom: 1px solid #4f6537;
		}
	}
}
.loop-3,.loop-9 {
	position: absolute;
	top: -21%;
	right: -27%;
	max-width: 160px;
}
.loop-6,.loop-12 {
	content: "";
	position: absolute;
	top: -26%;
	left: -27%;
	max-width: 160px;
}
.page-voice-contents {
	border-top: 1px solid #f8f7f2;
	background: #f8f7f2;
	color: #1c1f21;
	position: relative;
	margin-top: 130px;
	padding-bottom: 80px;
	&-top {
		align-items: center;
		border: 1px solid #c3c0b4;
		position: absolute;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		font-size: 20px;
		top: -50px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 740px;
		letter-spacing: 0.1em;
		width: 100%;
		height: 55px;
		&:before {
			content: "";
			border-style: solid;
			position: absolute;
			border-color: #c3c0b4 #c3c0b4 transparent transparent;
			border-width: 10px;
			bottom: -20px;
			left:50%;
			transform:translate(-50%,0);
		}
		&:after {
			content: "";
			border-style: solid;
			position: absolute;
			border-color: #f8f7f2 #f8f7f2 transparent transparent;
			border-width: 9px;
			z-index: 2;
			bottom: -18px;
			left:50%;
			transform:translate(-50%,0);
		}
	}//top
	&-box {
		background-color: #fff;
		border-bottom: 1px solid #c3c0b4;
		border-right: 1px solid #c3c0b4;
		color: #1c1f21;
		display: flex;
		justify-content: flex-start;
		font-size: 13px;
		flex-wrap: wrap;
		margin-top: 30px;
		max-width: 740px;
		padding: 16px 50px 12px 50px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		ul {
			max-width:100%;
			display: block;
		}
		&.top {
			margin-top: 77px;
		}
		&.cook {
			border:none;
			font-style: normal;
			padding: 40px 70px;
			position: relative;
			&:before{
				content:"";
				display: block;
				position: absolute;
				width:0;
				height:0;
				top: 0;
				left: 0;
				border-top: 20px solid #c6cfbc;
				border-right: 20px solid transparent;
			}
		}
		&-img {
			background-color: #f8f7f2;
			border-radius: 50%;
			width: 80px;
			height: 80px;
		}
		&-text {
			max-width: 500px;
			width: 100%;
			margin-left: 49px;
			line-height: (26 / 13);
			.date {
				text-align: right;
			}
		}
	}//box
}
ul.cook-img {
	border-bottom: 1px solid #c6cfbc;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 40px;
	width: 100%;
	.img {
		background: url('http://placehold.jp/195x120.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 195px;
		height: 120px;
	}
}
.cook-title {
	color: #1c1f21;
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 38px;
	width: 100%;
	letter-spacing: 0.1em;
}
.question {
	font-family: 'kreon_r';
	font-size: 16px;
	font-weight : 700;
	margin-bottom: 9px;
	word-wrap: break-word;
	max-width:100%;
	> {
		word-wrap: break-word;
		max-width:100%;
	}
}
.answer {
	font-weight: 400;
	line-height: (28 / 14);
	padding-bottom: 40px;
	word-wrap: break-word;
	max-width:100%;
	> {
		word-wrap: break-word;
		max-width:100%;
	}
}

@include breakpoint(sp) {
	.cooktop {
		margin-top: 60px;
	}
	.page-voice-contents-top_border-left {
		display: none;
	}
	.page-voice-contents-top_border-right {
		display: none;
	}
	.box-num3x::before {
		content: none;
	}
	.box-num6x::before {
		content: none;
	}
	.customer-feedback-footer {
		font-size: 12px;
		font-style: oblique;
		margin-top: 40px;
		p {
			font-weight: 400;
			padding : 0 21.875%;
		}
	}//customer-feedback-footer
	.page-voice-wrap {
		width: 87.5%;
	}
	.page-voice-top {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0.1em;
		line-height: (32 / 16);
		&-text {
			font-size: 12px;
			font-style: oblique;
			font-weight: 400;
			text-align: left;
			margin: 0;
			a {
				color : #68603e;
				border-bottom: 1px solid #68603e;
				font-size: 13px;
				height: 31px;
			}
		}
		&-menubox {
			margin-top: 20px;
			margin-left: 0;
			margin-right: 0;
			max-width: 100%;
			padding: 0 5px;
			height: 75px;
			a {
				display: block;
				color: #4f6537;
				font-size: 13px;
				height: 36px;
				position: relative;
				padding: 5px 0;
			}
			a:after{
				content: "";
				position: absolute;
				top: 13px;
				right: 10px;
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 7.5px 0;
				border-color: #c6cfbc transparent transparent;
			}
			a.mod-border {
				border-bottom: 1px solid #4f6537;
			}
		}
	}//top
	.page-voice-contents {
		margin-top: 78px;
		padding: 0 3.125%;
		padding-bottom: 30px;
		&-top {
			font-size: 13px;
			top: -25px;
			height: 30px;
			width: calc(100% - 10px);
			&:before {
			border-width: 5px;
			bottom: -10px;
			right: 48%;
			}
			&:after {
			border-width: 4px;
			bottom: -8px;
			right: 48.3%;
			}
		}//contents-top
		&-box {
			font-size: 13px;
			margin-top: 20px;
			padding: 20px 10px;
			display: flex;
			justify-content: flex-start;
			flex-wrap: nowrap;
			width: 100%;
			&.cook {
				padding: 30px 3.125%;
				flex-wrap: wrap;
				&:before{
					content:"";
					display: block;
					position: absolute;
					width:0;
					height:0;
					top: 0;
					left: 0;
					border-top: 20px solid #c6cfbc;
					border-right: 20px solid transparent;
				}
			}
			&.top {
				margin-top: 45px;
			}
			&-img {
				background-color: #f8f7f2;
				border-radius: 50%;
				width: 40px;
				height: 40px;
			}
			&-text {
				max-width: 80.615385%;//84.615384616%;
				font-size: 12px;
				margin-left: 5px;
				.date {
					text-align: right;
				}
			}
		}//contents-box
	}//.page-voice-contents
	.cook-title {
		font-size: 14px;
		margin-bottom: 20px;
		width: 100%;
	}
	.cook-img {
		padding-bottom: 30px;
		justify-content: space-around;
		width: 99.9796875%;
		.img {
			background: url('http://placehold.jp/127.5x77.5png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			width: 39.53125%;
			height: 0;
			padding-top: 30.49%;
		}
	}
	.question {
		font-size: 13px;
		margin-bottom: 15px;
		span {
			&:first-child {
				font-size: 15px;
				font-weight: normal;
			}
			&:last-child {
				font-family: '游ゴシック体', sans-serif;
				font-size: 13px;
			}
		}
	}
	.answer {
		padding-bottom: 20px;
	}
	.loop-3,.loop-9,.loop-6,.loop-12 {
		display: none;
	}
}//breakpoint
