.wp-pagenavi,
.cart-pagenavi {
	text-align: center;
	@extend .font-kreon_r;
	a {
		@include opacity--anim(.6);
	}
	> *{
		display: inline-block;
		margin: 0 10px;
	}
	&.mod-margin-yomimono{
		margin:30px 0 120px;
	}
	.page.mod-leader{
		vertical-align: top;
		letter-spacing: 2px;
	}
	.page.smaller{
		color: #68603e;
		font-size: 18px;
	}
	.current{
		color: #4f6537;
		font-size: 24px;
	}
	.page.larger{
		color: #68603e;
		font-size: 18px;
	}
	.previouspostslink{
		@include text-indent-none();
		display: inline-block;
		background: resolve("arrow_pager_left.png") no-repeat 0 0;
		width: width("arrow_pager_left.png");
		height: height("arrow_pager_left.png");
		background-size: size("arrow_pager_left.png");
	}
	.nextpostslink{
		@include text-indent-none();
		display: inline-block;
		background: resolve("arrow_pager_right.png") no-repeat 0 0;
		width: width("arrow_pager_right.png");
		height: height("arrow_pager_right.png");
		background-size: size("arrow_pager_right.png");
	}
	.first{
		color: #68603e;
		font-size: 14px;
		margin-left: 30px;
		letter-spacing: 1.4px;
	}
	.last{
		color: #68603e;
		font-size: 14px;
		margin-right: 30px;
		letter-spacing: 1.4px;
	}

	&.mod-category{
		font-family: Arial, Helvetica, sans-serif;
		.current{
			background-color: #ccc;
			border: 1px solid #ccc;
			color: #000;
			font-size: 14px;
			padding: 6px 11px;
			margin: 0 3.5px;
		}
		.page{
			font-size: 14px;
			color: #000;
			border: 1px solid #ccc;
			padding: 6px 10px;
			margin: 0 3.5px 5px;
		}
		.previouspostslink{
			background: none;
			position: relative;
			width: 20px;
			&:before{
				position: absolute;
				content: '';
				display: inline-block;
				left: 3px;
				width: 5px;
				height: 6px;
				border-top: 1px solid #000;
				border-right: 1px solid #000;
				-webkit-transform: rotate(225deg);
				transform: rotate(225deg);
			}
		}
		.nextpostslink{
			background: none;
			position: relative;
			width: 20px;
			margin-left: 10px;
			&:before{
				position: absolute;
				content: '';
				display: inline-block;
				left: 3px;
				width: 7px;
				height: 5px;
				border-top: 1px solid #000;
				border-right: 1px solid #000;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
}

@include breakpoint(sp) {
	.wp-pagenavi,.cart-pagenavi{
		> *{
			display: inline-block;
			margin: 0 5px;
		}
		&.mod-margin-yomimono{
			margin:30px 0 0;
		}
		.previouspostslink{
			background: resolve("arrow_pager_left_sp@2x.png") no-repeat 0 0;
			width: width("arrow_pager_left_sp@2x.png",2);
			height: height("arrow_pager_left_sp@2x.png",2);
			margin-left: 0;
			background-size: size("arrow_pager_left_sp@2x.png",2);
			background-position: center center;
			margin-left: 0px;
		}
		.nextpostslink{
			background: resolve("arrow_pager_right_sp@2x.png") no-repeat 0 0;
			width: width("arrow_pager_right_sp@2x.png",2);
			height: height("arrow_pager_right_sp@2x.png",2);
			margin-right: 0px;
			background-size: size("arrow_pager_right_sp@2x.png",2);
			background-position: center center;
			margin-left: 0px;
		}
		.first{
			color: #68603e;
			font-size: 14px;
			margin-left: 0px;
		}
		.last{
			color: #68603e;
			font-size: 14px;
			margin-right: 0px;
		}
		&.mod-category{
			.nextpostslink{
				margin-left: 5px;
			}
		}
	}
}

