.product_modal_screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
}

.product_modal {
  position: fixed;
  z-index: 10001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  max-width: 400px;
  width: 100%;
  padding: 30px;
}

.image_recommend {
  width: 102px;
  height: 102px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .product_modal {
    max-width: 80%;
  }

  .product_modal_lead {
    margin-bottom: 10px;
  }

  .product_modal_recommend {
    margin-top: 10px;
  }

  .image_recommend {
    width: 77px;
    height: 77px;
    object-fit: cover;
  }
  
  .product_image_close {
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }
}

.product_modal_lead {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 25px;
  font-weight: 700;
}

.product_modal_name {
  text-align: center;
  font-size: 1rem;
}

.product_modal_price {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 20px;
}

.product_modal_image {
  max-width: 40%;
  text-align: center;
  margin: auto;
}

.product_modal_btns {
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .cart {
    background-color: #f7f7f7;

    span {
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 19px auto;
      background-image: url(../images/icon_cart.png);
      width: 19px;
      height: 16px;
      vertical-align: -2px;
    }
  }

  a {
    display: block;
    width: 46%;
    border: 1px solid #595959;
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    padding: 10px 5px;
    color: #595959;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.product_modal_recommend {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &:after {
    content: "";
    display: block;
    height: 0;
    width: 30%;
  }

  li {
    width: 30%;
  }

  a {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}

.product_modal-close {
  display: block;
  background: url(../images/btn_modal_close.png) no-repeat 0 0;
  background-size: 18.5px 18.5px;
  width: 18.5px;
  height: 18.5px;
  position: absolute;
  top: 20px;
  right: 30px;
}

@media only screen and (max-width: 768px) {
  .product_modal {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
  }

  .product_modal-close {
    top: 10px;
    right: 10px;
  }

  .product_modal_btns a {
    font-size: 0.7rem;
  }
}