.gnavi{
	margin-top: 43px;
	margin-left: 79px;
	line-height: 45px;
	list-style: none;
	a {
		color: $black;
	}
	.nav-current {
		color: #4f6537;
		* {
			color: #4f6537;
		}
	}
	&-list{
		&-item{
			display: table;
			position: relative;
			font-size: 15px;
			font-weight: bold;
			padding-left: 18px;
			padding-right: 100px;
			letter-spacing: 1.5px;
			&:hover{
				background-image: resolve('gnavi-hover.png');
				background-repeat: no-repeat;
				background-position: 0 20px;
			}
			& > *{
				margin-left: 21px;
			}
			&.mod-kreon{
				@extend .font-kreon_r;
				letter-spacing: 2.4px;
			}
		}
		&-child{
			display: none;
			position: absolute;
			left: 143px;
			top: 0px;
			font-size: 12px;
			color: #68603e;
			width: 185px;
			border-bottom-width: 0;
			border-right: 1px solid #68603e;
			border-bottom: 1px solid #68603e;
			background-color: #fff;
			z-index: 10;
			line-height: 26px;
			padding-top: 11px;
			padding-bottom: 3px;
			&-item{
				position: relative;
				padding-left: 19px;
				padding-left: 24px;
				letter-spacing: 1px;
				a {
					color: #68603e;
				}
				&:hover{
					background-image: resolve('sub-navi-hover.png');
					background-repeat: no-repeat;
					background-position: 10px;
				}
			}
		}
	}
}

@media only screen and (max-width:1205px){
	.gnavi {
		margin-left: 8px;
	}
}
