.lower-mv {
	width: 100%;
	max-width:1040px;
	margin:0 auto 40px;
}
@include breakpoint(sp) {
	.lower-mv {
		margin-bottom: 10px;
	}
}
