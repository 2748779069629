.yasaiset{
	.area-has-deviated_line {
		$c:&;
		position: relative;
		background-color: #f5f7f2;
		padding:69px 82px;
		max-width: 960px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		& + #{$c} {
			margin-top: 140px;
		}
		&:before {
			content: "";
			top: 10px;
			right: 5px;
			position: absolute;
			width: 100%;
			height: 99%;
			border-right: 2px solid #c6cfbc;
			border-bottom: 2px solid #c6cfbc;
			padding: 0;
			pointer-events: none;
			padding-bottom: 3px;
		}
		&:nth-child(odd) {
			background-color: #f8f7f2;
			&:before {
				border-right: 2px solid #c3c0b4;
				border-bottom: 2px solid #c3c0b4;
			}
		}
		&-introduction {
			@extend .clearfix;
		}
		&-slide {
			float: left;
			width: 100%;
			&-main {
				position: relative;
				width: 100%;
				height: 270px;
				& > * {
					padding:0;
					margin: 0;
					width: 100%;
					height: 270px;
					background-repeat:no-repeat;
					background-position:center center;
					background-size:cover;
					opacity: 0;
					position: absolute;
					transition:opacity .6s;
					&.active {
						opacity: 1;
					}
				}
			}
			&-ctrl {
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
				margin-top: 17px;
				&:after {
					padding:0;
					margin: 0;
					width: 160px;
					height: 0;
					display: block;
				}
				.set_size{
					font-size: 26px;
					font-family: 'kreon_l';
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(0,0,0,0.3);
					color: #fff;
					width: 40px;
					height: 40px;
					text-align: center;
					opacity: 1;
					transition: opacity 0.3;
				}
				& > * {
					position: relative;
					margin-bottom: -5px;
					cursor: pointer;
					padding:0;
					margin: 0;
					width: 160px;
					height: 100px;
					background-repeat:no-repeat;
					background-position:center center;
					background-size:cover;
					//@include opacity--anim(.5);
					transition:  inherit;
					opacity: inherit;
					margin-bottom: 5px;
					&.active {
						opacity: inherit;
						.set_size{
							opacity: 0;
							transition: opacity 0.3;
						}
						.mask{
							width: 100%;
							height: 100%;
							background: rgba(0,0,0,0.3);
							transition: background 0.3s;
							.mask-img{
								//transition: opacity 0.3s;
								background: resolve("mask_set_s.png") no-repeat 0 0;
								width: 100%;
								height: 100%;
								background-position: center;
								opacity: 1;
							}
						}
					}
					&:hover{
						.set_size{
							opacity: 0;
							transition: opacity 0.3;
						}
						opacity: inherit;
						.mask{
							width: 100%;
							height: 100%;
							background: rgba(0,0,0,0.3);
							transition: background 0.3s;
							.mask-img{
								//transition: opacity 0.3s;
								background: resolve("mask_set_s.png") no-repeat 0 0;
								width: 100%;
								height: 100%;
								background-position: center;
								opacity: 1;
							}
						}
					}
					.mask{
						width: 100%;
						height: 100%;
						transition: background 0.3s;
						background: rgba(255,255,255,0);
						.mask-img{
							transition: opacity 0.3s;
							background: resolve("mask_set_s.png") no-repeat 0 0;
							width: 100%;
							height: 100%;
							background-position: center;
							opacity: 0;
						}
					}
				}
			}
		}
	}
}

@include breakpoint(sp) {
	.yasaiset{
		.area-has-deviated_line {
			$c:&;
			position: relative;
			background-color: #f5f7f2;
			padding:69px 82px;
			max-width: 960px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			& + #{$c} {
				margin-top: 140px;
			}
			&:before {
				content: "";
				top: 10px;
				right: 5px;
				position: absolute;
				width: 100%;
				height: 99%;
				border-right: 2px solid #c6cfbc;
				border-bottom: 2px solid #c6cfbc;
				padding: 0;
				pointer-events: none;
				padding-bottom: 3px;
			}
			&:nth-child(odd) {
				background-color: #f8f7f2;
				&:before {
					border-right: 2px solid #c3c0b4;
					border-bottom: 2px solid #c3c0b4;
				}
			}
			&-slide {
				float: none;
				width: 100%;
				&-main {
					position:absolute;
					top:0;
					left:50%;
					transform:translate(-50%,0);
					width: 100%;
					& > * {
						padding:0;
						margin: 0;
						width: 100%;
						padding-top: 51.92307692307693%;
						height: 0;
						background-repeat:no-repeat;
						background-position:center center;
						background-size:cover;
						opacity: 0;
						position: absolute;
						transition:opacity .6s;
						&.active {
							opacity: 1;
						}
					}
				}
				&-ctrl {
					display: flex;
					justify-content:space-between;
					flex-wrap:wrap;
					justify-content: center;
					margin-top: 55%;
					margin-bottom: 20px;
					text-align: center;
					&:after {
						// padding:0;
						// margin: 0;
						// width: 60px;
						// height: 0;
						// display: block;
						display: none;
					}
					& > * {
						font-family: 'kreon_r';
						margin-bottom: -5px;
						cursor: pointer;
						padding:0;
						margin: 0;
						margin-bottom: 5px;
						width: 30px;
						height: 30px;
						font-size: 20px;
						background-color: #474b42;
						color: #fff;
						background-repeat:no-repeat;
						background-position:center center;
						background-size:cover;
						transition:  inherit;
						opacity: inherit;
						&.active {
							opacity: inherit;
							background-color: #fff;
							color: #474b42;
							border: solid 1px;
						}
						+ * {
							margin-left: 10px;
						}
						&:hover{
							opacity: inherit;
							padding: 0px;
							.mask{
								width: 100%;
								height: 100%;
								background: rgba(0,0,0,0.3);
								transition: background 0.3s;
								.mask-img{
									//transition: opacity 0.3s;
									background: resolve("mask_set_s.png") no-repeat 0 0;
									width: 100%;
									height: 100%;
									background-position: center;
									opacity: 1;
								}
							}
						}
						.mask{
							width: 100%;
							height: 100%;
							transition: background 0.3s;
							background: rgba(255,255,255,0);
							.mask-img{
								transition: opacity 0.3s;
								background: resolve("mask_set_s.png") no-repeat 0 0;
								width: 100%;
								height: 100%;
								background-position: center;
								opacity: 0;
							}
						}
					}
				}
			}
		}
	}
}



