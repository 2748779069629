.contents-news{
	width: 49%;
	background-color: #f5f7f2;
	position: relative;
	padding-top: 77px;
	padding-bottom: 52px;
	display: block;
	margin-left: 10px;
	&:before{
		content: "";
		top: 10px;
		right: 3px;
		position: absolute;
		width: 99%;
		height: 99%;
		border-right: 2px solid #c6cfbc;
		border-bottom: 2px solid #c6cfbc;
		padding: 0;
		pointer-events: none;
	}
	&-article{
		$c:&;
		width: 415px;
		margin: 0 auto;
		background:resolve('line-black-dashed.png') no-repeat center bottom;
		background-size: size('line-black-dashed.png',2);
		margin-top: 0px;
		display: block;
		color: $black;
		&-info{
			padding-bottom: 10px;
		}
		&-title{
			font-size: 14px;
			margin-top: 5px;
			display: block;
			line-height: 23px;
			#{$c}-date {
				font-weight: 400;
			}
			a {
				color: #4f6537;
			}
		}
		&-date{
			font-family: "kreon_r";
			font-size: 12px;
			display: inline-block;
			letter-spacing: 1px;
		}
		&-desc {
			color: $black;
			font-size: 14px;
			line-height: (24 / 14);
			font-weight: 500;
		}
		& + &{
			margin-top: 15px;
		}
	}
}

@include breakpoint(sp) {
	.contents-news{
		width: 100%;
		background-color: #f5f7f2;
		position: relative;
		padding: 65px 0px 40px;
		margin: 0 auto;
		margin-top: 105px;
		&:before{
			content: "";
			top: 10px;
			right: 5px;
			position: absolute;
			width: 98%;
			height: 99%;
			border-right: 2px solid #c3c0b4;
			border-bottom: 2px solid #c3c0b4;
			padding: 0;
			pointer-events: none;
		}
		&-article{
			width: 85%;
			margin: 0 auto;
			border-bottom: 0;
			background:resolve('line-black-dashed.png') no-repeat center bottom;
			background-size: size('line-black-dashed.png',2);
			padding-bottom: 15px;
			&-info {
				padding-bottom: 0;
			}
			&-title{
				font-size: 13px;
				margin-top: 5px;
			}
			&-date{
				font-size: 12px;
				display: inline-block;
				letter-spacing: 1px;
			}
		}
	}
}
