.products-fixed-footer {
	position: fixed;
	width: 100%;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:center;
	border-top: 1px solid #d1d9c9;
	z-index: 10;
	> * {
		width: 50%;
		height: 45px;
		display: flex;
		justify-content:center;
		flex-wrap:wrap;
		align-items:center;
		color: #60744b;
		background-color: rgba(255,255,255,.8);
		font-size: 14px;
		letter-spacing: 1px;
		span {
			font-size: 15px;
			font-family: 'kreon_r';
			font-weight: normal;
		}
	}
	&-btn {
		font-size: 12px;
		font-weight: 700;
		background-color: rgba(104,96,62,.9);
		> * {
			color: #fff;
			display: block;
			// &:after {
			// 	content:"";
			// 	background:resolve('icon_cart_f.png') no-repeat left top;
			// 	background-size: size('icon_cart_f.png',2);
			// 	width:width('icon_cart_f.png',2);
			// 	height:height('icon_cart_f.png',2);
			// 	display: inline-block;
			// 	margin-left: 10px;
			// 	vertical-align: -1px;
			// }
		}
	}
}
.js-products-fixed-footer {
	display: none;
}
@include breakpoint(sp) {
}
