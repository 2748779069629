.thumbnail-item-horizontal{
	$c:&;
	position: relative;
	font-size: 14px;
	display: flex;
	justify-content:space-between;
	width: 100%;
	+ #{$c} {
		margin-top: 30px;
	}

	.thumbnail-item-linkmore {
		position: absolute;
		right: 40px;
		bottom:25px;
		width: auto;
	}

	&.mod-odd{
		color: #675f3e;
		background-color: #f8f7f2;
		border-bottom: 1px solid #c3c0b4;
		&:after{
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 5px 5px;
			border-color: transparent transparent #c3c0b4;
		}
		.thumbnail-item-linkmore {
			color: #68603e;
			&:after {
				content:"";
				display: inline-block;
				background:resolve('arrow_right_brown.png') no-repeat left top;
				background-size: size('arrow_right_brown.png',2);
				width:width('arrow_right_brown.png',2);
				height:height('arrow_right_brown.png',2);
				margin-left: 9px;
			}
		}
	}
	&.mod-even{
		position: relative;
		font-size: 14px;
		color: #4f6537;
		background-color: #f5f7f2;
		border-bottom: 1px solid #c6cfbc;
		&:after{
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 5px 5px;
			border-color: transparent transparent #c6cfbc;
		}
	}

	@at-root {
		#{$c}.mod-large_triangle {
			$imgwidth:306px;
			border-bottom: 0;
			#{$c}-text {
				font-size: 14px;
			}
			#{$c}-img{
				&.mod-1col{
					width:$imgwidth;
					min-height:190px;
				}
			}
			#{$c}-textarea{
				width: calc(100% - #{$imgwidth});
			}
			&.mod-odd,
			&.mod-even {
				&:after {
					border-width: 0 0 12px 12px;
				}
			}
		}
	}

	&.mod-photo-s {
		$imgwidth:299px;
		#{$c}-img.mod-1col {
			width: $imgwidth;
		}
		#{$c}-textarea{
			padding:24px 40px;
			width: calc(100% - #{$imgwidth});
		}
		#{$c}-text {
			padding-top: 7px;
		}
	}

	&-img{
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height:100%;
		display: block;
		&.mod-1col{
			width:300px;
			min-height:190px;
		}
		&.mod-img-on {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition:opacity .8s;
		}
		&:hover.mod-img-on{
			opacity: 1;
		}
	}
	&-textarea{
		width: 660px;
		padding:26px 39px;
	}
	&-text{
		padding-top: 5px;
		line-height: 2;
	}
	&-title{
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.1em;
		&-inner {
			display: flex;
			justify-content:flex-start;
			align-items:flex-start;
			.name {
				white-space: nowrap;
			}
		}
		.sep_pc {
			padding-left: 1em;
			padding-right: 1em;
		}
	}
}

.ie_10 {
	.thumbnail-item-horizontal-title-inner {
		span {
			display: block;
		}
		.name {
			max-width: 40%;
		}
		.part {
			max-width: 40%;
		}
	}
}

@include breakpoint(sp) {
	.thumbnail-item-horizontal{
		$c:&;
		position: relative;
		flex-direction:column;
		width: 100%;
		min-width: 100%;
		&-img{
			width: 100%;
			&.mod-1col{
				width: 100%;
			}
			&.mod-img-on {
				display: none;
			}
			&:hover {
				opacity: 1;
			}
		}
		&-textarea{
			width: 100%;
			padding:20px 20px;
		}
		&-text{
			line-height: (48 / 26);
		}
		&-title{
			p{
				font-size: 14px;
			}
			span {
				display: block;
			}
			.name {
				width: auto;
			}
		}

		@at-root {
			#{$c}.mod-large_triangle {
				border-bottom: 0;
				#{$c}-text {
					font-size: 13px;
				}
				#{$c}-img{
					&.mod-1col{
						width: 100%;
					}
				}
				#{$c}-textarea{
					width: 100%;
				}
				&.mod-odd,
				&.mod-even {
					&:after {
						border-width: 0 0 12px 12px;
					}
				}
			}
		}

		&.mod-photo-s {
			#{$c}-img.mod-1col {
				width: 100%;
				min-height: 0;
				height: 0;
				padding-top: 61.0714%;
			}
			#{$c}-textarea{
				padding:24px 0;
				width: 92.8571%;
				margin-left: auto;
				margin-right: auto;
			}
			#{$c}-text {
				padding-top: 7px;
			}
		}

		.thumbnail-item-linkmore {
			position: static;
			width: 100%;
			text-align: right;
			margin-top: 8px;
		}
	}
}
