// cart.on-the-slope.comのfooter用
// Railsを利用しないcart配下のHTMLでも利用するため、こちらに定義
.footer-cart {
  background-color: #eaefe5;
  padding-top: 32px;
  padding-bottom: 64px;
}
.footer-inner {
  font-size: 12px;
  letter-spacing: .1em;
  line-height: 1.8;
  max-width: 87.5%;
  margin-left: auto;
  margin-right: auto;
  a {
    color: #1c1f21;
  }
  .footer-cart-title {
    font-size :15px;
    font-weight: 700;
    text-align: center;
    > a {
      border-bottom: 1px solid #1c1f21;
      line-height: 1;
      padding-bottom: 5px;
      display: inline-block;
    }
  }
  .footer-cart-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > .list-interval::before {
      content: "|";
      display: inline-block;
      text-align: center;
      width: 10px;
      margin: 0px 3px;
    }
  }
}
.footer-list {
  display: flex;
  justify-content: center;
  gap: 12px;
  .footer-list-item {
    font-weight: 700;
    border-bottom: 1px solid;
  }
}
.pc-center {
  text-align: center;
}
@include breakpoint(sp) {
  .footer-inner {
    .footer-cart-title {
      text-align: initial;
    }
    .footer-cart-content {
      display: inline-block;
    }
    .footer-cart-content li {
      display: inline;
    }
  }
  .pc-center {
    text-align: initial;
  }
  .footer-list {
    justify-content: initial;
  }
}