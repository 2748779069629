.voice-icon {
	position: relative;
	&:after {
		content:"";
		display: inline-block;
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}
	&.mod-family {
		&:after {
			background:resolve('icon_voice_family.png') no-repeat left top;
			background-size: size('icon_voice_family.png');
			width:width('icon_voice_family.png');
			height:height('icon_voice_family.png');
		}
	}
	&.mod-female {
		&:after {
			background:resolve('icon_voice_female.png') no-repeat left top;
			background-size: size('icon_voice_female.png');
			width:width('icon_voice_female.png');
			height:height('icon_voice_female.png');
		}
	}
	&.mod-man {
		&:after {
			background:resolve('icon_voice_man.png') no-repeat left top;
			background-size: size('icon_voice_man.png');
			width:width('icon_voice_man.png');
			height:height('icon_voice_man.png');
		}
	}
	&.mod-pair {
		&:after {
			background:resolve('icon_voice_pair.png') no-repeat left top;
			background-size: size('icon_voice_pair.png');
			width:width('icon_voice_pair.png');
			height:height('icon_voice_pair.png');
		}
	}
}

.shop {
	.box-voice-icon {
		border-radius: 50%;
		width: 74px;
		height: 74px;
		background-color: #fff;
		display: inline-block;
	}
}
.box-voice {
	display: block;
	&-inner {
		position: relative;
		background-color: #fff;
		padding:5px 11px 4px;
		color: $black;
		font-size: 13px;
		font-weight: 400;
		line-height: (26 / 13);
		font-style: italic;
		border-right: 1px solid #c3c0b4;
		border-bottom: 1px solid #c3c0b4;
		justify-content:center;
		display: flex;
		flex-direction:column;
		flex-wrap:wrap;
		align-items:flex-start;
		& > * {
			width: 100%;
		}
		&:after {
			content:"";
			position: absolute;
			background:resolve('point_voice.png') no-repeat left top;
			background-size: size('point_voice.png',2);
			width:width('point_voice.png',2);
			height:height('point_voice.png',2);
			bottom: -18px;
			right: 20px;
		}
	}
	&-icon {
		margin-top: 24px;
		text-align: right;
		&:after {
			content:"";
			display: inline-block;
		}
		@extend .voice-icon;
	}

	&-date {
		text-align: right;
	}
}

.box-has-outer_line.mod-underline {
	.box-voice {
		width: 300px;
	}
}

.ie_10 {
	.box-has-outer_line-inner {
		width: 1000px;
		margin-left: auto;
		margin-right: auto;
	}
	.box-slash-colored + .box-has-outer_line-inner {
		width:300px;
	}
	.box-voice  {
		width:300px;
		display: block;
	}
}

@include breakpoint(sp) {
	.voice-icon {
		position: relative;
		&.mod-family {
			&:after {
				background:resolve('icon_voice_family.png') no-repeat left top;
				background-size: size('icon_voice_family.png',1.5);
				width:width('icon_voice_family.png',1.5);
				height:height('icon_voice_family.png',1.5);
			}
		}
		&.mod-female {
			&:after {
				background:resolve('icon_voice_female.png') no-repeat left top;
				background-size: size('icon_voice_female.png',1.5);
				width:width('icon_voice_female.png',1.5);
				height:height('icon_voice_female.png',1.5);
			}
		}
		&.mod-man {
			&:after {
				background:resolve('icon_voice_man.png',1.5) no-repeat left top;
				background-size: size('icon_voice_man.png',1.5);
				width:width('icon_voice_man.png',1.5);
				height:height('icon_voice_man.png',1.5);
			}
		}
		&.mod-pair {
			&:after {
				background:resolve('icon_voice_pair.png') no-repeat left top;
				background-size: size('icon_voice_pair.png',1.5);
				width:width('icon_voice_pair.png',1.5);
				height:height('icon_voice_pair.png',1.5);
			}
		}
	}
	.shop {
		.box-voice-icon {
			width: 50px;
			height: 50px;
		}
	}
	.box-voice {
		$c:&;
		display: none;
		&.active {
			display: block;
		}
		&-inner {
			line-height: (48 / 26);
			&:after {
				display: none;
			}
		}
		&-inner + &-icon {
			display: none;
		}&-icon {
			position: relative;
			border:1px solid #8a8261;
			border-radius: 50%;
			&.active {
				&:before {
					content:"";
					position: absolute;
					background:resolve('point_voice.png') no-repeat left top;
					background-size: size('point_voice.png',2);
					width:width('point_voice.png',2);
					height:height('point_voice.png',2);
					top:-26px;
					left:10px;
				}
				border:1px solid #fff;
			}
		}
		& + .icon_wrap {
			display: flex;
			justify-content:center;
			flex-wrap:wrap;
			#{$c}-icon {
				margin-left: 20px;
				margin-right: 20px;
			}
		}
	}
	.box-has-outer_line.mod-underline {
		.box-voice {
			width: 100%;
		}
	}
}
