.add-area{
	$c:&;
	position: relative;
	margin-top: 10px;
	padding-bottom: 55px;
	&.mod-confirm{
		padding-bottom: 0px;
	}
	&-info{
		position: relative;
		font-size: 14px;
		background:resolve('line-gray-dashed-@2x.png') repeat-x left bottom;
		background-size: size('line-gray-dashed-@2x.png',2);//retinaは第二引数に2
		border-color: #cdd2d5;
		padding-bottom: 22px;
		&.mod-confirm{
			border-top: 1px solid;
			border-bottom: 1px solid;
			padding: 10px 0;
			border-color: #cdd2d5;
			background-image: none;
		}
		&_title{
			font-weight: bold;
		}
		p {
			width: 65%;
			+ p{
				margin-top: 10px;
			}
		}
		#{$c}-btn-wrap{
			display: flex;
			justify-content: space-between;
			width: 195px;
			position: absolute;
			right: 0px;
			bottom: 31px;
			.box-link.mod-half.mod_no_arrow{
				width: 86px;
				height: 36px;
				min-width: auto;
			}
		}
		& + & {
			margin-top: 15px;
		}
	}
	.btn_wrap-around-center_arrow_left {
		position: absolute;
		font-size: 13px;
		bottom: 0;
		top: inherit;
		color: #000;
		@include opacity--anim(.6);
	}
	.btn_wrap-around-center_arrow_right {
		position: absolute;
		font-size: 13px;
		bottom: 0;
		right: 0;
		top: inherit;
		color: #000;
		@include opacity--anim(.6);
	}
	label{
		display:block;
		.radio-text{
			cursor: pointer;
			font-weight: 700;
			&:before{
				content: "";
				display: inline-block;
				background: url(../images/radio_button.png) no-repeat 0 0;
				width: 10px;
				height: 10px;
				margin-right: 6px;
			}
		}
	}
}
@include breakpoint(sp) {
	.add-area{
		$c:&;
		position: relative;
		margin-top: 10px;
		padding-bottom: 55px;
		&.mod-confirm{
			padding-bottom: 0px;
		}
		&-info{
			position: relative;
			font-size: 14px;
			padding-bottom: 5px;
			&.mod-confirm{
				border-top: 1px solid;
				border-bottom: 1px solid;
				padding: 10px 0;
				border-color: #cdd2d5;
			}
			&_title{
				font-weight: bold;
			}
			p {
				width: 100%;
				& + p{
					margin-top: 10px;
				}
			}
			#{$c}-btn-wrap{
				display: block;
				position: static;
				width: 100%;
				position: static;
				margin: 20px 0px;
				.box-link.mod-half.mod_no_arrow{
					width: 180px;
					height: 36px;
					line-height: 36px;
					border: 1px solid #000;
					margin: 20px auto 20px auto;
				}
			}
			& + & {
				margin-top: 15px;
			}
		}
	}
}
