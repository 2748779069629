.account-button:before {
	content: '';
	background : resolve('account-icon.png');
	background-size: size('account-icon.png',2);
	background-repeat: no-repeat;
	display: inline-block;
	width: 16px;
	height: 19px;
	position: relative;
	top: 10px;
	left: 21px;
	padding:10px 20px;
}
.address-button:before {
	content: '';
	background : resolve('address-icon.png');
	background-size: size('address-icon.png',2);
	background-repeat: no-repeat;
	display: inline-block;
	width: 20px;
	height: 15px;
	padding: 10px 0;
	padding-left: 19px;
	padding-right: 20px;
	position: relative;
	top: 10px;
	left: 19px;
}
.checkout {
	align-items: center;
	background-color: #4f6537;
	display: flex;
	padding-left: 58px;
	position: relative;
	margin-bottom: 29px;
	height: 40px;
	.text {
		color: #fff;
		display: flex;
		justify-content: flex-start;
		flex-wrap: nowrap;
		padding-right: 30px;
		font-weight: 700;
		font-size: 14px;
		&:before {
			content: "";
			background:resolve('en_icon.png') no-repeat left top;
			background-size: size('en_icon.png',2);
			width:width('en_icon.png',2);
			height:height('en_icon.png',2);
			position: absolute;
			top: 12px;
			left: 22px;
		}
		&::after {
			content: "";
			background:resolve('whitearrow-right.png') no-repeat left top;
			background-size: size('whitearrow-right.png',2);
			width:width('whitearrow-right.png',2);
			height:height('whitearrow-right.png',2);
			position: absolute;
			top: 16px;
			right: 21px;
		}
	}
}
.creditcard-button:before {
	content: '';
	background : resolve('credit-icon.png');
	background-size: size('credit-icon.png',2);
	background-repeat: no-repeat;
	display: inline-block;
	width: 16px;
	height: 20px;
	padding: 11px 26px;
	position: relative;
	top: 11px;
	left: 22px;
}
.creditcard-sub-text {
	padding:0!important;
	padding-top: 5px!important;
	padding-left: 8px!important;
	padding-bottom: 9px!important;
	font-size: 12px;
	font-weight: 400;
}


li.product-category-list.mypage-navlist  {
	margin-bottom: 40px;
	position: relative;
	p {
		font-family: 'kreon_r';
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.1em;
		padding-bottom: 7px;
	}
	ul.child {
		padding-top: 10px;
	}
	li {
		padding-top: 10px;
		position: relative;
		a {
			background-color: #f5f7f2;
			border: 1px dotted #1c1f21;
			display: block;
			span.mail-magazine-button {
				padding-top: 4px;
				padding-bottom: 3px;
			}
			span.creditcard-button {
				padding-top: 7px;
				line-height : (24 / 14);
			}
			span {
				align-items: center;
				display: flex;
				justify-content: flex-start;
				flex-wrap: nowrap;
				padding-right: 30px;
			}
	 	}
	 	&.mod-has_icon_down:before {
			content: "";
			background:resolve('btn-open-close-active.png') no-repeat left top;
			background-size: size('btn-open-close-active.png',2);
			width:width('btn-open-close-active.png',2);
			height:height('btn-open-close-active.png',2);
			display: block;
			right: 18px;
			top: 50%;
			position: absolute;
	 	}
	 	&.mod-has_icon_up:before {
			content: "";
			background:resolve('btn-open-close.png') no-repeat left top;
			background-size: size('btn-open-close.png',2);
			width:width('btn-open-close.png',2);
			height:height('btn-open-close.png',2);
			display: block;
			right: 18px;
			top: 50%;
			position: absolute;
	 	}
	}//li
}//ul
.logout-button:before {
	content: '';
	background : resolve('logout-icon.png');
	background-size: size('logout-icon.png',2);
	background-repeat: no-repeat;
	display: inline-block;
	width: 17px;
	height: 20px;
	position: relative;
	top: 10px;
	left: 21px;
	padding:10px 20px;
}
.mail-magazine-button:before {
	content: '';
	background : resolve('mail-icon.png');
	background-size: size('mail-icon.png',2);
	background-repeat: no-repeat;
	display: inline-block;
	width: 18px;
	height: 13px;
	padding: 10px 0;
	padding-left: 20px;
	padding-right: 21px;
	position: relative;
	left: 20px;
	top: 10px;
}

.history-button:before {
	content:"";
	background:resolve('history-icon.png') no-repeat left top;
	background-size: size('history-icon.png',2);//retinaは第二引数に2
	width:width('history-icon.png',2);
	height:height('history-icon.png',2);
	padding:10px 20px;
	display: inline-block;
	position: relative;
	left: 21px;
	top: 10px;
}
.ruotine-button:before {
	content:"";
	background:resolve('routine-icon.png') no-repeat left top;
	background-size: size('routine-icon.png',2);//retinaは第二引数に2
	width:width('routine-icon.png',2);
	height:height('routine-icon.png',2);
	padding:10px 20px;
	display: inline-block;
	position: relative;
	left: 21px;
	top: 10px;
}
.contact-button:before {
	content: "";
	background: resolve('contact-icon.png');
	background-size: size('contact-icon.png',2);
	width: 20px;
	height: 17px;
	background-repeat: no-repeat;
	display: inline-block;
	position: relative;
	top: 12px;
	padding: 10px 0;
  padding-left: 20px;
  padding-right: 21px;
  left: 20px
}
.teiki-button:before {
	content: "";
	display: inline-block;
	background: resolve('ico_teiki_delivery.png');
	background-size: size('ico_teiki_delivery.png',2);
	background-size: 21px 31px;
	background-repeat: no-repeat;
	width: 16px;
	height: 16px;
	position: relative;
	top: 3px;
	left: -1px;
	padding: 10px 20px;
	background-position: 50%;
}
.kimagure-button:before {
	content: "";
	display: inline-block;
	background: resolve('ico_kimagure.png');
	background-size: 21px 21px;
	background-repeat: no-repeat;
	width: 16px;
	height: 16px;
	position: relative;
	top: 1px;
	left: -1px;
	padding: 10px 20px;
	background-position: 50%;
}

/**
	お気に入り
*/

.like-button:before {
	content: '';
	background : resolve('icon-heart.png');
	background-size: 20px 19px;
	background-repeat: no-repeat;
	display: inline-block;
	width: 15px;
	height: 19px;
	position: relative;
	top: 10px;
	left: 18px;
	padding:10px 20px;
}
