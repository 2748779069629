.c-border-dotted{
	&:after{
		background-image: linear-gradient(90deg,#9d9d9d 4px,transparent 0,transparent 0);
		background-size: 6px 1px;
		background-repeat: repeat-x;
		content: '';
		display: inline-block;
		height: 12px;
		width: 100%;
	}
	.sp{
		display: none;
	}
}
@include breakpoint(sp) {
	.c-border-dotted{
		&.sp{
		}
	
	}
}