.table_min_list {
	background-color: #fff;
	border-top: solid 1px #c6cfbc;
	td {
		padding: 6px 11px;
		border-bottom: solid 1px #c6cfbc;
		+ td {
			background:resolve('line-user-table-green.png') repeat-y left top;
			background-size: size('line-user-table-green.png',2);
		}
		&:last-of-type {
			border-right: 0px;
		}
	}
	.week {
		font-weight: bold;
		width: 83px;
	}
	.place {
		width: 210px;
		padding-left: 18px;
		padding-right: 20px;
		font-size: 13px;
	}
}
@include breakpoint(sp) {

}
