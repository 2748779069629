html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend {
	background: none repeat scroll 0 0;
	border: 0 none;
	margin: 0;
	outline: 0 none;
	padding: 0;
	vertical-align: baseline;
}
body {
	position: relative;
	background-color: #fff;
	font-size: $base_font_size;
	font-weight: 500;
	color:$font_color;
	line-height:$line_height;
	@if $-webkit-text-size-adjust == false {-webkit-text-size-adjust: none;}
	overflow-wrap: break-word;
	min-width: 1080px;
	//overflow-y: hidden;
}
body.lower {
	&:before {
		content:"";
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		background:resolve('bg_lower_header.png') repeat center;
		background-size: size('bg_lower_header.png');//retinaは第二引数に2
		width:100%;
		height:250px;
	}
}
body.lower-bg {
	&:after {
		content:"";
		z-index: -1;
		position: absolute;
		bottom: 0;
		left: 0;
		background:resolve('bg_lower_footer.png') repeat center;
		background-size: size('bg_lower_footer.png');//retinaは第二引数に2
		width:100%;
		height:830px;
	}
	&.post-type-archive-articles,
	&.tax-articles_category {
		&:after {
			display: none;
		}
	}
}

main {
	display: block;
}


input[type="text"],input[type="textarea"],input[type="radio"],input[type="radio"],select{
	outline: none;
}

input[type="radio"]{
	position: absolute;
	opacity: 0;
	margin-right: 0;
	left: 0;
	top: 0;
}


select{
	border-radius: 0px;
	appearance: none;
	border-radius: 0;
	border: 0;
	margin: 0;
	padding: 0;
	background: none transparent;
	vertical-align: middle;
	font-size: inherit;
	color: inherit;
}
a.tel_link {
	color: inherit!important;
	cursor: default!important;
	border-bottom:none!important;
	text-decoration: none!important;
	&:hover {
		opacity: 1!important;
	}
}
@include breakpoint(sp) {
	body{
		min-width: 100%;
		&.lower {
			&:before {
				display: none;
			}
		}
		&.lower-bg {
			&:after {
				height: 35.3%;
			}
		}
	}
}

*{
	@if $-webkit-tap-highlight == false {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	}

	box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6{
	line-height: 1.2;
}

table, caption, tbody, tfoot, thead {
	margin: 0;
	outline: 0 none;
	padding: 0;
}

th, td {
	vertical-align: top;
	background: none repeat scroll 0 0;
	margin: 0;
	outline: 0 none;
	padding: 0;
}

p,span,div{
	line-height:inherit;
}


a {
	color:$link_color;
	text-decoration: none;
	img {
		border:none;
		outline:0 !important;
	}
}

a:hover {
	text-decoration: none;
	opacity: 0.6;
}

button:focus {outline:0 !important;}
button:active {
	outline:none;
}

a,p,img,div{
	&:focus {
		outline: none;
	}
}

ol, ul {list-style: none outside none;}

fieldset, img {vertical-align: bottom;}

blockquote, q {quotes: none;}

blockquote:before, blockquote:after, q:before, q:after {content: none;}
ins {text-decoration: none;}
del {text-decoration: line-through;}
table {border-collapse: collapse;border-spacing: 0;}
br {letter-spacing: 0;}
hr {border: 0 none;height: 0;visibility: hidden;}
select, input, textarea {font-size: 99%;}
pre, code {font: 100% monospace;}

img {
	max-width: 100%;
	height: auto;
}

input[type="submit"],input[type="text"],textarea {
	-webkit-appearance:none;
}

.base_width {
	width: 960px;
	margin-left: auto;
	margin-right: auto;
	@include breakpoint(sp){
		width: 87.5%;
	}
}

.pc,
.pc-content {
  @include breakpoint(sp) {
    display: none;
  }
}

.sp,
.sp-content {
  display: none;
  @include breakpoint(sp) {
    display: block;
  }
}
.main {
	position: relative;
}

.anchor,
.common-component .post-content * + .anchor {
	padding-top: 150px;
	margin-top: -150px;
	display: block;
	height: 1px;
	width: 100%;
	z-index: -1;
	position: relative;
	@include breakpoint(sp) {
		padding-top: 120px;
		margin-top: -120px;
	}
}

@include breakpoint(sp){
	#responsive_flg {
		display: none;
	}
}

/*clearfix*/
.clearfix:after{
	content:".";
	display: block;
	height:0px;
	clear:both;
	line-height:0;
	visibility:hidden;
}
.clearfix {
	*zoom:1;
}

.clearboth{
	clear: both;
}

