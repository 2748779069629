.header-scl{
	width: 100%;
	position: fixed;
	top: 0px;
	background-color: rgba(255,255,255,0.8);
	display: none;
	z-index: 999999;
	&-nav {
		display: flex;
		justify-content:flex-end;
		flex-wrap:wrap;
		align-items:center;
	}
	.nav-current {
		color: #4f6537;
		* {
			color: #4f6537;
		}
	}
	&.mod-shop {
		.lang-btn {
			font-weight: normal;
			border:1px solid $black;
			border-right:1px solid $black;
			font-size: 12px;
		}
	}
}
