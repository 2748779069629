.header-list{
	font-size: 0px;
	& li{
		font-size: 12px;
		letter-spacing: 1px;
		display: inline-block;
		letter-spacing: 1px;

	}
}
