.s_product{
	.area-has-deviated_line {
		$c:&;
		position: relative;
		background-color: #f5f7f2;
		padding:69px 82px;
		max-width: 660px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		+ #{$c} {
			margin-top: 140px;
		}
		&:before {
			content: "";
			top: 10px;
			right: 5px;
			position: absolute;
			width: 100%;
			height: 99%;
			border-right: 2px solid #c6cfbc;
			border-bottom: 2px solid #c6cfbc;
			padding: 0;
			pointer-events: none;
			padding-bottom: 3px;
		}
		&:nth-child(odd) {
			background-color: #f8f7f2;
			&:before {
				border-right: 2px solid #c3c0b4;
				border-bottom: 2px solid #c3c0b4;
			}
		}
		&-slide-arrow-left{
			position: absolute;
			z-index: 999;
			background: resolve("arrow-left-area-has-deviated_line-slide.png") no-repeat 0 0;
			width: width("arrow-left-area-has-deviated_line-slide.png");
			height: height("arrow-left-area-has-deviated_line-slide.png");
			background-position: center;
			top: 49%;
			left: -24px;
		}
		&-slide-arrow-right{
			position: absolute;
			z-index: 999;
			background: resolve("arrow-right-area-has-deviated_line-slide.png") no-repeat 0 0;
			width: width("arrow-right-area-has-deviated_line-slide.png");
			height: height("arrow-right-area-has-deviated_line-slide.png");
			background-position: center;
			top: 49%;
			right: -24px;
		}
		&-introduction {
			@extend .clearfix;
		}
		&-slide {
			float: none;
			width: 100%;
			position: relative;
			&-main {
				position: relative;
				width: 100%;
				height: 430px;
				& > * {
					padding:0;
					margin: 0;
					width: 100%;
					height: 430px;
					background-repeat:no-repeat;
					background-position:center center;
					background-size:cover;
					opacity: 0;
					position: absolute;
					transition:opacity .6s;
					&.active {
						opacity: 1;
					}
				}
				&-label{
					position: absolute;
					font-size: 14px;
					font-weight: bold;
					right: 0px;
					bottom: 0px;
					text-align: center;
					color: #000;
					width: 120px;
					display: table;
					padding: 20px 0px;
					background-color: red;
					background-color: hsla(0,0%,100%,.6);
				}
			}
			&-ctrl {
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
				margin-top: 17px;
				&:after {
					padding:0;
					margin: 0;
					width: 98px;
					height: 0;
					display: block;
				}
				& > * {
					margin-bottom: -5px;
					cursor: pointer;
					padding:0;
					margin: 0;
					margin-bottom: 5px;
					width: 98px;
					height: 60px;
					background-repeat:no-repeat;
					background-position:center center;
					background-size:cover;
					@include opacity--anim(.5);
					transition:opacity .6s;
					&.active {
						opacity: .5;
					}
					&.empty {
						height: 0;
						padding:0;
						margin: 0;
					}
				}
			}
		}
		&-explain {
			float: right;
			width: 437px;
			color: #1c1f21;
			font-size: 14px;
			font-weight: 500;
			line-height: (28 / 14);
			margin-right: 4px;
		}
		&-infomation {
			margin-top: 41px;
			background: #fff;
			position: relative;
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
		}
		&-sns {
			position: absolute;
			right:416px;
			top: -15px;
			& > * {
				margin-left: 10px;
				@include opacity--anim(.5);
			}
		}
		&-detail {
			width: 420px;
			padding:22px 30px;
			min-height: 225px;
			display: flex;
			justify-content:flex-start;
			flex-wrap:wrap;
			align-items:center;
		}
		&-detail_table {
			width: 100%;
			th {
				width: 80px;
				color: #1c1f21;
				font-size: 13px;
				font-weight: 700;
				line-height: 2;
				letter-spacing: 1.3px;
				text-align: left;
			}
			td {
				color: #1c1f21;
				font-size: 13px;
				font-weight: 500;
				line-height: 2;
				//letter-spacing: 1.3px;
			}
		}
		&-map {
			width: 380px;
			height: 100%;
			position: absolute;
			border:2px solid #fff;
			right: 0;
			top: 0;
		}
		.js-map {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}
}


@include breakpoint(sp) {
.s_product{
	.area-has-deviated_line-slide-arrow-right,
	.area-has-deviated_line-slide-arrow-left {
		display: none;
	}
	.area-has-deviated_line {
		$c:&;
		position: relative;
		background-color: #f5f7f2;
		padding:69px 82px;
		max-width: 660px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		& + #{$c} {
			margin-top: 140px;
		}
		&:before {
			content: "";
			top: 10px;
			right: 5px;
			position: absolute;
			width: 100%;
			height: 99%;
			border-right: 2px solid #c6cfbc;
			border-bottom: 2px solid #c6cfbc;
			padding: 0;
			pointer-events: none;
			padding-bottom: 3px;
		}
		&:nth-child(odd) {
			background-color: #f8f7f2;
			&:before {
				border-right: 2px solid #c3c0b4;
				border-bottom: 2px solid #c3c0b4;
			}
		}
		&-slide {
			float: none;
			width: 100%;
			&-main {
				position: relative;
        width: 100%;
        height: 0;
				padding-top: 65.1515%;
				& > * {
					padding:0;
					margin: 0;
					width: 100%;
					height: 0px;
					padding-top: 65.1515%;
					background-repeat:no-repeat;
					background-position:center center;
					background-size:cover;
					opacity: 0;
          position: absolute;
          top: 0;
					transition:opacity .6s;
					&.active {
						opacity: 1;
					}
				}
				&-label{
					font-size: 12px;
					font-weight: bold;
					position: absolute;
					right: 0px;
					bottom: 0px;
					text-align: center;
					color: #000;
					width: 120px;
					display: table;
					padding: 5px 0px;
					background-color: red;
					background-color: hsla(0,0%,100%,.6);
				}
			}
			&-ctrl {
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
				margin-top: 25px;
				&:after {
					padding:0;
					margin: 0;
					width: 98px;
					height: 0;
					display: block;
				}
				& > * {
					margin-bottom: -5px;
					cursor: pointer;
					padding:22% 0 0;
					margin: 0 0 5px 0;
					width: 32.1428%;
					height:0;
					background-repeat:no-repeat;
					background-position:center center;
					background-size:cover;
					@include opacity--anim(.5);
					&.active {
						opacity: .5;
					}
				}
			}
		}

	}
}
}
