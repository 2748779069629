.bx-slider-wrapper{
	margin-top: 55px;
}

.bx-wrapper{
	border-width: 0px !important;
	box-shadow: none !important;
}

.bx-viewport{
	height: auto!important;
}
