.thumbnail-item-has_line_title {
	$c:&;
	@at-root {
		a#{$c} {
			@include opacity--anim(.5);
		}
	}
	&.mod-even {
		#{$c}-title {
			color: #4f6537;
			&:after {
				background-color: #f5f7f2;
			}
		}
	}
	&-img {
		width: 100%;
		height: 140px;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
	}
	&-title {
		color: #68603e;
		font-size: 16px;
		font-weight: 700;
		line-height: 28px;
		position: relative;
		padding:3px 0 3px 30px;
		&:after {
			content:"";
			position: absolute;
			background-color: #f8f7f2;
			width:30px;
			height: 100%;
			left: 10px;
			top: 0;
			z-index: -1;
		}
	}
}
@include breakpoint(sp) {
	.thumbnail-item-has_line_title {
		$c:&;
		&-img {
			width: 100%;
			height: 0;
			padding-top: 58.9090%;
		}
		&-title {
			color: #68603e;
			font-size: 13px;
			font-weight: 700;
			line-height: 1.8;
			padding-left: 22px;
			&:after {
				width: 22px;
			}
		}
	}
}
