.text-bg-line{
	font-size: 16px;
	font-weight: bold;
	font-style: italic;
	background-color: #e4dfdf;
	color: #68603e;
	opacity: 0.8;
	line-height: 1.3;
	padding: 0 10px;
	display: inline-block;
}

@include breakpoint(sp) {
}
