.header{
	position: relative;
	&-logo-scl {
		display: block;
		padding-left: 10px;
	}
	&-inner{
		&-scl{
			top: 0;
			max-width: 1160px;
			min-width: 1080px;
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
			width: 100%;
			margin: 0 auto;
			align-items:center;
			&-nav {
				display: flex;
				justify-content:flex-end;
				flex-wrap:wrap;
				align-items:center;
			}
		}
	}
}

.current-menu-item {
	a {
		cursor: default;
		color: #4f6537!important;
		&:hover {
			opacity: 1;
		}
	}
}
