.article-footer + .title.mod-triangle-above {
	margin-top: 180px;
}

.title.mod-triangle-above + .glid.mod-transform-img {
	margin-top: 15px;
	width: 100%;

	.thumbnail-transform-item.mod-odd,
	.thumbnail-transform-item.mod-even {
		border-bottom: none;
		&:after {
			display: none;
		}
	}
}
@include breakpoint(sp) {
	.article-footer + .title.mod-triangle-above {
		margin-top: 90px;
	}
	.title.mod-triangle-above + .glid.mod-transform-img {
		padding: 0;
		.thumbnail-transform-item-label-title {
			font-size: 14px;
			text-align: center;
		}
	}

	.single-articles .page-header {
		margin-top: 0;
	}
}
