.routine-appeal {
	margin-top: 72px;
	display: flex;
	justify-content:space-between;
	flex-wrap:wrap;
	.post-content {
		margin-top: 0;
		h3+* {
			margin-top: 19px;
		}
	}
	&-img {
		width: 50%;
		height: 300px;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		right: 127px;
		position: relative;
		z-index: 1;
		&:after {
			content:"";
			position: absolute;
			width: 86%;
			height: 100%;
			border-right:  1px solid #c6cfbc;
			border-bottom: 1px solid #c6cfbc;
			z-index: -1;
			top: 4px;
			right: -5px;
		}
	}
	&-textarea {
		width: 50%;
	}
	&-textarea-inner {
		margin-left: -60px;
	}
}
@include breakpoint(sp) {
	.routine-appeal {
		margin-top: 90px;
		display: block;
		.post-content {
			margin-top: 0;
			h3+* {
				margin-top: 19px;
			}
		}
		&-img {
			width: 100%;
			height: 0;
			padding-top: 37.5%;
			right: 0;
			&:after {
				display: none;
			}
		}
		&-textarea {
			width: 87.5%;
			margin-top: 30px!important;
			margin-left: auto;
			margin-right: auto;
		}
		&-textarea-inner {
			margin-left: 0;
		}
	}
}
