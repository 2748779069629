.contents-month{
	background-color: #f5f7f2;
	&.mod-brown{
		background-color: #f8f7f2;
		.month_title{
			color: #68603e;
		}
		.month_desc{
			color: #68603e;
		}
	}
	&_inner{
		display: flex;
		max-width: 960px;
		justify-content: space-between;
		position: relative;
		padding-top: 55px;
		padding-bottom: 60px;
		margin-left: auto ;
		margin-right: auto;
		&_arrow_left{
			content: '.';
			background: resolve("arrow_contents-month_left.png",2) no-repeat 0 0;
			width: width("arrow_contents-month_left.png");
			height: height("arrow_contents-month_left.png");
			background-size: 50%;
			background-position: center;
			position: absolute;
			top: 45%;
			left: -125px;
			transform: translateY(-50%);
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
			cursor: pointer;

		}
		&_arrow_right{
			content: '.';
			background: resolve("arrow_contents-month_right.png",2) no-repeat 0 0;
			width: width("arrow_contents-month_right.png");
			height: height("arrow_contents-month_right.png");
			background-size: 50%;
			background-position: center;
			position: absolute;
			top: 45%;
			right: -120px;
			transform: translateY(-50%);
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
			cursor: pointer;
		}
	}
	&-info-wrap{
		font-size: 16px;
		color: #4f6537;
		width: 400px;

		.box-link{
			font-size: 13px;
			font-weight: bold;
			width: 100%;
			margin-top: 20px;
			color: #000;
			height: 40px;
			border-right: solid 1px #000;
			border-bottom: solid 1px #000;
			text-align: center;
			line-height: 40px;
			display: block;
			position: relative;
			letter-spacing: 1.3px;
			transition: opacity .6s;
			opacity: 1;
			cursor: pointer;
			&.mod_no_arrow{
			background: #fff;
				&::after{
				height: 0px;
				}
			}
			&.mod-half{
				width: 170px;
				min-width: 170px;
				margin-top: 29px;
			}
			&:hover{
				transition: opacity .6s;
				opacity: 0.6;
			}
		}
	}
	.month_title{
		font-size: 18px;
		font-weight: bold;
		@extend .font-kreon_r;
		letter-spacing: 3px;
		.ext_title{
			font-size: 28px;
			font-weight: normal;
			letter-spacing: 2.8px;
			margin-right: 5px;
		}
		.ext_ja {
			@extend .font-yu_go;
			font-size: 18px;
			letter-spacing: 1.8px;
			font-weight: bold;
		}
	}
	.month_desc{
		font-size: 16px;
		margin-top: 6px;
		line-height: 2;
	}
	&_set-items_header{
		@extend .font-kreon_r;
		font-size: 14px;
		letter-spacing: 1px;
		font-weight: bold;
		color: #000;
		border-bottom: solid 1px #000;
		padding-bottom: 10px;
		letter-spacing: 1px;
		margin-top: 28px;
		padding-bottom: 10px;
	}
	&_set-items{
		font-size: 13px;
		color: #000;
		margin-top: 15px;
		padding-bottom: 15px;
		border-bottom: solid 1px #cdd2d5;
		.list_header{
			padding-left: 10px;
			background-position: left 3px;
		}
		&-wrap-inner {
			display: none;
			&.active {
				display: block;
			}
		}
	}
	&_set-items_btn{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 90%;
		margin: 0 auto;
	}
	&-thumbail-wrap{
		width: 520px;
		margin-top: 5px;
	}
}

@include breakpoint(sp) {
	.contents-month{
		background-color: #fff;
		&.mod-green{
			background-color: #fff;
		}
		&_set-items_header {
			padding-bottom: 7px;
		}
		&_inner{
			width: 87.5%;
			display: block;
			padding: 10px 0;
			margin-left: auto;
			margin-right: auto;
			&_arrow_left{
				display: none;
			}
			&_arrow_right{
				display: none;
			}
		}
		&-info-wrap{
			width: 100%;
			.month_title{
				font-size: 15px;
				font-weight: bold;
				letter-spacing: 2px;
				.ext_title{
					font-size: 20px;
					margin-right: 0px;
				}
				.ext_ja {
					font-size: 15px;
					letter-spacing: 1.5px;
				}
			}
			.month_desc{
				font-size: 14px;
			}
			.box-link{
				&:hover:after{
					right: -20px;
				}
				&.mod_no_arrow{
					background: #fff;
					&::after{
					height: 9px;
					}
				}
				&.mod-half{
					width: 100%;
					margin-top: 29px;
					min-width: 100%;
				}
			}
		}
		&-thumbail-wrap{
			width: 100%;
			margin-top: 5px;
		}
	}
}
