.search-form {
	margin-top: 33px;
	position: relative;
	input[type="text"] {
		-webkit-appearance:none;
		border-width: 1px;
		border-color: #1c1f21;
		border-style: solid;
		border-radius:0;
		background-color: #ffffff;
		color: #1c1f21;
		font-size: 12px;
		font-weight: 500;
		line-height: 1;
		padding: 4px 7px;
		width: 100%;
		padding-right: 17px;
		&:focus {
			outline: 0;
		}
	}
	input[type="submit"] {
		background:resolve('icon_search.png') no-repeat left center;
		background-size: size('icon_search.png',2);//retinaは第二引数に2
		width:width('icon_search.png',2);
		height:height('icon_search.png',2);
		position: absolute;
		position:absolute;
		top:50%;
		height: 100%;
		transform:translate(0,-50%);
		right: 7px;
		@include text-indent-none();
		border:none;
		-webkit-appearance:none;
		border-radius:0;
		cursor: pointer;
	}
	&.mod-article-renew{
		margin-top: 0;
		margin-bottom: 30px;
	}
}
.search-result {
	position: absolute;
	color: $black;
	font-size: 14px;
	right: 0;
	bottom: -78px;
}
@include breakpoint(sp) {
	.search-form {
		margin-top: 25px;
		input[type="submit"] {
			padding:0;
		}
		input[type="text"] {
			font-size: 16px;
		}
	}
}
