.article-sns{
	margin:50px 0 30px;
	&-cont{
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}
	&-items{
		display: flex;
		justify-content: center;
		margin-left: 10px;
	}
	&-item{
		margin: 0 5px;
		&.mod-copy{
			position: relative;

			&:hover{
				cursor: pointer;
				opacity: 0.7;
			}
			&:before{
				content: "copied";
				display:block;
				background-color: rgba(240,240,240,1);
				padding:5px;
				position: absolute;
				top:-5px;
				left:50%;
				font-size: 10px;
				line-height: 1;
				transform:translate(-50% ,-100%);
				font-weight: bold;
				border-radius: 3px;
				opacity: 0;
				transition:0.15s ease-in;
			}
			&.is-copied:before{
				opacity: 1;
			}
		}
	}
	&-txt{
		font-size: 12px;
		text-align: center;
		font-weight: 700;
		line-height: 1;
	}
}
@include breakpoint(sp) {
	.article-sns{
		&-cont{

		}
	}
}