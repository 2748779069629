.box-link-cat_faq {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	color: #4f6537;
	border: solid 1px #4f6537;
	margin-bottom: 125px;
	position: relative;
	z-index: 3;
	nav{
		width: 100%;
	}
	ul{
		width: 100%;
		font-size: 0px;
	}
	.box-link-cat-item{
		font-size: 14px;
		font-weight: bold;
		color: #4f6537;
		letter-spacing: 2px;
		display: inline-block;
		text-align: left;
		width: 33.3333%;
		position: relative;
		border-right: solid 1px #4f6537;
		margin: 13px 0px;
		padding-left: 20px;
		padding-right: 40px;
		&:after{
			content: "";
			position: absolute;
			top: 6px;
			right: 20px;
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7.5px 0;
			border-color: #c3c0b4 transparent transparent;
		}
		&:nth-of-type(3n){
			border-right: solid 0px #4f6537;
		}
		a{
			color: #4f6537;
		}

	}
	.box-link-cat-item_separate{
		border-top: 1px solid #4f6537;
		width: 98%;
		margin: 0 auto;
	}
	.search-form {
		position: absolute;
		margin-top: 0;
		right: 0;
		bottom: -50px;
	}
	&.no_results {
		border:none;
		.search-form {
			bottom: -30px;
		}
		.search-result {
			bottom: -58px;
		}
		> nav {
			display: none;
		}
	}
}


@include breakpoint(sp) {
	.box-link-cat_faq{
		width: 100%;
		margin-bottom: 100px;
		.box-link-cat-item{
			font-size: 13px;
			display: block;
			width: 100%;
			position: relative;
			border-right: solid 0px #4f6537;
			margin: 13px 0px;
			padding-left: 20px;
			padding-right: 40px;
			&:after{
				content: "";
				position: absolute;
				top: 6px;
				right: 20px;
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 7.5px 0;
				border-color: #c3c0b4 transparent transparent;
			}
			&:nth-of-type(3n){
				border-right: solid 0px #4f6537;
			}
			a {
				color: #4f6537;
			}
		}
		.box-link-cat-item_separate{
			border-top: 1px solid #4f6537;
			width: 95%;
			margin: 0 auto;
		}
		.search-form {
			width: 210px;
		}
	}
}
