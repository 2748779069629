.thumbnail_2col{
	width: 230px;
	color: #000;
	border-right: 1px solid #c3c0b4;
	border-bottom: 1px solid #c3c0b4;
	padding-top: 8px;
	padding-bottom: 10px;
	font-size: 0px;
	font-size: 0;
	display: flex;
	justify-content: start;
	align-items: center;
	.inner_text{
		margin-left: 17px;
		text-align: center;
		&.mod-short-margin-left{
			margin-left: 10px;
		}
	}
	span.mod-jp{
		font-size: 15px;
		font-weight: bold;
		letter-spacing: 2px;
		line-height: 1.2;
	}
	span.mod-en{
		font-size: 17px;
		font-family: kreon_r;
		font-weight: normal;
		letter-spacing: 2px;
	}
	&.mod_ichinichi{

	}
	&.sp{
		display:none;
	}
	&.mod-text-center {
		.inner_text {
			width: 100%;
			text-align: center;
			margin-left: 0!important;
		}
	}
}

@include breakpoint(sp) {
	.thumbnail_2col{
		width: 49%;
		color: #000;
		height: 80px;
		border-right: 1px solid #c3c0b4;
		border-bottom: 1px solid #c3c0b4;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-top: 10px;
		font-size: 0px;
		font-size: 0;
		display: flex;
		justify-content: space-around;
		align-items: center;
		.inner_text{
			margin-left: 0px;
		}
		span.mod-jp{
			font-size: 13px;
			font-weight: bold;
		}
		span.mod-en{
			font-size: 15px;
			font-family: kreon_r;
			font-weight: normal;
		}
		&.mod_ichinichi{
			width: 85%;
			margin: 0 auto;
			border-right: 0px;
			.inner_text{
				position: relative;
				&.mod-triangle{
					&::after{
						content: ".";
						display: inline-block;
						margin-left: 25px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 5px 0 5px;
						border-color: #c3c0b4 transparent transparent transparent;
					}
				}
			}
		}
		&.sp{
			display: flex;
		}
	}
}
