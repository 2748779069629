.c-template-label{
	font-size: 12px;
	border-radius: 13px;
	border: 1px solid;
	text-align: center;
	font-weight: 700;
	// padding:0 19px;
	width: 140px;
	line-height: 1.7;
	display: inline-block;
	// padding-bottom: 3px;
	margin-bottom: 3px;

	&._small{
		font-size: 11px;
		line-height: 1.8;
		width: auto;
		// padding:0 13px;
		padding-left: 13px;
		padding-right: 13px;
		margin: 1px 2px;
	}
	&._ex_small{
		font-size: 11px;
		line-height: 1.6;
		width: auto;
		padding-left: 10px;
		padding-right: 10px;
		margin: 0 2px 0 0;
	}
	&._simple{
		color: #333;
		border-top:none;
		border-left:none;
		border-right:none;
		border-radius:inherit;
		border-bottom: 1px solid #000;
		padding-right: 0;
		padding-left:0;
		width: auto;
		font-size:12px;
		letter-spacing: 0.05em;
		line-height: 1.4;
	}
	&._mod-category{
		// line-height: 1;
		border: none;
		text-decoration: underline;
	}
}
@include breakpoint(sp) {
	.c-template-label{
		font-size: 11px;
		margin-top: 2px;
		&._small{
			font-size: 9.5px;
			line-height: 1.5;
			width: auto;
			padding: 2px 10px 0;
		}
		&._simple{
			font-size: 11px;
			padding:0;
		}
	}
}