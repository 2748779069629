.article_shisin {
	width: 100%;
	font-size: 14px;
	p {
		letter-spacing: 0px;
		text-align: justify;
	}
	&-text{
		line-height: 27px;
	}
	&-space{
		height: 30px;
	}
	.box-triangle{
		position: relative;
		background-color: #f8f7f2;
		margin-top: 53px;
		padding-bottom: 20px;
		h3{
			font-size: 20px;
			font-style: italic;
			letter-spacing: 3px;
			padding-top: 25px;
			color: #675f3e;
			text-align: center;
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			border-top: 20px solid #c3c0b4;
			border-right: 20px solid transparent;
		}
		ul{
 			margin: 20px 80px 0px 150px;
 			a{
 				display: block;
 			}
			li span{
				font-size: 14px;
				font-weight: bold;
				font-style: italic;
				color: #675f3e;
				letter-spacing: 2px;
				margin-right: 9px;
				& + span{
					font-size: 24px;
					font-weight: normal;
					font-family: kreon_r;
					letter-spacing: 3px;
					margin-right: 15px;
					& + span{
						font-size: 16px;
						font-weight: bold;
						font-family: inherit;
						letter-spacing: 3px;
					}
				}
			}
		}
	}// End .box-triangle
	.box-shadow{
		width: 959px;
		margin-top: 133px;
		padding: 57px 135px 33px 163px;
		letter-spacing: 0px;
		font-size: 14px;
		border-right: 1px solid #c3c0b4;
		border-bottom: 1px solid #c3c0b4;
		&.mod-green{
			border-right: 1px solid #c6cfbc;
			border-bottom: 1px solid #c6cfbc;
		}
		&:after{
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: #f5f7f2;
			z-index: -10;
			top: 5px;
			left: 6px;
		}
		& p{
			letter-spacing: 0px;
			line-height: 26px;
		}
		& ul {
			margin-top: 4px;
			& li{
				line-height: 28px;
			}
			& p{
				background: resolve("text-list-glay.png")  left no-repeat;
				padding-left: 19px;
				background-position: 3px 10px;
			}
		}
	}// End .box-shadow
	&.mod-margin-top{
		margin-top: 125px;
	}
	&_header-img{
		background: resolve("shishin_2.png") no-repeat 0 0;
		width: width("shishin_2.png",2);
		height: height("shishin_2.png",2);
		background-size: cover;
		background-position: center;
	}
}
@include breakpoint(sp) {
	.article_shisin {
		width: 90%;
		margin: 0 auto;
		font-size: 14px;
		p {
			letter-spacing: 0px;
		}
		&-text{
			line-height: 27px;
		}
		&-space{
			height: 30px;
		}
		.box-triangle{
			position: relative;
			background-color: #f8f7f2;
			margin-top: 40px;
			 padding: 15px;
			& h3{
				font-size: 20px;
				font-style: italic;
				letter-spacing: 3px;
				padding-top: 25px;
				color: #675f3e;
				text-align: center;
			}
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				border-top: 16px solid #c3c0b4;
				border-right: 16px solid transparent;
			}
			ul{
				margin-top: 20px;
				margin-left: auto;
				margin-right: auto;
				padding-bottom: 20px;
				li {
					margin-top:  20px;
				}
				li span{
					font-size: 14px;
					font-weight: bold;
					font-style: italic;
					color: #675f3e;
					letter-spacing: 2px;
					margin-right: 9px;
					& + span{
						font-size: 24px;
						font-weight: normal;
						font-family: kreon_r;
						letter-spacing: 3px;
						margin-right: 15px;
						& + span{
							font-size: 16px;
							font-weight: bold;
							font-family: inherit;
							letter-spacing: 3px;
							margin-right: 0px;
						}
					}
				}
			}
		}// End .box-triangle
		.box-shadow{
			width: 100%;
			margin-top: 75px;
			padding: 10px;
			letter-spacing: 0px;
			font-size: 14px;
			border-right: 1px solid #c3c0b4;
			border-bottom: 1px solid #c3c0b4;
			padding-right: 15px;
			padding-bottom: 15px;
			&:after{
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				background-color: #f5f7f2;
				z-index: -10;
				top: 5px;
				left: 5px;
			}
			& p{
				letter-spacing: 0px;
				line-height: 26px;
			}& ul {

				margin-top: 4px;
				& li{
					line-height: 28px;
				}
				& p{
					background: resolve("text-list-glay.png")  left no-repeat;
					padding-left: 19px;
					background-position: 3px 10px;
				}
			}
		}// End .box-shadow
		&.mod-margin-top{
			margin-top: 50px;
		}
		&_header-img{
			width: 113%;
			margin-left: -21.250px;
			padding-top: 44.53125%;
			height: 0px;
		}
	}
}
