.box-triangle {
	position: relative;
	background-color: #f8f7f2;
	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		border-top: 20px solid ;
		border-right: 20px solid transparent;
		color: #c3c0b4;
	}
	&.mod-green{
	background-color: #f5f7f2 !important;
		&::before{
			color: #c6cfbc !important;
		}
	}
}

@include breakpoint(sp) {
	.box-triangle{

	}
}
