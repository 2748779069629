.table-basic {
	width: 100%;
	border-bottom: 1px solid #cdd2d5;
	color:#1c1f21;
	th,td {
		font-size: 13px;
		line-height: 2;
		border-top: 1px solid #cdd2d5;
		padding:8px 8px 6px 20px;
	}
	td {
		padding-left: 0;
	}
	th {
		text-align: left;
	}
	a {
		color:#4f6537;
		border-bottom: 1px solid #4f6537;
		padding-bottom: 1px;
		text-decoration: none;
	}
	&.mod-mt-large {
		margin-top: 20px;
	}
	&.mod-recruit {
		margin-top: 21px;
		td {
			padding-left: 41px;
		}
	}
}
@include breakpoint(sp) {
	.table-basic {
		th,td{
			padding:0.7em 0px 0.5em;
			line-height: (48 / 26);
		}
		th{
			text-align: left;
			min-width: 60px;
		}
		td{
			padding-left: 10px;
		}

		&.mod-mt-large {
			margin-top: 0;
		}
		&.mod-recruit {
			margin-top: 21px;
			td {
				padding-left: 10px;
			}
		}
	}
}
