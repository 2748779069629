.header-under-layer{
	&-navi{
		text-align: right;
		display: inline-block;
	}
	&-inner{
		width: 1040px;
		margin-right: auto;
		margin-left: auto;
		padding-top: 25px;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
	}
	&-sub-navi {
		font-size: 0px;
		&-list {
			&-item {
				position: relative;
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 1px;
				display: inline-block;
				letter-spacing: 1px;
				padding-bottom: 18px;
			}
			&-child {
				position: absolute;
				left: -23px;
				text-align: left;
				color: $brown;
				font-family: kreon_r;
				width: 125px;
				padding: 8px 0;
				top: 28px;
				border-bottom-width: 0;
				border-right: 1px solid $brown;
				border-bottom: 1px solid $brown;
				background-color: #fff;
				display: none;
				z-index: 10;
				.font-kreon_r {
					font-size: 14px;
					letter-spacing: 1.4px;
				}
				&-item {
					position: relative;
					line-height: 1.5;
					padding-left: 23px;
					&:hover {
						background-image: resolve('sub-navi-hover.png');
						background-repeat: no-repeat;
						background-position: 10px;
					}
					a{
						color: #68603e !important;
					}
				}
				&-item + &-item {
					margin-top: 8px;
				}
			}
			a {
				color: #000;
			}
		}
	}
}
@media only screen and (max-width:1129px){
	.header-under-layer-inner {
		padding-right: 50px;
	}
}
