.split{
	position:relative;
	width: 20px;
	height: 10px;
	margin: 0 10px;
	display: inline-block;
	&-line{
		position: absolute;
		top: -4px;
		right: 0;
		left: 0;
		bottom: 0;
		width: 12px;
		display: inline-block;
		height: 12px;
		border: none;
		border-right: solid 1px #000;
		transform: rotate(44deg);
		-moz-transform: rotate(44deg);
		-webkit-transform: rotate(44deg);
	}
	&:first-child {
		display: none;
	}
}
