.loading-filter {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background:resolve('animation-loading.gif') no-repeat center center rgba(255,255,255,.8);
	background-size: size('animation-loading.gif',2);
	z-index: 300;
}
@include breakpoint(sp) {
}
