.shop .header-inner,
.shop .header-under-layer,
.shop .header-scl {
  .gnavi-list-item {
    display: block;
    line-height: 1;
  }

  .gnavi + .sns {
    margin-top: 20px;
  }

  .sub-menu {
    &.gnavi-list-category {
      padding-top: 0;
      width: 850px;
    }
    .gnavi-list-category-inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 15px;
      width: 100%;
      li {
        width: 32%;
        a {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-start;
          margin-bottom: 15px;
        }

        .img {
          border-radius: 50%;
          width: 40px;

          img {
            vertical-align: -2px;
          }
        }

        .link {
          width: calc(100% - 50px);
          line-height: 1.6;
          font-size: 14px;
          color: #68603e;
        }
      }
    }
    .gnavi-list-category-inner:after {
      content: "";
      display: block;
      width: 32%;
      height: 0px;
    }
    .menu-item {
      + .menu-item {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 8px;
      }
    }
  }

  .gnavi-scl {
    .sub-menu {
      &.gnavi-list-category {
        padding-top: 0;
        width: 400px;
      }

      .gnavi-list-category-inner li {
        width: 45%;
        .link {
          font-size: 12px;
        }
        &:after {
          width: 45%;
        }
      }
    }
  }

  .menu-item {
    + .menu-item {
      margin-top: 20px;

      &.small {
        margin-top: 30px;
      }
    }

    &.small {
      font-size: 12px;

      + .menu-item.small {
        margin-top: 10px;
      }
    }
  }

  #spnavi-search {
    display: none;

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .glid {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.shop.shop-lower .header-under-layer {
  .sub-menu {
    &.gnavi-list-category{
      width: 400px;
      top: 45px;
      padding-top: 13px;
    }
    .gnavi-list-category-inner {
      padding: 0 15px 15px 15px;
      &:after {
        width: 28%;
        height: 1px;
      }

      li {
        width: 30%;

        .img {
          border-radius: 50%;
          width: 30px;

          img {
            vertical-align: 7px;
          }
        }

        .link {
          width: calc(100% - 40px);
          line-height: 1.6;
          text-align: left;
          font-size: 12px;
        }
      }
    }
  }

  .gnavi-scl {
    .sub-menu{
       .gnavi-list-category-inner {
        padding: 15px 15px 15px 15px;
      }
      &.gnavi-list-category {
        padding-top: 0;
        width: 400px;
      }
    }
  }

  .small.menu-item {
    display: none;
  }
}