body.lower.s_product{
	&:after{
		content: "";
		z-index: -1;
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		background: url(../images/bg_lower_header.png) repeat 50%;
		background-size: 1280px 250px;
		height: 1500px;
	}
}

.s_product{
	.glid.thumbnail-item-has_under_title-wrap{
		width: 100%;
		&:after {
			content:"";
			width: 32.2727%;
			height: 0;
		}
	}
	.thumbnail-item-has_under_title{
		margin-bottom: 0px;
		&.mod-3items{
			width: 32.2727%;
			transition: opacity .6s;
			opacity: 1;
			&:hover{
				transition: opacity .6s;
				opacity: 0.6;
			}
		}
		&-img{
			height: 103px;
		}
	}
	.btn_wrap-around-center{
		justify-content: space-between;
		margin-top: 56px;
		.btn-half_line{
			margin-left: 0;
			margin-right: 0;
			& + .btn-half_line{
				margin-right: 14px;
			}
		}
	}
	.small_post-contents{
		margin-top: 100px;
	}
	.post_content-aside_footer-inner{
		margin-top: 150px;
		& + .post_content-aside_footer-inner{
			margin-top: 85px;
		}
	}
	.article-header-headline.mod-thumbnail{
		color: #000;
	}
	.glid.mod-transform-img{
		width: 100%;
		margin-top: 0px;
	}
	.thumbnail-transform-item{
		width: 32.2727%;
		&-img{
			width: 100%;
		}
		&-label{
			padding-bottom: 10px;
			&::before{
				height: 100%;
			}
		}
	}
	.banner-wrap{
		margin-top: 85px;
	}
	.form{
		margin-top: 50px;
	}
	.yasai_case{
		margin-top: 90px;
	}
}

@include breakpoint(sp) {
	body.lower.s_product{
		&:after{
			content: "";
			z-index: -1;
			position: absolute;
			left: 0;
			width: 100%;
			bottom: 0;
			background: url(../images/bg_lower_header.png) repeat 50%;
			background-size: 1280px 250px;
			height: 635px;
		}
	}
	.s_product{
		.small_post-contents {
			margin-top: 80px;
		}
		.btn_wrap-around-center{
			margin-top: 0;
			.btn-half_line{
				width: 100%;
				& + .btn-half_line{
					margin-right: 0px;
				}
			}
		}
		.banner-wrap {
			width: 100%;
			max-width: 100%;
		}
		.thumbnail-item-has_under_title{
			width: 49.1071%;
			margin-bottom: 0px;
			&.mod-3items{
				width: 32.2727%;
			}
			&-img{
				height: 0px;
				padding-top: 65.7276%;
			}
		}
		.thumbnail-transform-item{
			width: 47.2727%;
			padding-bottom: 0;
			&-label{
				&-title{
					text-align: left !important;
				}
			}
		}
		.post_content-aside_footer-inner{
			margin-top: 60px;
			& + .post_content-aside_footer-inner{
				margin-top: 45px;
			}
		}
		.post_content-aside_footer {
			width: 100%;
			.thumbnail-item-has_under_title {
				&-wrap {
					margin-bottom: 30px;
				}
				&.mod-3items{
					width: 100%;
					a {
						display: block;
					}
					& + * {
						margin-top: 20px;
					}
				}
				&-img{
					width: 45.4285%;
					height: 0px;
					padding-top: 30%;
					float: left;
				}
				&-title {
					font-size: 14px;
					line-height: (44 / 28);
					overflow: hidden;
					padding: 0 0 0 10px;
					text-align: left;
				}
			}
		}
		.glid.mod-transform-img {
			padding:0;
		}
		.box-link.mod-width-ex {
			margin-top: 0;
		}
		.form{
			margin-top: 60px;
		}
	}
}
