.glid {
	display: flex;
	width: 960px;
	margin: 0 auto;
	margin-top: 75px;
	-ms-flex-pack: justify;
	justify-content: space-between;
	flex-wrap: wrap;
	&.no-header {
		margin-top: 0;
	}
	&.mod-after-title {
		margin-top: 20px;
	}
	&.mod-after-title-s {
		margin-top: 17px;
	}
	&.mod-project {
		margin-top: 70px;
		&:after {
			content: "";
			display: block;
			width: 300px;
			height: 0;
		}
	}
	&.mod-u_project {
		margin-top: 20px;
	}
	&.mod-transform-img_foot_components {
		margin-top: 0px;
	}
	&.mod-content-1col {
		.main_col {
			position: relative;
			width: 100%;
		}
	}
	&.mod-content-2col {
		.main_col {
			position: relative;
			max-width: 660px !important;
			width: 660px;
			order: 2;
			z-index: 1;
		}
		.side_col {
			max-width: 220px !important;
			width: 220px;
			order: 1;
			z-index: 1;
			//margin-top: 13px;
		}
		.harf_col {
			width: 50%;
		}
		&.mod-yomimono-new {
			width: 1040px;
			// font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN",
				// "Hiragino Kaku Gothic Pro";
			.main_col {
				width: 720px;
				max-width: 720px !important;
			}
			.side_col {
				margin-left: 50px;
			}
			&.mod-detail {

				.article-yomimono-entry-line {
					margin-top: 57px;
				}
				.thumbnail-basic-item-ttl {
					margin-top: 84px;
					margin-bottom: 13px;
				}
			}
		}
	}
	&.mod-content-harf {
		width: 100%;
		.halr_col {
			width: 48.4375%;
			&-img {
				background: resolve("btn_modal_close.png") no-repeat left top;
				background-size: cover;
				background-position: center;
				padding-top: 60.569536%;
				height: 0px;
			}
		}
	}
	&.mod-thumbnail_2col-wrap {
		margin-top: 135px;
		margin-bottom: 165px;
	}
	&.mod-content-3col {
		.main_col {
			position: relative;
			max-width: 320px !important;
			width: 100%;
			order: 2;
			z-index: 1;
			&.mod_ichinichi {
				max-width: 300px !important;
			}
		}
	}
	&.mod-4col {
		&:before {
			content: "";
			display: block;
			width: 24.47917%;
			order: 1;
			display: block;
		}
		&:after {
			content: "";
			width: 24.47917%;
			height: 0;
			display: block;
		}
	}
	&.mod-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}
	.single_col-img &-1col {
		width: 100%;
		& + & {
			margin-top: 30px;
		}
	}
	&-3col {
		width: 300px;
		position: relative;
		margin-bottom: 30px;
	}
	&-4col {
		width: 230px;
		position: relative;
		margin-bottom: 14px;
	}
	&-6col {
		width: 160px;
	}
	&-single_col-img {
		width: 47.91666666666667%;
		margin: 0 auto;
		&_img {
			width: 100%;
			height: 0px;
			padding-top: 65.21739130434783%;
			background: resolve("../images/btn_modal_close.png") no-repeat 0 0;
			background-size: cover;
			background-position: 50%;
		}
	}
	&.mod-wrap-3col {
		&:after {
			content: "";
			display: block;
			width: 300px;
			height: 0;
		}
	}
	&.mod-wrap-4col {
		&:before {
			content: "";
			display: block;
			width: 230px;
			order: 1;
		}
		&:after {
			content: "";
			display: block;
			width: 230px;
		}
	}
	&-5col {
		width: 185px;
		position: relative;
	}
	&.mod-shop-characteristic {
		width: 1135px;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 75px;
		& > * {
			width: 360px;
			margin-left: 0;
			margin-right: 0;
		}
	}
	&.mod-img-aside {
		.col-img {
			width: 189px;
		}
		.col-content {
			width: calc(100% - calc(189px + 64px));
		}
	}
	&.mod-after-title + &.mod-wrap-3col {
		margin-top: 60px;
	}

	//shop top renewal
	&.mod-card-4col {
		&:before,
		&:after {
			content: "";
			display: block;
			width: 225px;
			order: 1;
			height: 0;
		}
	}
	&.mod-card-5col {
		&:before,
		&:after {
			content: "";
			display: block;
			width: 176px;
			order: 1;
			height: 0;
		}
	}
	&.mod-shop-standard {
		margin-top: 5px;
		justify-content: center;
		width: 970px;
	}
	&.mod-shop-pickup {
		margin-top: 25px;
	}
	&.mod-shop-entry{
		flex-wrap:nowrap;
		margin:0;
		justify-content: flex-start;
	}
	&.mod-shop-banner {
		margin-top: 50px;
		justify-content: center;
		width: 960px;
		> * {
			display: block;
			width: 310px;
			height: 90px;
			margin: 10px 5px 0;
			background-size: cover;
			background-position: center;
			@extend .text_indent_none;
		}
	}
	&.mod-shop-category {
		margin-top: 40px;
		& + & {
			margin-top: 0;
		}
	}
	&.mod-shop-artile {
		margin-top: 55px;
		&:before,
		&:after {
			content: "";
			width: 225px;
			order: 1;
		}
	}
}
.ie {
	.glid.mod-content-2col .main_col {
		.thumbnail-basic-item-wrap {
			width: 660px;
			> a {
				display: block;
				&.btn-half_line.mod-sp {
					display: none;
				}
			}
			&:before {
				display: none;
			}
			&.mod-news {
				width: auto;
			}
			&.mod-popular {
				width: auto;
			}
		}
	}
	.glid.mod-content-2col {
		&.mod-yomimono-new {
			.main_col {
				.thumbnail-basic-item-wrap.mod-f_yomimono.mod-recipe {
					> a {
						display: flex;
					}
				}
			}
		}
	}
}

@include breakpoint(sp) {
	.glid {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: 0 auto;
		margin-top: 75px;
		justify-content: space-between;
		&.no-header {
			padding-left: 20px;
			padding-right: 20px;
		}
		&.mod-after-title {
			margin-top: 10px;
		}
		&.mod-inner {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			margin-top: 30px;
			margin-left: auto;
			margin-right: auto;
		}
		&.mod-content-1col {
			.main_col {
				position: relative;
			}
		}
		&.mod-content-2col {
			.main_col {
				max-width: 87.5% !important;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				order: 1;
			}
			.side_col {
				max-width: 87.5% !important;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				order: 2;
				margin-top: 75px;
			}
			&.mod-yomimono-new {
				width: 100%;
				margin-top: 30px;
				.main_col {
					max-width: 87.5% !important;
				}
				&.mod-detail {
					// margin-top: 38px;

					.main_col {
						max-width: 87.5% !important;
						width: 100%;

						.post-content_header {
							width: 84%;
						}
					}
					.post-content {
						&.mod-yomimono-new {
							// width: 84%;
						}
					}
					.thumbnail-basic-item-ttl {
						// width: 88%;
						margin: 20px auto 0;
						font-size: 16px;
						text-align: center;
					}
					.article-yomimono-entry-line {
						margin-top: 30px;
					}
				}
			}
		}
		&.mod-content-harf {
			width: 100%;
			.halr_col {
				width: 100%;
				margin-top: 25px;
				&-img {
					background: resolve("btn_modal_close.png") no-repeat left
						top;
					background-size: cover;
					background-position: center;
					padding-top: 60.569536%;
					height: 0px;
				}
			}
		}
		&.mod-thumbnail_2col-wrap {
			margin-top: 90px;
			margin-bottom: 80px;
		}
		&.mod-content-3col {
			.main_col {
				position: relative;
				max-width: 100% !important;
				width: 100%;
				order: 2;
				z-index: 1;
				&.mod_ichinichi {
					max-width: 100% !important;
				}
			}
		}
		&-1col {
			width: 90%;
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;
			& + & {
				margin-top: 30px;
			}
		}
		&-3col {
			// width: 300px;
			width: 90%;
			position: relative;
			margin: auto;
			& + & {
				margin-top: 40px;
			}
		}
		&-4col,
		&-5col {
			width: 48%;
			position: relative;
			margin-bottom: 10px;
		}
		&-single_col-img {
			width: 100%;
			margin: 0 auto;
			&_img {
				width: 100%;
				height: 0px;
				padding-top: 65.21739130434783%;
				background: resolve("../images/btn_modal_close.png") no-repeat 0
					0;
				background-size: cover;
				background-position: 50%;
			}
		}
		&.mod-wrap-3col {
			&:after {
				display: none;
			}
		}
		&.mod-wrap-4col,
		&.mod-wrap-5col {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			&:before,
			&:after {
				display: none;
			}
		}
		&.mod-transform-img {
			margin-top: 35px;
			padding: 20px;
		}

		&.mod-shop-characteristic {
			width: (600 / 640) * 100%;
			display: block;
			margin-top: 50px;
			& > * {
				width: 100%;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&.mod-img-aside {
			.col-img {
				width: 100%;
				order: 2;
			}
			.col-content {
				width: 100%;
				order: 1;
			}
		}
		&.mod-after-title + &.mod-wrap-3col {
			margin-top: 40px;
		}

		//shop top renewal
		&.mod-card-4col {
			&:before,
			&:after {
				display: none;
			}
		}
		&.mod-card-5col {
			margin-top: 0;
			&:before,
			&:after {
				display: none;
			}
		}
		&.mod-shop-standard {
			padding: 0 5px;
			width: 100%;
		}
		&.mod-shop-pickup {
			margin-top: 10px;
			> .card.mod-4col {
				width: calc(50% - 10px);
			}
		}
		&.mod-shop-banner {
			margin-top: 0px;
			padding: 0 10px;
			width: 100%;
			max-width: 495px;
			> * {
				margin: 10px 0 0;
				width: 100%;
				height: 0;
				padding-top: percentage(140 / 475);
			}
		}
		&.mod-shop-category {
			padding: 0 10px;
			margin-top: 0px;
		}
		&.mod-shop-entry {
			// flex-wrap:nowrap;
		}
		&.mod-shop-artile {
			margin-top: 30px;
			&:before,
			&:after {
				display: none;
			}
		}
	}
	.ie {
		.glid.mod-content-2col .main_col {
			.thumbnail-basic-item-wrap {
				width: 90%;
				&.mod-popular {
					> a {
						display: flex;
					}
				}
				&.mod-f_yomimono {
					&.mod-cat {
						&.only_sp {
							width: 70%;
						}
					}
				}
				&.mod-news {
					width: 90%;
				}
				&.mod-popular {
					width: 90%;
				}
			}
		}
	}
}
