/* ***************************************
# Mixins 汎用mixin
*************************************** */
// RESPONSIVE
// @include breakpoint(sp) { ... }
// @include breakpoint(tb) { ... }
// --------------------
@mixin breakpoint($size){
  $flg: false;
  @each $breakpoint,$value in $breakpoints{
    @if $size == $breakpoint {
      $flg: true;
      @media only screen and ( max-width: map-get($breakpoints, $breakpoint)){
        @content;
      }
    }
  }
  @if $flg == false {
    @media only screen and ( $size ){
      @content;
    }
  }
}

@mixin clearfix{
	&:after{
		content:".";
		display: block;
		height:0px;
		clear:both;
		line-height:0;
		visibility:hidden;
	}

	@if $support-ie7 or $support-ie6 {
		*zoom:1;
	}
}

@mixin rolloverop {
	&:hover {
		filter: progid:DXImageTransfor.Microsoft.Alpha(Opacity=70);
		opacity: 0.7;
	}
}
/* ******************************
# テキスト非表示
****************************** */
.text_indent_none{
	text-indent: 100%;
	white-space: nowrap;
	display: block;
	overflow: hidden;
}

@mixin text_indent_none{
	text-indent: 100%;
	white-space: nowrap;
	display: block;
	overflow: hidden;
}
/* ===============================================
for bem
=============================================== */
//Element
@mixin e($name) {
  @at-root #{&}__#{$name} {
	@content;
  }
}

//Modifier
@mixin m($name) {
  @at-root #{&}--#{$name} {
	@content;
  }
}
/* ===============================================
common parts
=============================================== */
//画像をマウスオーバーで拡大 imgに対して適用
@mixin scale_img($ratio:1.04,$speed:0.3) {
	transition: transform #{$speed}s linear;
	&:hover {
		transform: scale($ratio);
	}
}

//画像をマウスオーバーで拡大 hover使用なし
@mixin scale_img_hasnt_hover($ratio:1.04,$speed:0.3) {
	transition: transform #{$speed}s linear;
}

@mixin opacity($opacity:0.8) {
	opacity: $opacity;
}

@mixin opacity--anim($opacity:0.8) {
	transition:opacity 0.6s;
	&:hover {
		opacity: $opacity;
	}
	@include breakpoint(sp){
		&:hover {
			opacity: 1;
		}
		&:active {
			opacify:$opacity;
		}
	}
}


@mixin text-color-scheme{
	&.mod-odd{
		color: #68603e;
	}
	&.mod-even{
		color: #4f6537;
	}
}
@mixin border-top-color-scheme{
	&.mod-odd,
	&.mod-odd *{
		border-color: #c3c0b4;
	}
	&.mod-even,
	&.mod-even *{
		border-color: #c6cfbc;
	}
}

@mixin triangle-top-left-color-scheme{
	&.mod-odd:before,
	&.mod-odd *:before{
		border-color:#c3c0b4 transparent;
	}
	&.mod-even:before,
	&.mod-even *:before{
		border-color:#c6cfbc transparent;
	}
}
/* ===============================================
postcss assets
=============================================== */
// @function resolve($url,$ratio = 1) {
//   @return unquote("resolve(#{$url},#{$ratio})");
// }

// @function width($url,$ratio = 1) {
//   @return unquote("width(#{$url},#{$ratio})");
// }

// @function height($url,$ratio = 1) {
//   @return unquote("height(#{$url},#{$ratio})");
// }

// @function size($url,$ratio = 1) {
//   @return unquote("size(#{$url},#{$ratio})");
// }

// @function inline($url,$ratio = 1) {
//   @return unquote("inline(#{$url},#{$ratio})");
// }

@mixin back-ground-reponsive($width,$height) {
	padding-top:$height / $width * 100%;
}
