.swiper-container-shop-wrap {
	width: 100%;
	overflow: hidden;
	position: relative;
	margin-top: 50px;
	.swiper-button-prev{
		display: inline-block;
		background: resolve("arrow_left_shop.png") no-repeat 0 0;
		width: width("arrow_left_shop.png");
		height: height("arrow_left_shop.png");
		position: static;
		margin-bottom: 0;
	}
	.swiper-button-next{
		display: inline-block;
		background: resolve("arrow_right_shop.png") no-repeat 0 0;
		width: width("arrow_right_shop.png");
		height: height("arrow_right_shop.png");
		position: static;
		margin-bottom: 0;
	}
	.swiper-pagination{
		display: inline-block;
		bottom: 0px;
		width: auto;
		position: static;
		padding: 0 13px;
		&-bullet{
			&:nth-of-type(odd){
				border: 1px solid #4f6537;
				width: 8px;
				height: 8px;
				border-radius: 0%;
				opacity: 1;
				background-color: #fff;
				margin-left: 5px;
				margin-right: 5px;
				display: inline-block;
				vertical-align: middle;
			}
			&-active:nth-of-type(odd){
				background-color: #4f6537 !important;
				width: 12px;
				height: 12px;
				border-radius: 0%;
				opacity: 1;
			}
			&:nth-of-type(even){
				border: 1px solid #68603e;
				width: 8px;
				height: 8px;
				border-radius: 0%;
				opacity: 1;
				background-color: #fff;
				margin-left: 5px;
				margin-right: 5px;
				display: inline-block;
				vertical-align: middle;
			}
			&-active:nth-of-type(even){
				background-color: #68603e !important;
				width: 12px;
				height: 12px;
				border-radius: 0%;
				opacity: 1;
			}
		}
	}
}
.swiper-ctrls {
	margin-top: 75px;
	display: flex;
	justify-content:center;
	flex-wrap:wrap;
	align-items:baseline;
}
.swiper-container-shop{
	overflow: visible!important;
	position: relative;
	width: 100%;
	margin-left: -60px;
	.swiper-wrapper {
		align-items:flex-start;
	}
	.swiper-slide{
		$c:&;
		width: 280px;
		height: 255px;
		transition:all .6s;
		position: relative;
		top: 75px;
		&-img {
			transition:all .6s;
			width: 280px;
			height: 180px;
			background-size: cover;
			background-position: center;
			background-repeat:no-repeat;
			display: block;
			position: relative;
			display: block;
		}
		&-mask{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.4);
			transition: all 0.6s ease;
			opacity: 0;
			& p{
				font-size: 14px;
				color: #ffff;
				text-align: center;
				font-family: kreon_r;
				font-family: kreon_r;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				letter-spacing: .1em;
				&:after{
					content: '';
					position: absolute;
					background-image: resolve('arrow-right-mask.png');
					background-repeat: no-repeat;
					background-position: center;
					width: 100%;
					height: 10px;
					top: 25px;
					bottom: 0;
					left: 0;
					right: 0;
				}
			}
		}
		&:hover .swiper-slide-mask{
			opacity: 1;
		}

		&-active{
			width: 400px !important;
			height: 255px;
			margin-top: 0px;
			top: 0;
			.swiper-slide-img {
				width: 400px !important;
				height: 255px;
			}
		}
		.slider-left-corner{
			background: resolve("slider-left-corner.png") no-repeat 0 0;
			width: width("slider-left-corner.png");
			height: height("slider-left-corner.png");
			position: absolute;
			top: -1px;
			left: -1px;
		}
		.slider-right-corner{
			background: resolve("slider-right-corner.png") no-repeat 0 0;
			width: width("slider-right-corner.png");
			height: height("slider-right-corner.png");
			position: absolute;
			bottom: -1px;
			right: -1px;
		}
	}
	.swiper-slide-title{
		text-align: center;
		width: 100%;
		color: #4f6537;
		font-family: 'kreon_r';
		letter-spacing: 3px;
		font-size: 18px;
		margin-top: 16px;
	}
}

@include breakpoint(sp) {
	.swiper-container-shop-wrap {
		width: 100%;
		overflow: inherit;
		position: relative;
		margin-top: 0px;
		.swiper-button-prev{
			display: inline-block;
			background: resolve("arrow_left_shop.png") no-repeat 0 0;
			width: width("arrow_left_shop.png");
			height: height("arrow_left_shop.png");
			position: static;
			margin-bottom: 0;
		}
		.swiper-button-next{
			display: inline-block;
			background: resolve("arrow_right_shop.png") no-repeat 0 0;
			width: width("arrow_right_shop.png");
			height: height("arrow_right_shop.png");
			position: static;
			margin-bottom: 0;
		}
		.swiper-pagination{
			display: inline-block;
			bottom: 0px;
			width: auto;
			position: static;
			padding: 0 13px;
			&-bullet{
				&:nth-of-type(odd){
					border: 1px solid #4f6537;
					width: 8px;
					height: 8px;
					border-radius: 0%;
					opacity: 1;
					background-color: #fff;
					margin-left: 5px;
					margin-right: 5px;
					display: inline-block;
					vertical-align: middle;
				}
				&-active:nth-of-type(odd){
					background-color: #4f6537 !important;
					width: 12px;
					height: 12px;
					border-radius: 0%;
					opacity: 1;
				}
				&:nth-of-type(even){
					border: 1px solid #68603e;
					width: 8px;
					height: 8px;
					border-radius: 0%;
					opacity: 1;
					background-color: #fff;
					margin-left: 5px;
					margin-right: 5px;
					display: inline-block;
					vertical-align: middle;
				}
				&-active:nth-of-type(even){
					background-color: #68603e !important;
					width: 12px;
					height: 12px;
					border-radius: 0%;
					opacity: 1;
				}
			}
		}
	}
	.swiper-ctrls {
		display: none;
	}
	.swiper-container-shop{
		overflow: visible!important;
		position: relative;
		width: 100%;
		margin-left: 0;
		.swiper-wrapper {
			align-items:baseline;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			transform: none !important;
			width: 90%;
			margin: 0 auto;
		}
		.swiper-slide {
			$c:&;
			width: 49%;
			height: auto;
			margin-top: 25px;
			top:0;
			&-img {
				width: 100%;
				height: 0;
				padding-top: 61%;
				background-size: cover;
				background-position: center;
				background-repeat:no-repeat;
				display: block;
			}
			&-mask{
				width: 100%;
				height: 90px;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(0,0,0,0.4);
				transition: all 0.6s ease;
				opacity: 0;
				p{
					font-size: 14px;
					color: #ffff;
					text-align: center;
					font-family: kreon_r;
					font-family: kreon_r;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					letter-spacing: .1em;
					&:after{
						content: '';
						position: absolute;
						background-image: resolve('arrow-right-mask.png');
						background-repeat: no-repeat;
						background-position: center;
						width: 100%;
						height: 10px;
						top: 25px;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
			}
			&:hover .swiper-slide-mask{
				opacity: 1;
			}
			&-active{
				width: 45% !important;
				height: 90px;
				margin-top: 0px;
				.swiper-slide-img {
					width: 45% !important;
					height: 180px;
				}
			}

		}
		.swiper-slide-title{
			text-align: center;
			width: 100%;
			color: #4f6537;
			letter-spacing: 1.4px;
			font-size: 14px;
			font-weight: bold;
			margin-top: 8px;
		}
	}

	.swiper-container-shop-wrap {
		margin-top: 15px;
	}
}

