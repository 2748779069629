.form-date_pick {
	span{
		font-size: 14px;
		font-weight: bold;
		margin-right: 25px;
		}
	.form-date_pick_datepicker{
		width: 160px;
		font-size: 15px;
		padding: 4px 10px;
		background-image: resolve('ico_data-pick.png',2);
		background-repeat: no-repeat;
		background-position: 95%;
		background-size: 14%;
		border: solid 1px #c6cfbc;
	}
}
@include breakpoint(sp) {
	.form-date_pick {
		.form-date_pick_datepicker{
			width: 100%;
			background-size: 8%;
		}
	}
}
