.header-sp-links {
	display: flex;
	justify-content:flex-end;
	align-items:flex-end;
	a > * {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}
.btn-sp-navi,.btn-shop,.header-sp-links-btn {
	display:block;
	vertical-align: top;
	position: relative;
	color: #000;
	text-align: center;
	margin-left: 15px;
	span {
		position: static;
		letter-spacing: 1px;
		bottom: -21px;
		left: 8px;
		font-size: 12px;
		font-family: kreon_r;
		display: block;
		margin-top: 5px;
	}
}
.btn-shop {
	span {
		margin-top: 2px;
	}
}
.header-sp.mod-shop {
	.btn-sp-navi {
		margin-left: 0;
	}
	.btn-sp-navi + .btn-sp-navi {
		margin-left: 15px;
	}

	@include breakpoint(exsp){
		.btn-sp-navi + .btn-sp-navi {
			margin-left: 10px;
		}
	}
}
