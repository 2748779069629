.footer-info{
	font-size: 12px;
	font-family: kreon_r;
	position: absolute;
	top: 177px;
	letter-spacing: 1px;
	line-height: 24px;
	&-contact{
		font-size: 12px;
		font-weight: 700;
		color: #000;
		position: relative;
		border-bottom: 1px solid;
		padding-bottom: 0px;
		display: table;
		margin-top: 9px;
	}
}

@include breakpoint(sp) {
	.footer-info{
		position: relative;
		padding-bottom: 30px;
		top: inherit;
		bottom: 0px;
		right: 0px;
		line-height: 28px;
		margin-bottom: 0;
		padding-bottom: 0;
		&-contact{
			font-size: 12px;
			font-weight: 700;
			position: relative;
			margin-top: 20px;
			border-bottom: 1px solid;
			padding-bottom: 7px;
			display: table;
			line-height: 0px;
		}
	}
}
