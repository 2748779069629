.order-list-area{
	.order{
		line-height: (22 /13);
		&-list-table{
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid #cdd2d5;
			text-align: center;
			.tit{
				font-weight: bold;
				border-bottom: 1px solid #cdd2d5;
				padding: 7px 0px;
				font-size: 13px;
			}
			.count{
				width: 100%;
				max-width: 105px;
				font-weight: bold;
				border-right: 1px dashed #cdd2d5;
			}
			.item{
				max-width:414px;
				width: 100%;
				border-right: 1px dashed #cdd2d5;
			}
			.sum{
				width: 100%;
				max-width: 139px;
			}
			.child{
				li {
					border-bottom: 1px solid #cdd2d5;
					padding: 13px 0;
					font-size: 13px;
				}
			}
		}
		&-list_label{
			font-size: 14px;
			h4{
				font-size: 14px;
				margin-bottom: 12px;
			}
			p + p {
				margin-top: 4px;
			}
			& + .order-list_label{
				margin-top: 35px;
			}
		}
	}//order
}
@include breakpoint(sp) {

}
