.box-has_triangle-point {
	$c:&;
	position: relative;
	background-color: #f8f7f2;
	padding:29px 20px 40px 40px;
	&.mod-bg-green {
		background-color: #f5f7f2;
		&:before {
			border-top: 20px solid #c6cfbc;
		}
		#{$c}-inner {
			margin-top: 34px;
		}
		.desined-scrollbar {
			.ps__rail-y {
				background-color: #c6cfbc;
			}
			.ps__thumb-y {
				background-color: #4f6537;
			}
		}
	}
	&:before {
		content:"";
		position: absolute;
		top: 0;
		left: 0;
		border-top: 20px solid #c3c0b4;
		border-right: 20px solid transparent;
	}

	&-title {
		color: $black;
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 2px;
		margin-left: -20px;
		span {
			display: block;
			line-height: 1;
			text-align: center;
		}
		.en {
			@extend .font-kreon_r;
		}
		.ja {
			font-size: 12px;
			font-weight: 700;
			margin-top: 10px;
		}
		a {
			display: block;
			@include opacity--anim(.5);
		}
	}
	&-inner {
		margin-top: 32px;
		a {
			color: $black;
			display: block;
			@include opacity--anim(.6);
		}
	}
	.contents-news-article {
		$c:&;
		width: 100%;
		&-title {
			font-weight: 700;
			margin-top: 2px;
		}
		& + #{$c} {
			margin-top: 11px;
		}
	}
}
@include breakpoint(sp) {
	.box-has_triangle-point {
		$c:&;
		padding:29px 10px 30px 15px;

		&-title {
			font-size: 18px;
			letter-spacing: 1.8px;
			margin-left: -10px;
		}

		.contents-news-article {
			$c:&;
			width: 100%;
			&-title {
				font-size: 13px;
			}
			& + #{$c} {
				margin-top: 11px;
			}
		}
	}
}
