.post_content-aside_footer {
	width: 100%;
	max-width:960px;
	margin-left: auto;
	margin-right: auto;
	order:3;
	&-inner {
		max-width:660px;
		margin-left:auto;
	}
	 .title.mod-triangle-above {
		margin-top: 144px;
	}
	.glid.mod-transform-img {
		margin-top: 20px;
		width: 100%;
		.thumbnail-transform {
			&-item-img-book {
				height: 129px;
			}
			&-item-img {
				width: 100%;
				height: 121px;
			}
			&-item.mod-odd,
			&-item.mod-even {
				border-bottom: none;
				&:after {
					display: none;
				}
			}
			&-item-label:before {
				height: 49px;
			}
		}
	}
	.thumbnail-transform-item {
		width:32.272727273%;
		display: block;
	}
}
@include breakpoint(sp) {
	.post_content-aside_footer {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		.title.mod-triangle-above {
			margin-top: 90px;
		}
		.title.mod-triangle-above + .glid.mod-transform-img {
			padding: 0;
			.thumbnail-transform-item-label-title {
				font-size: 14px;
				text-align: center;
			}
		}
		.thumbnail-transform-item {
			width:48.96429%;
		}
	}
}
