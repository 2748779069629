.set-intro {
	position: relative;
	display: flex;
	@include text-color-scheme;
	@include border-top-color-scheme;
	@include triangle-top-left-color-scheme;
	&-image{
		width:calc(50% - 20px);
		&-element{
			width: 100%;
			height: 300px;
			background-size:cover;
			background-position: center center;
			@extend .text_indent_none;
		}
	}
	&-text{
		flex:1;
		margin-top: 20px;
		border-top: 1px solid #c3c0b4;
		position: relative;
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 5px 0 0 ;
			border-color: #c3c0b4 transparent transparent;
		}

		&-title{
			max-width: 580px;
			padding: 26px 0 20px 60px ;
			font-size: 18px;
			font-weight: bold;
			font-style:italic;
			letter-spacing: 0.1em;
		}
		&-textarea{
			max-width: 580px;
			padding-left: 100px;
  			font-size: 14px;
  			line-height: 2;
  			* + p{
  				margin-top: 2em;
  			}
		}
	}
}
@include breakpoint(sp) {
	.set-intro {
		flex-direction:column;
		&-image{
			width:100%;
			&-element{
				width: 100%;
				height: (310 / 640 * 100vw);
			}
		}
		&-text{
			margin-top: 30px;
			border-top: 0;
			position: relative;
			&:before{
				display: none;
			}

			&-title{
				max-width: 100%;
				width: 90%;
				margin:0 auto;
				padding:0 0 1em;
				font-size: 14px;
			}
			&-textarea{
				max-width: 100%;
				width: 90%;
				margin:0 auto;
				padding:0;
	  			font-size: 13px;
	  			* + p{
	  				margin-top: 2em;
	  			}
			}
		}
	}
}
