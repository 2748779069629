.event_detail {
	&-content{
		width: 100%;
		max-width:680px;
		margin:80px auto 120px;
		padding:0 10px;
	}

	&-heading{
		position: relative;
		padding-right: 10px;
		margin-bottom: 10px;
		&-title{
			text-align: center;
			font-style: italic;
			font-size: 20px;
			line-height: 2;
			padding:18px 70px 20px;
			background: #f8f7f2;
			letter-spacing: 0.1em;
		}
		&-area{
			position: absolute;
			top:28px;
			right:0px;
			font-size: 14px;
			border-bottom: 1px solid #000;
			padding-left: 20px;
			font-weight: bold;
		}
	}

	&-meta{
		margin-bottom: 40px;
		display: flex;
		&-left{
			width:(250 / 660) * 100%;
		}
		&-right{
			width:65%;
			text-align: right;
			padding-right: 10px;
		}
	}

	&-cat{
		@extend .clearfix;
		margin-top: 7px;
		li{
			float: left;
			border-left:3px solid #c3c0b4;
			padding-left: 6px;
			font-size: 13px;
			margin-right: 16px;
			line-height: 1.2;
			margin-bottom: 14px;
			font-weight: bold;
			letter-spacing: 0.1em;
		}
		a{
			color:#68603e;
		}
	}

	&-date{
		font-family: kreon_r;
		font-size: 14px;
		letter-spacing: 0.1em;
		.month{
			font-size: 20px;
			letter-spacing: 0.1em;
		}
		.day{
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0.1em;
		}
		.time{
			font-size: 17px;
			margin-left: 17px;
			letter-spacing: 0.1em;
		}
	}
	&-place{
		font-weight: bold;
		margin-top: 5px;
		font-size: 14px;
		a {
			color: $black;
		}
		&:before{
			content:"";
			display: inline-block;
			background:resolve("ico_place.png") center no-repeat;
			background-size:size("ico_place.png",2);
			width:width("ico_place.png",2);
			height:height("ico_place.png",2);
			margin-right: 6px;
			position: relative;
			bottom:-2px;
		}
	}
	&-main{
		line-height: 2;
  		font-size: 14px;
  		color:#1c1f21;
  		margin-bottom: 38px;
  		p,h2,h3,h4,h5,h6{
  			font-size: 14px;
  			line-height: 2;
  		}
  		* + p,
  		* + h2,
  		* + h3,
  		* + h4,
  		* + h5,
  		* + h6{
			margin-top: 2em;
  		}
  		h3{
  			color:#4f6537;
  		}
  		a{
  			color:#4f6537;
  		}
	}

	&-info{
  		a{
  			color:#4f6537;
  		}
		h3{
			border-bottom: 1px solid #000;
			padding:14px 10px;
			font-size: 14px;
		}
		dl{
			@extend .clearfix;
			line-height: 2;
			border-bottom: 1px solid #cdd2d5;
			font-size: 13px;
		}
		dt{
			clear: left;
			float: left;
			font-size: 13px;
			width: 120px;
			font-weight: bold;
			padding:8px 10px 7px 10px;
			border-top: 1px solid #cdd2d5;
			line-height: 1.5;
			&:first-of-type{
				border-top: 0;
			}
		}
		dd{
			float: left;
			padding:8px 0 7px;
			width: calc(100% - 120px);
			border-top: 1px solid #cdd2d5;
			&:first-of-type{
				border-top: 0;
			}
		}
	}
}
@include breakpoint(sp) {
	.event_detail {
		&-content{
			margin:40px auto 90px;
			padding:0 20px;
		}

		&-heading{
			position: relative;
			padding-right: 0px;
			margin-bottom: 0px;
			&-title{
				font-size: 14px;
				padding:10px 20px;
				text-align: left;
				line-height: (44 / 28);
				br{
					display: none;
				}
			}
			&-area{
				top:-15px;
				right:-5px;
				font-size: 12px;
				padding:0 5px;
			}
		}
		&-meta{
			margin-bottom: 20px;
			display: flex;
			flex-wrap:wrap;
			&-left{
				width:100%;
			}
			&-right{
				width:100%;
				text-align: center;
				padding-right: 0px;
				margin-top: 10px;
			}
		}
		&-cat{
			margin-top: 9px;
			li{
				font-size: 12px;
				margin:0 26px 8px 0;
			}
		}

		&-date{
			font-family: kreon_r;
			font-size: 14px;
			letter-spacing: 0.1em;
			.month,
			.day,
			.time{
				font-size: 15px;
				margin-left: 0px;
			}
			.time{
				display: block;
			}
		}

		&-place{
			font-size: 12px;
			margin-top: 0;
		}
		&-main{
			line-height: (48 / 26);
	  		font-size: 13px;
	  		color:#1c1f21;
	  		margin-bottom: 35px;
	  		p,h2,h3,h4,h5,h6{
	  			font-size: 13px;
	  			line-height: (48 / 26);
	  		}
	  		h3{
	  			color:#4f6537;
	  		}
	  		a{
	  			color:#4f6537;
	  		}
		}

		&-info{
	  		a{
	  			color:#4f6537;
	  		}
			h3{
				border-bottom: 1px solid #000;
				padding:10px 5px;
			}
			dl{
				line-height: (48 / 26);

			}
			dt{
				clear: left;
				float: left;
				font-size: 13px;
				width: 70px;
				font-weight: bold;
				padding:8px 5px;
			}
			dd{
				float: left;
				padding:7px 0;
				width: calc(100% - 70px);
			}
		}

	}
}
