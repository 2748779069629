.box-event_info {
	$c:&;
	display: flex;
	justify-content:space-between;
	flex-wrap:wrap;
	padding:27px 60px;
	position: relative;
	color: $black;
	background-color: #f8f7f2;
	position: relative;
	z-index: 1;
	@at-root {
		a#{$c} {
			@include opacity--anim(.5);
		}
	}
	a {
		color: $black;
		display: block;
		@include opacity--anim(.5);
	}
	&-heading {
		width: 129px;
		height: 38px;
		border-width: 1px;
		border-color: #c3c0b4;
		border-style: solid;
		line-height: 38px;
		color: #675f3e;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 1.4px ;
		text-align: center;
		margin-left: -40px;
		position: relative;
		z-index: 2;
		margin-bottom: -9px;
	}
	&-wrap {
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		margin-bottom: -27px;
		& > * {
			width: 48.9583%;
			margin-top: 0;
			margin-bottom: 27px;
		}
		#{$c} + #{$c} {
			margin-top: 0;
		}
		#{$c} {
			padding:30px 30px 33px;
		}
		#{$c}-title {
			& > * {
				font-size: 14px;
			}
			span {
				font-size: 12px;
			}
		}
		#{$c}-img {
			width:100%;
			height: 100px;
		}
		#{$c}-img-wrap {
			width: 164px;
		}
		#{$c}-btn {
			width: 100%;
			background-color: #fff;
			display: block;
			text-align: center;
			color: #68603e;
			font-size: 13px;
			font-weight: 700;
			line-height: 36px;
			height: 36px;
			border-bottom: 1px solid #c3c0b4;
			border-right: 1px solid #c3c0b4;
			margin-top: 20px;
			letter-spacing: 1.3px;
			&.sp {
				display: none;
			}
			transition:background-color .6s;
			&:hover {
				background-color: #f8f7f2;
				opacity: 1;
			}
		}
		#{$c}-info {
			width:calc(100% - 184px)
		}
		#{$c}-date {
			span {
				font-size: 14px;
				margin-left: 0;
			}
		}
		span#{$c}-w {
			display: inline-block;
			font-size: 13px;
		}
		span#{$c}-time {
			display: block;
			width: 100%;
		}

		.contents-pickup-thumbnail-img-new {
			font-size: 12px;
			width: auto;
			height: auto;
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
			padding:0 5px;
			background-color: #474b42;
			span {
				display: block;
				width: auto;
				height: auto;
				position: relative;
			}
		}
	}
	&:after {
		content:"";
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #c3c0b4;
		bottom: -5px;
		left: 0;
	}
	&-img {
		width: 260px;
		height: 158px;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:cover;
		position: relative;
	}
	&-info {
		width:calc(100% - 300px)
	}
	&-title {
		font-size: 16px;
		font-weight: 700;
		line-height: 1.6;
		letter-spacing: .1em;
		font-style: italic;
		* {
			line-height: (34 / 18);
		}
		span {
			font-size: 14px;
			display: block;
		}
	}
	&-date {
		margin-top: 12px;
		line-height: ( 24 / 16.5);
		@extend .font-kreon_r;
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 2px;
	}
	&-w {
		@extend .font-yu_go;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 1.6px;
	}
	&-time {
		@extend .font-kreon_r;
		font-size: 17px;
		font-weight: 400;
		line-height: (24 / 17);
		letter-spacing: .1em;
		margin-left: 11px;
	}
	.event_detail-cat {
		margin-top: 0;
		li {
			color: #68603e;
			margin-bottom: 10px;
		}
	}
	.contents-pickup-thumbnail-img-new {
		top: 0;
		left: 0;
		@extend .font-yu_go;
		font-weight: 700;
	}
	.event_detail-place {
		margin-top: -4px;
		&:before {
			margin-right: 10px;
			margin-top: 10px;
		}
	}
}
.box-event_info + .box-event_info {
	margin-top: 27px;
}
@include breakpoint(sp) {
	.box-event_info + .box-event_info {
		margin-top: 43px;
	}
	.box-event_info {
		$c:&;
		display: block;
		padding:20px;

		&-heading {
			width: 120px;
			height: 34px;
			line-height: 34px;
			font-size: 13px;
			letter-spacing: 1.3px;
			margin-left: -5px;
		}
		&-wrap {
			display: block;
			margin-bottom: 0;
			margin-top: 27px;
			& > * {
				width: 100%;
				margin-top: 0;
				margin-bottom: 20px;
			}
			#{$c} {
				padding:20px 8px;
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
				margin-bottom: 24px;
			}
			#{$c}-img-wrap {
				width: 41.42857%;
				margin-bottom: 0;
				.box-event_info-btn {
					display: none;
				}
			}
			#{$c}-img {
				width:100%;
				height: 0;
				padding-top: 67.3387%;
			}

			#{$c}-title {
				margin-top: 0;
				& > * {
					font-size: 14px;
				}
			}
			#{$c}-btn {
				width: 75.8620%;
				font-size: 12px;
				letter-spacing: 1.2px;
				margin-left: auto;
				margin-right: auto;
				display: none;
				&.sp {
					display: block;
				}
			}
			#{$c}-info {
				width:calc(100% - calc(41.42857% + 10px))
			}
			#{$c}-date {
				text-align: left;
				span {
					font-size: 14px;
					margin-left: 0;
				}
			}
			span#{$c}-w {
				display: inline-block;
				font-size: 12px;
			}
			span#{$c}-time {
				text-align: left;
				display: block;
				font-size: 13px;
			}

			.contents-pickup-thumbnail-img-new {
				border-top:0;
				border-left:0;
				span {
					top: 0;
				}
			}

			.event_detail-cat li {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
				float: none;
			}
		}
		&:after {
			bottom: -5px;
		}
		&-img {
			width: 100%;
			height: 0;
			padding-top: 61.6666%;
		}
		&-info {
			width:100%;
			display: flex;
			justify-content:flex-start;
			flex-wrap:wrap;
			flex-direction:column;
			align-items:flex-start;
		}
		&-title {
			font-size: 14px;
			order:1;
			margin-top: 15px;
			line-height: ( 44 / 28);
		}
		&-date {
			width: 100%;
			margin-top: 12px;
			font-size: 15px;
			letter-spacing: 1.5px;
			display: block;
			text-align: center;
			order:3;
		}
		&-w {
			font-size: 12px;
			font-weight: 700;
			letter-spacing: 1.2px;
		}
		&-time {
			display: block;
			text-align: center;
			width: 100%;
			font-size: 14px;
			letter-spacing: 1.4px;
			margin-left: 0;
			margin-top: 5px;
		}
		.event_detail-cat {
			margin-top: 15px;
			margin-bottom: 0;
			order:2;
		}
		.event_detail-place {
			margin-top: 0;
			order: 4;
			width: 100%;
			text-align: center;
			&:before {
				margin-top: 5px;
				margin-right: 6px;
			}
		}
		.contents-pickup-thumbnail-img-new {
			border-top:0;
			border-left:0;
			padding: 4px 5px;
		}
	}
	.ie {
		.box-event_info .contents-pickup-thumbnail-img-new {
			padding-top: 5px;
		}
		.box-event_info-title {
			width: 100%;
		}
	}
}
