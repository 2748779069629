.sns{
	font-size: 0px;
	margin-top: 11px;
	margin-left: 119px;
	&.mod-header-under-layer{
		display: inline-block;
		margin-top: 10px;
		margin-left: 10px;
		& a{
			margin-left: 20px;
		}
	}
	&-list{
		letter-spacing: 19px;
		&-icon{
			font-size: 20px;
			display: inline-block;
			&-item{
				color: #000;
				&.mod-round{
					display: inline-block;
					border-radius: 50%;
					background-color: #000;
					color: #fff;
					border: 1px solid #fff;
					width: 33px;
					height: 33px;
					line-height: 32px;
					text-align: center;
					font-size: 19px;
					transition: all .6s;
				}
			}
			&.mod-footer-spnavi{
				letter-spacing: 35px;
			}
		}
	}
	&.mod-scl{
		margin-left: 47px;
		margin-top: 0px;
	}
	&-contents{
		width: 960px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}
	&-block{
		width: 440px;
		&.loading {
			background: url(../images/animation-loading.gif) no-repeat 50% #fff;
			background-size: 30px 28px;
		}
	}
	&-title{
		font-weight: normal;
		a {
			margin-right: 10px;
		}
		span{
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 2px;
			@extend .font-kreon_r;
		}
	}
	&-thumbnail{
		margin-top: 40px;
		&-up{
			display: flex;
			justify-content: space-between;
			flex-wrap:wrap;
			align-items:center;
			margin-bottom: -7px;
			& > * {
				margin-bottom: 7px;
				width: (275 / 560) * 100%;
				@include opacity--anim(.5);
				display: block;
			}
		}

	}
}

@media only screen and (max-width:1205px){
	.sns {
		margin-left: 49px;
	}
}

@include breakpoint(sp) {
	.sns{
		font-size: 0px;
		margin-top: 11px;
		margin-left: 119px;
		&-list{
			letter-spacing: 19px;
			&-icon{
				font-size: 20px;
				display: inline-block;
				&-item{
					color: #000;
					&.mod-round{
						display: inline-block;
						border-radius: 50%;
						background-color: #000;
						color: #fff;
						border: 1px solid #fff;
						width: 33px;
						height: 33px;
						line-height: 32px;
						text-align: center;
						font-size: 19px;
						transition: all .6s;
					}
				}
			}
		}
		&.mod-scl{
			margin-left: 47px;
			margin-top: 0px;
			padding-top: 10px;
		}
		&-contents{
			width:(560 / 640) * 100%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		&-block{
			width: 100%;
			&.loading {
				background: url(../images/animation-loading.gif) no-repeat 50% 80px #fff;
				background-size: 30px 28px;
				min-height: 150px;
			}
		}
		&-block + &-block {
			margin-top: 40px;
		}
		&-title{
			text-align: center;
			a {
				margin-right: 10px;
			}
			span{
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 1px;
				font-family: kreon_r;
			}
		}
		&-thumbnail{
			margin-top: 30px;
			&-up{
				align-items: flex-start;
				display: flex;
				justify-content: space-between;
			}
			// &-under{
			// 	align-items: flex-start;
			// 	display: flex;
			// 	justify-content: space-between;
			// 	margin-top: 10px;
			// }
		}
	}
}
