.card-thumbnail-custom {
	position: relative;
	color:#1c1f21;
	border-bottom:1px solid #675f3e;
	$c:&;
	&:before{
		content: "";
		display:block;
		background: #f8f7f2;
		width: 100%;
		height: 70%;
		position: absolute;
		bottom:0;
		left:0;
		z-index: 0;
	}
	&-bg{
		display:block;
		background: #f8f7f2;
		width: 100%;
		height: 70%;
		position: absolute;
		bottom:0;
		left:0;
		z-index: 0;
		border:2px solid #f8f7f2;
		transition:0.3s;
		opacity: 1;
	}
	// &.mod-brown{
	// 	&:before{
	// 		background: #675f3e;
	// 	}
	// 	#{$c}-title,
	// 	#{$c}-text{
	// 		color:#fff;
	// 	}
	// }
	// &.mod-green{
	// 	&:before{
	// 		background: #4f6537;
	// 	}
	// 	#{$c}-title,
	// 	#{$c}-text{
	// 		color:#fff;
	// 	}
	// }
	&:hover{
		opacity: 1;
	}
	&.mod-2col{
		width: 475px;
		padding-bottom: 20px;
		margin:0 5px;
		border:0;
		&:before{
			height: 190px;
		}
		#{$c}-bg{
			height: 190px;
		}
		&:hover #{$c}-bg{
			opacity: 0.7;
		}
		#{$c}-thumb{
			height: 235px;
		}
		&:hover #{$c}-thumb{
			opacity: 1;
		}
		#{$c}-title{
			font-size: 28px;
			margin-top: 20px;
		}
	}
	&.mod-4col{
		width: 225px;
		&:before{
			height: 150px;
		}
		#{$c}-thumb{
			height: 186px;
		}
		&:hover #{$c}-thumb{
			transform:translate(0% ,-5px);
		}
		#{$c}-title{
			font-size: 16px;
			padding:10px 0;
		}
	}
	&.mod-5col{
		width: 176px;
		margin-top: 10px;
		&:before{
			height: 117px;
		}
		#{$c}-thumb{
			height: 150px;
		}
		&:hover #{$c}-thumb{
			transform:translate(0% ,-5px);
		}
		#{$c}-title{
			font-size: 13px;
			padding:7px 0;
		}
		&.mod-spacer{
			visibility: hidden;
		}
	}
	&-thumb{
		width: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		transition:0.3s;
		position: relative;
		opacity: 1;
		#{$c}:hover &{
			transform:translate(0% ,-10px);
			opacity: 0.7;
		}
	}
	&-title{
		font-size: 15px;
		font-weight: 700;
		text-align: center;
		font-size: 13px;
		font-weight: bold;
		color:#675f3e;
		position: relative;
		letter-spacing: 0.05em;
		padding: 0 0.7em;
		&.mod-white{
			color:#fff;
		}
	}
	&-text{
		font-size: 14px;
		margin-top: 10px;
		line-height: (23 / 13);
		position: relative;
		text-align: center;
		padding: 0 1em;
		&.mod-white{
			color:#fff;
		}
	}
}
@include breakpoint(sp) {
	.card-thumbnail-custom {
		margin-top: 20px;
		$c:&;
		&.mod-2col{
			width: calc(50% - 10px);
			margin-top: 20px;
			padding-bottom: 10px;
			&:before{
				height: 65%;
			}
			#{$c}-bg{
				height: 65%;
			}
			#{$c}-thumb{
				height: 0;
				padding-top:percentage(235 / 470);
				padding-top: 60%;
			}
			#{$c}-title{
				font-size: 16px;
				margin-top: 10px;
			}
			#{$c}-text{
				font-size: 13px;
				margin-top: 5px;
			}
		}
		&.mod-4col{
			width: calc(50% - 5px);
			margin-top: 20px;
			&:before{
				height: 55%;
			}
			#{$c}-thumb{
				height: 0;
				padding-top:percentage(186 / 225);
			}
			#{$c}-title{
				font-size: 14px;
			}
		}
		&.mod-5col{
			width: calc(32%);
			margin-top: 20px;
			&:before{
				height: 55%;
			}
			#{$c}-thumb{
				height: 0;
				padding-top:percentage(186 / 225);
			}
			#{$c}-title{
				font-size: 12px;
				letter-spacing: 0;
			}
		}
	}
}
