.mypage {
	.border-b {
		border-bottom: 1px solid #c6cfbc;
	}
	.bannar {
		background:url('http://placehold.jp/660x140.png');
		background-repeat: no-repeat;
		background-position: center;
		max-width: 660px;
		width: 100%;
		height: 140px;
	}
	.border-top {
		border-top: 1px solid #cdd2d5;
	}

	.change {
		margin-top: 80px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding-left: 5px;
		a {
			align-items: center;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			font-size: 15px;
			max-width: 355px;
			width: 100%;
			height: 70px;
			&.box-link::after {
				background-color: #fff;
			}
		}
	}
	.f-12 {
		font-size: 12px;
	}
	.f-14 {
		font-size: 14px;
	}

	.kreon-r {
		font-family: 'kreon_r';
	}

	.line28-14 {
		line-height: (28 / 14);
	}
	.pb-10 {
		padding-bottom: 10px;
	}
	.pb-15 {
		padding-bottom: 15px;
	}
	.text-center {
		text-align: center;
	}
	.title.mod-triangle-above.mod-green {
		padding: 15px 19px;
	}
	.top {
		font-weight: 700;
	}
	.w-660 {
		max-width: 660px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

@include breakpoint(sp) {
	.mypage {
		.bannar {
			background:url('http://placehold.jp/560x120.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			max-width: 560px;
			height: 120px;
		}
		.change {
			margin-top: 40px;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			a {
				align-items: center;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				font-size: 12px;
				max-width: 240px;
				width: 100%;
				line-height: 2;
				padding : 10px 25px;
			}
		}
		.news-contents-text.f-14.normal {
			font-size: 13px;
			padding : 0;
		}
		.title.mod-triangle-above.mod-green {
			padding: 15px 10px!important;
		}
	}//mypage
}//breakpoint
