.form_shop{
	@extend .font-yu_go;
	input[name=submit], input[name=submitBack], input[name=submitConfirm] {
		position: static;
		opacity: 1;
		left: 0;
		outline: none;
		border-radius: 0;
	}
	input[type=radio]{
		pointer-events: none;
	}
	width: 100%;
	padding: 0 11px 0 4px;
	margin-top: 17px;
	border-top: solid 1px #000;
	.line{
		padding: 16px 0 12px 5px;
		border-bottom: dashed 1px #cdd2d5;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content:space-between;
		// &:nth-of-type(3){
		// 	border-bottom: solid 1px #cdd2d5;
		// }
		.form-item-input.mod-has-select {
			width:195px;
			& + * {
				padding-left:15px;
			}
		}
	}
	.line > * {
		display: inline-block;
		vertical-align: middle;
	}
	.regularly{
		.row_1{
			font-size: 14px;
			width:100px;
			text-align: left;
			position: relative;
	 	}
		.row_2{
			font-size: 16px;
			width: 100px;
			text-align: left;
			position: relative;
		}
		.row_3{
			font-size: 16px;
			width: 121px;
			text-align: left;
			position: relative;
		}
		.row_4{
			width: 194px;
			font-size: 14px;
			position: relative;
		}
		.row_5{
			font-size: 14px;
			position: relative;
			input{
				position: relative;
				opacity: 1;
				left: inherit;
				vertical-align: middle;
			}
		}
	}

	.once{
		.row_1{
			font-size: 14px;
			width: 138px;
			vertical-align: text-top;
			position: relative;
			.caution_text{
				font-size: 12px;
				color: #4f6537;
				position: absolute;
				top: 20px;
			}
	 	}
		.row_2{
			font-size: 16px;
			@extend .font-kreon_r;
			width: 53px;
		}
		.row_3{
			font-size: 16px;
			@extend .font-kreon_r;
			width: 120px;
		}
		.row_4{
			font-size: 14px;
			width: 192px;
		}
		.row_5{
			font-size: 14px;
			width: inherit;
			input{
				position: relative;
				opacity: 1;
				left: inherit;
				vertical-align: middle;
			}
		}
	}

	.row-has_radio {
		ul li{
			display: inline-block;
			& + li{
				margin-left: 20px;
			}
		}
		label{
			position: relative;
			& + label {
				margin-left: 25px;
			}
			input[type="radio"]{
				position: absolute;
				opacity: 0;
				margin-right: 0;
				left: 0;
				top: 0;
			}
			.radio-text{
				cursor: pointer;
				&:before{
					content: '';
					display: inline-block;
					background: resolve("radio_button.png")  no-repeat 0 0;
					width: width("radio_button.png");
					height: height("radio_button.png");
					margin-right: 6px;
				}
				&.mod-on:before {
					content: '';
					background: resolve("radio-button_on.png")  no-repeat 0 0;
					width: width("radio-button_on.png");
					height: height("radio-button_on.png");
				}
			}
		}
	}
	.input_number{
		width: 41px;
		height: 24px;
		margin-left: 10px;
		border-radius: 0;
	}
	&_under_text{
		font-size: 13px;
		margin-top: 14px;
		padding-left: 9px;
		letter-spacing: 1px;
		line-height: 2;
	}
	.not_in_stock {
		color: red;
		font-weight: 700;
	}
	.nodata {
		display: none!important;
	}
}
@include breakpoint(sp) {
	.form_shop{
		border-top: solid 0px #000;
		border-bottom: solid 0px #000;
		.line {
			display: block;
			label {
				display: block;
				font-size: 13px;
				& + label {
					margin-left: 0!important;
					margin-top: 5px;
				}
			}
			.row_above {
				display: flex;
				justify-content:flex-start;
				flex-wrap:wrap;
				align-items:flex-start;
				font-size: 13px;
				line-height: 1;
				.row + .row {
					margin-left: 20px;
				}
			}
			.row_below {
				margin-top: 15px;
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
				align-items:center;
				font-size: 13px;
				line-height: 1;
				.row {
					position: relative;
				}
			}
		}
		&_title{
			font-size: 14px;
			font-weight: bold;
		}
		&_desc{
			font-size: 13px;
		}
	}
	.select-box{
		width: 100%;
		position: relative;
		margin-top: 15px;
		border: 1px solid #4f6537;
		background-color: #fff;
		border-radius: 0px;
		select {
			background-color: #fff;
			font-weight: bold;
			color: #4f6537;
			padding: 8px 10px;
			width: 90%;
			font-size: 13px;
			border: none;
			cursor: pointer;
			outline: none;
			-webkit-appearance: none;
		}
		&:after{
			content: "";
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7.5px 0;
			border-color: #c3c0b4 transparent transparent;
		}
	}
	.input_number {
		border:1px solid $black;
	}
	.caution_text {
		font-size: 10px;
		color:#4f6537;
		line-height: 2;
		display: block;
	}
}
