.box-slash-heading{
	$c:&;
	@extend .clearfix;
	width: auto;
	height: auto;
	position: absolute;
	top: -39px;
	left: -4px;
	&-above{
		font-size: 20px;
		display: inline-block;
		line-height: 1;
		padding-right: 4px;
		vertical-align: top;
		color: #1c1f21;
		transform:translateX(43px);
		padding-top: 4px;
		letter-spacing: 1.6px;
		//font-family: kreon_r;
	}
	&-below{
		font-size: 20px;
		display: inline-block;
		vertical-align: bottom;
		color: #1c1f21;
		font-size: 12px;
		line-height: 1;
		transform: translateX(-30px);
		padding-bottom: 9px;
		letter-spacing: 1.2px;
	}
	img{
		display: inline-block;
	}

	&.mod-lower {
		width: auto;
		height: auto;
		position: absolute;
		top: -43px;
		left: -1px;
		margin-left: 4px;
		#{$c}-above {
			display: inline-block;
			line-height: 1;
			padding-right: 4px;
			vertical-align: top;
			color: #1c1f21;
			font-size: 16px;
			font-weight: 700;
			transform:translateX(43px);
			padding-top: 7px;
			letter-spacing: 1.6px;
			//font-family: kreon_r;
		}
		#{$c}-below {
			display: inline-block;
			vertical-align: bottom;
			color: #1c1f21;
			font-size: 12px;
			font-weight: 700;
			line-height: 1;
			transform:translateX(-40px);
			padding-bottom: 7px;
			letter-spacing: 1.2px;
		}
		#{$c}-slash {
			display: inline-block;
			img.pc {
				display: inline-block;
			}
			img.sp {
				display: none;
			}
		}
	}// end lower
	&.mod-shishin {
		width: auto;
		height: auto;
		font-style: italic;
		top: -11px;
		left: 45px;
		//ページ内リンク位置調整
		padding-top: 100px;
		margin-top: -100px;
		#{$c}-above {
			display: inline-block;
			line-height: 1;
			padding-right: 4px;
			vertical-align: top;
			font-size: 16px;
			font-weight: 700;
			color: #675f3e;
			transform: translate(13px,-10px);
			padding-top: 4px;
			letter-spacing: 1.6px;
			font-family: kreon_r;
			&.mod-green{
				color: #4f6537;
			}
			& span{
				font-size: 26px;
				font-family: kreon_r;
				margin-left: 9px;
				font-weight: normal;
			}
		}
		#{$c}-below {
			display: inline-block;
			vertical-align: bottom;
			color: #1c1f21;
			font-size: 18px;
			font-weight: 700;
			color: #675f3e;
			line-height: 1;
			transform: translate(-16px,6px);
			padding-bottom: 7px;
			letter-spacing: 1.2px;
			&.mod-green{
				color: #4f6537;
			}
		}
		#{$c}-slash {
			display: inline-block;
			img {
				position: static;
			}
			&.pc{
			display: inline-block;
			}
		}
	}// end .mod-shishin
}

.home {
	.box-slash-heading-above {
		font-family: 'kreon_r';
	}
}

@include breakpoint(sp) {
	.box-slash-heading{
		$c:&;
		width: auto;
		height: auto;
		font-weight: bold;
		position: absolute;
		top: -30px;
		left: -30px;
		min-width: 240px;
		&-above{
			font-size: 18px;
			display: inline-block;
			line-height: 1;
			padding-right: 4px;
			vertical-align: top;
			color: #1c1f21;
			transform:translateX(43px);
			padding-top: 4px;
			letter-spacing: 1.6px;
			//font-family: kreon_r;
		}
		&-below{
			font-size: 12px;
			display: inline-block;
			vertical-align: bottom;
			color: #1c1f21;
			font-size: 12px;
			line-height: 1;
			transform: translateX(-30px);
			padding-bottom: 9px;
			letter-spacing: 1.2px;
		}
		img{
			width: 35%;
			position: static;
		}

		&.mod-lower {
			width: auto;
			height: auto;
			top: -30px;
			margin-left: -10px;
			#{$c}-above {
				transform:translateX(30px);
				padding-top: 3px;
				font-weight: 700;
				-webkit-font-smoothing:auto;
			}
			#{$c}-below {
				transform:translateX(-29px);
				padding-bottom:5px;
			}
			#{$c}-slash {
				img.pc {
					display: none;
				}
				img.sp {
					display: inline-block;
					width: 58px;
				}
			}
		}
		&.mod-shishin {
			width: auto;
			height: auto;
			font-style: italic;
			top: -40px;
			left: 45px;
			position: static;
			text-align: center;
			#{$c}-above {
				display: inline-block;
				line-height: 1;
				padding: 0px;
				vertical-align: middle;
				font-size: 16px;
				font-weight: 700;
				color: #675f3e;
				transform: translate(0px,0px);
				padding-top: 4px;
				letter-spacing: 1.6px;
				font-family: kreon_r;
				& span{
					font-size: 26px;
					font-family: kreon_r;
					margin-left: 0px;
				}
			}
			#{$c}-below {
				display: block;
				text-align: center;
				vertical-align: bottom;
				color: #1c1f21;
				font-size: 18px;
				font-weight: 700;
				color: #675f3e;
				line-height: 35px;
				transform: translateX(0px);
				padding-bottom: 7px;
				letter-spacing: 0px;
				margin-top: 8px;
			}
			#{$c}-slash {
				display: inline-block;
				img {
					position: static;
				}
				&.pc{
				display: none;
				}
			}
		}
	}
	&.pc{
		display: none;
	}
}
