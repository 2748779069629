.footer{
	background-color: #f8f7f2;
	padding-bottom: 40px;
	margin-top: 30px;
	&-inner{
		max-width: 980px;
		width: 100%;
		margin: 0 auto;
		position: relative;
		&::after{
			content: ".";
			display: block;
			height: 0;
			clear: both;
			line-height: 0;
			visibility: hidden;
		}
	}
	&-bg{
	}
}

.lower {
	.footer {
		margin-top: 150px;
	}
}

@include breakpoint(sp) {
	.footer{
		background-color: #f8f7f2;
		padding-bottom: 0;

		&-navi {
			padding: 20px;
		}
		&-inner{
			max-width: 970px;
			width: 100%;
			position: relative;
			&:after {
				display: none;
			}
		}
		&-bg{
			margin-top: 0;
			padding: 25px 20px;
			background-color: #efede5;
		}
		&-split{
			display: inline-block;
			width: 1px;
			height: 12px;
			margin: 0 10px;
			background-color: gray;
			}
	}
	.lower {
		.footer {
			margin-top: 20px;
		}
	}

}
