.box-over_right-bottom-line{
	width: 100%;
	background-color: #f8f7f2;
	position: relative;
	padding: 65px 70px 60px;
	display: block;
	&.mod-green{
		background-color: #f5f7f2;
	}
	&.mod-harf{
		width: 48.4375%;
		margin: 0 auto;
	}
	&::before{
		content: "";
		top: 5px;
		right: 3px;
		position: absolute;
		width: 99%;
		height: 99%;
		border-right: 2px solid #c3c0b4;
		border-bottom: 2px solid #c3c0b4;
		padding: 0;
		pointer-events: none;
	}
}

@include breakpoint(sp) {
	.box-over_right-bottom-line{
		width: 100%;
		background-color: #f8f7f2;
		position: relative;
		padding: 40px 40px 35px;
		display: block;
		&.mod-green{
			background-color: #f5f7f2;
		}
		&.mod-harf{
			width: 100%;
			margin: 0 auto;
			& + .mod-harf{
				margin-top: 15px;
			}
		}
		&::before{
			content: "";
			top: 5px;
			right: 3px;
			position: absolute;
			width: 99%;
			height: 99%;
			border-right: 2px solid #c3c0b4;
			border-bottom: 2px solid #c3c0b4;
			padding: 0;
			pointer-events: none;
		}
	}
}
