.lang{
	position: absolute;
	top: 19px;
	right: 0;
	font-size: 0px;
	&.mod-underlayer{
		top: 18px;
	}
	&.mod-scl{
		position: static;
		font-size: 0px;
		margin-top: 0;
		margin-left: 19px;
		display: inline-block;
	}
	&.mod-footer-spnavi{
		display: inline-block;
		position: relative;
		top: 0px;
	}
	&-btn{
		font-size: 14px;
		letter-spacing: 1.4px;
		font-family: kreon_r;
		font-weight: bold;
		width: 52px;
		text-align: center;
		padding: 4px;
		margin-right: 0px;
		margin-left: auto;
		border: solid 1px;
		border-right: none;
		display: block;
		& + & {
			margin-top: 9px;
		}
		&.mod-scl{
			display: inline;
			padding: 4px 9px;
			& + &{
				border-right: solid 1px;
			}
		}
		&.mod-underlayer{
			width: 50px;
		}
		&.mod-footer-spnavi{
			display: inline;
			padding: 5px 15px;
			position: relative;
			top: 0px;
			& + & {
				border-right: solid 1px;
			}
		}
		a{
			color: #000;
			font-weight: normal;
		}
	}
}
