.shop {
	#wrap {
		opacity: 1;
	}
	#wrap:before {
		content: "";
		// background:resolve('bg-shop@2x.png') repeat center top;
		// background-size: size('bg-shop@2x.png',2);//retinaは第二引数に2
		position: absolute;
		top: 0;
		// width: 100%;
		// height: 1190px;
		background: resolve('bg_pattern.png') center top repeat;
		background-size: size('bg_pattern.png',2);
		width: 100%;
		height: 2100px;
	}

	.box-shadow.mod-pickup {
		border-right:1px solid #c3c0b4;
		border-bottom:1px solid #c3c0b4;
	}

	.thumbnail-transform-item {
		&-label-date {
			margin-top: 0;
			z-index: 2;
			position: relative;
		}
		&-label-lead {
			z-index: 2;
			position: relative;
			margin-top: 4px;
			font-weight: 400;
		}
		&-img {
			width: 232px;
			height: 132px;
			&-book {
				height: 142px;
			}
		}
	}
	&-article-wrap {
		width: 100%;
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
		& > * {
			width: 472px;
			margin-left: 0;
			margin-right: 0;
			display: block;
		}
		&-inner {
			position: relative;
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
			margin-left: 0;
			margin-right: 0;
			border-bottom: 1px solid #c3c0b4;
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 5px 5px;
				border-color: transparent transparent #c3c0b4;
			}
			.thumbnail-transform-item-label:before {
				height: 50px;
			}
			&.mod-after-green {
				border-bottom: 1px solid #c6cfbc;
				.thumbnail-transform-item-label:before {
					background-color: #f5f7f2;
				}
				&:after {
					border-color: transparent transparent #c6cfbc;
				}
			}
			& > * {
				width:49.15254%;
				border-bottom: 0!important;
				display: block;
				&:after {
					display: none;
				}
			}
		}
	}

	.btn-more-wrap {
		text-align: right;
		width: 100%;
		margin-top: 14px;
		a {
			color:$black;
			position: static;
			display: inline-block;
		}
	}

	.section.mod-article {
		margin-top: 60px;
		+ .section.mod-article {
			margin-top: 40px;
		}
	}
}

.section.mod-pickup.mod-shop {
	.box-shadow.mod-area-has-map:after, .box-shadow.mod-pickup:after {
		background-color: #f8f7f2;
	}
	.triangle {
		border-top: 20px solid #c3c0b4;
		border-right: 20px solid transparent;
	}
	.contents-pickup-thumbnail-img-description {
		color: #675f3e;
	}
}

.glid.mod-3col.mod-category {
	& > * {
		width: 310px;
	}
	.thumbnail-item-text {
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		padding:18px 20px;
		letter-spacing: 1.6px;
	}
}
.glid.mod-4col.mod-category {
	margin-top: 14px;
	margin-bottom: -14px;
	& > * {
		width: 230px;
	}
	.thumbnail-item {
		margin-bottom: 14px;
		&-img {
			height: 140px;
		}
		&-text {
			display: flex;
			justify-content:center;
			flex-wrap:wrap;
			align-items:center;
			text-align: center;
			font-size: 13px;
			font-weight: 700;
			padding:11px 20px;
			letter-spacing: 1.6px;
		}
	}

	& + .banner-wrap {
		margin-top: 52px;
	}
}
.section.mod-shop-info + .section.mod-bg-pattern-green {
	margin-top: 90px;
	padding-bottom: 100px;
}

.shop-lower {
	&.lower:before {
		display: none;
	}
	#wrap:before {
		content: "";
		background:resolve('bg-shop@2x.png') repeat center top;
		background-size: size('bg-shop@2x.png',2);//retinaは第二引数に2
		position: absolute;
		top: -7px;
		width: 100%;
		height: 265px;
	}
	.header-under-layer-sub-navi-list {
		.sub-navi-list-item + .sub-navi-list-item {
			margin-left: 26px;
		}
		.sub-navi-list-item {
			padding-bottom: 0;
		}
	}
	.header-under-layer-gnavi-list-item {
		letter-spacing: 1.5px;
	}
	.header-under-layer-gnavi {
		margin-top: 11px;
	}
	.page-header {
		margin-top: 39px;
	}
}
.shop-routine {
	.section.mod-bg-pattern-green {
		margin-top: 70px;
		padding-bottom: 90px;
	}
}

.ie_10 {
	.shop-article-wrap-inner {
		width:472px;
	}
}

@media only screen and (max-width:1205px){
	.shop-top .header-logo h1 img {
		width: 373px;
	}
}

@include breakpoint(sp) {
	.shop {
		#wrap:before {
			top: 14px;
			// height: 1000px;
		}
		.section.mod-pickup.mod-shop + .section.mod-article {
			margin-top: 110px;
		}
		.thumbnail-transform-item {
			padding-bottom: 0;
			margin-bottom: 0;
			&-label-date {
				margin-top: 0;
				z-index: 2;
				position: relative;
			}
			&-label-lead {
				z-index: 2;
				position: relative;
				margin-top: 4px;
			}
			&-img {
				width: 100%;
				height: 104px;
				&-book {
					height: 115px;
				}
			}
		}
		&-article-wrap {
			width: 87.5%;
			max-width: 100%;
			display: block;
			& > * {
				width: 100%;
				padding: 0!important;
			}
			&-inner {
				position: relative;
				display: flex;
				justify-content:space-between;
				flex-wrap:wrap;
				margin-left: 0;
				margin-right: 0;
				border-bottom: 1px solid #c3c0b4;
				padding-bottom: 21px;
				.thumbnail-transform-item-label:before {
					height: 110%;
				}
				& > * {
					width:49.15254%;
					border-bottom: 0!important;
					&:after {
						display: none;
					}
				}
			}
		}

		.btn-more-wrap {
			text-align: center;
			width: 100%;
			margin-top: 0;
			a {
				display: block;
				position: relative;
				top:auto;
				right:auto;
				color:$black;
				font-size: 13px;
				border-right: 1px solid #000;
				border-bottom: 1px solid #000;
				text-align: center;
				width: 240px;
				height: 40px;
				line-height: 40px;
				margin-top: 35px;
				margin-left: auto;
				margin-right: auto;
				&:after {
					position: absolute;
					top: 50%;
					right: -20px;
					transform: translateY(-50%);
					background-color: #fff;
					width: 29px;
					height: 9px;
				}
			}
		}
		.glid.mod-transform-img {
			margin-top: 80px;
		}

		.section.mod-article {
			margin-top: 40px;
			+ .section.mod-article {
				margin-top: 60px;
			}
		}
	}
	.shop-article-wrap .glid.mod-transform-img:first-child {
		margin-top: 40px;
	}
	.section.mod-pickup.mod-shop {
		.triangle {
			border-top: 15px solid #c3c0b4;
			border-right: 15px solid transparent;
		}
		.contents-pickup-thumbnail-subtitle {
			color: #68603e;
		}
	}

	.glid.mod-3col.mod-category {
		width: 87.5%;
		margin:40px auto -18px;
		& > * {
			width: 100%;
			margin-bottom: 18px;
		}
		.thumbnail-item {
			&-img {
				height: 0;
				padding-top: 43.9285%;
			}
			&-text {
				font-size: 14px;
				padding:15px 10px;
				letter-spacing: 1.4px;
			}
		}
	}
	.glid.mod-4col.mod-category {
		width: 87.5%;
		margin:18px auto -18px;
		& > * {
			width: 49.1071%;
		}
		.thumbnail-item {
			margin-bottom: 18px;
			&-img {
				height: 0;
				padding-top: 64%;
			}
			&-text {
				font-size: 12px;
				padding:20px 10px;
				letter-spacing: 1.2px;
			}
		}

		& + .banner-wrap {
			margin-top: 80px;
		}
	}

	.title-shop-characteristic {
		width:77.8125%;
		margin-left: auto;
		margin-right: auto;
	}

	.shop-routine {
		.section.mod-bg-pattern-green {
			margin-top: 0;
			padding-bottom: 0;
			background: none;
			padding-top: 20px;
		}
		.col-img {
			display: none;
		}
		.glid.mod-img-aside {
			width: 100%;
		}
		.glid {
			width: 87.5%;
		}
		.totop-sp {
			margin-top: 30px;
		}
	}
	.shop-lower #wrap:before {
		display: none;
	}

	.section.mod-shop-info + .section.mod-bg-pattern-green {
		margin-top: 45px;
		padding-bottom: 50px;
	}
}
