.contents-event{
	width: 49%;
	background-color: #f8f7f2;
	position: relative;
	padding: 80px 100px 55px;
	display: block;
	&::before{
		content: "";
		top: 10px;
		right: 3px;
		position: absolute;
		width: 99%;
		height: 99%;
		border-right: 2px solid #c3c0b4;
		border-bottom: 2px solid #c3c0b4;
		padding: 0;
		pointer-events: none;
	}
	&-article{
		$c:&;
		display: block;
		color: #000;
		@extend .clearfix;
		&:hover{
			opacity: 1.0;
		}
		& + #{$c} {
			margin-top: 40px;
		}
		&.sp{
			display :none;
		}
		&-thumbail{
			position: relative;
			display: block;
			width: 180px;
			height: 110px;
			background-image: url('http://placehold.jp/180x110.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			float: left;
			&-mask{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				background-color: rgba(0,0,0,0.4);
				transition: all 0.6s ease;
				opacity: 0;
				& p{
					font-size: 14px;
					position: relative;
					color: #ffff;
					margin-top: 35px;
					text-align: center;
					font-family: "kreon_r";
					&::after{
						content: '';
						position: absolute;
						background-image: resolve('arrow-right-mask.png');
						background-repeat: no-repeat;
						background-position: center;
						width: 100%;
						height: 10px;
						top: 25px;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
			}
			&:hover &-mask{
				opacity: 1;
			}
		}
		&-info{
			padding-left: 31px;
			overflow:hidden;
			@extend .clearfix;
		}
		&-place{
			font-size: 12px;
			background-color: #474b42;
			color: #fff;
			padding: 0px 7px;
			height: 22px;
			line-height: 22px;
			display: table;
			font-weight: bold;
		}
		&-title{
			width: auto;
			max-width:200px;
			font-size: 14px;
			border-top: dotted 1px;
			padding-top: 8px;
		}
		&-date{
			font-size: 20px;
			font-family: "kreon_r";
			letter-spacing: 2px;
			margin-top: 10px;
			display: block;
		}
		& + &{
			margin-top: 40px;
		}
	}
}
.contents-event-article-flex-sp {
	@extend .clearfix;
}

@media only screen and (max-width:1281px){
	.contents-event {
		padding: 80px 60px 55px;
	}
}


@include breakpoint(sp) {
	.contents-event-article+.contents-event-article {
		margin-top: 0;
	}
	.contents-event{
		width: 100%;
		background-color: #f8f7f2;
		position: relative;
		padding: 65px 10px 40px;
		margin: 0 auto;
		&::before{
			content: "";
			top: 10px;
			right: 5px;
			position: absolute;
			width: 98%;
			height: 99%;
			border-right: 2px solid #c3c0b4;
			border-bottom: 2px solid #c3c0b4;
			padding: 0;
			pointer-events: none;
		}
		&.no-events {
        	display: none;
    	}
		&-article{
			display: block;
			margin-top: 35px;
			&.pc{
				display :none;
			}
			&.sp{
				margin-top: 10px;
				display :block;
			}
			&.sp + &.sp {
				margin-top: 20px;
			}
			&-flex-sp{
				margin-top: 10px;
			}
			&-thumbail{
				width:41.4285%;
				float: left;
				height: 0;
				padding-top: 30%;
			}
			&-info{
				width: 97%;
				padding-left: 0;
			}
			&-place{
				font-size: 11px;
				background-color: #000;
				color: #fff;
				padding:0 8px;
				height: auto;
				display: block;
				margin-right: 15px;
				float: left;
			}
			&-title{
				font-size: 14px;
				border-top: dotted 0px;
				padding-top: 8px;
				padding-left: 10px;
				overflow: hidden;
				width: auto;
			}
			&-date{
				margin-top: 0px;
				font-size: 15px;
				font-family: "kreon_r";
				letter-spacing: 2px;
				display: block;
				overflow: hidden;
				background:resolve('line-black-dashed.png') no-repeat center bottom;
				background-size: size('line-black-dashed.png',2);
			}
		}
	}
}
