.footer-navi{
	float: left;
	list-style: none;
	margin-top: 52px;
	.sub-menu {
		margin-top: 10px;
		padding-bottom: 11px;
		a {
			font-weight: 500;
		}
	}
	&-title{
		font-size: 14px;
		font-weight: 700;
		position: relative;
		letter-spacing: 2px;
		display: table;
		color: #000;
		vertical-align: middle;
		line-height: 1;
		letter-spacing: 1px;
		a {
			color: $black;
		}
		&.mod-event{
			margin-top: 27px;
		}
		&.mod-read{
			margin-top: 18px;
		}
		&:before{
			content: "";
			display: inline-block;
			width: 6px;
			height: 6px;
			background-color: #68603e;
			line-height: 1;
			margin-right: 10px;
			vertical-align: middle;
		}
		margin-top: 18px;
	}
	&-title-under-line{
		font-size: 14px;
		font-weight: 700;
		position: relative;
		padding-bottom: 1px;
		border-bottom: solid 1px;
		display: table;
		color: #000;
		letter-spacing: 1px;
	}
	&-child{
		font-size: 12px;
		position:relative;
		color: #000;
		letter-spacing: 1px;
		line-height: 2;
		a {
			color: $black;
		}
		&:before{
			content: '-';
			position: relative;
			margin-right: 5px;
		}
		&.mod-kreon{
			font-family: kreon_r;
		}
	}
	.sep {
		display: none;
	}
}

li.footer-navi-title:nth-of-type(1) {
	margin-top: 0;
}

@include breakpoint(sp) {
	.footer-navi{
		float: none;
		list-style: none;
		margin-top: 0;
		&-title{
			font-size: 15px;
			font-weight: 700;
			position: relative;
			letter-spacing: 2px;
			display: table;
			text-indent: 1em;
			margin-top: 0;
			width: 50%;
			&.menu-item-has-children {
				width: 100%;
			}
			&.mod-event{
				display: inline-block;
				margin-top: 15px;
			}
			&.mod-read{
				display: inline-block;
				margin-top: 15px;
				margin-left: 70px;
			}
			&:before{
				content: '';
				position: absolute;
				top: 5px;
				left: -15px;
				width: 6px;
				height: 6px;
				background-color: #000;
				margin-left: 1em;
			}
			& + ul {
				margin-top: 3px;
			}
		}
		&-title-under-line{
			font-size: 14px;
			font-weight: 700;
			position: relative;
			border-bottom: solid 1px #c3c0b4;
			display: inline-block;
		}
		&-child{
			font-size: 12px;
			position:relative;
			&:before{
				content: '';
				position: absolute;
				top: -4px;
				left: -15px;
			}
			&.mod-kreon{
				font-family: kreon_r;
			}
		}
		.sub-menu {
			text-indent: 0;
			font-size: 0;
			a {
				font-size: 12px;
			}
			li {
				text-indent: 0;
				&:after {
					content:"";
					margin-left: 10px;
					margin-right: 10px;
					height: 12px;
					width: 1px;
					background-color: $black;
					display: inline-block;
					vertical-align: -1px;
				}
				&:last-child:after {
					display: none;
				}
			}
			.sep {
				display: block;
			}
		}
	}
}
