.text{
	&_post{
		font-size: 14px;
		font-weight: 500;
		line-height: 2;
	}
	&_link{
		color: #4f6537;
		border-bottom: solid 1px #4f6537;
		display: inline;
		&.mod-border_green{
			border-color: #4f6537;
		}
	}
	&_caution{
		font-size: 12px;
		font-weight: bold;
		color: #ff5f3a;
	}
	&_red{
		color: #ff5f3a;
		&.mod-bold{
			font-weight: bold;
		}
	}
	&_back-home{
		text-align: center;
		font-size: 12px;
		font-weight: bold;
		a {
			color: #000;
		}
	}
	&_green{
		font-size: 14px;
		color: #4f6537;
		&.mod-small{
			font-size: 13px;
		}
		&.mod-bold{
			font-weight: bold;
		}
	}
	&_order-list-info{
		margin-top: 40px;
		@extend .font-kreon_r;
		&_order{
			letter-spacing: 1px;
		}
		&_num{
			margin-left: 17px;
			letter-spacing: 1.5px;
		}
		&_date{
			letter-spacing: 1px;
			margin-left: 19px;
		}
		&_supplement{
			margin-left: 17px;
			letter-spacing: 1.5px;
			font-size: 13px;
		}
	}
	&-caution {
		font-size: 13px;
		font-weight: bold;
		color:red;
	}
}

@include breakpoint(sp){
	.text_order-list-info_order {
		span {
			display: block;
			padding-left: 0!important;
		}
	}
	.text_order-list-info_supplement,
	.text_order-list-info_date {
		display: block;
		margin-left: 0;
	}
}
