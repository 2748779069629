.side-article {
	&-title {
		margin-top: 30px;
		font-size: 14px;
		font-weight: 700;
		line-height: (34 / 14);
		padding-bottom: 4px;
		border-bottom: 1px solid #1c1f21;
		padding-bottom: 0;
		margin-bottom: 10px;
	}
	&-item {
		background:resolve('line-black-dashed.png') repeat-x left bottom;
		background-size: size('line-black-dashed.png',2);
		padding-bottom: 13px;
		margin-bottom: 10px;
		word-wrap:break-word;
		a {
			color: #1c1f21;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.9;
			@include opacity--anim(.5);
			display: block;
		}
	}
}
@include breakpoint(sp) {
	.side-article {
		&-title {
			margin-top: 25px;
			margin-bottom: 10px;
		}
		&-item {
			padding-bottom: 10px;
			margin-bottom: 10px;
		}
	}
}
