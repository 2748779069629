.post-content {
	margin-top: 62px;
	font-size: 14px;
	font-weight: 500;
	line-height: (28 / 14);
	* + * {
		margin-top: 28px;
	}
	h2 {
		color: #4f6537;
		font-size: 18px;
		font-weight: 700;
		line-height: (28 / 18);
		padding-bottom: 5px;
		border-bottom: 1px solid #c6cfbc;
		& + * {
			margin-top: 13px;
		}
	}

	h3 {
		font-size: 18px;
		font-weight: 700;
		line-height: (28 / 18);
		font-style: italic;
		& + * {
			margin-top: 8px;
		}
	}

	.wp-caption {
		margin-top: 25px;
		& + * {
			margin-top: 32px;
		}
	}
	.wp-caption-text {
		font-weight: 700;
		margin-bottom: 14px;
		&:before {
			display: inline-block;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 6px 0 6px;
			border-color: #c6cfbc transparent transparent transparent;
			margin-right: 10px;
			vertical-align: 1px;
		}
	}

	ul {
		li {
			+ li {
				margin-top: 0;
			}
			&:before {
				content: "";
				border-radius: 50%;
				background-color: #c6cfbc;
				width: 6px;
				height: 6px;
				display: inline-block;
				margin-right: 11px;
				vertical-align: 2px;
			}
		}
	}
	blockquote {
		font-style: italic;
		position: relative;
		color: #4f6537;
		font-weight: 700;
		width: 95.4545%;
		padding: 13px 37px;
		&:before {
			content: "“";
			@extend .font-yu_mincho;
			color: #c6cfbc;
			font-size: 50px;
			font-weight: 500;
			line-height: 1;
			position: absolute;
			top: 0;
			left: 0;
		}
		&:after {
			content: "”";
			@extend .font-yu_mincho;
			color: #c6cfbc;
			font-size: 50px;
			font-weight: 500;
			line-height: 1;
			position: absolute;
			bottom: -22px;
			right: -8px;
		}
	}

	strong {
		color: #4f6537;
		font-weight: 700;
	}

	a {
		color: #4f6537;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
			opacity: 1;
		}
	}

	table {
		td,
		th {
			border: 1px solid #ccc;
			padding: 10px 15px;
		}
		&.mod-narrow {
			td,
			th {
				padding: 5px 8px;
			}
		}
		&.has_no_sideborder {
			td,
			th {
				border: 1px dotted #ccc;
				border-left: 0;
				border-right: 0;
			}
		}
	}

	iframe {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	// &_header {
	// 	width: 458px;
	// 	margin: 0 auto;
	// 	text-align: center;
	// }
	// &_header h1 {
	// 	letter-spacing: 0.08em;
	// 	line-height: 1.7;
	// 	text-align: center;
	// 	display: inline;
	// }
	// &_meta {
	// 	display: flex;
	// 	margin-top: 28px;
	// 	margin-bottom: 32px;
	// 	justify-content: center;
	// 	&.pc {
	// 	}
	// 	&.sp {
	// 		display: none;
	// 	}
	// }
	// &_label {
	// 	margin-left: 10px;
	// 	margin-right: 10px;
	// }
	// &_tag {
	// 	margin-left: 10px;
	// 	margin-right: 10px;
	// }
	// &_date {
	// 	&:before {
	// 		content: "";
	// 		display: inline-block;
	// 		background-color: #000;
	// 		width: 1px;
	// 		height: 12px;
	// 		margin-right: 23px;
	// 	}
	// 	font-family: "calibri", Garamond, "Comic Sans MS", sans-serif;
	// 	font-size: 13px;
	// 	margin-left: 10px;
	// 	margin-right: 10px;
	// 	letter-spacing: -0.03em;
	// 	// span{
	// 	// border-left: 1px solid #000;
	// 	// }

	// 	&.sp {
	// 		display: none;
	// 	}
	// }
	&.mod-yomimono-new {
		width: 640px;
		max-width: 100%;
		margin: 0 auto;
		// h2 {
		// 	border: none;
		// 	font-size: 24px;
		// }
		// p {
		// 	letter-spacing: -0.04em;
		// }
		// & * + * {
		// 	margin-top: 29px;
		// }
		// & > * {
		// 	width: 90%;
		// 	margin-left: auto;
		// 	margin-right: auto;
		// }
		// & > img {
		// 	width: 100%;
		// }
		// img + * {
		// 	margin-top: 42px;
		// }
		// img:not(:first-child) {
		// 	margin-top: 36px;
		// }
		// & + .mod-article {
		// 	.thumbnail-basic-item-ttl {
		// 		margin-top: 83px;
		// 		margin-bottom: 14px;
		// 	}
		// }
		strong {
			color: #000;
		}

		a.has-border {
			color: #000;
			transition: color 0.3s;
			border-bottom: 1px dashed #53942b;
			text-decoration: none;
			&:hover {
				color: #53942b;
			}
		}

		h2 {
			font-size: 22px;
			line-height: (39 / 24);
			padding-left: 30px;
			position: relative;
			border-bottom: 0;
			color: #000;
			margin-top: 60px;
			&:before {
				content: "";
				display: block;
				background: resolve("icon_h2.png") center no-repeat;
				background-size: size("icon_h2.png", 2);
				width: width("icon_h2.png", 2);
				height: height("icon_h2.png", 2);
				position: absolute;
				left: 6px;
				top: 5px;
			}
		}
		.wp-caption {
			border: 0;
			padding: 0;
			width: 100%;
			max-width: 100%;
			margin-left: 0;
			margin-right: 0;
			&.alignnone {
				margin-left: 0;
				margin-right: 0;
			}
			img {
				max-width: 100%;
			}
			.wp-caption-text {
				&:before {
					content: "";
					display: none;
				}
				font-size: 12px;
				text-align: left;
				margin-top: 10px;
				font-weight: normal;
				padding: 0;
				line-height: 1.7;
			}
		}
	}
}
.no-header .post-content {
	margin-top: 28px;
}

.list-point {
	li {
		padding-left: 16px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			border-radius: 50%;
			background-color: #c6cfbc;
			width: 6px;
			height: 6px;
			display: inline-block;
			left: 0;
			top: 10px;
		}
	}
}
.img_wrap-2col {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	& > * {
		width: 49%;
		margin-top: 0 !important;
	}
	&.sp {
		display: none;
	}
}
a.link-icon-map {
	display: inline-block;
	background: url(../images/line-black-dashed.png) repeat-x center bottom;
	background-size: size("line-black-dashed.png", 2);
	color: $black !important;
	text-decoration: none !important;
	line-height: 1;
	padding-bottom: 3px;
	margin-left: 8px;
	border-bottom: 0 !important;
	&:before {
		content: "";
		display: inline-block;
		background: resolve("icon_map.png") no-repeat left top;
		background-size: size("icon_map.png");
		width: width("icon_map.png");
		height: height("icon_map.png");
		margin-right: 4px;
		vertical-align: -1px;
	}
}

.page-template-default-page-php {
	.post-content {
		margin-top: 0;
		width: 87.5%;
		margin-left: auto;
		margin-right: auto;
	}
}

.icon-sentence {
	@extend .clearfix;
	&-img {
		float: left;
		width: 10%;
	}
	&-text {
		float: left;
		width: 90%;
		margin: 1em 0 0;
	}
}
@include breakpoint(sp) {
	.post-content {
		margin-top: 40px;
		font-size: 13px;
		* + * {
			margin-top: 22px;
		}
		// &_header {
		// 	text-align: left;
		// 	.post-content {
		// 		&_meta {
		// 			&.pc {
		// 				display: none;
		// 			}
		// 			&.sp {
		// 				display: flex;
		// 				justify-content: left;
		// 				margin-top: 5px;
		// 				margin-bottom: 18px;
		// 				.post-content {
		// 					&_label {
		// 						margin: 0;
		// 						line-height: 1.6;
		// 					}
		// 					&_tag {
		// 						font-size: 11px;
		// 					}
		// 				}
		// 			}
		// 		}
		// 		&_date {
		// 			&.sp {
		// 				&:before {
		// 					content: "更新日";
		// 					width: 40px;
		// 					background-color: inherit;
		// 					margin-right: 0;
		// 					font-size: 11px;
		// 				}
		// 				display: block;
		// 				float: right;
		// 				font-size: 11px;
		// 				margin-top: 27px;
		// 				margin-right: 0;
		// 				margin-bottom: 6px;
		// 			}
		// 		}
		// 	}
		// }
		&.mod-yomimono-new {
			// p {
			// 	font-size: 12px;
			// }
			// img {
			// 	& + * {
			// 		margin-top: 28px;
			// 	}
			// }
			font-size: 14px;
			& + .mod-article {
				.thumbnail-basic-item-ttl {
					text-align: center;
					margin-top: 25px;
					font-size: 16px;
					width: 90%;
					margin-left: auto;
					margin-right: auto;
				}
			}
			h2 {
				font-size: 17px;
				line-height: (26 / 16);
				padding-left: 20px;
				margin-top: 60px;
				&:before {
					content: "";
					display: block;
					background: resolve("icon_h2.png") center no-repeat;
					background-size: size("icon_h2.png", 2.5);
					width: width("icon_h2.png", 2.5);
					height: height("icon_h2.png", 2.5);
					position: absolute;
					left: 0px;
					top: 3px;
				}
			}
		}

		h2 {
			font-size: 14px;
			line-height: (48 / 28);
			padding-bottom: 10px;
		}

		h3 {
			font-size: 14px;
			& + * {
				margin-top: 10px;
			}
		}

		.wp-caption {
			margin-top: 22px;
			& + * {
				margin-top: 22px;
			}
		}
		.wp-caption-text {
			font-weight: 700;
			margin-bottom: 10px;
			&:before {
				margin-right: 8px;
			}
		}

		ul {
			li {
				+ li {
					margin-top: 0;
				}
				&:before {
					margin-right: 5px;
				}
			}
		}

		blockquote {
			width: 100%;
			padding: 20px;
			&:before {
				font-size: 40px;
			}
			&:after {
				font-size: 40px;
				bottom: -20px;
				right: 13px;
			}
		}
		// &.mod-yomimono-new {
		// 	width: 84%;
		// 	& > * {
		// 		width: 100%;
		// 	}
		// }
		// &_header {
		// 	width: 84%;

		// 	h1 {
		// 		font-size: 17px;
		// 		text-align: left;
		// 		line-height: 1.8;
		// 		letter-spacing: 0.02em;
		// 	}
		// }
	}
	.post-content_header {
		h1 {
			font-size: 17px;
		}
	}
	.img_wrap-2col {
		&.sp {
			display: flex;
		}
	}
	a.link-icon-map {
		&:before {
			content: "";
			display: inline-block;
			background: url(../images/icon_map.png) no-repeat 0 0;
			background-size: 8px auto;
			width: 8px;
			height: 11px;
			margin-right: 4px;
			vertical-align: -1px;
		}
	}

	.page-template-default-page-php {
		.post-content {
			margin-top: 0;
		}
	}

	.icon-sentence {
		&-img {
			float: left;
			width: 15%;
		}
		&-text {
			float: left;
			width: 85%;
			margin: 1em 0 28px;
		}
	}
}
