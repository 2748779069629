.corporate_shipping {
	width: 960px;
	margin: 60px auto 0;

	font-size: 14px;
	line-height: 2;

	.common-component {
		.article-header-headline_triangle.mod-h1 {
			font-size: 17px;
			line-height: 1.7;
		}
		.article-header-headline_triangle.mod-h2 {
			margin: 40px 0 15px;
		}
	}
	.header-bottom-text {
		margin-top: -15px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		.text {
			padding-left: 28px;
		}
		& + .columns {
			margin-top: 20px;
		}
	}


	.columns {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.column {
			width: percentage(470/960);

			&-head {
				padding: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				.title {
					text-align: center;
					font-weight: 700;
					line-height: 1.7;
					&-main {
						display: block;
						font-size: 16px;
					}
				}
			}

			&-body {
				padding: 30px;
			}

			&.mod-green {
				.column-head {
					background-color: #c6cfbc;
				}
				.column-body {
					background-color: #f5f7f2;
				}
			}
			&.mod-brown {
				.column-head {
					background-color: #c3c0b4;
				}
				.column-body {
					background-color: #f8f7f2;
				}
			}
		}

		& + .text {
			margin-top: 20px;
		}
	}

	.table {
		width: 100%;
		font-size: 13px;
		line-height: 1.7;

		&-title {
			font-weight: 700;
			margin-bottom: 2px;
		}
		& + .table-title {
			margin-top: 20px;
		}

		thead {
			border-top: 1px solid #cdd2d5;
		}
		tbody {
			border-top: 1px solid #cdd2d5;
			tr {
				border-bottom: 1px solid #cdd2d5;
				th {
					font-weight: normal;
				}
				td {
					background-color: #fff;
				}
			}
		}
		tr {
			> * + * {
				border-left: 1px dotted #cdd2d5;
			}
			th, td {
				padding: 6px;
				text-align: center;
				vertical-align: middle;
			}
		}

		&.mod-3col {
			tr {
				> * {
					width: percentage(1/3);
				}
			}
		}
		&.mod-2col {
			tr {
				> * {
					width: percentage(1/2);
				}
			}
		}
	}

}

@include breakpoint(sp) {
	.corporate_shipping {
		width: 100%;
		margin-top: 40px;
		font-size: 13px;

		.common-component {
			.article-header-headline_triangle.mod-h1 {
				font-size: 17px;
				line-height: 1.7;
			}
			.article-header-headline_triangle.mod-h2 {
				margin: 40px 0 20px;
			}
		}
		.header-bottom-text {
			flex-wrap: wrap;
			.text {
				padding: 0 25px;
			}
			.link-arrow {
				margin-top: 10px;
				width: 100%;
				display: block;
				text-align: right;
			}
		}

		article {
			width: 87.5%;
			margin: 0 auto;
		}

		.columns {
			flex-wrap: wrap;

			.column {
				width: 100%;

				&-head {
					padding: 10px;
					.title {
						&-main {
							font-size: 15px;
						}
					}
				}

				&-body {
					padding: 20px;
				}
			}
			.column + .column {
				margin-top: 40px;
			}
		}

		.table {
			font-size: 12px;
		}
	}
}
