.mv{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 30;
	background-image: resolve('mv_01.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
	background-position: center center;
	img {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}
	&.mod-sp {
		display: none;
	}
}
#intro_filter {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index:40;
	background:resolve('animation-loading.gif') no-repeat center center #fff;
	background-size: size('animation-loading.gif',2);
}
.mv-click{
	display: none;
}

@include breakpoint(sp){
	.mv {
		&.mod-sp {
			display: block;
		}
		&.mod-pc {
			display: none;
		}
		img {
			max-width:80%;
		}
	}
}
