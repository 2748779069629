.box-shadow{
	width: 100%;
	border-right: 2px solid #c6cfbc;
	border-bottom: 2px solid #c6cfbc;
	position: relative;
	background-color: #fff;
	&.mod-parts{
		padding: 30px 10px;
		&:after{
			content: "";
			width: 100%;
			height: 100%;
			right: -8px;
			bottom: -8px;
			position: absolute;
			background-color: #f5f7f2;
			z-index: -10;
		}
		&.mod-brown{
			border-right: 2px solid #c3c0b4;
			border-bottom: 2px solid #c3c0b4;
			&:after{
				content: "";
				width: 100%;
				height: 100%;
				right: -8px;
				bottom: -8px;
				position: absolute;
				background-color: #f8f7f2;
				z-index: -10;
			}
		}
		&.mod-harf{
			width: 48.4375%;
		}
		&.mod-trisection{
			width: 30.208333333333332%;
		}
	}
	&.mod-pickup{
		width: 960px;
		margin: 0 auto;
		padding: 30px 40px;
		padding-bottom: 33px;
		padding-right: 30px;
		&:after{
			content: "";
			width: 100%;
			height: 100%;
			right: -8px;
			bottom: -8px;
			position: absolute;
			background-color: #f5f7f2;
			z-index: -10;
		}
	}
	&.mod-area-has-map{
		&:after{
			content: "";
			width: 100%;
			height: 100%;
			right: -10px;
			bottom: -10px;
			position: absolute;
			background-color: #f5f7f2;
			z-index: -10;
		}
	}
}



@include breakpoint(sp) {
	.box-shadow{
		border-right: 2px solid #c6cfbc;
		border-bottom: 2px solid #c6cfbc;
		position: relative;
		background-color: #fff;
		&.mod-parts{
			padding: 30px;
			&:after{
				content: "";
				width: 100%;
				height: 100%;
				right: -8px;
				bottom: -8px;
				position: absolute;
				background-color: #f5f7f2;
				z-index: -10;
			}
			&.mod-brown{
				border-right: 2px solid #c3c0b4;
				border-bottom: 2px solid #c3c0b4;
				&:after{
					content: "";
					width: 100%;
					height: 100%;
					right: -8px;
					bottom: -8px;
					position: absolute;
					background-color: #f8f7f2;
					z-index: -10;
				}
			}
			&.mod-harf{
				width: 100%;
			}
			&.mod-trisection{
				width: 100%;
			}
			& + .mod-parts{
				margin-top: 25px;
			}
		}
		&.mod-pickup{
			display: block;
			margin-top: 0px;
			width: 92%;
			padding-left: 0px;
			padding-right: 0px;
			&:after{
				content: "";
				width: 100%;
				height: 100%;
				right: -5px;
				bottom: -5px;
				position: absolute;
				background-color: #f5f7f2;
				z-index: -10;
			}
		}
	}
}
