.area-has-deviated_line {
	$c:&;
	position: relative;
	background-color: #f5f7f2;
	padding:69px 82px;
	max-width: 960px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	& + #{$c} {
		margin-top: 140px;
	}
	&:before {
		content: "";
		top: 10px;
		right: 5px;
		position: absolute;
		width: 100%;
		height: 99%;
		border-right: 2px solid #c6cfbc;
		border-bottom: 2px solid #c6cfbc;
		padding: 0;
		pointer-events: none;
		padding-bottom: 3px;
	}
	&:nth-child(odd) {
		background-color: #f8f7f2;
		&:before {
			border-right: 2px solid #c3c0b4;
			border-bottom: 2px solid #c3c0b4;
		}
	}
	&-introduction {
		@extend .clearfix;
	}
	&-slide {
		float: left;
		width: 316px;
		&-main {
			position: relative;
			width: 316px;
			height: 200px;
			overflow: hidden;
			& > * {
				padding:0;
				margin: 0;
				width: 316px;
				height: 200px;
				background-repeat:no-repeat;
				background-position:center center;
				background-size:cover;
				opacity: 0;
				position: absolute;
				transition:opacity .6s;
				&.active {
					opacity: 1;
				}
			}
		}
		&-ctrl {
			display: flex;
			justify-content:space-between;
			flex-wrap:wrap;
			margin-top: 11px;
			&:after {
				content:"";
				padding:0;
				margin: 0;
				width: 98px;
				height: 0;
				display: block;
			}
			& > * {
				margin-bottom: -5px;
				cursor: pointer;
				padding:0;
				margin: 0;
				width: 98px;
				height: 60px;
				background-repeat:no-repeat;
				background-position:center center;
				background-size:cover;
				@include opacity--anim(.5);
				transition:opacity .6s;
				display: block;
				&.active {
					opacity: .5;
				}
				margin-bottom: 5px;
			}
		}
	}
	&-title {
		font-size: 20px;
		line-height: 2;
		margin-top: -10px;
	}
	&-addr {
		font-size: 13px;
		line-height: 2;
		position: relative;
		padding-left: 20px;
		margin-top: 6px;
		+ * {
			margin-top: 8px;
		}
		&:before {
			content:"";
			top: 4px;
			left: 0;
			display: block;
			position: absolute;
			background:resolve('ico_place_bg.png') no-repeat left top;
			background-size: size('ico_place_bg.png', 2);
			width:width('ico_place_bg.png', 2);
			height:height('ico_place_bg.png', 2);
		}
	}
	&-explain {
		float: right;
		width: 437px;
		color: #1c1f21;
		font-size: 14px;
		font-weight: 500;
		line-height: (28 / 14);
		margin-right: 4px;
	}
	&-infomation {
		margin-top: 41px;
		background: #fff;
		position: relative;
		display: flex;
		justify-content:space-between;
		flex-wrap:wrap;
	}
	&-btns {
		display: flex;
		justify-content:center;
		flex-wrap:wrap;
		margin-top: 30px;
		> .btn-half_line.mod-narrow {
			width: 120px;
			min-width: 120px;
			font-size: 13px;
			padding-left: 0;
			padding-right: 0;
			letter-spacing: 1px;
		}
		> .btn-half_line + a{
			display: block;
			margin-left: 30px;
		}
	}
	&-sns {
		position: absolute;
		right:416px;
		top: -15px;
		& > * {
			margin-left: 10px;
			@include opacity--anim(.5);
		}
	}
	&-detail {
		width: 420px;
		padding:22px 30px;
		min-height: 225px;
		display: flex;
		justify-content:flex-start;
		flex-wrap:wrap;
		align-items:center;
	}
	&-detail_table {
		width: 100%;
		th {
			width: 80px;
			color: #1c1f21;
			font-size: 13px;
			font-weight: 700;
			line-height: 2;
			letter-spacing: 1.3px;
			text-align: left;
		}
		td {
			color: #1c1f21;
			font-size: 13px;
			font-weight: 500;
			line-height: 2;
			//letter-spacing: 1.3px;
		}
	}
	&-map {
		width: 380px;
		height: 100%;
		position: absolute;
		border:2px solid #fff;
		right: 0;
		top: 0;
	}
	.js-map {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	&.mod-masonry {
		width: 298px;
		margin-bottom: 32px;
		padding: 0;
		border-bottom:1px solid #c3c0b4;
		position: relative;
		margin-top: 0;
		@include breakpoint(sp){
			width: 90%;
			position: relative!important;
			top: auto!important;
			left: auto!important;
		}
		&:before {
			display: none;
		}
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 5px 5px;
			border-color: transparent transparent #c3c0b4;
		}
		.area-has-deviated_line-slide-main {
			height: auto;
		}
		.area-has-deviated_line-slide,.area-has-deviated_line-explain,.area-has-deviated_line-slide-main {
			float: none;
			width: 100%;
		}
		.area-has-deviated_line-slide-main > * {
			opacity: 1;
			width: 100%;
			height: auto;
			z-index: 2;
			position: relative;
		}
		.area-has-deviated_line-title {
			font-size: 16px;
			line-height: 1.625;
			margin-top: 0;
		}
		.area-has-deviated_line-explain {
			padding: 17px 17px 25px;
			background-color: #f8f7f2;
		}
		.area-has-deviated_line-addr {
			line-height: 1.6;
			margin-top: 13px;
			&:before {
				top: 1px;
			}
		}
		.area-has-deviated_line-btns {
			margin-top: 20px;
		}
	}
}
.page-header + .area-has-deviated_line {
	margin-top: 120px;
}
@include breakpoint(sp) {
	.area-has-deviated_line {
		$c:&;
		padding:60px 12px 40px 10px;
		width: 90%;
		&:before {
			height:99.3%;
		}
		& + #{$c} {
			margin-top: 75px;
		}

		&-slide {
			float: none;
			width: 100%;
			&-main {
				position: relative;
				width: 100%;
        height: 200px;
				& > * {
					width: 100%;
					height: 100%;
				}
			}
		}
		&-title {
			margin-top: 15px;
		}
		&-btns {
			> .btn-half_line.mod-narrow {
				margin-bottom: 0;
				margin-top: 0;
			}
		}
		&-explain {
			float: none;
			width: 100%;
			//margin-top: 40px;
			font-size: 13px;
		}
		&-infomation {
			margin-top: 30px;
			display: block;
		}
		&-sns {
			left: auto;
			right: 15px;
		}
		&-detail {
			width: 100%;
			padding:30px 20px 20px;
			display: block;
			min-height: auto;
		}
		&-detail_table {
			width: 100%;
			th {
				width: 70px;
			}
			th,td {
				line-height: (48 / 26);
				font-size: 13px;
				a {
					color:$black;
				}
			}
			td {
				font-weight: 400;
			}
		}
		&-map {
			width: 100%;
			padding-top: 60%;
			position: relative;
		}
	}
	.page-header + .area-has-deviated_line {
		margin-top: 70px;
	}
}
