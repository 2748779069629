.detail-info {
	&-info{
		width: 100%;
		max-width: 660px;
		margin: 0 auto;

		a{
			color:#4f6537;
		}
		h3{
			border-bottom: 1px solid #000;
			padding:14px 10px;
			font-size: 14px;
		}
		dl{
			@extend .clearfix;
			line-height: 2;
			border-bottom: 1px solid #cdd2d5;
			font-size: 13px;
		}
		dt{
			clear: left;
			float: left;
			font-size: 13px;
			width: 170px;
			font-weight: bold;
			padding:8px 10px 7px 10px;
			border-top: 1px solid #cdd2d5;
			&:first-of-type{
				border-top: 0;
			}
		}
		dd{
			float: left;
			padding:8px 0 7px;
			width: 230px;
			border-top: 1px solid #cdd2d5;
			//border-right: 1px dashed #cdd2d5;
			background:resolve('line-user-table.png') repeat-y 100% top;
			background-size: size('line-user-table.png',2);
			&:first-of-type{
				border-top: 0;
			}
			& + dd{
				float: left;
				padding:8px 0 7px;
				width: 260px;
				text-align: center;
				border-top: 1px solid #cdd2d5;
				border-right: 0px solid #cdd2d5;
				background-image: none;
			}
		}
	}
}
@include breakpoint(sp) {

}
