

.card-thumbnail-book{
	padding-top: 10px;
	padding-bottom: 16px;
	display: block;
	border-bottom: solid 1px #c6cfbc;
	position: relative;
	color: #4f6537;
	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 5px 5px;
		border-color: transparent transparent #c6cfbc transparent;
	}

	&.mod-4col{
		width: 225px;
		&:nth-child(n+5){
			margin-top: 60px;
		}
	}
	&:hover{
		opacity: 1.0;
	}
	&-date{
		font-size: 12px;
		text-align: right;
		line-height: 1;
		font-family: kreon_r;
		position: absolute;
		top:0;
		right: 0;
	}
	&-img{
		margin-top: 5px;
		position: relative;
		width: 225px;
		height: 130px;
		background-image: url("http://placehold.jp/225x130.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		&-book{
			width: 100%;
			height: 140px;
			z-index: 2;
			position: relative;
		}


		&:before{
			content: "MORE";
			display:block;
			position: relative;
			color: #fff;
			font-family: kreon_r;
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding-top:calc(31% - 1em);
			letter-spacing: 0.1em;
			background-color: rgba(0,0,0,0.4);
			text-align: center;
			transition: all 0.6s ease;
			opacity: 0;
		}
		&:after{
			content: '';
			position: absolute;
			background:resolve("arrow-right-mask.png") center no-repeat;
			background-size:size("arrow-right-mask.png");
			width:width("arrow-right-mask.png");
			height:height("arrow-right-mask.png");
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			margin-top: 20px;
			transition: all 0.6s ease;
			opacity: 0;
		}
		&:hover:before,
		&:hover:after{
			opacity: 1;
		}
	}
	&-tag{
		font-size: 14px;
		text-align: center;
		margin-top: 25px;
		font-weight: bold;
		position: relative;
		height: 30px;
		overflow: hidden;
		&:before,
		&:after{
			content: "";
			display:block;
			position: absolute;
			left:0;
			border-right:#eaefe5 15px solid;
			border-left:transparent 15px solid;
		}
		&:before{
			border-top:transparent 15px solid;
			border-bottom:#eaefe5 15px solid;
			bottom:50%;
		}
		&:after{
			border-top:#eaefe5 15px solid;
			border-bottom:transparent 15px solid;
			top:50%;
		}
		&-inner{
			background: #eaefe5;
			margin-left: 15px;
			padding:5px 15px 4px 0;
			position: relative;
			z-index: 1;
			&:before{
				content: "";
				display:block;
				width:4px;
				height:4px;
				background: #ffffff;
				border-radius: 100%;
				position: absolute;
				top:50%;
				left:-6px;
				z-index: 1;
				transform:translate(0% ,-50%);
			}
		}
	}
	&-title{
		font-size: 16px;
		font-weight: 700;
		line-height: (28 / 16);
		margin-top: 8px;
	}
	&-text{
		font-size: 13px;
		line-height: 1.8;
		margin-top: 5px;
	}
}



@include breakpoint(sp) {
	.card-thumbnail-book{
		padding:10px;
		display:flex;
		justify-content:space-between;
		&.mod-4col{
			width: 100%;
			&:nth-child(n+5){
				margin-top: 0px;
			}
		}
		&-img{
			width: 100px;
			height: 100px;
			img{
				display: none;
			}
		}
		&-cont{
			padding-left:10px;
			flex:1;
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
		}
		&-date{
			position: static;
			padding-top: 3px;
		}
		&-tag{
			margin-top: 3px;
			display: inline-block;
			height: auto;
			&-inner{
				font-size: 10px;
				padding:2px 10px 2px 0px;
				&:before{
					width:3px;
					height: 3px;
					left:-9px;
				}
			}
		}
		&-title{
			width: 100%;
			line-height: 1.4;
		}
		&-text{
			width: 100%;
			order:3;
		}
	}
}
