.order-list-thumbnail-area {
	.order{
		&-list-thumbnail-table {
			border-top: 1px solid #cdd2d5;
			text-align: center;
			width: 100%;
			td{
				border-right: 1px dashed #cdd2d5;
				border-bottom: 1px solid #cdd2d5;
 				padding: 16px 0;
				vertical-align: middle;
				&:last-of-type{
					border-right: 0px dashed #cdd2d5;
					display: table-cell;
				}
			}
			tr.tit{
				td{
					padding: 7px 0;
					font-size: 13px;
					font-weight: bold;
				}
			}
			tr:last-child {
				td {
					border-bottom: 1px solid $black;
				}
			}
			tr + tr{
				font-size: 14px;
				@extend .font-kreon_r;
			}
			.item{
				width: 330px;
			}
			.count{
				width: 70px;
			}
			.single-price{
				width: 95px;
			}
			.sum-price{
				width: 90px;
			}
			.add-remove{
				width: 70px;
			}
			.item-2col{
				display: flex;
				justify-content: space-between;
				padding: 0 20px;
				line-height: (22 /13);
				p {
					font-size: 13px;
					text-align: left;
					margin-left: 20px;
				}
			}
		}
	}//order
	&_under-text{
		text-align: right;
		font-size: 14px;
		font-weight: bold;
		margin-top: 13px;
		.count-item{
			display: inline-block;
			.label{

			}
			.value{
				margin-left: 14px;
			}
		}
		.count-sum{
			display: inline-block;
			margin-left: 42px;
			.label{

			}
			.value{
				margin-left: 12px;
			}
		}
	}
}
.order-list-caution {
	background-color: rgb(245, 247, 242);
	font-size: 12px;
	color: #ff5f3a;
	padding:11px 12px;
	line-height: (20 / 12);
	margin-left: 20px;
	margin-right: 20px;
	text-align: left;
	margin-top: 10px;
}
@include breakpoint(sp) {
	.order-list-caution {
		margin-left: 0;
		margin-right: 0;
	}
}
