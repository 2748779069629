.title-has-leadtext {
	width: 100%;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	align-items:center;
	&-inner{
		padding:0 40px;
	}
	&-title{
		font-size: 22px;
		letter-spacing: 0.05em;
		text-align: center;
		font-weight: 700;
		line-height: 1.5;
		color:#675f3e;
	}
	&-text{
		text-align: center;
		font-size: 14px;
		color:#68603e;
		margin-top: 5px;
	}
	&-btn{
		position: absolute;
		top:50%;
		right: 0;
		transform:translate(0% ,-50%);
		margin-top: 0 !important;
	}
}
@include breakpoint(sp) {
	.title-has-leadtext {

		&-inner{
			padding:0 25px;
			max-width: 270px;
		}
		&-title{
			font-size: 20px;
		}
		&-text{
			font-size: 13px;
		}
	}
}
