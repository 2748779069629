.slide-entry {
	position: relative;
	width: 980px;
	margin:40px auto 0;
	// background: #ddd;
	&-inner{
		overflow: hidden;
	}
	&-prev,
	&-next{
		top:130px;
		&.swiper-button-disabled{
			display: none;
		}
	}
	&-prev{
		left:-50px;
		background: resolve('arrow_left_swiper_shop.png') center no-repeat;
		background-size: size('arrow_left_swiper_shop.png',2);
		width: width('arrow_left_swiper_shop.png',2);
		height: height('arrow_left_swiper_shop.png',2);
		left:-80px;
	}
	&-next{
		right:-50px;
		background: resolve('arrow_right_swiper_shop.png') center no-repeat;
		background-size: size('arrow_right_swiper_shop.png',2);
		width: width('arrow_right_swiper_shop.png',2);
		height: height('arrow_right_swiper_shop.png',2);
		right:-80px;
	}

	.swiper-wrapper{
		height: auto;
	}
}
@media only screen and ( max-width: 1024px){
	.slide-entry {
		&-prev{
			left:-40px;
		}
		&-next{
			right:-40px;
		}
	}
}
@include breakpoint(sp) {
	.slide-entry {
		margin-top: 20px;
		// width: 820px;
		width: 100%;
		padding-left: 4px;
		&-prev,
		&-next{
			display: none;
		}
	}
}
