.thumbnail-basic-item-wrap {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	justify-content: space-between;
	flex-wrap: wrap;
	&.mod-f_yomimono {
		+ * {
			margin-top: 80px;
		}
		&.mod-news {
			+ * {
				// margin-top: 54px;
			}
			.thumbnail-basic-item {
				// margin-top: 40px;
				padding-bottom: 0;
				width: 225px;
				&-img {
					position: relative;
					height: 0;
					width: 100%;
					padding-top: 100%;
					&.has-icon-new{
						&:before{
							content: "";
							display:block;
							background: resolve('icon-new.png') no-repeat left top;
							background-size:contain;
							width:width('icon-new.png',2);
							height:height('icon-new.png',2);
							position: absolute;
							left: 0;
							top: 0;
						}
					}
				}
				&-label {
					padding: 10px 0 0;
					&-cont {
						text-align: right;
					}
					&-date {
						font-family: "calibri", Garamond, "Comic Sans MS",
							sans-serif;
						position: absolute;
						bottom: -21px;
						left: -18px;
						display: inline-block;
						background-color: #fff;
						text-align: center;
						color: #000;
						padding: 5px 13px 3px 18px;
						line-height: 1.5;
						letter-spacing: 0.09em;
						.date-large {
							font-size: 18px;
							font-size: 21px;
						}
					}
					&-lead {
						color: #000;
						margin-top: 8px;
						font-weight: 700;
						&-tit {
							font-size: 15px;
							line-height: 1.5;
						}
						&-txt {
							font-size: 12px;
							margin-top: 13px;
							letter-spacing: -0.02em;
						}
					}
				}
			}
			& ~ .article-yomimono-entry-line {
				margin-top: 60px;
			}
			&::after {
				width: 225px;
			}
		}
		&.mod-popular {
			+ * {
				// margin-top: 41px;
			}
			.thumbnail-basic-item {
				color: #000;
				width: 135px;
				&:nth-child(-n + 5) {
					margin-top: 0;
				}
				&-label {
					padding-top: 20px;
					padding-left: 0;
					padding-right: 0;

					&-date {
						font-family: "calibri", Garamond, "Comic Sans MS",
							sans-serif;
						margin-top: 10px;
						// padding-left: 4px;
					}
					&-lead {
						margin-top: 7px;
						&-tit {
							font-size: 15px;
							font-weight: 700;
						}
						&-txt {
							font-size: 12px;
							margin-top: 10px;
							line-height: 1.5;
							font-weight: 700;
							display: none;
						}
					}
					&-right {
					}
				}
				&-img {
					position: relative;
					height: 0;
					width: 100%;
					padding-top: 100%;
					&-rank{
						font-family: "calibri", Garamond, "Comic Sans MS",
							sans-serif;
						// font-size: 18px;
						font-size: 16px;
						// content: "01";
						background-color: #fbf558;
						width: 42.5px;
						height: 42.5px;
						display: inline-block;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						text-decoration: underline;
					}
					&.has-icon-new{
						&:before{
							content: "";
							display:block;
							background: resolve('icon-new.png') no-repeat left top;
							background-size:contain;
							width:width('icon-new.png',2);
							height:height('icon-new.png',2);
							position: absolute;
							left: 50px;
							top: 0;
						}
					}
				}
				&._left {
					width: 100%;
					padding-bottom: 0;
				}
			}
			& ~ .article-yomimono-entry-line {
				margin-top: 60px;
			}
			&:after {
				width: 135px;
				order: 1;
			}
			&:before {
				content: "";
				width: 135px;
				height: 0;
				display: block;
				order: 1;
			}
		}
		&.mod-cat {
			margin: 0 auto;
			width: 32%;
			.search-form {
				width: 100%;
			}
			& ~ .article-yomimono-label-items {
				margin-top: 52px;
			}
			&.only_pc {
				display: flex;
			}
			&.only_sp {
				display: none;
			}
		}
		// &.mod-recipe {
		// 	.thumbnail-basic-item {
		// 		width: 48%;
		// 		display: flex;
		// 		justify-content: space-between;
		// 		// align-items: center;
		// 		// align-items: center;
		// 		padding-bottom: 7px;
		// 		&:nth-child(-n + 4) {
		// 			margin-top: 0;
		// 		}
		// 		&-img {
		// 			position: relative;
		// 			height: 0;
		// 			width: 100%;
		// 			padding-top: 100%;
		// 		}
		// 		&-tag {
		// 			&-cont {
		// 				display: flex;
		// 				flex-direction: column;
		// 			}
		// 		}
		// 		&-label {
		// 			// width: 82%;
		// 			width: 70%;
		// 			padding-top: 0;
		// 			padding: 0 0 0 17px;
		// 			&-lead {
		// 				letter-spacing: -0.02em;
		// 				&-txt {
		// 					color: #000;
		// 					font-size: 15px;
		// 					font-weight: 700;
		// 				}
		// 			}
		// 			&-cont {
		// 				display: flex;
		// 				justify-content: space-between;
		// 				margin-top: 14px;
		// 			}
		// 			&-date {
		// 				color: #000;
		// 				font-family: "calibri", Garamond, "Comic Sans MS",
		// 					sans-serif;
		// 				font-size: 12px;
		// 				padding-right: 16px;
		// 			}
		// 		}
		// 		&._left {
		// 			width: 31.56%;
		// 			padding-bottom: 0;
		// 		}
		// 	}
			// &._mod-category {
			// 	.thumbnail-basic-item {
			// 		margin-top: 27px;
			// 		&-img {
			// 			width: 100%;
			// 			padding-top: 100%;
			// 			&._mod-article {
			// 				// width: 107px;
			// 				width: 100%;
			// 				// padding-top: 31.05%;
			// 				padding-top: 100%;
			// 				&._left {
			// 					width: 30%;
			// 				}
			// 			}
			// 		}
			// 		&-label {
			// 			width: 66%;

			// 			&-text {
			// 				color: #666;
			// 				font-size: 12px;
			// 				font-weight: 700;
			// 				margin-top: 6px;
			// 			}
			// 			&-cont {
			// 				margin-top: 0;
			// 			}
			// 			&-lead {
			// 				margin-top: 10px;
			// 			}
			// 			&-date {
			// 				font-size: 13px;
			// 				padding-right: 8px;
			// 				letter-spacing: -0.01em;
			// 			}
			// 		}
			// 		&:nth-child(-n + 4) {
			// 			// margin-top: 28px;
			// 			margin-top: 27px;
			// 		}
			// 		._left {
			// 			width: 31.05%;
			// 		}
			// 		&-tag {
			// 			&-cont {
			// 				width: 33.37%;
			// 			}
			// 			&._add {
			// 				display: flex;
			// 				flex-direction: column;
			// 			}
			// 		}
			// 	}
			// 	&._mod-article {
			// 		& + .thumbnail-basic-item-ttl {
			// 			margin-top: 38px;
			// 		}
			// 		.thumbnail-basic-item {
			// 			margin-top: 5px;
			// 			&:nth-child(-n + 4) {
			// 				margin-top: 0;
			// 				margin-bottom: 5px;
			// 			}
			// 			&-label {
			// 				padding-left: 8px;
			// 				&-date {
			// 					padding-right: 15px;
			// 					letter-spacing: -0.03em;
			// 				}
			// 				&-lead {
			// 					margin-top: 9px;
			// 				}
			// 			}
			// 		}
			// 		&._other {
			// 			.thumbnail-basic-item {
			// 				&-label {
			// 					margin-top: 18px;
			// 					&-lead {
			// 						margin-top: 5px;
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}
			// }
		// }
	}
	&.mod-under-border {
		padding-bottom: 80px;
		position: relative;
		&.has_explain {
			padding-bottom: 100px;
			+ .article-footer:before {
				top: -55px;
				background: resolve(
						"thumbnail-basic-item_under_border_thick.png"
					)
					no-repeat 0 0;
				background-size: size(
					"thumbnail-basic-item_under_border_thick.png",
					2
				);
			}
		}
		+ .article-footer {
			position: relative;
			&-text {
				font-size: 14px;
				line-height: 2;
				color: #4f6537;
			}
			&:before {
				content: ".";
				color: #fff;
				position: absolute;
				bottom: 0px;
				background: resolve("thumbnail-basic-item_under_border.png")
					no-repeat 0 0;
				background-size: size(
					"thumbnail-basic-item_under_border.png",
					2
				);
				width: 100%;
				height: 1px;
				left: 0;
				top: -30px;
			}
		}
	}
	&:after {
		content: "";
		width: 214px;
		height: 0;
		display: block;
	}
}

.thumbnail-basic-item {
	$c: &;
	padding-bottom: 15px;
	width: 214px;
	margin-top: 60px;
	@at-root {
		a#{$c} {
			@include opacity--anim(0.5);
		}
		a.nolink {
			opacity: 1 !important;
		}
	}
	&:nth-child(-n + 3) {
		//上から１２３のみ指定
		margin-top: 0px;
	}
	&.mod-brown {
		border-bottom: solid 1px #c3c0b4;
		position: relative;
		color: #68603e;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 5px 5px;
			border-color: transparent transparent #c3c0b4 transparent;
		}
	}
	&.mod-green {
		border-bottom: solid 1px #c6cfbc;
		position: relative;
		color: #4f6537;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 5px 5px;
			border-color: transparent transparent #c6cfbc transparent;
		}
	}
	&-img {
		position: relative;
		width: 213px;
		height: 114px;
		background-image: url("http://placehold.jp/213x114.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	&-label {
		z-index: 0;
		position: relative;
		padding: 0 11px;
		padding-top: 17px;
		&-num {
			font-size: 12px;
			@extend .font-kreon_r;
		}
		&-date {
			font-size: 12px;
			@extend .font-kreon_r;
		}
		&-lead {
			font-size: 14px;
			//@extend .font-kreon_r;
			margin-top: 3px;
			line-height: 1.7;
			word-wrap: break-word;
		}
	}
	&.mod-2col {
		float: left;
	}
	&.mod-single{
		&:nth-child(n+4){
			display: none;
		}
	}
	// &-ttl {
	// 	font-size: 18px;
	// 	font-weight: 700;
	// 	margin-top: 115px;
	// 	margin-bottom: 0;
	// }
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 1s;
}

@include breakpoint(sp) {
	.thumbnail-basic-item-wrap {
		&.mod-f_yomimono {
			margin-bottom: 90px;
			&:last-of-type {
				margin-bottom: 0;
				padding-bottom: 0;
			}
			&.mod-news {
				// width: 89%;
				margin-bottom: 0;
				.thumbnail-basic-item {
					// width: calc(50% -5px) !important;
					width: 45.93%;
					margin-top: 0;
					margin-bottom: 23px;
					&-img {
						padding-top: 100%;
					}
					&-label {
						display: flex;
						flex-direction: column;
						&-date {
							font-size: 10px;
							left: -13px;
							margin-top: 6px;
							padding: 3px 13px;
							bottom: -11px;
							.date-large {
								font-size: 17px;
								line-height: 1;
								letter-spacing: 0;
							}
						}
						&-lead {
							font-size: 14px;
							margin-top: 2px;
							order: 1;
							&-tit {
								font-size: 14px;
								margin-top: 5px;
							}
							&-txt {
								font-size: 12px;
								margin-top: 6px;
								color: #666666;
								line-height: 1.5;
							}
						}
						&-cont {
							order: 2;
							// float: left;
							text-align: left;
							margin-top: 2px;
						}
					}
				}
				& + * {
					// margin-top: 13px;
				}
				& ~ .article-yomimono-entry-line {
					margin-top: 35px;
				}
			}
			&.mod-popular {
				+ * {
					// margin-top: 26px;
				}
				// width: 90%;
				margin-bottom: 0;
				.thumbnail-basic-item {
					width: 100%;
					display: flex;
					justify-content: space-between;
					margin-bottom: 7px;

					&-img {
						// width: 104px;
						// padding-top: 30.815%;

						&:before {
							font-size: 13.5px;
						}
					}
					&-label {
						width: 68%;
						padding: 0 8px;
						&-date {
							margin-top: 0;
							font-size: 11px;
							letter-spacing: 0.01em;
						}
						&-right {
							line-height: 1;
							display: flex;
							justify-content: space-between;
							align-items: center;
						}
						&-lead {
							padding-left: 5px;
							&-tit {
								font-size: 14px;
							}
							&-txt {
								font-size: 12px;
								margin-top: 0;
								display: block;
							}
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
					&._left {
						width: 30%;
					}
				}
				& ~ .article-yomimono-entry-line {
					margin-top: 36px;
				}
			}
			// &.mod-recipe {
			// 	width: 90%;
			// 	margin-bottom: 20px;
			// 	.thumbnail-basic-item {
			// 		width: 100%;
			// 		margin-bottom: 0;
			// 		&-img {
			// 			// width: 91px;
			// 			// padding-top:26.965%;
			// 		}
			// 		&-label {
			// 			width: 82%;
			// 			&-lead {
			// 				margin-top: 12px;
			// 				&-txt {
			// 					font-size: 13px;
			// 				}
			// 			}
			// 			&-cont {
			// 				margin-top: 0;
			// 			}
			// 			&-date {
			// 				margin-top: 0;
			// 			}
			// 		}
			// 		&-btn {
			// 			&.only_sp {
			// 			}
			// 		}
			// 	}
			// 	& + .thumbnail-basic-item {
			// 		&-btn {
			// 			&.only_sp {
			// 				display: block;
			// 				margin-top: 30px;
			// 			}
			// 		}
			// 	}
			// 	&._mod-category {
			// 		.thumbnail-basic-item {
			// 			margin-top: 15px;
			// 			&-img {
			// 				// width: 107px;
			// 				// padding-top: 31.705%
			// 			}
			// 			&-label {
			// 				&-text {
			// 					font-size: 10px;
			// 					color: #666666;
			// 					width: 95%;
			// 					line-height: 1.4;
			// 				}
			// 				width: 73%;
			// 				&-date {
			// 					font-size: 12px;
			// 				}
			// 			}
			// 			&:nth-child(-n + 4) {
			// 				margin-top: 15px;
			// 			}
			// 		}
			// 		&._mod-article {
			// 			width: 88%;
			// 			.thumbnail-basic-item-label {
			// 				width: 69%;
			// 				padding-left: 17px;
			// 			}
			// 		}
			// 	}
			// }
			&.mod-cat {
				margin: 0 auto;
				// width: 32%;
				width: 65%;
				.search-form {
					width: 100%;
				}
				& ~ .article-yomimono-label-items {
					margin-top: 26px;
				}
				&.only_pc {
					display: none;
				}
				&.only_sp {
					display: flex;
				}
			}
			&.mod-cat {
				& + .article-yomimono-label-items {
					flex-direction: column;
					.article-yomimono-label-item {
						width: 100%;
						.article-yomimono-label-item-link {
							// padding-right: 30px;
							padding-right: 0;
						}
					}
				}
			}
		}
		&.mod-g_yomimono {
			padding-bottom: 80px;
			border-bottom: dotted 1px;
		}
		.btn-half_line {
			font-size: 12px;
			padding: 10px 0;
			width: 70%;
			margin: 0px auto 0px auto;
			display: block;
			&.mod-area-has-map {
				p:after {
					content: "";
					display: inline-block;
					background: resolve("icon_blank@2x.png") no-repeat left top;
					background-size: size("icon_blank@2x.png", 2);
					width: width("icon_blank@2x.png", 2);
					height: height("icon_blank@2x.png", 2);
					margin-left: 6px;
				}
			}
			&.mod-sp {
				display: block;
			}
		}
		&.mod-under-border {
			&.has_explain {
				padding-bottom: 80px;
				+ .article-footer:before {
					top: -60px;
					background: resolve(
							"thumbnail-basic-item_under_border_thick.png"
						)
						no-repeat 0 0;
					background-size: size(
						"thumbnail-basic-item_under_border_thick.png",
						2
					);
				}
			}
			padding-bottom: 50px;
			position: relative;
			&:after {
				content: ".";
				color: #fff;
				position: absolute;
				bottom: 0px;
				background: resolve("thumbnail-basic-item_under_border.png")
					no-repeat 0 0;
				background-size: size(
					"thumbnail-basic-item_under_border.png",
					2
				);
				width: 100%;
			}
		}
		&:after {
			display: none;
		}
	} //thumbnail-basic-item-wrap

	.thumbnail-basic-item {
		padding-bottom: 10px;
		width: (275 / 560) * 100%;
		margin-bottom: 30px;
		margin-top: 0px;
		&:nth-child(-n + 2) {
			margin-top: 0px;
		}
		&:last-of-type {
			margin-right: 0px;
			//margin-bottom: 0px;
		}
		&.mod-odd {
			border-bottom: solid 1px #c3c0b4;
			position: relative;
			color: #68603e;
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 5px 5px;
				border-color: transparent transparent #c3c0b4 transparent;
			}
		}
		&.mod-even {
			border-bottom: solid 1px #c6cfbc;
			position: relative;
			color: #4f6537;
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 5px 5px;
				border-color: transparent transparent #c6cfbc transparent;
			}
		}
		&-img {
			width: 100%;
			height: 0px;
			padding-top: 58.909%;
			position: relative;
			z-index: 2;
			position: relative;
		}
		&-label {
			margin-left: 0px;
			z-index: 2;
			position: relative;
			padding: 0px;
			&-date {
				font-size: 13px;
				margin-top: 6px;
			}
			&-num {
				font-size: 12px;
				margin-top: 10px;
			}
			&-lead {
				font-size: 14px;
				margin-top: 2px;
			}
		}

		&.mod-single{
			&:nth-child(n+4){
				display: block;
			}
		}
	}
}
